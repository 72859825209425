<template>
  <div class="login-wrapper">
    <form class="login-form" @submit="login">
      <label>Welcome back</label>
      <input id="email" type="email" placeholder="Email" v-model="user.email" required/>
      <input id="password" type="password" placeholder="Password" v-model="user.password" required/>
      <button id="login" type="submit" class="btn btn-primary">Log In</button>
    </form>
    <hr />
    <router-link to="register" class="btn btn-secondary">Create Account</router-link>
    <router-link to="forgot" class="btn btn-secondary">Forgot Password</router-link>
  </div>
</template>


<script>
  import Axios from 'axios'

  export default {
    name: 'home',
    data () {
      return {
        user: {
          email: '',
          password: ''
        }
      }
    },
    methods: {
      login (evt) {
        evt.preventDefault()

        this.$http.post('/auth/login', {user: this.user})
          .then((res) => {
            if (res.success) {
              localStorage.setItem('token', res.data.token)
              localStorage.setItem('permissions', res.data.permissions)
              localStorage.setItem('videoPermission', res.data.videoPermission)
              localStorage.setItem('user', res.data.user)

              this.$notify({
                group: 'notifications',
                title: 'Welcome to our amazing platform :)',
                text: '',
                type: 'success'
              })
              // Set Authorization Header globally
              // Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
              this.$router.push(`/admin/${res.data.permissions[0]}`)
            }
          })
          .catch((err) => {
            console.log(err.response)
            this.$notify({
              group: 'notifications',
              title: err.response.status === 401 ? err.response.data.message : 'There was a problem logging you in. Please try again :(',
              text: '',
              type: 'error'
            })
          })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .login-wrapper label{
    color: #2c3e50;
    text-transform: uppercase;
    font-weight: 900
  }

  .login-wrapper .login-form input{
    display: block;
    width: 100%;
    height: 45px;
    padding: 0 10px;
    margin-bottom: 15px;
    border: 1px solid #eaeaea;
  }

  .login-wrapper .login-form a{
    color: #fff;
    display: block;
    padding: 10px;
    text-align: center;
    margin-bottom: 15px;
    background-color: #f2494a;
  }

  .login-wrapper .login-form a:hover{
    background-color: #de4242;
    text-decoration: none;
  }

  .login-wrapper .register{
    text-align: center;
  }

  .login-wrapper .register a{
    color: #f2494a;
    font-size: 12px;
  }

  .login-wrapper .register.forgot a{
    font-size: 10px;
  }

  .btn {
    color: #fff;
    display: block;
    padding: 10px;
    text-align: center;
    margin-bottom: 15px;
    border: none;
    border-radius: 0;
    width: 100%;

    &.btn-primary {
      background-color: #f2494a;
    }
  }

  .login-form button:hover,
  .login-form button:active,
  .login-form button:active:focus{
    background-color: #de4242 !important;
    border: none !important;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus{
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

</style>
