<template>
  <div class="artist-edit-component">
    <div class="artist-edit-content">

      <div class="row align-items-center header">
        <div class="col-6">
          <div class="title">
            <router-link to="/admin/artists">Artists </router-link>/
            <span v-if="artist && artist.name">{{artist.name}}</span>
          </div>
        </div>
        <div class="col-6">
          <div class="typeahead-wrapper">
            <button type="submit" form="videoForm" class="typeahead-btn">Update</button>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row" v-if="artist">

          <div class="col-sm-12 col-md-3">
            <div class="artist-image">
              <img :src="artist.image" alt="" v-if="artist.image && artist.image !== ''"/>

              <div class="loading isLoadingImage" v-if="isLoadingImage">
                <div class="content-wrapper">
                  <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                  </div>
                </div>
              </div>
            </div>
            <b-form-file class="artist-file" v-model="artistImage" @change.native="uploadImage($event)" :state="Boolean(artistImage)" placeholder="Image 800 x 800px"></b-form-file>
          </div>

          <div class="col-sm-12 col-md-9">
            <div class="artist-info">
              <div class="general">

                <b-form @submit="save" id="videoForm" class="bootstrap-form">

                  <b-form-row>
                    <b-col sm="12" md="6">
                      <strong class="video-labels">Name <strong class='required'>*</strong></strong>
                      <b-form-group>
                        <b-form-input id="Name"
                                      type="text"
                                      required
                                      v-model="artist.name"
                                      placeholder="Add name...">
                        </b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col sm="12" md="6">
                      <b-form-group label="Musicbrainz ID">
                        <b-input-group>
                          <b-form-input id="Musicbrainz"
                                        type="text"
                                        :disabled="true"
                                        v-model="artist.Musicbrainz"
                                        placeholder="Add Musicbrainz ID...">
                          </b-form-input>
                          <b-input-group-append>
                            <b-btn class="typeahead-btn" variant="outline-success" @click="generateMusicBrainzID">Generate</b-btn>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <b-form-row>
                    <b-col>
                      <b-form-group label="Slug">
                        <b-input-group>
                          <b-form-input id="slug"
                                        type="text"
                                        required
                                        v-model="artist.slug">
                          </b-form-input>
                          <b-input-group-append>
                            <b-btn class="typeahead-btn" variant="outline-success" @click="generateArtistSlug">Generate</b-btn>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <b-form-row>
                    <b-col>
                      <b-form-group label="Description">
                        <b-input-group>
                          <vue-editor v-model="artist.html" :editorToolbar="customToolbar"></vue-editor>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                </b-form>

              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>


<script>
import { VueEditor } from 'vue2-editor'
export default {
  name: 'ArtistsEdit',
  components: {
    VueEditor
  },
  data () {
    return {
      artist: {
        _id: '',
        name: '',
        Musicbrainz: null,
        '7digital': null,
        Senzari: null,
        image: null,
        slug: null,
        html: null
      },
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote'],
        [{ 'header': 1 }, { 'header': 2 }], // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'align': [] }]
      ],
      formData: new FormData(),
      artistImage: null,
      isLoadingImage: false
    }
  },

  mounted () {
    this.artistID = this.$route.params.id

    this.$http.get(`/artists/${this.artistID}`)
      .then((res) => {
        this.artist = res.data
        this.artist.image = this.artist.image || 'static/img/LaMusica-Icon-artist-placeholder.png'
        this.artist.description = this.artist.description || ''
      })
      .catch((err) => {
        console.log(err)
      })
  },

  methods: {
    save (evt) {
      evt.preventDefault()
      this.$http.put('/artists', {artist: this.artist})
        .then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Artist successfully created :)',
              text: '',
              type: 'success'
            })
            this.$router.push('/admin/artists')
          } else {
            this.$notify({
              group: 'notifications',
              title: res.message,
              text: '',
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            group: 'notifications',
            title: 'Sorry, something went wrong :(',
            text: '',
            type: 'error'
          })
        })
    },
    uploadImage (event) {
      let input = event.target

      if (input.files && input.files[0]) {
        if (/\.(jpe?g|png)$/i.test(input.files[0].name)) {
          let reader = new FileReader()
          reader.readAsDataURL(input.files[0])

          reader.onload = (e) => {
            const URL = window.URL || window.webkitURL
            const image = new Image()
            image.src = URL.createObjectURL(input.files[0])
            image.onload = () => {
              let height = image.height
              let width = image.width

              if (height !== 800 || width !== 800) {
                this.$notify({
                  group: 'notifications',
                  title: 'Image size allowed 800 x 800px :(',
                  text: '',
                  type: 'error'
                })

                return false
              } else {
                this.formData = new FormData()
                this.formData.append('image', input.files[0])
                this.isLoadingImage = true

                this.$http.post('/artists/image',
                  this.formData,
                  {
                    headers:
                                                {
                                                  'Content-Type': 'multipart/form-data'
                                                }
                  }
                )
                  .then((res) => {
                    if (res.success) {
                      console.log(res.data)
                      this.artistImage = res.data[0]
                      this.artist.image = res.data[0]

                      this.$notify({
                        group: 'notifications',
                        title: 'Image uplodaded successfully :)',
                        text: '',
                        type: 'success'
                      })
                    } else {
                      this.$notify({
                        group: 'notifications',
                        title: `${res.message} :(`,
                        text: '',
                        type: 'error'
                      })
                    }

                    this.clearFormData(this.formData)
                  }).catch((err) => {
                    console.log(err)
                    this.clearFormData(this.formData)
                  })
              }
            }
          }
        } else {
          this.$notify({
            group: 'notifications',
            title: 'Image file should be JPG or PNG format :(',
            text: '',
            type: 'error'
          })
        }
      }
    },
    clearFormData () {
      this.formData = new FormData()
      this.isLoadingImage = false
    },
    generateMusicBrainzID () {},
    generateArtistSlug () {
      if (!this.artist.name || this.artist.name === '') {
        this.$notify({
          group: 'notifications',
          title: 'Artist name is required in order to generate an slug',
          text: '',
          type: 'error'
        })
        return
      }
      this.$http.post('/artists/slug', {
        string: this.artist.name
      }).then((response) => {
        if (response.is_unique) {
          this.$notify({
            group: 'notifications',
            title: 'Unique slug generated successfully',
            text: '',
            type: 'success'
          })
          this.artist.slug = response.slug
        } else {
          this.artist.slug = ''
          this.$notify({
            group: 'notifications',
            title: 'Another Artist already has the same generated slug, please write your own',
            text: '',
            type: 'error'
          })
        }
      })
    }
  }
}
</script>

<style>
  .ql-snow {
    border: 1px solid #ccc;
    background: white;
  }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .quillWrapper {
    width: 100%;
  }

  .artist-edit-component{
    position: relative;
  }

  .typeahead-wrapper {
    justify-content: flex-end;
  }

  .artist-edit-component .artist-edit-content{
    z-index: 1;
    position: relative;
  }

  .artist-edit-component .header{
    margin-bottom: 30px;
  }

  .artist-image{
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border: 1px solid #eaeaea;
    margin-bottom: 15px;
    position: relative;
  }

  .artist-image img{
    width: 100%
  }

  .artist-edit-component .controls-wrapper {
    margin-bottom: 30px;
  }

  .artist-info {
    font-weight: bold;
    height: 100%;
    display: flex;
    flex-direction: column;

    .col-form-label {
      text-transform: uppercase;
    }

    .general{
      .title {
        font-size: 14px;
        margin: 0;
        text-transform: capitalize;
      }
    }

  }

  .isLoadingImage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
  }

  .content-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .content-wrapper .spinner {
    margin: 0 auto;
    width: 50px;
    height: 40px;
  }

  .content-wrapper .spinner > div {
    background-color: #f2494a;
    height: 100%;
    width: 6px;
    display: inline-block;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .content-wrapper .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .content-wrapper.spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .content-wrapper .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .content-wrapper .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
  }

  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }  20% {
         transform: scaleY(1.0);
         -webkit-transform: scaleY(1.0);
       }
  }

  @media (max-width: 768px) {
    .artist-file {
      margin-bottom: 20px;
    }
  }

</style>
