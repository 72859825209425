<template>
  <div class="media-all-component">

    <div class="page-header">
      <div class="typeahead-wrapper">
        <div class="typeahead">
          <input id="input" class="form-control" type="text" placeholder="Search Podcasts" v-model="query"  @keyup.13="search">
          <div class="btn btn-search" @click="search">
            <i class="fas fa-search"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="media-all-list">
        <transition-group tag="div" class="row" name="list-animation">
          <div class="col-md-4" v-for="(track, index) in tracks" v-bind:key="track._id">
            <div class="media-card">
              <div class="track-img" @click="selectMedia(track)">
                <img :src="$apiUrl + '/media/artworks/' + track.image + '.jpg'" alt="" v-if="track.image"/>
                <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!track.image"/>
              </div>
              <div class="track-controls">
                <div class="icon-wrapper" @click="toggle(index)">
                  <i class="fas fa-play" v-if="!track.playing"></i>
                  <i class="fas fa-pause" v-if="track.playing"></i>
                </div>

                <div class="player">
                  <div class="track-title">{{track.title}}</div>
                  <div class="track-duration">{{secondsToMinutes(track.duration)}}</div>
                </div>

                <div class="wavesurfer" :id="'wavesurfer' + index"></div>

                <div class="submenu-actions">
                  <div class="icon">
                    <i class="fas fa-ellipsis-h"></i>
                  </div>
                  <div class="actions">
                    <ul>
                      <li>Copy</li>
                      <li @click="addToPlaylist(track)">To Playlist</li>
                      <li @click="confirm(track, index)">Delete</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>

      <b-pagination v-if="pages > 1 && displayPaging" align="right" size="md" :total-rows="limit * pages" v-model="current" :per-page="limit" @change="getResults"></b-pagination>
    </div>

    <b-modal id="addToplaylist" ref="addToplaylist" :ok-only="true" ok-title="Add" @ok="saveToPlaylist" size="md" title="Select Playlist">
      <div class="row modal-wrapper">
        <div class="col-12">
          <input type="text" placeholder="Find Playlist..." v-model="queryPlaylist" />
        </div>
        <div class="col-12">
          <ul class="playlistUpdated-list">
            <li v-for="playlist in queryResults" v-bind:key="playlist._id" @click="selectPlaylist(playlist)" v-bind:class="{selected: playlist.selected}">
              <div class="playlists-list-card-img">
                <img :src="playlist.meta.exploreImagery.hdpiImageURL" alt="" v-if="playlist.meta && playlist.meta.exploreImagery && playlist.meta.exploreImagery.hdpiImageURL" />
                <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!playlist.meta || !playlist.meta.exploreImagery || !playlist.meta.exploreImagery.hdpiImageURL"/>
              </div>
              <div class="playlist-info">
                <div class="playlist-title">{{playlist.title}}</div>
                <div class="playlist-tracks">{{playlist.tracks.length}} Tracks</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-modal>

    <b-modal id="confirmRemovePodcast" ref="confirmRemovePodcast" size="md" title="Confirm" @ok="handleRemove(toDelete)">
      <div class="row modal-wrapper">
        <div class="col-12">
          Are you sure want to remove <strong>{{toDelete.track.title}}</strong> podcast?
        </div>
      </div>
    </b-modal>
  </div>
</template>


<script>
    import typeahead from '../../share/typeahead'
    import WaveSurfer from 'wavesurfer.js'

    export default {
      name: 'PodcastsAll',
      components: {
        typeahead
      },
      data () {
        return {
          tracks: [],
          limit: 60,
          pages: 1,
          current: 1,
          playlists: [],
          playlistSelected: null,
          trackToAdd: null,
          query: '',
          displayPaging: true,
          queryPlaylist: '',
          toDelete: {
            index: 0,
            track: ''
          }
        }
      },
      computed: {
        queryResults () {
          return this.playlists.filter(playlist => {
            return playlist.title.toLowerCase().includes(this.queryPlaylist.toLowerCase())
          })
        }
      },
      mounted () {
        this.$http.get('/media', {params: {type: 'podcasts', limit: this.limit, page: this.pages}})
          .then((res) => {
            this.tracks = res.data.media
            this.pages = res.data.pages
            this.current = res.data.current
            this.tracks.forEach((track, index) => {
              this.$set(track, 'playing', false)
            })
          })
          .catch((err) => {
            console.log(err)
          })
      },
      methods: {
        confirm (track, index) {
          this.$refs.confirmRemovePodcast.show()
          this.toDelete = {
            index: index,
            track: track
          }
        },
        handleRemove (toDelete) {
          this.$http.delete('/media', {params: {mediaID: toDelete.track._id}})
            .then((res) => {
              this.$notify({
                group: 'notifications',
                title: 'Media successfully removed :(',
                text: '',
                type: 'success'
              })
              this.tracks.splice(toDelete.index, 1)
            })
            .catch((err) => {
              console.log(err)
            })
        },
        search () {
          if (this.query === '') {
            this.pages = 1
            this.$http.get('/media', {params: {limit: this.limit, page: this.pages}})
              .then((res) => {
                this.displayPaging = true
                this.tracks = res.data.media
                this.pages = res.data.pages
                this.current = res.data.current
                this.tracks.forEach((track, index) => {
                  this.$set(track, 'playing', false)
                })
              })
              .catch((err) => {
                console.log(err)
              })
            return
          }
          this.$http.get(`/media/search?q=${this.query}`)
            .then((res) => {
              this.tracks = res.data
              this.tracks.forEach((track, index) => {
                this.$set(track, 'playing', false)
              })
              this.displayPaging = false
            })
            .catch((err) => {
              console.log(err)
            })
        },
        addToPlaylist (track) {
          EventHandler.emit('isLoading', true)
          this.$http.get('/playlists', {params: {limit: 0, page: 1}})
            .then((res) => {
              this.playlists = res.data.playlists
              this.trackToAdd = track
              EventHandler.emit('isLoading', false)
              this.$refs.addToplaylist.show()
            })
            .catch((err) => {
              console.log(err)
            })
        },
        selectPlaylist (playlist, index) {
          if (playlist.selected) {
            playlist.selected = false
          } else {
            this.playlists.forEach((item) => {
              if (item.selected) {
                item.selected = false
              } else {
                this.$set(item, 'selected', false)
              }
            })
            this.$set(playlist, 'selected', true)
            this.playlistSelected = playlist
          }
        },
        saveToPlaylist () {
          if (!this.playlistSelected) {
            this.$notify({
              group: 'notifications',
              title: 'No Playlist selected :(',
              text: '',
              type: 'error'
            })

            return
          }
          this.playlistSelected.tracks.unshift({order: 0, trackId: this.trackToAdd._id})
          this.playlists.forEach((item) => {
            delete item.selected
          })
          // this.playlistSelected.published = false
          this.$http.put('/playlists', {playlist: this.playlistSelected})
            .then((res) => {
              this.$notify({
                group: 'notifications',
                title: 'Track added successfully :)',
                text: '',
                type: 'success'
              })
            })
            .catch((err) => {
              console.log(err)
            })
        },
        selectMedia (track) {
          this.searchList = []
          this.$router.push('edit/' + track._id)
        },
        getResults (page) {
          this.$http.get('/media', {params: {type: 'podcasts', limit: this.limit, page: page}})
            .then((res) => {
              this.tracks = res.data.media
              this.pages = res.data.pages
              this.current = res.data.current
              this.tracks.forEach((track, index) => {
                this.$set(track, 'playing', false)
              })
            })
            .catch((err) => {
              console.log(err)
            })
        },
        toggle (index) {
          this.tracks.forEach((track, i) => {
            if (track.playing && index !== i) {
              track.playing = false
              this.wavesurfer.pause()
              this.wavesurfer.empty()
              this.wavesurfer.destroy()
            }
          })

          if (this.tracks[index].playing) {
            this.tracks[index].playing = false
            this.wavesurfer.pause()
            this.wavesurfer.empty()
            this.wavesurfer.destroy()
          } else {
            this.wavesurfer = WaveSurfer.create({
              container: `#wavesurfer${index}`,
              height: '60',
              backend: 'MediaElement',
              waveColor: '#FD8283',
              progressColor: '#f2494a',
              responsive: true,
              barWidth: 2
            })

            this.wavesurfer.load(`${this.$apiUrl}/media/tracks/${this.tracks[index]._id}.m4a`)
            this.wavesurfer.play()

            this.tracks[index].playing = true
          }
        },
        // TODO move to a helper file
        secondsToMinutes (secs) {
          let secNum = parseInt(secs, 10)
          let hours = Math.floor(secNum / 3600) % 24
          let minutes = Math.floor(secNum / 60) % 60
          let seconds = secNum % 60
          return [hours, minutes, seconds]
            .map(v => v < 10 ? '0' + v : v)
            .filter((v, i) => v !== '00' || i > 0)
            .join(':')
        }
      }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .media-all-component .submenu-actions {
    margin: 0 10px;
  }

  .media-all-list {
    padding: 5px 0;
    margin-bottom: 25px;
  }

  .media-all-list .media-card {
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 93px;
    margin-bottom: 10px;
    border: 1px solid #eaeaea;
  }

  .media-all-list .media-card img {
    width: 60px;
    cursor: pointer;
  }

  .media-all-list .media-card .track-controls{
    display: flex;
    flex: 1;
    align-items: center;
  }

  .media-all-list .media-card .track-controls .icon-wrapper, .media-all-list .media-card .track-controls .remove{
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
  }

  .media-all-list .media-card .track-controls .remove{
    border-left: 1px solid #eaeaea;
  }

  .media-all-list .media-card .track-controls .player{
    margin-right: 15px;
  }

  .wavesurfer{
    flex: 1;
  }

  .wavesurfer canvas{
    max-width: none;
  }

  ul.playlistUpdated-list{
    list-style-type: none;
    overflow-y: scroll;
    max-height: 400px;
    padding: 3px;
  }

  ul.playlistUpdated-list .playlist-title{
    font-size: 12px;
    font-weight: bold;
  }

  ul.playlistUpdated-list .playlist-tracks{
    font-size: 10px;
    font-weight: bold;
  }

  ul.playlistUpdated-list li {
    list-style-type: none;
    display: flex;
    background-color: #fff;
    margin-bottom: 10px;
    height: 60px;
    align-items: center;
    border: 1px solid #eaeaea;
    cursor: pointer;
  }

  ul.playlistUpdated-list li.selected {
    background-color: #eaeaea;
  }

  ul.playlistUpdated-list li .playlists-list-card-img {
    margin-right: 15px;
  }

  ul.playlistUpdated-list li .playlists-list-card-img img{
    height: 60px;
    width: auto;
  }

  .btn-search{
    height: 45px;
    background: #f2494a;
    border-radius: 0;
    color: #fff;
    line-height: 32px;
  }

</style>
