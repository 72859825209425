import Axios from 'axios'

export default class Http {
  constructor () {
    this.url = process.env.VUE_APP_ROOT_API
    Axios.interceptors.response.use(this.handleResponse, this.handleError)
    Axios.interceptors.request.use((config) => {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
      return config
    })
  }

  getURL (endpoint) {
    let url = null

    if (endpoint.indexOf('http') === 0) {
      url = endpoint
    } else {
      url = this.url + endpoint
    }
    return url
  }

  get (endPoint, params) {
    return Axios.get(this.getURL(endPoint), params).then((res) => res.data)
  }

  post (endPoint, data, config) {
    return Axios.post(this.getURL(endPoint), data, config).then((res) => res.data)
  }

  put (endPoint, data) {
    return Axios.put(this.getURL(endPoint), data).then((res) => res.data)
  }

  patch (endPoint, data) {
    return Axios.patch(this.getURL(endPoint), data).then((res) => res.data)
  }

  delete (endPoint, params) {
    return Axios.delete(this.getURL(endPoint), params).then((res) => res.data)
  }

  handleResponse (response) {
    return response
  }

  handleError (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('copiedTracks')
      localStorage.removeItem('token')
      localStorage.removeItem('permissions')
      localStorage.removeItem('videoPermission')
      localStorage.removeItem('user')

      if (window.location.pathname !== '/') {
        window.location.reload()
      }
    }

    return Promise.reject(error)
  }
}
