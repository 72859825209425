<template>
  <div class="assets-component">
    <div class="page-header">
      <div class="typeahead-wrapper">
        <div class="typeahead">
          <input id="input" class="form-control" type="text" v-bind:placeholder="labels.search">
          <div class="btn btn-search">
            <i class="fas fa-search"></i>
          </div>
        </div>
        <div class="add-btn">{{ labels.upload }}</div>
      </div>

      <div class="controls-wrapper">
        <ul>
          <li class="asset-types-navigation" v-for="assetType in assetTypes" :key="assetType.type"
              :class="{active: activeAssetType === assetType.type}"
          >
            <router-link :to="'?type=' + assetType.type">
              {{ assetType.label }}
            </router-link>
          </li>
        </ul>
      </div>

    </div>
    <div class="page-content">
      <div class="assets-list">
        <assets-videos v-if="activeAssetType && activeAssetType.type == 'videos'"></assets-videos>
      </div>
    </div>
  </div>
</template>

<script>
import AssetsVideos from './videos/AssetsVideos'

export default {
  name: 'AssetsManagement',
  components: {AssetsVideos},
  data () {
    return {
      assetTypes: [
        {
          label: 'Videos',
          type: 'videos',
          searchLabel: 'Search videos',
          uploadLabel: 'Upload video'
        },
        {
          label: 'Podcasts',
          type: 'podcasts',
          searchLabel: 'Search podcasts',
          uploadLabel: 'Upload podcast'
        },
        {
          label: 'Images',
          type: 'images',
          searchLabel: 'Search images',
          uploadLabel: 'Upload image'
        }
      ],
      activeAssetType: null,
      labels: {
        search: 'Search',
        upload: 'Upload'
      }
    }
  },
  mounted () {
    const getType = new URLSearchParams(window.location.search).get('type') || 'videos'

    const types = this.assetTypes.filter((item) => {
      return item.type == getType
    })

    if (types.length > 0) {
      this.activeAssetType = types[0]
      this.labels.search = this.activeAssetType.searchLabel
      this.labels.upload = this.activeAssetType.uploadLabel
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.assets-component {
  .page-content {
    margin-top: 130px;
  }

  .controls-wrapper {
    ul {
      max-width: none;
      margin-bottom: 0 !important;
      margin-top: 20px;

      li {
        padding: 0;
      }

      a {
        color: #2c3e50;
        padding: 5px 30px;
        display: block;

        &:hover {
          text-decoration: none;
        }
      }

      .asset-types-navigation {
        &.active {
          background-color: #f2494a;

          a {
            color: #fff;
          }
        }
      }
    }
  }

  .btn-search {
    height: 45px;
    background: #f2494a;
    border-radius: 0;
    color: #fff;
    line-height: 32px;
  }
}
</style>
