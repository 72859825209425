<template>
  <div class="builder-all-component">
    <div class="page-header">
      <div class="typeahead-wrapper">
        <div class="typeahead">
          <input id="input" class="form-control" type="text" placeholder="Find Location..." v-model="query"  @keyup.13="search">
          <div class="btn btn-search" @click="search">
            <i class="fas fa-search"></i>
          </div>
        </div>
        <div class="add-btn" @click="addLocation">Add Location</div>
      </div>
    </div>

    <div class="page-content">
      <div class="builder-all-list">
        <transition-group tag="div" class="row" name="list-animation">
          <div class="col-md-6" v-for="(location, index) in locations" v-bind:key="location._id">
            <div class="location-card" :class="{published: location.published || location.type === 'web'}">
              <router-link class="image-location" :to="'locations/edit/' + location._id">
                <img :src="location.featuredCard" alt="" />
              </router-link>

              <div class="location-info">
                <div class="location-regionName" @click="navigate(location._id)">
                  {{location.regionName}}<br/>
                  <span class="number-of-featured-cards">{{location.rows[0].items.length}} featured cards</span>
                </div>
                <div class="location-lastUpdated">
                  Last Updated: <span>{{formatUpdatedAt(location.updatedAt)}}<br/></span>
                  <span class="location-lastUpdatedAgo">{{formatUpdatedAtAgo(location.updatedAt)}}</span>
                </div>
                <div class="submenu-actions">
                  <div class="icon">
                    <i class="fas fa-ellipsis-h"></i>
                  </div>
                  <div class="actions">
                    <ul>
                      <li @click="copyId(location._id)">Copy Location Id</li>
                      <li @click="navigate(location._id)">Edit</li>
                      <li @click="confirm(location, index)">Delete</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>

    <b-modal id="addLocation" ref="addLocation" ok-title="Create" size="md" title="Location Details" @ok="create()">
      <div class="row modal-wrapper">
        <div class="col-12">
          <input type="text" placeholder="Region Name" v-model="location.regionName"/>
        </div>
      </div>
      <div class="row modal-wrapper">
        <div class="col-12">
          <select v-model="location.type">
            <option v-for="(type, index) in locationTypes" v-bind:key="index" :value="type.value">
              {{type.label}}
            </option>
          </select>
        </div>
      </div>
      <div class="row modal-wrapper">
        <div class="col-4">
          <label>Latitude</label>
          <input type="number" placeholder="Latitude" v-model="location.lat"/>
        </div>
        <div class="col-4">
          <label>Longitude</label>
          <input type="number" placeholder="Longitude" v-model="location.long"/>
        </div>
        <div class="col-4">
          <label>Radius (Kilometers)</label>
          <input type="number" placeholder="Radius" v-model="location.radius"/>
        </div>
      </div>
      <div class="row modal-wrapper">
        <div class="col-12">
          <input type="text" placeholder="States" v-model="location.states"/>
          <div class="note">* List all States separate by comma</div>
        </div>
      </div>

      <div class="row modal-wrapper">
        <div class="col-12">
          <select v-model="selectedLayout">
            <option value="">Select layout to duplicate</option>
            <option v-for="location in locations" v-bind:key="location._id" :value="location._id">
              {{location.regionName}}
            </option>
          </select>
        </div>
      </div>

    </b-modal>

    <b-modal id="confirmRemoveLocation" ref="confirmRemoveLocation" size="md" title="Confirm" @ok="handleRemove(toDelete)">
      <div class="row modal-wrapper">
        <div class="col-12">
          Are you sure want to remove <strong>{{toDelete.location.regionName}}</strong> location?
        </div>
      </div>
    </b-modal>

  </div>
</template>


<script>
  import moment from 'moment'

  export default {
    name: 'Builder',
    data () {
      return {
        query: '',
        locations: [],
        location: {
          regionName: null,
          lat: null,
          long: null,
          states: null,
          type: '',
          rows: [
            {
              type: 'cards',
              titleEnglish: 'Featured Cards',
              titleSpanish: 'Cards Destacados',
              subtitleEnglish: '',
              subtitleSpanish: '',
              automated: 'none',
              displayTitles: true,
              items: [],
              shuffle: false
            },
            {
              type: 'circular',
              titleEnglish: 'Stations Near You',
              titleSpanish: 'Estaciones Cerca a Tí',
              subtitleEnglish: '',
              subtitleSpanish: '',
              automated: 'none',
              displayTitles: true,
              items: [],
              shuffle: false
            },
            {
              type: 'card',
              titleEnglish: 'English Title',
              titleSpanish: 'Título en Español',
              subtitleEnglish: '',
              subtitleSpanish: '',
              automated: 'automated_live_radio_shows',
              displayTitles: true,
              items: [],
              shuffle: false
            }
          ]
        },
        selectedLayout: '',
        selectedType: '',
        toDelete: {
          index: 0,
          location: ''
        },
        locationTypes: [
          {
            label: 'Select location type',
            value: ''
          },
          {
            label: 'Default',
            value: 'default'
          },
          {
            label: 'Web',
            value: 'web'
          }
        ],
        hasWeb: false
      }
    },
    computed: {},
    mounted () {
      let locations
      this.$http.get(`/locations`).then((res) => {
        locations = res.data
        return this.$http.get('/locations/all/featured_cards')
      }).then((res) => {
        console.log(res)
        locations = locations.map((location) => {
          if (location.type === 'web') {
            this.hasWeb = true
          }
          location.featuredCard = res.data[location._id]
          return location
        })
        this.locations = locations

        if (!this.hasWeb) {
          this.locationTypes.push({
            label: 'Web',
            value: 'web'
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    methods: {
      formatUpdatedAt (date) {
        if (!date) {
          return 'N/A'
        }
        return moment(date).format('dddd, MMMM Do, h:mm a')
      },
      formatUpdatedAtAgo (date) {
        if (!date) {
          return 'N/A'
        }
        return moment(date).fromNow()
      },
      create () {
        let toDuplicate = {}

        if (this.selectedLayout !== '') {
          toDuplicate = this.locations.filter((loc) => {
            return loc._id === this.selectedLayout
          })
          this.location.rows = toDuplicate[0].rows
        }

        const locationNumericalProperties = ['lat', 'long', 'radius']

        for (let i = 0; i < locationNumericalProperties.length; i++) {
          const prop = locationNumericalProperties[i]

          if (parseFloat(this.location[prop])) {
            this.location[prop] = parseFloat(this.location[prop])
          } else {
            return alert(prop + ' is not a number, please edit and try again')
          }
        }

        if (this.location.type === '') {
          return alert('Location type is required')
        }

        if (this.location.type === 'web') {
          this.location.rows[0].type = 'cards'
          this.location.rows[0].items_desktop_count = 1
          this.location.rows[0].items_mobile_count = 1
          this.location.rows[0].image_type = 'landscape'

          this.location.rows[1].type = 'carousel'
          this.location.rows[1].items_desktop_count = 7
          this.location.rows[1].items_mobile_count = 2
          this.location.rows[1].image_type = 'square'

          this.location.rows[2].type = 'carousel'
          this.location.rows[2].items_desktop_count = 2
          this.location.rows[2].items_mobile_count = 1
          this.location.rows[2].image_type = 'landscape'
        }

        this.$http.post('/locations', {location: this.location})
          .then((res) => {
            this.$notify({
              group: 'notifications',
              title: `Location created successfully :)`,
              text: '',
              type: 'success'
            })
            this.$router.push('/admin/locations/edit/' + res.data.id)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$notify({
                group: 'notifications',
                title: `${err.response.data} :(`,
                text: '',
                type: 'error'
              })
            } else {
              console.log(err.response)
            }
          })
      },
      addLocation () {
        this.$refs.addLocation.show()
      },
      search () {
        if (this.query !== '') {
          this.$http.get(`/locations/search?q=${this.query}`)
            .then((res) => {
              this.locations = res.data
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          this.$http.get(`/locations`)
            .then((res) => {
              this.locations = res.data
            })
            .catch((err) => {
              console.log(err)
            })
        }
      },
      copyId(id){
        navigator.clipboard.writeText(id)
        this.$notify({
              group: 'notifications',
              title: 'Copied to clipboard',
              text: `id: ${id}`,
              type: 'success'
            })
      },
      navigate (id) {
        this.$router.push('locations/edit/' + id)
      },
      confirm (location, index) {
        this.$refs.confirmRemoveLocation.show()
        this.toDelete = {
          index: index,
          location: location
        }
      },
      handleRemove (toDelete) {
        this.$http.delete(`/locations/${toDelete.location._id}`)
          .then((res) => {
            this.$notify({
              group: 'notifications',
              title: 'Location successfully removed :(',
              text: '',
              type: 'success'
            })
            if (toDelete.location.type === 'web') {
              this.locationTypes.push({
                label: 'Web',
                value: 'web'
              })
            }
            this.locations.splice(toDelete.index, 1)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$notify({
                group: 'notifications',
                title: `${err.response.data} :(`,
                text: '',
                type: 'error'
              })
            } else {
              console.log(err.response)
            }
          })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .builder-all-component .btn-search {
    height: 45px;
    background: #f2494a;
    border-radius: 0;
    color: #fff;
    line-height: 32px;
  }

  .location-card {
    border: 1px solid #eaeaea;
    border-left:2px solid #de4242;
    background-color: #fff;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .location-card.published{
    border-left: 2px solid #8cd200
  }

  .location-regionName{
    cursor: pointer;
  }

  .location-card .image-location {
    padding: 10px;
    width: 125px;
    margin-right: 15px;
    cursor: pointer;
  }

  .location-card .image-location img{
    width: 100%
  }

  .location-info {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  .location-info .submenu-actions{
    margin-right: 10px;
  }

  .note {
    font-size: 10px;
    font-weight: bold;
    margin-top: -10px;
    color: #f2494a;
    text-align: right;
    margin-bottom: 10px;
  }

  .number-of-featured-cards {
    font-style: italic;
    font-weight: normal;
  }

  .location-lastUpdated span {
    font-weight: normal
  }
</style>
