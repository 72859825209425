<template>
  <div>
    <carousel
      :responsive="{0: false, 768: true}"
      :nav="false"
      :dots="false"
      class="owl-theme-preview carousel"
    >
      <div v-for="(item, index) in row.items" :key="index">
        <row-preview-item :item="item" :displayTitles="row.displayTitles"></row-preview-item>
      </div>
    </carousel>
  </div>
</template>
<script>
import carousel from 'v-owl-carousel'
import RowPreviewItem from './RowPreviewItem'
export default {
  name: 'RowPreviewCarousel',
  props: ['row'],
  components: {RowPreviewItem, carousel}
}
</script>
