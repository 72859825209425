<template>
    <div class="cards-type-playlist">
      <div class="container">
        <div class="col-8 offset-2">
          <b-form @submit="save" class="bootstrap-form">

            <b-form-row>
              <b-col>
                <b-input-group>
                  <b-form-input required v-model="cardContest.id" type="url" trim @input.native="fixYouTubeURL"></b-form-input>
                  <b-input-group-append>
                    <div class="btn-prepend">Contest URL</div>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-form-row>
            <b-form-row class="justify-content-md-center">
              <b-col>
                <b-form-group label="Open External Window">
                  <b-form-checkbox
                      id="openInExternalWindow"
                      v-model="cardContest.openInExternalWindow"
                      name="openInExternalWindow">
                    Open Weblink In Separate Window (for iOS/Android)
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-form-row>
            <br>

            <BaseCardForm :card="cardContest" :imageSizes="imageUpload" :video="video" />

            <div class="wrapper-btn">
              <!-- <b-button variant="primary" class="card-btn btn-add btn-add-img" @click="addImage">Add Images</b-button> -->
              <b-button type="submit" variant="primary" class="card-btn">{{type === 'create' ? 'Create Card' : 'Update Card'}}</b-button>
            </div>
          </b-form>
        </div>
      </div>

    </div>

</template>


<script>
import { Datetime } from 'vue-datetime'
import CardTypeCommon from './CardTypeCommon'
import LoaderInline from '../../../share/LoaderInline'
import BaseCardForm from './BaseCardForm'
export default {
  name: 'ContestType',
  components: {
    Datetime,
    LoaderInline,
    BaseCardForm
  },
  props: ['card', 'type'],
  data () {
    const utils = new CardTypeCommon(this)
    return {
      formData: new FormData(),
      utils: utils,
      video: utils.uploads.video[0],
      imageUpload: utils.uploads.images,
      minDatetime: utils.configuration.minDatetime
    }
  },
  computed: {
    cardContest: {
      get () {
        return this.card
      },
      set (value) {
        this.card = value
      }
    }
  },
  mounted () {
    this.utils.setCardDefaultValues()
  },
  methods: {
    fixYouTubeURL (e) {
      const youtubeShortURL = 'https://youtu.be/'
      if (e.target.value.includes(youtubeShortURL)) {
        e.target.value = e.target.value.trim().replace(youtubeShortURL, 'https://www.youtube.com/watch?v=')
      }
    },

    addImage () {
      let images = this.imageUpload.filter((img) => {
        return img['750x750'] && img['1000x1100']
      })

      if (images.length === this.imageUpload.length) {
        this.imageUpload.push({
          file750: null,
          '750x750': null,
          placeholder750: 'Image 750x750px',
          width750: 750,
          height750: 750,

          file1000: null,
          '1000x1100': null,
          placeholder1000: 'Image 1000x1100px',
          width1000: 1000,
          height1000: 1100,

          file1296: null,
          '1296x560': null,
          placeholder1296: 'Image 1296 x 560px',
          width1296: 1296,
          height1296: 560
        })
      }
    },
    removeImage (index) {
      this.imageUpload.splice(index, 1)
    },
    uploadVideo (event) {
      this.utils.handleVideoUpload(event)
    },
    removeVideoCard () {
      this.utils.handleRemoveVideoCard()
    },
    save (evt) {
      evt.preventDefault()
      this.card.type = 'contest'

      if (!this.cardContest.id) {
        this.$notify({
          group: 'notifications',
          title: 'Contest URL is required :(',
          text: '',
          type: 'error'
        })

        return
      }

      if (!this.utils.validateCardBeforeSave()) {
        return
      }

      if (this.type === 'create') {
        this.create()
      } else {
        this.update()
      }
    },
    create () {
      this.$http.post('/cards', {card: this.cardContest})
        .then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Card created successfully :)',
              text: '',
              type: 'success'
            })
            this.$router.push('/admin/cards')
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Images are required :)',
              text: '',
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    update () {
      this.cardContest.id = this.cardContest.id.toString()
      this.$http.put('/cards', {card: this.cardContest})
        .then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Card updated successfully :)',
              text: '',
              type: 'success'
            })
            this.$router.push('/admin/cards')
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Images are required :)',
              text: '',
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style>
  .card-image video {
    width: 100%;
    margin-bottom: 10px;
  }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .cards-type-playlist {
  margin-top: 6%;
  height: calc(100vh - 210px);
  overflow: scroll;
} */

.cards-type-playlist .custom-file {
  margin-bottom: 15px;
}

.cards-type-playlist .wrapper-btn{
  display: flex;
  justify-content: flex-end;
}

.cards-type-playlist .card-btn{
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  text-transform: uppercase;
}

.cards-type-playlist .btn-add{
  margin-right: 10px;
}

.cards-type-playlist .btn-modal{
  height: 45px;
  margin-right: 0;
}

.card-image img, .card-image video{
  width: 100%;
  margin-bottom: 10px;
}

ul.playlistUpdated-list{
    list-style-type: none;
    overflow-y: scroll;
    max-height: 400px;
    padding: 3px;
}

ul.playlistUpdated-list .playlist-title{
  font-size: 12px;
  font-weight: bold;
}

ul.playlistUpdated-list .playlist-tracks{
  font-size: 10px;
  font-weight: bold;
}

ul.playlistUpdated-list li {
  list-style-type: none;
  display: flex;
  background-color: #fff;
  margin-bottom: 10px;
  height: 60px;
  align-items: center;
  border: 1px solid #eaeaea;
  cursor: pointer;
}

ul.playlistUpdated-list li.selected {
  background-color: #eaeaea;
}

ul.playlistUpdated-list li .playlists-list-card-img {
  margin-right: 15px;
}

ul.playlistUpdated-list li .playlists-list-card-img img{
  height: 60px;
  width: auto;
}

.image-group{
  display: flex;
  flex: 1;
  border: 1px solid #eaeaea;
  padding-top: 15px;
  margin-bottom: 15px;
  position: relative;
  flex-wrap: wrap;
}

.image-group .remove-img{
    position: absolute;
    top: -10px;
    right: -10px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #eaeaea;
    background-color: #eaeaea;
    text-align: center;
    cursor: pointer;
}

.btn-prepend {
    color: #fff;
    display: block;
    text-align: center;
    margin-bottom: 15px;
    background-color: #f2494a;
    border: none;
    border-radius: 0;
    height: 45px;
    line-height: 45px;
    text-transform: uppercase;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 12px;
}

.video-labels {
  font-weight: bold;
  font-size: 12px;
}

</style>
