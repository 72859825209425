<template>
  <div class="playlists-all-component">

    <div class="page-header">
      <div class="typeahead-wrapper">
        <div class="typeahead">
          <b-input-group prepend="Search">
            <b-form-input v-model="query" @keyup.13.native="search"></b-form-input>
            <b-dropdown :text="'by ' + searchType" variant="success" class="btn-search" slot="append">
              <b-dropdown-item @click="searchType = 'title'">Title</b-dropdown-item>
              <b-dropdown-item @click="searchType = 'artist'">Artist</b-dropdown-item>
            </b-dropdown>
          </b-input-group>
        </div>
        <router-link class="add-btn" to="/admin/videos/new" tag="div">Add Video</router-link>
      </div>
      <div class="video-filters">
        <div class="sort-videos" v-if="displayTypeSelected">
          Display as
          <b-dropdown id="ddown-sm" :text="displayTypeSelected.label" size="sm" class="sort-select btn-search">
            <b-dropdown-item-button v-for="(displayType, index) in displayTypes" v-bind:key="index" @click="switchView(displayType)">{{displayType.label}}</b-dropdown-item-button>
          </b-dropdown>
        </div>
        <div class="sort-videos" v-if="sortValueSelected">
          Order by
          <b-dropdown id="ddown-sm" :text="sortValueSelected.label" size="sm" class="m-2 sort-select btn-search">
            <b-dropdown-item-button v-for="(sortValue, index) in sortValues" v-bind:key="index" @click="switchSortValue(sortValue)">{{sortValue.label}}</b-dropdown-item-button>
          </b-dropdown>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="playlists-all-list">
        <transition-group tag="div" class="row" name="list-animation">
          <div class="col-sm-12" :class="col" v-for="(video, index) in videos" v-bind:key="video._id">

            <div class="playlists-list-card " :class="{list: displayTypeSelected.value === 'list'}">
              <div class="playlists-list-card-img" @click="navigate(video._id)">
                <img :src="video.images.card['2xImageURL']" alt="" v-if="video.images && video.images.card && video.images.card['2xImageURL']" />
                <img :src="video.images.row['2xImageURL']" alt="" v-if="!video.images || !video.images.card || !video.images.card['2xImageURL']"/>
                <img src="/static/img/placeholder-video-cms.jpg" alt="" v-if="(!video.images || !video.images.card || !video.images.card['2xImageURL']) && (!video.images.row || !video.images.row['2xImageURL'])"/>
              </div>
              <div class="general-wrapper-video-content" :class="{published: video.published}">
                <div class="playlists-list-card-content" :class="{published: video.published}">
                  <div class="playlists-list-card-title">
                    {{video.title.es}}
                  </div>
                  <div class="submenu-actions" v-if="can_publish">
                    <div class="icon">
                      <i class="fas fa-ellipsis-h"></i>
                    </div>
                    <div class="actions">
                      <ul>
                        <!-- <li @click="video.published = !video.published; updateStatus(video)">{{video.published ? 'Unpublish' : 'Publish'}}</li> -->
                        <li @click="confirm(video, index)">Delete</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- <div class="video-description">
                  {{video.description.es}}
                </div> -->
                <div class="video-icons">
                  <div class="icon-wrapper" v-b-tooltip.hover.topright title="Landscape Video" v-if="video.file && video.file.landscape && video.file.landscape.m3u8 && video.file.landscape.m3u8 !== ''" @click="openPlayer(video, 'landscape')">
                    <i class="fas fa-arrows-alt-h"></i>
                  </div>
                  <div class="icon-wrapper" v-b-tooltip.hover.topright title="Portrait Video" v-if="video.file && video.file.portrait && video.file.portrait.m3u8 && video.file.portrait.m3u8 !== ''" @click="openPlayer(video, 'portrait')">
                    <i class="fas fa-arrows-alt-v"></i>
                  </div>
                  <div class="icon-wrapper" v-b-tooltip.hover.topright title="Modified History" @click="showHistory(video)">
                    <i class="fas fa-history"></i>
                  </div>

                  <b-badge class="video-badge" v-if="video.onCarousel">Carousel</b-badge>
                  <b-badge class="video-badge grey-badge" v-if="video.published">Published</b-badge>
                </div>
                <hr/>
                <div class="more-info-wrapper">
                  <div class="more-info">
                    <strong class="start">Set to Start: </strong>{{formatDate(video.available.start)}}
                  </div>
                  <div class="more-info">
                    <strong class="updated">Last Updated: </strong>{{formatDate(video.lastUpdated)}}
                  </div>
                  <div class="more-info" v-if="video.authorsHistory.length">
                    <strong class="saved">Last Saved By: </strong>{{video.authorsHistory[0].fullname}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>

      <b-pagination v-if="pages > 1 && displayPaging" align="right" size="md" :total-rows="limit * pages" v-model="current" :per-page="limit" @change="getResults"></b-pagination>
    </div>

    <b-modal id="videoPlayerModal"
             ref="videoPlayerModal"
             size="lg"
             title="Video Player"
             :no-close-on-backdrop="true"
             :hide-header-close="true"
             :ok-only="true"
             ok-title="Close"
             @ok="handleOk">
      <div class="row modal-wrapper">
        <div class="col-12">
          <video-player class="video-player-box"
                        ref="videoPlayer"
                        :options="videoModalOption"
                        :playsinline="true"
                        v-if="videoModalOption.sources[0].src">
          </video-player>
        </div>
      </div>
    </b-modal>

    <b-modal id="videoModifiedHistory"
             ref="videoModifiedHistory"
             size="lg"
             title="Modified history"
             :ok-only="true"
             ok-title="Close"
             @ok="closeHistory">
      <div class="row modal-wrapper">
        <div class="col-12">
          <b-table striped hover :items="authorsHistory"></b-table>
        </div>
      </div>
    </b-modal>

    <b-modal id="confirmRemoveVideo" ref="confirmRemoveVideo" size="md" title="Confirm" @ok="handleRemove">
      <div class="row modal-wrapper">
        <div class="col-12">
          Are you sure want to remove it?
        </div>
      </div>
    </b-modal>
  </div>
</template>


<script>
import typeaheadCategories from '../../share/TypeaheadCategories'
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'VideosAll',
  components: {
    typeaheadCategories,
    axios
  },
  data () {
    return {
      searchType: 'title',
      sortValues: [
        {
          label: 'Last Updated',
          value: 'lastUpdated'
        },
        {
          label: 'Date Created',
          value: 'dateCreated'
        }
      ],
      sortValueSelected: null,
      displayTypes: [
        {
          label: 'List',
          value: 'list'
        },
        {
          label: 'Grid',
          value: 'grid'
        }
      ],
      displayTypeSelected: null,
      timeout: null,
      searchList: [],
      videos: [],
      limit: 80,
      pages: 1,
      current: 1,
      toDelete: {
        index: 0,
        video: ''
      },
      query: '',
      displayPaging: true,
      displayList: true,
      videoModalOption: {
        language: 'en',
        responsive: true,
        sources: [{
          type: 'application/x-mpegURL',
          src: null
        }],
        techOrder: ['html5'],
        sourceOrder: true,
        flash: {
          hls: { withCredentials: false },
          swf: '/static/media/video-js.swf'
        },
        html5: { hls: { withCredentials: false } },
        fluid: true
      },
      users: {},
      authorsHistory: [],
      can_publish: false
    }
  },

  computed: {
    col () {
      return this.displayTypeSelected.value === 'list' ? 'col-lg-6 col-md-12' : 'col-lg-4 col-md-6'
    }
  },

  mounted () {
    EventHandler.emit('isLoading', true)
    if (localStorage.getItem('videoPermission') !== '') {
      this.can_publish = true
    }
    this.displayTypeSelected = JSON.parse(localStorage.getItem('type')) || this.displayTypes[0]
    this.sortValueSelected = this.sortValues[0]
    axios.all([this.getVideos(), this.getRecentVideos(), this.getUsers()])
      .then(axios.spread((videos, recentVideos, users) => {
        this.videos = videos.data.videos
        this.pages = videos.data.pages
        this.current = videos.data.current
        this.recentVideos = recentVideos.data
        this.users = users.data

        this.mapVideosOnCarousel(this.videos, this.recentVideos)

        this.videos.forEach(video => {
          let authorsHistory = []

          video.authors.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date)
          })

          video.authors.forEach((author) => {
            authorsHistory.push({
              ...this.users[author._id],
              date: moment(author.date).format('MMMM Do YYYY, h:mm:ss a')
            })
          })

          this.$set(video, 'authorsHistory', authorsHistory)
        })

        EventHandler.emit('isLoading', false)
      }))
      .catch((err) => {
        console.log(err)
        EventHandler.emit('isLoading', false)
      })
  },

  methods: {
    openPlayer (video, type) {
      this.$refs.videoPlayerModal.show()
      this.videoModalOption.sources[0].src = video.file[type]['m3u8']
    },
    handleOk () {
      this.$refs.videoPlayerModal.hide()
      this.videoModalOption.sources[0].src = null
    },
    getVideos () {
      return this.$http.get('/videos', {params: {limit: this.limit, page: this.page, sortBy: this.sortValueSelected.value}})
    },
    getRecentVideos () {
      return this.$http.get('/videos/recent?orientation=portrait&pagesize=30')
    },
    getUsers () {
      return this.$http.get('/videos/users')
    },
    search () {
      if (this.query === '') {
        this.pages = 1
        this.$http.get('/videos', {params: {limit: this.limit, page: this.pages, sortBy: this.sortValueSelected.value}})
          .then((res) => {
            this.displayPaging = true
            this.videos = res.data.videos
            this.pages = res.data.pages
            this.current = res.data.current
            this.mapVideosOnCarousel(this.videos, this.recentVideos)

            this.videos.forEach(video => {
              let authorsHistory = []

              video.authors.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date)
              })

              video.authors.forEach((author) => {
                authorsHistory.push({
                  ...this.users[author._id],
                  date: moment(author.date).format('MMMM Do YYYY, h:mm:ss a')
                })
              })

              this.$set(video, 'authorsHistory', authorsHistory)
            })
          })
          .catch((err) => {
            console.log(err)
          })
        return
      }
      this.$http.get(`/videos/search?q=${this.query}&type=${this.searchType}`)
        .then((res) => {
          if (res.success) {
            this.videos = res.data
            this.displayPaging = false
            this.mapVideosOnCarousel(this.videos, this.recentVideos)

            this.videos.forEach(video => {
              let authorsHistory = []

              video.authors.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date)
              })

              video.authors.forEach((author) => {
                authorsHistory.push({
                  ...this.users[author._id],
                  date: moment(author.date).format('MMMM Do YYYY, h:mm:ss a')
                })
              })

              this.$set(video, 'authorsHistory', authorsHistory)
            })
          } else {
            this.$notify({
              group: 'notifications',
              title: res.message,
              text: '',
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    confirm (video, index) {
      this.$refs.confirmRemoveVideo.show()
      this.toDelete = {
        index: index,
        video: video
      }
    },
    handleRemove (toDelete) {
      this.$http.delete('/videos', {params: {videoID: this.toDelete.video._id}})
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: 'Video successfully removed :(',
            text: '',
            type: 'success'
          })
          this.videos.splice(this.toDelete.index, 1)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getResults (page) {
      this.$http.get('/videos', {params: {limit: this.limit, page: page, sortBy: this.sortValueSelected.value}})
        .then((res) => {
          this.videos = res.data.videos
          this.pages = res.data.pages
          this.current = res.data.current

          this.mapVideosOnCarousel(this.videos, this.recentVideos)

          this.videos.forEach(video => {
            let authorsHistory = []

            video.authors.sort(function (a, b) {
              return new Date(b.date) - new Date(a.date)
            })

            video.authors.forEach((author) => {
              authorsHistory.push({
                ...this.users[author._id],
                date: moment(author.date).format('MMMM Do YYYY, h:mm:ss a')
              })
            })

            this.$set(video, 'authorsHistory', authorsHistory)
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateStatus (video) {
      this.$http.put(`/videos`, {video: video})
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: `Video ${video.title.es} status updated :)`,
            text: '',
            type: 'success'
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    navigate (id) {
      this.$router.push('videos/edit/' + id)
    },
    switchView (type) {
      localStorage.setItem('type', JSON.stringify(type))
      this.displayTypeSelected = type
    },
    switchSortValue (sortValue) {
      this.sortValueSelected = sortValue

      axios.all([this.getVideos(), this.getRecentVideos()])
        .then(axios.spread((videos, recentVideos) => {
          this.videos = videos.data.videos
          this.pages = videos.data.pages
          this.current = videos.data.current
          this.recentVideos = recentVideos.data

          this.mapVideosOnCarousel(this.videos, this.recentVideos)

          this.videos.forEach(video => {
            let authorsHistory = []

            video.authors.sort(function (a, b) {
              return new Date(b.date) - new Date(a.date)
            })

            video.authors.forEach((author) => {
              authorsHistory.push({
                ...this.users[author._id],
                date: moment(author.date).format('MMMM Do YYYY, h:mm:ss a')
              })
            })

            this.$set(video, 'authorsHistory', authorsHistory)
          })
        }))
        .catch((err) => {
          console.log(err)
        })
    },
    mapVideosOnCarousel (videos, recentVideos) {
      videos.forEach(video => {
        recentVideos.forEach((recent, index) => {
          if (video._id === recent._id.$oid && index <= 5) {
            this.$set(video, 'onCarousel', true)
          }
        })
      })
    },
    showHistory (video) {
      this.authorsHistory = video.authorsHistory
      this.$refs.videoModifiedHistory.show()
    },
    closeHistory () {},
    formatDate (date) {
      return moment(date).format('MMMM Do YYYY, h:mm a')
    },
    formatDescription (s, n) {
      let cut = s.indexOf(' ', n)
      if (cut === -1) return s
      return s.substring(0, cut) + '...'
    }
  }
}
</script>

<style>
.video-js {
  width: 100%;
}

.btn-search .dropdown-toggle{
  height: 45px;
  background: #f2494a;
  border-radius: 0;
  color: #fff;
  line-height: 32px;
  border: none;
  font-size: 12px;
  text-transform: uppercase;
}

.btn-search .dropdown-toggle:hover,
.btn-search .dropdown-toggle:active,
.btn-search .dropdown-toggle:focus,
.btn-search .btn-success:not(:disabled):not(.disabled):active:focus,
.btn-search .btn-success:not(:disabled):not(.disabled):active,
.input-group > .input-group-append > .b-dropdown.btn-search > .btn {
  background: #f2494a;
  box-shadow: none;
}

.btn-search .dropdown-toggle::after {
  margin-left: 0.555em;
  vertical-align: 0.155em;
}

.btn-search .dropdown-menu {
  font-size: 12px;
  min-width: 7rem;
}

.btn-search .dropdown-menu button {
  cursor: pointer;
}

.sort-select.m-2 {
  margin-left: 0.1rem !important;
  margin-right: 20px !important;
}

.btn-search.sort-select button {
  border-radius: 0;
  height: auto;
  outline: none;
}

.btn-search.sort-select .btn-secondary:not(:disabled):not(.disabled):active,
.btn-search.sort-select .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background: #f2494a;
  box-shadow: none;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  font-size: 11px;
  background: #f2494a;
}

.tooltip .arrow::before {
  border-top-color: #f2494a;
}

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.page-header {
  padding-bottom: 15px;
}

.video-filters {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 5px;
  font-size: 16px;
  color: #2c3e50;
  line-height: 28px;
  justify-content: center;
}

.video-filters .sort-videos {
  font-size: 14px;
  margin-right: 30px;
}

.video-filters .display-filter {
  padding: 0px 7px;
  border: 2px solid #2c3e50;
  cursor: pointer;
}

.typeahead-wrapper {
  margin-bottom: 20px;
}

.page-content {
  margin-top: 135px;
}

.playlists-all-list {
  padding: 5px 0;
  margin-bottom: 25px;
}

.playlists-list-card {
  position: relative;
  background-color: #fff;
  margin-bottom: 20px;
  border: 1px solid #eaeaea;
}

.playlists-list-card.list {
  display: flex;
}

.playlists-list-card.list .playlists-list-card-img {
  width: 30%
}

.playlists-list-card.list .playlists-list-card-img img {
  /* width: 150px; */
}

.playlists-list-card.list  .general-wrapper-video-content {
  flex: 1;
  width: 70%;
}

.playlists-list-card .playlist-feature {
  background-color: rgba(242, 73, 74, 0.95);
  color: #fff;
  padding: 5px 10px;
  position: absolute;
  left: 0;
  width: 70px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 1.2;
}

.playlists-list-card .playlists-list-card-img{
  cursor: pointer;
}

.playlists-list-card .playlists-list-card-img img {
  width: 100%;
}

.general-wrapper-video-content {
  padding: 15px;
  border-bottom: 1px solid #f2494a;
}

.general-wrapper-video-content.published {
  border-bottom: 2px solid #8cd200
}

.playlists-list-card .playlists-list-card-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.playlists-list-card .playlists-list-card-title {
  font-size: 12px;
  font-weight: 900;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.general-wrapper-video-content .video-description {
  font-size: 12px;
  margin-bottom: 20px;
}

.video-icons {
  display: flex;
  margin-bottom: 20px;
}

.video-icons .icon-wrapper {
  border: 1px solid #2c3e50;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin-right: 5px;
  font-size: 12px;
  color: #2c3e50;
  cursor: pointer;
}

.video-icons .icon-wrapper:hover {
  background-color: #2c3e50;
  color: #fff;
  transition: all .2s;
}

.video-icons .video-badge {
  background-color: #f2494a;
  border-color: #f2494a;
  color: #fff;
  cursor: default;
  line-height: 1.3;
  margin-right: 4px;
}

.video-icons .video-badge.grey-badge {
  background-color: grey;
  border-color: grey;
}

.more-info {
  font-size: 10px;
  margin-bottom: 5px;
}

.more-info strong {
  color: #83909c;
  text-transform: uppercase;
  font-weight: 900;
}

.more-info .start {
  margin-right: 10px;
}

.more-info .updated {
  margin-right: 3px;
}

.more-info .saved {
  margin-right: 2px;
}

@media (max-width: 768px) {
  .typeahead-wrapper {
    margin-bottom: 0;
  }

  .input-group-prepend {
    display: none;
  }

  .add-btn {
    width: 100px;
  }

  .video-filters {
    display: none;
  }

  .page-content {
    margin-top: 60px;
  }
}



</style>
