<template>
  <div>
    <div class="item-preview-content">
      <div class="row">
        <div class="col-12">
          <assets-item-preview-video v-if="item.type === 'video'" :item="item"></assets-item-preview-video>
          <assets-item-preview-audio v-if="item.type === 'audio'" :item="item"></assets-item-preview-audio>
        </div>
        <div class="col-12">
          <div class="item-preview-meta mb-2">
            <div class="label">File Name</div>
            <div class="value">{{ item.file_name }}</div>
          </div>
        </div>
        <div class="col-6">
          <div class="item-preview-meta  mb-2">
            <div class="label">File Size</div>
            <div class="value">{{formatFileSize(item.file_size)}}</div>
          </div>
        </div>
        <div class="col-6">
          <div class="item-preview-meta  mb-2">
            <div class="label">File Type</div>
            <div class="value">{{ item.mime_type }}</div>
          </div>
        </div>
        <div class="col-6">
          <div class="item-preview-meta  mb-2">
            <div class="label">Upload Date</div>
            <div class="value">{{formatTimestamp(item.date_modified)}}</div>
          </div>
        </div>
        <div class="col-6">
          <div v-if="item.dimensions" class="item-preview-meta  mb-2">
            <div class="label">Dimensions</div>
            <div class="value">{{ item.dimensions.width }} x {{ item.dimensions.height}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="item-preview-footer">
      <div class="controls-wrapper">
        <ul>
          <b-btn class="add-podcast modal-btn" v-if="showDownload" v-on:click="download(item.file_url, $event)">Download</b-btn>
          <b-btn class="add-podcast modal-btn" v-if="showEncode" v-on:click="encode(item)">Encode</b-btn>
          <b-btn class="add-podcast modal-btn" v-if="showSelect" v-on:click="select(item)">Select</b-btn>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import AssetsItemPreviewVideo from './AssetsItemPreviewVideo'
import AssetsItemPreviewAudio from './AssetsItemPreviewAudio'
export default {
  name: 'AssetsItemPreview',
  components: {AssetsItemPreviewVideo, AssetsItemPreviewAudio},
  props: {
    item: {
      type: Object,
      required: true
    },
    showEncode: {
      required: false,
      type: Boolean,
      default: true
    },
    showSelect: {
      required: false,
      type: Boolean,
      default: false
    },
    showDownload: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  mounted () {
  },
  methods: {
    download (url, $event) {
      $event.preventDefault()
      $event.stopPropagation()
      window.open(url, 'blank')
    },

    encode(item) {
      this.$emit('encode', item)
    },

    select(item) {
      this.$emit('selected', item)
    }
  }
}
</script>
<style scoped lang="scss">
.item-preview-content {
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;

  .item-preview-title{
    padding: 5px;
    word-break: break-all;
    margin: 0;
  }

  .item-preview-meta{
    font-size: 12px;
    .label{
      font-weight: 600;
      text-transform: uppercase;
      color: rgba(0,0,0,.6);
    }
    .value{
      word-break: break-all;
    }
  }
}

.item-preview-footer{
  height: 40px;
  padding-top: 20px;
  padding-right: 15px;
}
</style>
