<template>
  <div>
    <b-form-row>
      <div class="video-labels">Featured Artist</div>
      <b-button variant="primary" class="artist-btn" v-for="(artist, index) in artists" :key="index">
        {{artist.name}} <b-badge variant="light" @click="removeArtist(index)">x</b-badge>
      </b-button>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group>
          <b-input-group>
            <b-form-input id="featuredArtist"
                          type="text"
                          v-model="newArtist"
                          placeholder="Search Artist..."
                          @keyup.native="searchArtist"
                          @focus.native="showArtistListResult = true; artistList = []"
                          @blur.native="hideArtistListResult()"
                          autocomplete="off">
            </b-form-input>
            <b-input-group-append>
              <b-btn class="typeahead-btn find-artist" variant="outline-success">Find Artist</b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <ul v-show="artistList.length > 0 && showArtistListResult" class="searchArtistResults">
          <li v-for="(artist, index) in artistList" v-bind:key="index" @click="addArtist(artist)">
            {{artist.name}}
          </li>
        </ul>

        <span class="addNewArtist" v-if="addNewArtist" @click="openNewArtist">Add new artist...</span>
      </b-col>
    </b-form-row>
    <b-modal id="createArtist" ref="createArtist" size="lg"
             title="Create New Artist" ok-title="Create"
             :hide-header-close="true" :no-close-on-backdrop="true"
             @ok="createArtist" @cancel="cancelArtist">
      <div class="row modal-wrapper">
        <div class="col-4">
          <div class="artist-image">
            <img :src="artist.image" alt="" v-if="artist.image && artist.image !== ''"/>
            <img src="/static/img/LaMusica-Icon-artist-placeholder.png" alt="" v-if="!artist.image" />
            <LoaderInline v-if="isLoadingImage" />
          </div>
          <b-form-file v-model="artistImage" @change.native="uploadArtistImage($event)" :state="Boolean(artistImage)" placeholder="Image 800 x 800px"></b-form-file>
        </div>

        <div class="col-8">
          <div class="artist-info">
            <div class="general">

              <b-form id="artistForm" class="bootstrap-form">
                <b-form-row>
                  <b-col>
                    <b-form-group label="Name <strong class='required'>*</strong>">
                      <b-form-input id="Name"
                                    type="text"
                                    v-model="artist.name"
                                    placeholder="Add artist name...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group label="Musicbrainz ID">
                      <b-input-group>
                        <b-form-input id="Musicbrainz"
                                      type="text"
                                      :disabled="true"
                                      v-model="artist.Musicbrainz"
                                      placeholder="Add Musicbrainz ID...">
                        </b-form-input>
                        <b-input-group-append>
                          <b-btn class="typeahead-btn" variant="outline-success" @click="generateMusicBrainzID">Generate</b-btn>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group label="Slug">
                      <b-input-group>
                        <b-form-input id="slug"
                                      type="text"
                                      v-model="artist.slug">
                        </b-form-input>
                        <b-input-group-append>
                          <b-btn class="typeahead-btn" variant="outline-success" @click="generateArtistSlug()">Generate</b-btn>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-form-row>

              </b-form>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import LoaderInline from '../../share/LoaderInline'
export default {
  name: 'AddArtistsModal',
  components: {
    LoaderInline
  },
  data () {
    return {
      formData: null,
      addNewArtist: false,
      newArtist: '',
      artistList: [],
      artistImage: null,
      isLoadingImage: false,
      artist: {
        name: '',
        Musicbrainz: null,
        image: null,
        slug: null
      }
    }
  },
  props: {
    artists: {
      required: true,
      type: Array
    }
  },
  methods: {
    uploadArtistImage (event) {
      const input = event.target
      const file = input.files && input.files[0]
      if (!file) {
        return
      }
      if (/\.(jpe?g|png)$/i.test()) {
        return this.$notify({
          group: 'notifications',
          title: 'Image file should be JPG or PNG format :(',
          text: '',
          type: 'error'
        })
      }
      const reader = new FileReader()
      reader.readAsDataURL(input.files[0])

      reader.onload = (e) => {
        const URL = window.URL || window.webkitURL
        const image = new Image()
        image.src = URL.createObjectURL(input.files[0])
        image.onload = () => {
          let height = image.height
          let width = image.width

          if (height !== 800 || width !== 800) {
            return this.$notify({
              group: 'notifications',
              title: 'Image size allowed 800 x 800px :(',
              text: '',
              type: 'error'
            })
          }
          this.formData = new FormData()
          this.formData.append('image', input.files[0])
          this.isLoadingImage = true

          const config = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }

          this.$http.post('/artists/image', this.formData, config).then((res) => {
            if (res.success) {
              this.artistImage = res.data[0]
              this.artist.image = res.data[0]

              this.$notify({
                group: 'notifications',
                title: 'Image uplodaded successfully :)',
                text: '',
                type: 'success'
              })
            } else {
              this.$notify({
                group: 'notifications',
                title: `${res.message} :(`,
                text: '',
                type: 'error'
              })
            }
          }).catch((err) => {
            console.log(err)
            this.$notify({
              group: 'notifications',
              title: `There was an error this image to the artist :(`,
              text: '',
              type: 'error'
            })
          }).finally(() => {
            this.isLoadingImage = false
            this.formData = null
          })
        }
      }
    },
    generateArtistSlug () {
      if (!this.artist.name || this.artist.name === '') {
        this.$notify({
          group: 'notifications',
          title: 'Artist name is required in order to generate an slug',
          text: '',
          type: 'error'
        })
        return
      }
      this.$http.post('/artists/slug', {
        string: this.artist.name
      }).then((response) => {
        if (response.is_unique) {
          this.$notify({
            group: 'notifications',
            title: 'Unique slug generated successfully',
            text: '',
            type: 'success'
          })
          this.artist.slug = response.slug
        } else {
          this.artist.slug = ''
          this.$notify({
            group: 'notifications',
            title: 'Another Artist already has the same generated slug, please write your own',
            text: '',
            type: 'error'
          })
        }
      })
    },
    addArtist (artist) {
      if (this.newArtist) {
        this.artists.push(artist)
        this.newArtist = ''
      }
    },
    removeArtist (index) {
      this.artists.splice(index, 1)
    },
    openNewArtist () {
      this.artist.name = this.newArtist
      this.$refs.createArtist.show()
    },
    generateMusicBrainzID () {},
    createArtist (bvEvt) {
      bvEvt.preventDefault()
      if (!this.artist.name) {
        return this.$notify({
          group: 'notifications',
          title: 'Artist name is required :(',
          text: '',
          type: 'error'
        })
      }

      if (!this.artist.slug) {
        return this.$notify({
          group: 'notifications',
          title: 'Artist slug is required :(',
          text: '',
          type: 'error'
        })
      }

      this.$http.post('/artists', {artist: this.artist})
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: 'Artist successfully created :)',
            text: '',
            type: 'success'
          })

          this.artistList.push({
            _id: res.data.id,
            name: this.artist.name
          })

          this.newArtist = ''
          this.addNewArtist = false
          this.$refs.createArtist.hide()
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            group: 'notifications',
            title: 'Sorry, something went wrong :(',
            text: '',
            type: 'error'
          })
          this.$refs.createArtist.hide()
        })
    },
    hideArtistListResult () {
      setTimeout(() => {
        this.showArtistListResult = false
      }, 200)
    },
    searchArtist (event) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (event.target.value === '') {
          this.artistList = []
          this.addNewArtist = false
          return
        }
        this.$http.get(`/videos/artists?q=${event.target.value}`)
          .then((res) => {
            if (res.data.length === 0) {
              this.isLoading = false
              this.artistList = []
              this.addNewArtist = true
              return
            }

            this.artistList = res.data
            this.showArtistListResult = true
            this.addNewArtist = false
          })
          .catch((err) => {
            console.log(err)
          })
      }, 250)
    },
    cancelArtist () {
      this.artist = {
        name: '',
        Musicbrainz: null,
        image: null
      }
    }
  }
}
</script>

<style scoped>

.video-labels {
  padding: 10px 0;
  width: 100%;
  display: block;
  font-size: 14px;
  font-weight: bold;
}

.addNewArtist {
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  color: #f2494a;
}

.find-artist,
.find-artist:hover,
.find-artist:active,
.find-artist:active,
.find-artist:active:focus {
  background-color: #d6d6d6 !important;
  color: #2c3e50 !important;
  box-shadow: none !important;
  cursor: default !important;
}

.artist-btn {
  width: auto;
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
}

ul.searchArtistResults {
  z-index: 3;
  position: absolute;
  top: 46px;
  left: 5px;
  min-height: 40px;
  max-height: 200px;
  background-color: #fff;
  border: 1px solid #f7f7f7;
  list-style: none;
  overflow: scroll;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.175);
  box-shadow: 0 6px 12px rgba(0,0,0,0.175);
}

ul.searchArtistResults li {
  padding: 10px 20px;
  cursor: pointer;
}

ul.searchArtistResults li:hover {
  color: #fff;
  background-color: #f45354;
}

.artist-image {
  position: relative;
  margin-bottom: 10px;
}

.artist-image img {
  width: 100%;
}
</style>
