<template>
  <div class="dashboard-component">
    <div class="page-header">
      <div class="row align-items-center header">
        <div class="col-3">
          <div class="title">
            <router-link to="/admin/notifications">Notifications</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="row">
        <div class="col-md-12">
          <div>
            <b-row>
              <b-col>
                <h4>Sponsored Notifications</h4>
              </b-col>
              <b-col class="d-flex justify-content-end mr-4">
                <b-button @click="handleNotificationModalClick('add')" variant="danger">Add Sponsored Notification</b-button> 
              </b-col>
            </b-row>
            
            

            <b-table small :fields="sponsoredFields" :items="sponsoredNotifications" responsive="sm">


              <template #cell(headings)="data">
                <b>EN: </b>{{ data.value.en }}
                <br/>
                <b>ES: </b>{{ data.value.es }}
              </template>

              <template #cell(contents)="data">
                <b>EN: </b>{{ data.value.en }}
                <br/>
                <b>ES: </b>{{ data.value.es }}
              </template>

              <template #cell(data)="data">
                <a :href="data.value.url">{{ data.value.url }}</a>
              </template>

              <template #cell(sent_date)="data">
                {{ data.value }}
              </template>

              <template #cell(ttl)="data">
                {{ data.value }}
              </template>

              <template #cell(actions)="data">
                <b-button @click="handleNotificationModalClick('update',data.item)" variant="danger">edit</b-button>
                <b-button @click="deleteSponsoredNotification(data.item)" variant="danger">delete</b-button>
              </template>

            </b-table>
          </div>

          <div class="mt-5">
            <h4>General Notifications</h4>
            <!-- <b-button @click="syncMessage" variant="danger">Sync Messages</b-button> -->

            <b-table small :fields="generalFields" :items="notifications" responsive="sm">


              <template #cell(headings)="data">
                <b>EN: </b>{{ data.value.en }}
                <br/>
                <b>ES: </b>{{ data.value.es }}
              </template>

              <template #cell(contents)="data">
                <b>EN: </b>{{ data.value.en }}
                <br/>
                <b>ES: </b>{{ data.value.es }}
              </template>

              <template #cell(data)="data">
                <a :href="data.value.url">{{ data.value.url }}</a>
              </template>

              <template #cell(sent_date)="data">
                {{ data.value }}
              </template>

              <template #cell(ttl)="data">
                {{ data.value }}
              </template>

              <template #cell(actions)="data">
                <b-button @click="deleteGeneralNotification(data.item)" variant="danger">delete</b-button>
              </template>

            </b-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal hide-footer id="notificationModal">
      <div class="notification-card">
        <div class="card-title">
          <b-form-row>
            <h5 v-if="modalMode==='add'">Add Sponsored Notification</h5>
            <h5 v-else>Update Sponsored Notification</h5>
          </b-form-row>            
        </div>

        <b-form-row>
            <b-col>
              <b-form-group label="Sponsored Notification Title*">
                <b-form-input id="adsCompanionSizeWidth"
                              type="text"
                              v-model="modalNotification.headings.en"
                              :state="requiredStringValidation(modalNotification.headings.en)"
                              required
                              placeholder="Title English">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Sponsored Notification Title">
                <b-form-input id="adsCompanionSizeHeight"
                              type="text"
                              v-model="modalNotification.headings.es"
                              required
                              placeholder="Title Spanish">
                </b-form-input>
              </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row class="mt-2">
          <b-col>
            <b-form-group label="Sponsored Notification Message*">
              <b-form-input id="adsCompanionSizeWidth"
                            type="text"
                            :state="requiredStringValidation(modalNotification.contents.en)"
                            v-model="modalNotification.contents.en"
                            required
                            placeholder="Message English">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Sponsored Notification Message">
              <b-form-input id="adsCompanionSizeHeight"
                            type="text"
                            v-model="modalNotification.contents.es"
                            required
                            placeholder="Message Spanish">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row class="mt-2">
          
          <b-col>
            <b-form-group label="Start Date*">
            <b-form-datepicker id="start-datepicker"
            value-as-date
            :min="new Date()"
            :state="requiredDateValidation(datepickerModels.start)"
            v-model="datepickerModels.start" 
            class="mb-2"></b-form-datepicker>
          </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="End Date*">
            <b-form-datepicker id="end-datepicker" 
            value-as-date
            :min="new Date()"
            :state="requiredDateValidation(datepickerModels.end)"
            v-model="datepickerModels.end"
            class="mb-2"></b-form-datepicker>
          </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row class="mt-2">
          <b-col>
            <b-form-group label="Sponsored Notification URL">
              <b-form-input id="adsCompanionSizeWidth"
                            type="text"
                            v-model="modalNotification.data.url"
                            :state="requiredStringValidation(modalNotification.data.url)"
                            required
                            placeholder="URL">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row class="mt-2">
          <b-col class="pt-2">
              <upload @imgUploaded="sponsoredMessageImageUpdate" :data="sponsoredNotifImage"></upload>
          </b-col>
          <b-col>
            <div class="status-wrapper">
              <div class="video-labels">Published</div>
              <label class="switch">
                <input type="checkbox" v-model="modalNotification.published">
                <span class="slider"></span>
              </label>
            </div>
          </b-col>
          <b-col class="pt-2">
              
          </b-col>
        </b-form-row>

        <b-row>
          <b-col class="d-flex justify-content-center mt-4">
            <b-button v-if="modalMode==='add'" @click="addSponsoredMessage" variant="danger">Add Sponsored Notification</b-button>
            <b-button v-else @click="updateSponsoredMessage" variant="danger">Update Sponsored Notification</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

  </div>
</template>


<script>
  import { DateTime as LuxonDateTime } from 'luxon'
  import { Datetime } from 'vue-datetime'
  import axios from 'axios'
  import upload from '../../share/UploadImage'

  export default {
    name: 'Notifications',
    components: {
      Datetime,
      upload
    },
    data () {
      return {
        test:null,
        modalMode: 'add',
        datepickerModels: {
          start: null,
          end: null
        },
        sponsoredNotifImage: {
          label: 'Image for the sponsored message',
          description: '',
          isLoading: false,
          width: 320,
          height: 100,
          isDimensionVariable : false,
          url: '',
          uploadURL: '/notifications/sponsored/image'
        },
        modalNotification:{
          headings:{en:'',es:''},
          contents:{en:'',es:''},
          data:{url:'', type:'sponsored'},
          lm_image:'',
          send_after:null,
          ttl:null,
          published:false
        },
        notifications: [],
        sponsoredNotifications: [],
        sponsoredFields: [
          {label: 'Notification Title', key:'headings'},
          {label: 'Notification Message', key:'contents'},
          {label: 'Notification Url', key:'data'},
          {key:'send_after',label:'Start Date', formatter: 'getFormattedDate'},
          {key:'ttl', label:'End Date', formatter: 'getFormattedTtl'},
          {key:'actions', label:'Actions'},
        ],
        generalFields: [
          {label: 'Notification Title', key:'headings'},
          {label: 'Notification Message', key:'contents'},
          {label: 'Notification Url', key:'data'},
          {key:'send_after',label:'Start Date', formatter: 'getFormattedDate'},
          {key:'ttl', label:'End Date', formatter: 'getFormattedTtl'},
          {key:'included_segments', label:'Segment', formatter: 'segmentFormatter'},
          {key:'actions', label:'Actions'},
        ]
      }
    },
    mounted () {
      this.syncMessages()
      this.getSponsoredNotification()

      this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      this.resetModalNotification()
    })
    },

    methods: {
      requiredStringValidation(data){
        return data.length>0
      },
      requiredDateValidation(data){
        return data !==null
      },
      handleNotificationModalClick(mode, item){
        this.modalMode = mode
        if(item) {
          this.modalNotification = JSON.parse(JSON.stringify(item)) // deep cloning
          const ttlTime = item.ttl + item.send_after
          this.datepickerModels.start = new Date(item.send_after * 1000)
          this.datepickerModels.end = new Date(ttlTime * 1000)
          this.sponsoredNotifImage.url = this.modalNotification.lm_image
        }
        this.$bvModal.show('notificationModal')
      },
      sponsoredMessageImageUpdate (data, res) {
        this.sponsoredNotifImage.url = res.data[0]
        this.modalNotification.lm_image = this.sponsoredNotifImage.url
      },
      validateRequredFields(){
        return this.modalNotification.headings.en !=='' && this.modalNotification.contents.en !==''
        && this.modalNotification.data.url !=='' && this.datepickerModels.start !==null && this.datepickerModels.end !==null
      },
      async addSponsoredMessage(){
        if(!this.validateRequredFields()){
          this.$notify({
            group: 'notifications',
            title: 'please fill the required fields',
            text: '',
            type: 'error'
          })
          return
        }
        const url = `${this.$http.url}/notifications/sponsored`
        this.modalNotification.send_after = parseInt(this.datepickerModels.start.getTime() / 1000)
        const ttlDiff = parseInt((this.datepickerModels.end.getTime() - this.datepickerModels.start.getTime()) /1000)
        this.modalNotification.ttl = ttlDiff

        const response = await axios.post(url,this.modalNotification)
        this.showNotification(response)
      },
      async updateSponsoredMessage(){
        const url = `${this.$http.url}/notifications/sponsored`
        this.modalNotification.send_after = parseInt(this.datepickerModels.start.getTime() / 1000)
        const ttlDiff = parseInt((this.datepickerModels.end.getTime() - this.datepickerModels.start.getTime()) /1000)
        this.modalNotification.ttl = ttlDiff
        const publishValidation = this.validatePublishedUpdate()
        if(publishValidation){
          const response = await axios.put(url,this.modalNotification)
          this.showNotification(response)
        }else{
          this.$notify({
            group: 'notifications',
            title: 'Only one notification can be published',
            text: '',
            type: 'error'
          })
        }
      },
      validatePublishedUpdate(){
        const publishedNotification = this.sponsoredNotifications.find((eachNotification) => eachNotification.published===true)
        if(!publishedNotification){
          return true
        }else if(publishedNotification._id === this.modalNotification._id){
          return true
        }
        return false
      },
      async deleteSponsoredNotification(item){
        const userConfirm = confirm('are you sure you want to delete notification?')
        if(userConfirm){
          const url = `${this.$http.url}/notifications/sponsored/${item._id}`
          const response = await axios.delete(url)
          this.showNotification(response)
        }
      },
      async deleteGeneralNotification(item) {
        const userConfirm = confirm('are you sure you want to delete notification?')
        if(userConfirm){
          const url = `${this.$http.url}/notifications`
          const response = await axios.put(url, item)
          this.showNotification(response)
        }
      },
      resetModalNotification(){
        this.datepickerModels = {
          start: null,
          end: null
        }
        this.modalNotification ={
          headings:{en:'',es:''},
          contents:{en:'',es:''},
          data:{url:'', type:'sponsored'},
          lm_image:'',
          send_after:null,
          ttl:null,
          published:false
        }
      },
      showNotification(response){
        if(response.data.success){
          this.$bvModal.hide('notificationModal')
          this.resetModalNotification()
          this.getNotifications()
          this.getSponsoredNotification()
          this.$notify({
            group: 'notifications',
            title: response.data.message,
            text: '',
            type: 'success'
          })
        }else{
          this.$notify({
            group: 'notifications',
            title: response.data.message,
            text: '',
            type: 'error'
          })
        }
      },
      async syncMessages(){
        const url = `${this.$http.url}/notifications`
        const response = await axios.post(url)
        if(response.data.success){
          this.getNotifications()
        }
      },
      getFormattedDate (sendAfter) {
        return LuxonDateTime.fromJSDate(new Date(sendAfter * 1000)).toLocaleString({
          weekday: 'long',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        })
      },
      getFormattedTtl(ttl, key, item) {
        const ttlTime = ttl + item.send_after
        return LuxonDateTime.fromJSDate(new Date(ttlTime * 1000)).toLocaleString({
          weekday: 'long',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        })
      },
      segmentFormatter(segmentArray){
        if(segmentArray.length > 0){
          return segmentArray.join()
        }
      },
      async getNotifications () {
        const url = `${this.$http.url}/notifications`
        const response = await axios.get(url)
        this.notifications= response.data.data
      },
      addRowColorToPublishedNotification(rawResponse){
        rawResponse.map( (eachNotification) => {
          eachNotification._rowVariant=''
          if(eachNotification.published) {
            eachNotification._rowVariant='success'
          }
          return eachNotification
        })
      },
      async getSponsoredNotification() {
        const url = `${this.$http.url}/notifications/sponsored`
        const response = await axios.get(url)
        const rawSponsoredNotifications = response.data.data
        this.addRowColorToPublishedNotification(rawSponsoredNotifications)
        this.sponsoredNotifications= response.data.data
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .notification-card {
    border: 1px solid #eaeaea;
    background-color: #fff;
    font-size: 14px;
    margin-bottom: 15px;
    padding: 20px;

    .card-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .send {
        background-color: #de4242;
        color: #fff;
        padding: 5px 30px;
        font-size: 13px;
        cursor: pointer;
        &:hover {
          background-color: #bb3535;
        }
      }
    }
  }

  .form-group {
    line-height: 25px;
  }

  textarea.form-control {
    padding: 1em;
  }

  .search-wrapper {
    display: flex;
  }

  .btn-search{
    height: 45px;
    background: #f2494a;
    border-radius: 0;
    color: #fff;
    line-height: 32px;
  }

  .result-item {
    display: flex;
    padding: 1em;
    background: white;
    margin: 0.5em;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    img {
      max-width: 150px;
    }

    article {
      padding-left: 0.3em;
    }

    .result-title {
      font-weight: bold;
    }

    .result-type {
      font-style: italic;
      text-transform: capitalize;
    }

    .result-date {
      margin-top: 2px;
    }

    .result-title,
    .result-type,
    .result-date {
      display: block;
    }
  }

  ul.result-list {
    list-style-type: none;
    overflow-y: scroll;
    max-height: 400px;
    padding: 3px;
  }

</style>
