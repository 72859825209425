<template>
  <div class="contest-edit-component">
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-3">
          <div class="title">
            <router-link to="/admin/contests">contest </router-link>/
            <span v-if="contest && contest.titleSpanish">{{contest.titleSpanish}}</span>
          </div>
        </div>
        <div class="col-9 pull-right">
          <div class="typeahead-wrapper float-right">
            <button type="submit" form="contestForm"  class="typeahead-btn">Update</button>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="contest-content-wrapper">
        <div class="container">
          <div class="col-12">
            <b-form @submit="updateContest" v-on:keydown.13.prevent.stop id="contestForm" class="bootstrap-form">

              <b-form-row align-h="end">
                <b-col>
                  <div class="status-wrapper">
                    <div class="content-labels">Published / Status</div>
                    <label class="switch">
                      <input type="checkbox" v-model="contest.published">
                      <span class="slider"></span>
                    </label>
                  </div>
                </b-col>
              </b-form-row>

              <hr/>

              <b-form-row>
                <b-col>
                  <b-form-group label="Title Spanish">
                    <b-form-input id="titleSpanish"
                                  type="text"
                                  required
                                  v-model="contest.titleSpanish"
                                  placeholder="Add spanish text...">
                    </b-form-input>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group label="Title English">
                    <b-form-input id="titleEnglish"
                                  type="text"
                                  required
                                  v-model="contest.titleEnglish"
                                  placeholder="Add english text...">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr/>

              <b-form-row>
                <b-col sm="12" md="6">
                  <b-form-group label="Description (Español)">
                    <b-form-textarea id="description-es"
                                     v-model="contest.descriptionSpanish"
                                     placeholder="Add Spanish Text..."
                                     :rows="4"
                                     :max-rows="4">
                    </b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="6">
                  <b-form-group label="Description (English)">
                    <b-form-textarea id="description-en"
                                     v-model="contest.descriptionEnglish"
                                     placeholder="Add English Text..."
                                     :rows="4"
                                     :max-rows="4">
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr/>

              <b-form-row>
                <b-col>
                  <b-form-group label="Website URL">
                    <b-form-input id="website_url"
                                  type="url"
                                  required
                                  v-model="contest.url"
                                  placeholder="Add contest url text...">
                    </b-form-input>
                  </b-form-group>
                </b-col>

                <b-col v-if="stationSelected">
                  <div class="content-labels">Select Station</div>
                  <b-dropdown id="ddown-sm" :text="stationSelected.label" size="sm" class="sort-select btn-search">
                    <b-dropdown-item v-for="(station, index) in stations" v-bind:key="index" @click="selectStation(station)">{{station.label}}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-form-row>

              <hr/>

              <b-form-row>
                <b-col>
                  <b-form-group label="Image">
                    <b-form-input id="img"
                                  type="url"
                                  required
                                  v-model="contest.img"
                                  placeholder="Add default image url...">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr/>

              <b-form-row class="justify-content-md-center" v-if="contest.available">
                <b-col>
                  <div class="content-labels">Start date</div>
                  <datetime type="datetime"
                            v-model="contest.available.start"
                            class="theme-orange"
                            :minDatetime="minDatetime"
                            :phrases="{ok: 'Continue', cancel: 'Exit'}"
                            use12-hour>
                  </datetime>
                </b-col>

                <b-col>
                  <div class="content-labels">End date</div>
                  <datetime type="datetime"
                            v-model="contest.available.end"
                            class="theme-orange"
                            :minDatetime="minDatetime"
                            :phrases="{ok: 'Continue', cancel: 'Exit'}"
                            use12-hour>
                  </datetime>
                </b-col>
              </b-form-row>

            </b-form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import axios from 'axios'
import { Datetime } from 'vue-datetime'
import { DateTime as LuxonDateTime } from 'luxon'

export default {
  name: 'ContestsEdit',
  components: {
    axios,
    Datetime
  },
  data () {
    return {
      contest: {},
      minDatetime: LuxonDateTime.local().toISO(),
      stations: [],
      stationSelected: {
        label: null,
        value: null
      }
    }
  },
  mounted () {
    EventHandler.emit('isLoading', false)
    this.contestID = this.$route.params.id

    axios.all([this.getContest(), this.getStations()])
      .then(axios.spread((contest, stations) => {
        this.contest = contest.data

        this.stations = stations.data.map((st) => {
          if (st._id === this.contest.station) {
            this.stationSelected = {
              label: `${st.title} - ( ${st.callSign} )`,
              value: st._id
            }
          }
          return {
            label: `${st.title} - ( ${st.callSign} )`,
            value: st._id
          }
        })
      }))
      .catch((err) => {
        console.log(err)
        Event.emit('isLoading', false)
      })
  },
  methods: {
    updateContest () {
      if (this.contest.available.start >= this.contest.available.end) {
        this.$notify({
          group: 'notifications',
          title: '<code>Start Date</code> should be before <code>End date</code>',
          text: '',
          type: 'error'
        })
        return
      }
      this.$http.put('/contests', {contest: this.contest})
        .then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Contest updated successfully :)',
              text: '',
              type: 'success'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    selectStation (station) {
      this.stationSelected = station
      this.contest.station = station.value
    },
    getStations () {
      return this.$http.get('/stations')
    },
    getContest () {
      return this.$http.get(`/contests/${this.contestID}`)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.update-contest{
  float: right;
}

.content-labels {
  font-size: 14px;
  font-weight: bold;
  margin-right: 15px;
  padding-bottom: calc(0.375rem + 1px);
}

.status-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.contest-edit-component .required {
  color: #f2494a;
  font-size: 10px;
}

.col-form-label {
  font-size: inherit;
}

.contest-edit-component .contest-checkbox {
  margin-top: 10px;
}

.contest-edit-component .contest-checkbox input {
  width: auto;
  height: auto !important;
  display: inherit;
  margin-bottom: 0;
}
</style>
