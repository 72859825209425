<template>
    <div class="cards-type-playlist">
      <div class="container">
        <div class="col-8 offset-2">
          <b-form @submit="save" class="bootstrap-form">

            <b-form-row>
              <b-col>
                <b-input-group>
                  <b-form-input readonly required v-model="cardArtist.id"></b-form-input>
                  <b-input-group-append>
                    <b-btn variant="info" class="card-btn btn-add btn-modal" @click="openArtistsModal()">Add Artist</b-btn>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-form-row>

            <BaseCardForm :card="cardArtist" :imageSizes="imageUpload" :video="video" />

            <div class="wrapper-btn">
              <!-- <b-button variant="primary" class="card-btn btn-add btn-add-img" @click="addImage">Add Images</b-button> -->
              <b-button type="submit" variant="primary" class="card-btn">{{type === 'create' ? 'Create Card' : 'Update Card'}}</b-button>
            </div>
          </b-form>
        </div>
      </div>

      <b-modal id="addArtist" ref="addArtist" :ok-only="true" ok-title="Add" @ok="addTocard" size="md" title="Select Station">
        <div class="row modal-wrapper">
          <div class="col-12">
            <input type="text" placeholder="Search artists..." v-model="artistQuery" @input="queryArtists" />
          </div>
          <div class="col-12">
            <ul class="playlistUpdated-list">
              <li v-for="artist in artists" v-bind:key="artist._id" @click="selectArtist(artist)" v-bind:class="{selected: artist.selected}">
                <div class="playlists-list-card-img">
                  <img :src="artist.exploreImagery.hdpiImageURL" alt="" v-if="artist.exploreImagery && artist.exploreImagery.hdpiImageURL" />
                </div>
                <div class="playlist-info">
                  <div class="playlist-title">{{artist.name}}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </b-modal>

    </div>

</template>


<script>
import { Datetime } from 'vue-datetime'
import { DateTime as LuxonDateTime } from 'luxon'
import BaseCardForm from './BaseCardForm'
import CardTypeCommon from './CardTypeCommon'

export default {
  name: 'ArtistType',
  components: {
    BaseCardForm,
    Datetime
  },
  props: ['card', 'type'],
  data () {
    const utils = new CardTypeCommon(this)
    return {
      formData: new FormData(),
      utils: utils,
      video: utils.uploads.video[0],
      imageUpload: utils.uploads.images,
      artists: [],
      artistSelected: null,
      artistQuery: '',
      minDatetime: LuxonDateTime.local().toISO()
    }
  },
  computed: {
    cardArtist: {
      get () {
        return this.card
      },
      set (value) {
        this.card = value
      }
    }
  },
  mounted () {
    this.utils.setCardDefaultValues()
  },
  methods: {
    addImage () {
      let images = this.imageUpload.filter((img) => {
        return img['750x750'] && img['1000x1100']
      })

      if (images.length === this.imageUpload.length) {
        this.imageUpload.push({
          file750: null,
          '750x750': null,
          placeholder750: 'Image 750 x 750px',
          width750: 750,
          height750: 750,

          file1000: null,
          '1000x1100': null,
          placeholder1000: 'Image 1000 x 1100px',
          width1000: 1000,
          height1000: 1100
        })
      }
    },
    removeImage (index) {
      this.imageUpload.splice(index, 1)
    },
    uploadVideo (event, video) {
      let input = event.target
      this.formData = new FormData()

      if (input.files && input.files[0]) {
        if (/\.(mp4)$/i.test(input.files[0].name)) {
          let fileSize = input.files[0].size / 1024 / 1024

          if (fileSize > 3) {
            this.$notify({
              group: 'notifications',
              title: 'Video File size exceeds 3 MB :)',
              text: '',
              type: 'error'
            })

            return
          }

          this.formData.append('file', input.files[0])

          this.$http.post('/cards/video', this.formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
              if (res.success) {
                this.video['1000x1100'] = res.data[0]
                this.video.html = `<video controls><source src="${res.data[0]}" type="video/mp4"></video>`
              } else {
                this.$notify({
                  group: 'notifications',
                  title: 'Video file is required :)',
                  text: '',
                  type: 'error'
                })
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          this.$notify({
            group: 'notifications',
            title: 'Video should be mp4 format :(',
            text: '',
            type: 'error'
          })
        }
      }
    },
    removeVideoCard () {
      this.video.html = null
      this.video.file1000 = null
      this.video['1000x1100'] = null
      this.video.placeholder1000 = 'Video 1000 x 1100px'
      this.$refs.videoInput.reset()
    },
    uploadImg (event, imgWidth, imgHeight, type, index) {
      let input = event.target

      if (input.files && input.files[0]) {
        if (/\.(jpe?g|png|gif)$/i.test(input.files[0].name)) {
          const URL = window.URL || window.webkitURL
          const image = new Image()
          image.src = URL.createObjectURL(input.files[0])
          image.onload = () => {
            let height = image.height
            let width = image.width

            if (height !== imgHeight || width !== imgWidth) {
              this.$notify({
                group: 'notifications',
                title: `Image size allowed ${imgHeight} x ${imgWidth}px :(`,
                text: '',
                type: 'error'
              })
              this.imageUpload[index].file = null

              return false
            } else {
              this.formData.append(`file${index}`, input.files[0])

              this.$http.post('/cards/image', this.formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then((res) => {
                  if (res.success) {
                    this.imageUpload[index][type] = res.data[0]
                    this.formData.delete(`file${index}`)
                  } else {
                    this.$notify({
                      group: 'notifications',
                      title: 'Image file is required :)',
                      text: '',
                      type: 'error'
                    })
                    this.formData.delete(`file${index}`)
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
            }
          }
        } else {
          this.$notify({
            group: 'notifications',
            title: 'Image should be jpg :(',
            text: '',
            type: 'error'
          })
        }
      }
    },
    save (evt) {
      evt.preventDefault()
      this.card.type = 'artist'

      if (!this.cardArtist.id) {
        this.$notify({
          group: 'notifications',
          title: 'Artist selection is required :(',
          text: '',
          type: 'error'
        })

        return
      }

      if (!this.utils.validateCardBeforeSave()) {
        return
      }

      if (this.type === 'create') {
        this.create()
      } else {
        this.update()
      }
    },
    queryArtists () {
      return this.$http.get(`/artists/search?q=${this.artistQuery}`).then((res) => {
        this.artists = res.data
      }).catch((err) => {
        console.log(err)
      })
    },
    openArtistsModal () {
      EventHandler.emit('isLoading', true)
      this.$refs.addArtist.show()
      EventHandler.emit('isLoading', false)
    },
    selectArtist (artist) {
      if (artist.selected) {
        artist.selected = false
      } else {
        this.artists.forEach((item) => {
          if (item.selected) {
            item.selected = false
          } else {
            this.$set(item, 'selected', false)
          }
        })
        this.$set(artist, 'selected', true)
        this.artistSelected = artist
      }
    },
    addTocard () {
      if (!this.artistSelected || !this.artistSelected.selected) {
        this.$notify({
          group: 'notifications',
          title: 'No Artist selected :(',
          text: '',
          type: 'error'
        })

        return
      }

      this.cardArtist.id = this.artistSelected['_id']
      this.cardArtist.titleEnglish = this.artistSelected.name
      this.cardArtist.titleSpanish = this.artistSelected.name
    },
    create () {
      this.$http.post('/cards', {card: this.cardArtist})
        .then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Card created successfully :)',
              text: '',
              type: 'success'
            })
            this.$router.push('/admin/cards')
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Images are required :)',
              text: '',
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    update () {
      this.cardArtist.id = this.cardArtist.id.toString()
      this.$http.put('/cards', {card: this.cardArtist})
        .then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Card updated successfully :)',
              text: '',
              type: 'success'
            })
            this.$router.push('/admin/cards')
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Images are required :)',
              text: '',
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style>
  .card-image video {
    width: 100%;
    margin-bottom: 10px;
  }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .cards-type-playlist {
  margin-top: 6%;
  height: calc(100vh - 210px);
  overflow: scroll;
} */

.cards-type-playlist .custom-file {
  margin-bottom: 15px;
}

.cards-type-playlist .wrapper-btn{
  display: flex;
  justify-content: flex-end;
}

.cards-type-playlist .card-btn{
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  text-transform: uppercase;
}

.cards-type-playlist .btn-add{
  margin-right: 10px;
}

.cards-type-playlist .btn-modal{
  height: 45px;
  margin-right: 0;
}

.card-image img, .card-image video{
  width: 100%;
  margin-bottom: 10px;
}

ul.playlistUpdated-list{
    list-style-type: none;
    overflow-y: scroll;
    max-height: 400px;
    padding: 3px;
}

ul.playlistUpdated-list .playlist-title{
  font-size: 12px;
  font-weight: bold;
}

ul.playlistUpdated-list .playlist-tracks{
  font-size: 10px;
  font-weight: bold;
}

ul.playlistUpdated-list li {
  list-style-type: none;
  display: flex;
  background-color: #fff;
  margin-bottom: 10px;
  height: 60px;
  align-items: center;
  border: 1px solid #eaeaea;
  cursor: pointer;
}

ul.playlistUpdated-list li.selected {
  background-color: #eaeaea;
}

ul.playlistUpdated-list li .playlists-list-card-img {
  margin-right: 15px;
}

ul.playlistUpdated-list li .playlists-list-card-img img{
  height: 60px;
  width: auto;
}

.image-group{
  display: flex;
  flex: 1;
  border: 1px solid #eaeaea;
  padding-top: 15px;
  margin-bottom: 15px;
  position: relative;
  flex-wrap: wrap;
}

.image-group .remove-img{
    position: absolute;
    top: -10px;
    right: -10px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #eaeaea;
    background-color: #eaeaea;
    text-align: center;
    cursor: pointer;
}

.video-labels {
  font-weight: bold;
  font-size: 12px;
}

</style>
