import { render, staticRenderFns } from "./UploadSvg.vue?vue&type=template&id=7cd77aa9&scoped=true"
import script from "./UploadSvg.vue?vue&type=script&lang=js"
export * from "./UploadSvg.vue?vue&type=script&lang=js"
import style0 from "./UploadSvg.vue?vue&type=style&index=0&id=7cd77aa9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cd77aa9",
  null
  
)

export default component.exports