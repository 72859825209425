module.exports = {
  thumbnail: {
    label: 'List View Image - 1296x560',
    description: 'A 1296x560 image that is used on AppleTV & FireTV to display the livestream',
    imageKey: 'thumbnail',
    loading: false,
    width: 1296,
    height: 560,
    valid: false,
    isVideoType: true,
    url: ''
  },
  row: {
    label: 'Square Image - 1440px',
    description: 'A 1440x1440 image that is used on the square chips of the iOS & Android app homepage lower rows',
    imageKey: 'row',
    loading: false,
    width: 1440,
    height: 1440,
    valid: false,
    isVideoType: true,
    url: ''
  },
  landing: {
    label: 'Homepage Featured Image - 1920x850px',
    description: 'The URL of a 1920x850 image that is used on the AppleTV, FireTV, AndroidTV, and Roku homepage when that video is selected',
    imageKey: 'landing',
    loading: false,
    width: 1920,
    height: 850,
    valid: false,
    isVideoType: true,
    url: ''
  },
  live_now_small_img: {
    label: 'Circular Image - 300x300px',
    description: 'A square 300x300 image that is used on the top circular icons of the iOS & Android app.  The image is cropped at the mobile application level.',
    imageKey: 'live_now_small_img',
    isLoading: false,
    width: 300,
    height: 300,
    url: ''
  },
  placeholder_horizontal_img: {
    label: 'Placeholder Horizontal Image - 1920x1080px',
    description: 'A 1920x1080 image that is used as a placeholder image after a show ends or if a stream is down.',
    imageKey: 'placeholder_horizontal_img',
    isLoading: false,
    width: 1920,
    height: 1080,
    url: ''
  }
}
