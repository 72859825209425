<template>
  <div>
    <carousel
      :items="1"
      :nav="false"
      :loop="true"
      :autoplay="true"
      :autoplay-speed="1500"
      :autoplay-timeout="5000"
      :autoplay-hover-pause="true"
      :lazy-load="true"
      class="owl-theme-preview cards"
    >
      <div v-for="(item, index) in row.items" :key="index">
        <row-preview-item :item="item" :displayTitles="row.displayTitles"></row-preview-item>
      </div>
    </carousel>
  </div>
</template>
<script>
import carousel from 'v-owl-carousel'
import RowPreviewItem from './RowPreviewItem'
export default {
  name: 'RowPreviewCards',
  props: ['row'],
  components: {RowPreviewItem, carousel}
}
</script>
