<template>
  <div class="dmca-wrapper">
    <div class="label">
      DMCA
    </div>
    <ul>
      <li>
        <i class="fas fa-circle" :class="{valid: isAtLeastThreeHoursLong}"></i>
        <span>More than 3 hours of music</span>
      </li>
      <li>
        <i class="fas fa-circle" :class="{valid: hasLessThanFiveSongsFromSameArtist}"></i>
        <span>Less than 5 songs from same artist:<br/>
          <span class="repeated-artist-warning" v-if="repeatedArtists.length > 0" v-for="message in repeatedArtists">{{message}}</span>
        </span>
      </li>
      <li>
        <i class="fas fa-circle" :class="{valid: hasLessThanFourSongFromSameAlbum}"></i>
        <span>Has less than 4 songs from same album</span>
      </li>
      <li>
        <i class="fas fa-circle" :class="{valid: hasLessThanThreeSongsInARowFromSameAlbum}"></i>
        <span>Less than 3 songs in a row from same album.</span>
      </li>
      <li>
        <i class="fas fa-circle" :class="{valid: hasLessThanFourSongsInARowFromSameArtist}"></i>
        <span>Has less than 4 songs in a row from same artist.</span>
      </li>
    </ul>
  </div>
</template>


<script>
  export default {
    name: 'dmca',
    props: ['playlist'],
    data () {
      return {
        repeatedArtists: []
      }
    },
    computed: {
      isAtLeastThreeHoursLong () {
        let totalTime = 0

        this.playlist.tracks.forEach(function (track) {
          if (track.track.duration === '00:00') {
            track.track.duration = 0
          }
          totalTime += Number(track.track.duration)
        })

        return (3 * 60 * 60 < totalTime)
      },
      hasLessThanFiveSongsFromSameArtist () {
        const artists = {}
        this.repeatedArtists = []
        this.playlist.tracks.forEach(function (track) {
          // let artistId = track.track.artist._id
          const artistID = track.track.artist.name
          if (!artists[artistID]) {
            artists[artistID] = 0
          }
          artists[artistID]++
        })

        let passesTest = true
        for (let artistName in artists) {
          const count = artists[artistName]
          if (count >= 5) {
            this.repeatedArtists.push(`${artistName} (${count} times)`)
            passesTest = false
          }
        }

        if (passesTest) {
          this.repeatedArtists = []
        }

        return passesTest
      },
      hasLessThanFourSongFromSameAlbum () {
        let releases = {}

        this.playlist.tracks.forEach(function (track) {
          let releaseId = track.track.release.id

          if (!releases[releaseId]) {
            releases[releaseId] = 0
          }

          releases[releaseId]++
        })

        for (let k in releases) {
          if (releases[k] >= 4) {
            return false
          }
        }

        return true
      },
      hasLessThanThreeSongsInARowFromSameAlbum () {
        // Assume true unless proven wrong
        let hasLessThanThreeSongsInARowFromSameAlbum = true

        this.playlist.tracks.forEach(function (track, i, playlist) {
          let a = track.track
          let b = playlist[i - 1]
          let c = playlist[i - 2]

          if (a && b && c) {
            a = a.release.id
            b = b.track.release.id
            c = c.track.release.id

            if (a === b && b === c) {
              hasLessThanThreeSongsInARowFromSameAlbum = false
            }
          }
        })

        return hasLessThanThreeSongsInARowFromSameAlbum
      },
      hasLessThanFourSongsInARowFromSameArtist () {
        // Assume true unless proven wrong
        let hasLessThanFourSongsInARowFromSameArtist = true

        this.playlist.tracks.forEach(function (track, i, playlist) {
          let a = track.track
          let b = playlist[i - 1]
          let c = playlist[i - 2]
          let d = playlist[i - 3]

          if (a && b && c && d) {
            a = a.artist._id
            b = b.track.artist._id
            c = c.track.artist._id
            d = d.track.artist._id

            if (a === b && b === c && c === d) {
              hasLessThanFourSongsInARowFromSameArtist = false
            }
          }
        })

        return hasLessThanFourSongsInARowFromSameArtist
      }
    },
    created () {
      // console.log('DMCA', this.playlist)
      // console.log('isAtLeastThreeHoursLong', this.isAtLeastThreeHoursLong)
      // console.log('HasLessThanFourSongFromSameAlbum', this.HasLessThanFourSongFromSameAlbum)
      // console.log('hasLessThanFiveSongsFromSameArtist', this.hasLessThanFiveSongsFromSameArtist)
      // console.log('hasLessThanThreeSongsInARowFromSameAlbum', this.hasLessThanThreeSongsInARowFromSameAlbum)
      // console.log('hasLessThanFourSongsInARowFromSameArtist', this.hasLessThanFourSongsInARowFromSameArtist)
    },
    methods: {
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .repeated-artist-warning {
    background: red;
    color: white;
    text-decoration: none;
    padding: 0.3em;
    display: block;
  }

  .dmca-wrapper{
    background-color: #fff;
    border: 1px solid #eaeaea;
    display: flex;
  }

  .dmca-wrapper .label{
    max-width: 30px;
    padding: 0 10px;
    word-wrap: break-word;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    background-color: #495057;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    line-height: 1.4
  }

  .dmca-wrapper ul{
    padding: 10px 15px !important;
    list-style: none;
    margin-bottom: 0;
  }

  .dmca-wrapper ul li{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 2px;
    display: flex;
    align-items: center
  }

  .dmca-wrapper ul li .fas{
    font-size: 7px;
    margin-right: 6px;
    color: #f2494a;
  }

  .dmca-wrapper ul li .fas.valid{
    color: #8cd200;
  }
</style>
