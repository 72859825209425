<template>
  <div class="tests-component ads">
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-5">
          <div class="title">
            <router-link to="/admin/tests/ads">ADS TESTS </router-link>/
            <span v-if="test && test.title">{{test.title}}</span>
          </div>
        </div>
        <div class="col-7 pull-right">
          <div class="typeahead-wrapper float-right">
            <button class="typeahead-btn" @click="updateTest">Update</button>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">

      <div class="alert alert-light">
        <p><strong>Testing:</strong> Here you can control the individual states of several items across several items to test advertisements.</p>
        <p>You will most likely want to use the following ad units</p>
        <ul style="margin-left: 1.5em;">
          <li><code>/10297243/TEST_VAST_VideoPreRoll_1920x1080</code></li>
          <li><code>/10297243/TEST_VAST_VideoPreRoll_Landscape_640x480</code></li>
        </ul>
      </div>

      <transition-group tag="div" name="list-animation">
        <div v-for="(test, index) in test.tests" v-bind:key="test.type" class="card">
          <div class="card-title">
            <span>{{test.name}}</span>
            <div class="test-device">
              <span>Is Sponsored</span>
              <input type="checkbox" v-model="test.isSponsored" />
              <span>Disable Ads</span>
              <input type="checkbox" v-model="test.disableAds" />
            </div>
          </div>
          <div class="form">
            <div v-if="test.name === 'Stations'">
              <p><strong>Testing:</strong> Stations by default will have an advertising ID similar to the following pattern:</p>
              <p><code>/10297243/LaMusica_App_VideoPreRoll_Radio_WMEG_640x480v</code></p>
            </div>

            <div v-if="test.name === 'Livestreams'">
              <p><strong>Testing:</strong> Streams by default will have one of the following advertising ID typically:
                <ul class="livestream-sample-ad-units">
                  <li><code>/10297243/LaMusica_App_VideoPreRoll_Livestream_LosReyesdelaPunta_640x480v</code></li>
                  <li><code>/10297243/LaMusica_App_VideoPreRoll_Livestream_ElDespelote_640x480v</code></li>
                  <li><code>/10297243/LaMusica_App_VideoPreRoll_Livestream_ElCirco_640x480v</code></li>
                  <li><code>/10297243/LaMusica_App_VideoPreRoll_Livestream_LMovimiento_640x480v</code></li>
                  <li><code>/10297243/LaMusica_App_VideoPreRoll_Livestream_WhatsUp_640x480v</code></li>
                  <li><code>/10297243/LaMusica_App_VideoPreRoll_Livestream_ElBrunch_640x480v</code></li>
                  <li><code>/10297243/LaMusica_App_VideoPreRoll_Livestream_NacionZ_640x480v</code></li>
                  <li><code>/10297243/LaMusica_App_VideoPreRoll_Livestream_LaGarata_640x480v</code></li>
                  <li><code>/10297243/LaMusica_App_VideoPreRoll_Livestream_LosReyesdelaPunta_640x480v</code></li>
                  <li><code>/10297243/LaMusica_App_VideoPreRoll_Livestream_LaComay_640x480v</code></li>
                  <li><code>/10297243/LaMusica_App_VideoPreRoll_Livestream_Informe79_640x480v</code></li>
                  <li><code>/10297243/LaMusica_App_VideoPreRoll_Livestream_LosHP_640x480v</code></li>
                </ul>
              </p>
            </div>

            <div class="test-message">
              <div class="test-resource">
                <span class='test-placeholder'>Custom DFP VAST Preroll Video Ad Unit</span>
                <input type="text" class="test-input test-resource" v-model="test.DFPPrerollVideoAdUnitId" />
              </div>
            </div>
            <div class="test-message">
              <div class="test-resource">
                <span class='test-placeholder'>Custom SpotX VAST Preroll Video Ad Unit</span>
                <input type="text" class="test-input test-resource" v-model="test.SpotXPrerollVideoAdUnitId" />
              </div>
            </div>
            <div class="test-message">
              <div class="test-resource">
                <input type="text" class="test-input" v-model="test.resourceId" />
                <div class="button-resource" @click="openSearch(test.type, index)">Add Resource</div>
              </div>
            </div>
            <div>
              {{test.title}}
            </div>
          </div>
        </div>
      </transition-group>
    </div>


    <b-modal id="findResource" ref="findResource"
             :no-close-on-backdrop="true"
             :no-close-on-esc="true"
             :hide-header-close="true"
             :ok-only="true"
             @ok="handleClose"
             ok-title="Cancel"
             size="md"
             :title="'Find ' + testTypeToAddResource">

      <div class="row modal-wrapper">
        <div class="col-12">
          <div class="test-resource">
            <input type="text" class="test-input" v-model="query" />
            <div class="button-resource" @click="searchResource">SEARCH</div>
          </div>
        </div>
        <div class="col-12">
          <ul class="resource-list">
            <li v-for="resource in resources" v-bind:key="resource.resourceId" @click="selectResource(resource)">
              {{resource.title}}
            </li>
          </ul>
        </div>
      </div>

    </b-modal>
  </div>
</template>


<script>
  export default {
    name: 'AdsEdit',
    data () {
      return {
        resources: [],
        test: {},
        query: '',
        testTypeToAddResource: null,
        testIndexToAddResource: null
      }
    },

    mounted () {
      this.testID = this.$route.params.id
      this.$http.get(`/tests/ads/${this.testID}`).then((res) => {
        this.test = res.data
      })
    },

    methods: {
      openSearch (type, index) {
        this.testTypeToAddResource = type
        this.testIndexToAddResource = index
        this.$refs.findResource.show()
      },
      handleClose () {
        this.testTypeToAddResource = null
        this.testIndexToAddResource = null
        this.resources = []
        this.query = ''
      },
      searchResource () {
        this.$http.get(`/tests/ads/search?q=${this.query}&resource=${this.testTypeToAddResource}`)
          .then((res) => {
            this.resources = res.data
          })
          .catch((err) => {
            console.log(err)
            this.$notify({
              group: 'notifications',
              title: 'Sorry something went wrong. Please try again :(',
              text: '',
              type: 'error'
            })
          })
      },
      selectResource (resource) {
        this.test.tests[this.testIndexToAddResource].resourceId = resource.resourceId
        this.test.tests[this.testIndexToAddResource].isSponsored = resource.isSponsored
        this.test.tests[this.testIndexToAddResource].disableAds = resource.disableAds
        this.test.tests[this.testIndexToAddResource].SpotXPrerollVideoAdUnitId = resource.SpotXPrerollVideoAdUnitId
        this.test.tests[this.testIndexToAddResource].DFPPrerollVideoAdUnitId = resource.DFPPrerollVideoAdUnitId
        this.test.tests[this.testIndexToAddResource].title = resource.title
        this.testTypeToAddResource = null
        this.testIndexToAddResource = null
        this.resources = []
        this.query = ''
        this.$refs.findResource.hide()
      },
      updateTest () {
        this.$http.put('/tests/ads', {test: this.test}).then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Test updated successfully :)',
              text: '',
              type: 'success'
            })
          }
        }).catch((err) => {
          console.log(err)
          this.$notify({
            group: 'notifications',
            title: 'There was an error updating this Test :(',
            text: '',
            type: 'error'
          })
        })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

  .tests-component{
    display: block;
    width: 100%;
  }

  .tests-component .page-content .test-title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    /* justify-content: flex-end; */
    align-items: center;
  }

  .tests-component .page-content .test-title .send {
    background-color: #de4242;
    color: #fff;
    padding: 5px 15px;
    font-size: 12px;
    cursor: pointer;
    margin-right: 10px;
  }

  .tests-component .page-content .card {
    position: relative;
    background-color: #fff;
    margin-bottom: 10px;
    border: 1px solid #eaeaea;
    padding: 20px;
  }

  .tests-component .page-content .card .card-title {
    margin-bottom: 10px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tests-component .page-content .card .card-title .send {
    background-color: #de4242;
    color: #fff;
    padding: 5px 15px;
    font-size: 10px;
    cursor: pointer;
  }

  .tests-component .page-content .card .form .test-message {
    display: flex;
    margin-bottom: 15px;
  }

  .test-message input {
    height: 40px;
    padding: 0 10px;
  }

  .test-resource,
  .test-input {
    flex: 1;
  }

  .test-resource {
    display: flex;
    align-items: center;
  }

  .button-resource {
    font-size: 12px;
    padding: 11px;
    background-color: #de4242;
    color: #fff;
    cursor: pointer;
  }

  .tests-component .page-content .test-title .send:hover,
  .button-resource:hover {
    background-color: #c52626;
  }

  .first-column {
    margin-right: 15px;
  }

  .test-device input{
    margin-right: 15px;
  }

  .test-placeholder {
    padding: 10px;
    background-color: #e8e8e8;
  }

  ul.resource-list {
    list-style-type: none;
    overflow-y: scroll;
    max-height: 400px;
    padding: 3px;
  }

  ul.resource-list li {
    list-style-type: none;
    display: flex;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 15px;
    align-items: center;
    border: 1px solid #eaeaea;
    cursor: pointer;
    font-weight: bold;
  }

  ul.resource-list li:hover {
    background-color: #eaeaea;
  }

  .tests-component .ads .modal-wrapper .test-resource{
    margin-bottom: 15px;
  }

  .tests-component .ads .modal-wrapper .button-resource {
    font-size: 14px;
  }

  .tests-component .ads .modal-wrapper input {
    margin-bottom: 0;
  }

  .livestream-sample-ad-units {
    margin-left: 1em;
    padding-left: 1.5em;
    margin-bottom: 1em;
  }
</style>
