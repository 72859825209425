<template>
  <div class="typeahead">
    <input id="input" class="form-control" type="text" placeholder="Explore playlist collection..." v-model="query"  @keyup="keymonitor">
    <div class="loading" v-if="isLoading">
      <div class="spinner">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5"></div>
      </div>
    </div>
    <ul v-if="searchList.length > 0">
      <li v-for="playlist in searchList" v-bind:key="playlist._id" @click="onSelected(playlist)">
        <div class="playlist-img">
          <img :src="playlist.meta.exploreImagery.hdpiImageURL" alt="" v-if="playlist.meta && playlist.meta.exploreImagery && playlist.meta.exploreImagery.hdpiImageURL" />
          <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!playlist.meta || !playlist.meta.exploreImagery || !playlist.meta.exploreImagery.hdpiImageURL"/>
        </div>
        {{playlist.title}}
      </li>
    </ul>
  </div>
</template>


<script>
  export default {
    name: 'typeaheadCategories',

    data () {
      return {
        searchList: [],
        query: '',
        isLoading: false,
        timeout: null
      }
    },

    mounted () {
      EventHandler.listen('close-typeahead', () => {
        this.searchList = []
        this.query = ''
        this.isLoading = false
      })
    },

    methods: {
      onSelected (playlist) {
        this.$emit('playlistSelected', playlist)
      },
      keymonitor (event) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (event.target.value === '') {
            this.searchList = []
            this.isLoading = false
            return
          }
          this.isLoading = true
          this.$http.get(`/playlists/search?q=${event.target.value}`)
            .then((res) => {
              this.searchList = res.data
              this.isLoading = false
            })
            .catch((err) => {
              console.log(err)
            })
        }, 250)
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading{
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1;
    padding: 30px 0;
    border-top: 1px solid #dcdcdc;
    -webkit-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

  .spinner {
    margin: 0 auto;
    height: 40px;
    font-size: 10px;
  }

  .spinner > div {
    background-color: #f2494a;
    height: 100%;
    width: 6px;
    display: inline-block;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
  }

  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }  20% {
         transform: scaleY(1.0);
         -webkit-transform: scaleY(1.0);
       }
  }
</style>
