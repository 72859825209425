<template>
    <div class="dashboard-component">
      <div class="page-header">
        <div class="row align-items-center header">
          <div class="col-3">
            <div class="title">
              <router-link to="/admin/dashboard">Dashboard </router-link>/
              <select v-model="page" @change="getData()">
                <option v-for="page in pages" v-bind:value="page.value" v-bind:key="page.value">
                  {{ page.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content">
        <transition-group tag="div" class="row" name="list-animation">
          <div v-for="chart in charts" v-bind:key="chart.type" :class="chart.class">
            <div class="card-chart" :class="{'chart-table': chart.type === 'Table', 'red-chart': chart.type === 'Table'}">
              <div class="chart-loading" v-show="chartLoading">
                <div class="spinner">
                  <div class="rect1"></div>
                  <div class="rect2"></div>
                  <div class="rect3"></div>
                  <div class="rect4"></div>
                  <div class="rect5"></div>
                </div>
              </div>
              <div class="total" v-if="chart.type === 'Table' && chart.totalTableData">
                <strong>Top pages report</strong> <small>/ last week</small><br>
                <ul>
                  <li>{{formatNumber(chart.totalTableData['ga:pageviews'])}} <span>Total page views</span></li>
                  <li>{{formatNumber(chart.totalTableData['ga:newUsers'])}} <span>Total new users</span></li>
                  <li>{{formatNumber(chart.totalTableData['ga:sessions'])}} <span>Total new sessions</span></li>
                </ul>
              </div>
              <div class="title" v-html="chart.title" v-if="chart.type !== 'Table'"></div>
              <GChart :type="chart.type" :data="chart.tableData" :options="chart.options" />
            </div>
          </div>
        </transition-group>
      </div>

    </div>
</template>


<script>
import axios from 'axios'
import { GChart } from 'vue-google-charts'

export default {
  name: 'Dashboard',
  components: {GChart},
  data () {
    return {
      chartLoading: false,
      pages: [
        {
          value: 'all',
          name: 'Overall'
        },
        {
          value: '1460249',
          name: 'www.lamusica.com'
        },
        {
          value: '6941241',
          name: 'lamega.fm'
        },
        {
          value: '1460329',
          name: 'lamega.com'
        },
        {
          value: '1460425',
          name: 'mega963fm.com'
        },
        {
          value: '6941256',
          name: 'lanueva94.com'
        },
        {
          value: '1460500',
          name: 'elzol.com'
        },
        {
          value: '1460331',
          name: '931amor.com'
        },
        {
          value: '1460473',
          name: '979laraza.com'
        },
        {
          value: '1460419',
          name: 'mega.tv'
        },
        {
          value: '1460517',
          name: 'laley1079.com'
        },
        {
          value: '6941226',
          name: 'zeta93.lamusica.com'
        },
        {
          value: '58322948',
          name: 'z92miami.com'
        },
        {
          value: '179171959',
          name: 'play96fm.com'
        },
        {
          value: '80960558',
          name: 'ritmo95.com'
        },
        {
          value: '2855477',
          name: 'yosoyraza.com'
        }
      ],
      page: '1460249',
      charts: []
    }
  },

  mounted () {
    EventHandler.emit('isLoading', true)
    this.charts = [
      {
        type: 'LineChart',
        title: 'Page Views daily report <span>/ last month</span>',
        class: 'col-md-8',
        options: {
          height: 450,
          fontName: 'Avenir',
          fontSize: 11,
          colors: ['#f24a4a'],
          legend: { position: 'none' },
          animation: {
            startup: true,
            duration: 1000,
            easing: 'out'
          }
        },
        params: {
          'start-date': '30daysAgo',
          'end-date': 'today',
          'dimensions': 'ga:date',
          'metrics': 'ga:pageviews',
          'output': 'dataTable'
        },
        tableData: {},
        totalTableData: null
      },
      {
        type: 'Table',
        title: 'Total Page views <span> /last week</span>',
        class: 'col-md-4',
        options: {
          width: '100%',
          height: '100%',
          cssClassNames: {
            headerRow: 'headerRow',
            style: {
              'font-style': 'bold',
              'font-size': '22px'
            }
          }
        },
        params: {
          'start-date': '7daysAgo',
          'end-date': 'today',
          'dimensions': 'ga:pagePath',
          'metrics': 'ga:pageviews,ga:newUsers,ga:sessions',
          'sort': '-ga:pageviews',
          'max-results': '8',
          'output': 'dataTable'
        },
        tableData: {},
        totalTableData: null
      },
      {
        type: 'PieChart',
        title: 'Devices report <span>/ last week</span>',
        class: 'col-md-4',
        options: {
          height: 450,
          fontName: 'Avenir',
          fontSize: 14,
          slices: [
            {
              color: '#f24a4a'
            },
            {},
            {
              color: 'red'
            }
          ],
          legend: {
            position: 'bottom',
            textStyle: {
              color: 'blue',
              fontSize: 10
            }
          },
          pieHole: 0.4
        },
        params: {
          'start-date': '7daysAgo',
          'end-date': 'today',
          'dimensions': 'ga:deviceCategory',
          'metrics': 'ga:sessions',
          'sort': '-ga:sessions',
          'output': 'dataTable'
        },
        tableData: {},
        totalTableData: null
      },
      {
        type: 'ColumnChart',
        title: 'Unique Page Views report <span>/ last week</span>',
        class: 'col-md-8',
        options: {
          colors: ['#f24a4a'],
          legend: { position: 'none' },
          height: 450,
          fontName: 'Avenir',
          fontSize: 11,
          animation: {
            startup: true,
            duration: 1000,
            easing: 'out'
          }
        },
        params: {
          'start-date': '7daysAgo',
          'end-date': 'today',
          'dimensions': 'ga:pagePath',
          'metrics': 'ga:uniquePageviews',
          'sort': '-ga:uniquePageviews',
          'max-results': '10',
          'output': 'dataTable'
        },
        tableData: {},
        totalTableData: null
      }
    ]

    const requests = this.getRequests(this.page)

    axios.all(requests).then(axios.spread((lineChart, table, pieChart, columnChart) => {
      this.charts[0].tableData = lineChart.data
      if (table.data) {
        this.charts[1].tableData = table.data.dataTable
        this.charts[1].totalTableData = table.data.totals
      }
      this.charts[2].tableData = pieChart.data
      this.charts[3].tableData = columnChart.data
      EventHandler.emit('isLoading', false)
    }))
  },

  methods: {
    formatNumber (number) {
      if (isNaN(number)) {
        number = parseInt(number)
      }

      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getData () {
      this.chartLoading = true
      let requests = this.getRequests(this.page)

      axios.all(requests)
        .then(axios.spread((lineChart, table, pieChart, columnChart) => {
          this.charts[0].tableData = lineChart.data
          if (table.data) {
            this.charts[1].tableData = table.data.dataTable
            this.charts[1].totalTableData = table.data.totals
          }
          this.charts[2].tableData = pieChart.data
          this.charts[3].tableData = columnChart.data
          this.chartLoading = false
        }))
    },
    getRequests (page) {
      let requests = []

      this.charts.forEach((chart, index) => {
        requests[index] = (function (type, page, params, http) {
          return http.get('/analytics', {params: { type: type, page: page, params: params }})
        }(chart.type, page, chart.params, this.$http))
      })

      return requests
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.dashboard-component{
  display: block;
  width: 100%;
}

.dashboard-component .title {
  align-items: center;
  margin-top: 10px;
}

.dashboard-component select {
  margin-left: 5px;
}

.card-chart {
  position: relative;
  height: 500px;
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid #eaeaea;
}

.card-chart.chart-table {
  padding: 20px;
  overflow: scroll;
}

.card-chart.chart-table .total{
  color: #fff;
  font-size: 26px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
}

.card-chart .title {
  margin-top: 20px;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  width: 100%;
  text-align: center;
}

.card-chart .title span {
  font-size: 14px;
}

.card-chart.chart-table .total span{
  font-size: 14px;
}

.red-chart {
  /* background-color: #676767; */
  background-color: #2c3e50;
}

.google-visualization-table-table {
  background: transparent !important;
  color: #fff;
  font-size: 11px !important;
}

.google-visualization-table-tr-even,
.google-visualization-table-tr-odd {
  background-color: transparent !important;
}

.google-visualization-table .gradient {
  background-image: none !important;;
}

.google-visualization-table-table th,
.google-visualization-table-table td {
  border-right: 0 !important;
  padding: 10px .35em !important
}

.chart-loading {
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 9;
}

.chart-loading .spinner {
  margin: 0 auto;
  width: 50px;
  height: 40px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  }

.chart-loading .spinner > div {
  background-color: #f2494a;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.chart-loading .spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.chart-loading .spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.chart-loading .spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.chart-loading .spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
      }
}

.total ul {
  color: white;
  text-transform: initial;
  margin-left: 1.5em;
}
</style>
