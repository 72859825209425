<template>
  <div class="support-component">
    <div class="page-header">
      <div class="row align-items-center header">
        <div class="col-3">
          <div class="title">
            <router-link to="/admin/support">Customer Support</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="row">

        <div class="col-md-12">
          <div class="support-card">
            <div class="card-title">
              <h6>Find Payment</h6>
              <p>Find out if a payment was done with specific email in our database.</p>
            </div>

            <b-form inline @submit="findPayment" class="search-form">
              <b-input type='text' v-model="paymentEmail" placeholder="Enter email" trim required/>
              <b-button type="submit" variant="primary">Find Payment</b-button>
            </b-form>

            <table class="table" v-if="paymentFound">
              <tr>
                <th>Id</th>
                <th>Email</th>
                <th>code</th>
                <th>resource Id</th>
                <th>Order Id</th>
                <th>Event</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Source</th>
              </tr>
              <tr v-for="pay in paymentFound" v-bind:key="pay._id">
                <td>{{ pay._id }}</td>
                <td>{{ pay.email }}</td>
                <td>{{ pay.code }}</td>
                <td>{{ pay.resource_id }}</td>
                <td>{{ pay.order_id }}</td>
                <td>{{ pay.event }}</td>
                <td>{{ pay.order_amount }}</td>
                <td>{{ pay.order_date }}</td>
                <td>{{ pay.payment_source }}</td>

              </tr>
            </table>
          </div>
        </div>

        <div class="col-md-12">
          <div class="support-card">
            <div class="card-title">
              <h6>Find Customer</h6>
              <p>Check if a user profile has been created on lamusica's database.  If found, you will get a customer profile, payments made, and codes that have been redeemed on that profile.</p>
            </div>

            <b-form inline @submit="findUser" class="search-form">
              <b-input type='email' v-model="query" placeholder="Customer email" :formatter="formatter" trim required/>
              <b-button type="submit" variant="primary">Find User</b-button>
            </b-form>

            <div class="search-result">
              <div class="card-title" v-if="payments && payments.length">
                <h6>All Customer Payments</h6>
              </div>
              <table class="table" v-if="payments && payments.length">
                <tr>
                  <th>Email</th>
                  <th>Order #</th>
                  <th>Code</th>
                  <th>Resource</th>
                  <th>Source</th>
                </tr>
                <tr v-for="(payment, index) in payments" v-bind:key="index">
                  <td>{{ payment.email }}</td>
                  <td>{{ payment.order_id }}</td>
                  <td>{{ payment.code }}</td>
                  <td>{{ payment.resource_title }}</td>
                  <td>{{ payment.payment_source }}</td>
                </tr>
              </table>

              <div class="card-title profile" v-if="profile && firebaseUser">
                <h6>Customer Profile</h6>
              </div>

              <table class="table" v-if="profile">
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Provider</th>
                  <th>Firebase Id</th>
                </tr>
                <tr>
                  <td>{{ profile._id }}</td>
                  <td>{{ firebaseUser.displayName }}</td>
                  <td>{{ firebaseUser.email }}</td>
                  <td v-if="firebaseUser.providerData.length && firebaseUser.providerData[0].providerId">{{ firebaseUser.providerData[0].providerId }}</td>
                  <td>{{ firebaseUser.uid }}</td>
                </tr>
              </table>

              <div class="card-title profile" v-if="profile && profile.payments">
                <h6>Codes Redeemed on Profile</h6>
              </div>

              <table class="table" v-if="profile && profile.payments">
                <tr>
                  <th>Order #</th>
                  <th>Amount</th>
                  <th>Code</th>
                  <th>Resource</th>
                  <th>ips</th>
                </tr>
                <tr v-for="(payment, index) in profile.payments" v-bind:key="index">
                  <td>{{ payment.charge_id }}</td>
                  <td>{{ payment.charge_amount/100 }}</td>
                  <td>{{ payment.code }}</td>
                  <td>{{ payment.title }}</td>
                  <td>
                    <h5 v-if="payment.ips && payment.ips.length">
                        <span class="badge badge-secondary" v-for="(ip, index) in payment.ips" v-bind:key="index">
                          {{ ip }}
                        </span>
                        <button class="btn btn-primary" @click="clearIps(firebaseUser.uid, payment.code, index)">Clear Ips</button>
                    </h5>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="support-card">
            <div class="card-title">
              <h6>Create New Code</h6>
              <p>Use this if you have confirmed a user has paid, and needs a code.</p>
            </div>

            <b-form @submit="generateCode">
              <b-row>
                <b-col>
                  <b-input type='email' v-model="payment.email" placeholder="Customer email" :formatter="formatter" trim required/>
                </b-col>

                <b-col>
                  <b-form-select v-model="payment.resource_id" :options="options" required></b-form-select>
                </b-col>

                <b-col>
                  <b-button type="submit" variant="primary">Generate Code</b-button>
                </b-col>

                <b-col v-if="payment.code">
                  <div class="code">{{ payment.code }}</div>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </div>

        <div class="col-md-12">
          <div class="support-card">
            <div class="card-title">
              <h6>Send Email Receipt</h6>
              <p>Use this to either re-send a email receipt or, if you just generated a code from the form above, to send that receipt to the end user.</p>
            </div>

            <b-form @submit="sendEmail">
              <b-row>
                <b-col>
                  <b-input type='email' v-model="receipt.email" placeholder="Customer email" :formatter="formatter" trim required/>
                </b-col>

                <b-col>
                  <b-form-select v-model="receipt.resource_id" :options="receiptOptions" required></b-form-select>
                </b-col>

                <b-col>
                  <b-button type="submit" variant="primary">Send Email Receipt</b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </div>

        <div class="col-md-12">
          <div class="support-card">
            <div class="card-title">
              <h6>Block Code</h6>
              <p>Block code after refund is completed to a customer.</p>
            </div>

            <b-form inline @submit="refund" class="search-form">
              <b-input type='text' v-model="code" placeholder="Enter code" :formatter="formatterCode" trim required/>
              <b-button type="submit" variant="primary">Block Code</b-button>
            </b-form>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Support',
  data () {
    return {
      code: null,
      concerts: [],
      payments: null,
      profile: null,
      codeInProfile: null,
      firebaseUser: null,
      payment: {
        email: null,
        code: null,
        resource_id: null,
        order_id: 'xxxxxxxxxx',
        order_amount: '0',
        order_date: null,
        payment_source: 'support'
      },
      options: [
        { value: null, text: 'Select Concert' }
      ],
      query: null,
      receipt: {
        email: null,
        resource_id: null
      },
      receiptOptions: [
        { value: null, text: 'Select Concert' }
      ],
      paymentEmail: null,
      paymentFound: null
    }
  },

  mounted () {
    this.$http.get(`/livestreams`)
      .then((res) => {
        res.data.forEach(item => {
          if (item.paywall && item.paywall.has_paywall) {
            this.options.push({
              value: item._id,
              text: item.titleSpanish
            })
            this.receiptOptions.push({
              value: item._id,
              text: item.titleSpanish
            })
          }
        })
      })
      .catch((err) => {
        console.log(err)
      })
  },

  methods: {
    formatterCode (value) {
      return value.toUpperCase()
    },
    formatter (value) {
      return value.toLowerCase()
    },
    generateCode (evt) {
      evt.preventDefault()

      this.$http.post('/support/payments', { payment: this.payment })
        .then((response) => {
          console.log(response)
          this.payment.code = response.data
          this.payment.email = null
          this.payment.resource_id = null
          this.payment.order_date = null
        })
        .catch((err) => {
          console.log(err)
          this.payment.code = null
          this.payment.email = null
          this.payment.resource_id = null
          this.payment.order_date = null

          this.$notify({
            group: 'notifications',
            title: 'Something went wrong. Please try again.',
            text: '',
            type: 'error'
          })
        })
    },
    findUser (evt) {
      evt.preventDefault()
      this.$http.get(`${process.env.VUE_APP_AUTH_API}/concerts/payments?email=${this.query}`)
        .then((response) => {
          if (response && !response.profile) {
            this.$notify({
              group: 'notifications',
              title: 'Profile not founded',
              text: '',
              type: 'error'
            })
          }

          if (response && !response.payment.length) {
            this.$notify({
              group: 'notifications',
              title: 'Payments not founded',
              text: '',
              type: 'error'
            })
          }
          this.payments = response.payment
          this.profile = response.profile
          this.firebaseUser = response.firebaseUser
          this.query = null
        })
        .catch((err) => {
          console.log(err)
          this.query = null
        })
    },
    refund (evt) {
      evt.preventDefault()

      this.$http.post('/support/refund', { code: this.code })
        .then((response) => {
          this.$notify({
            group: 'notifications',
            title: 'Code blocked',
            text: '',
            type: 'success'
          })
          this.code = null
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            group: 'notifications',
            title: 'Something went wrong. Please try again.',
            text: '',
            type: 'error'
          })
          this.code = null
        })
    },
    findPayment (evt) {
      evt.preventDefault()

      this.$http.get(`${process.env.VUE_APP_AUTH_API}/concerts/payment?email=${this.paymentEmail}`)
        .then((response) => {
          if ((response && !response.payments) || !response.payments.length) {
            this.$notify({
              group: 'notifications',
              title: 'Sorry, there are not payments with this email',
              text: '',
              type: 'error'
            })
          }
          this.paymentFound = response.payments
          this.paymentEmail = null
        })
        .catch((err) => {
          console.log(err)
          this.code = null
        })
    },
    clearIps (firebaseID, code, index) {
      this.$http.post('/support/ips', { firebaseID: firebaseID, code: code })
        .then((response) => {
          console.log(response)
          this.profile.payments[index].ips = []
        })
        .catch((err) => {
          console.log(err)

          this.$notify({
            group: 'notifications',
            title: 'Something went wrong. Please try again.',
            text: '',
            type: 'error'
          })
        })
    },
    sendEmail (evt) {
      evt.preventDefault()
      this.$http.post(`${process.env.VUE_APP_AUTH_API}/concerts/receipt`, { receipt: this.receipt })
        .then((response) => {
          if (!response.success) {
            return this.$notify({
              group: 'notifications',
              title: response.message,
              text: '',
              type: 'error'
            })
          }

          this.receipt.email = null

          this.$notify({
            group: 'notifications',
            title: 'Email Receipt Sent',
            text: '',
            type: 'success'
          })
        })
        .catch((err) => {
          console.log(err)
          this.receipt.email = null
          this.$notify({
            group: 'notifications',
            title: 'Something went wrong. Please try again',
            text: '',
            type: 'error'
          })
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.support-component .page-header {
  top: 32px;
}

.support-card {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  margin-bottom: 40px;
}

.support-card .code {
  font-size: 28px;
  color: red;
  font-weight: 900;
}

.support-card .btn.btn-primary {
  width: 100%;
  background: #f2494a;
  border-color: #f2494a;
  font-weight: 900;
  text-transform: uppercase;
}

.support-card h5 {
  display: flex;
  align-items: center;
}

.support-card h5 .btn.btn-primary {
  width: auto;
  font-size: 11px;
}

.support-card .search-form {
  margin-bottom: 40px;
}

.support-card .search-form input {
  width: 300px;
  margin-right: 10px;
}

.support-card .search-form .btn.btn-primary {
  width: auto;
}

.card-title.profile {
  margin-top: 40px;
}

.badge {
  margin-right: 5px;
  font-size: 12px;
}
</style>
