<template>
  <div class="cards-type-playlist">
    <div class="container">
      <div class="col-8 offset-2">
        <b-form @submit="save" class="bootstrap-form">

          <b-form-row>
            <b-col>
              <b-input-group>
                <b-form-input readonly required v-model="cardVideo.id"></b-form-input>
                <b-input-group-prepend>
                  <b-btn variant="info" class="card-btn btn-add btn-modal" @click="addVideo()">Add Video</b-btn>
                </b-input-group-prepend>
              </b-input-group>
            </b-col>
          </b-form-row>

          <BaseCardForm :card="cardVideo" :imageSizes="imageUpload" :video="video" />

          <div class="wrapper-btn">
<!--            <b-button variant="primary" class="card-btn btn-add btn-add-img" @click="addImage">Add Images</b-button>-->
            <b-button type="submit" variant="primary" class="card-btn">{{type === 'create' ? 'Create Card' : 'Update Card'}}</b-button>
          </div>
        </b-form>
      </div>
    </div>

    <b-modal id="addPlaylist" ref="addVideo" :ok-only="true" ok-title="Add" @ok="addTocard" size="md" title="Select Video">
      <div class="row modal-wrapper">
        <div class="col-12">
          <div class="search-wrapper">
            <input type="text" placeholder="Find Video..." v-model="queryVideo" @keyup.13="search" />
            <div class="btn btn-search" @click="search">
              <i class="fas fa-search"></i>
            </div>
          </div>
        </div>

        <p>This list only shows videos that <strong>are published and are currently live</strong>.  If you are trying to find a video that will be go live in the future, please use the search bar.</p>

        <div class="col-12">
          <ul class="playlistUpdated-list">
            <li v-for="(video, index) in videos" v-bind:key="index" @click="selectVideo(video)" v-bind:class="{selected: video.selected}">
              <div class="playlists-list-card-img">
                <img :src="video.images.featured['2xImageURL']" alt="" v-if="video.images && video.images.featured" />
              </div>
              <div class="playlist-info">
                <div class="playlist-title" v-if="video.title && video.title.es">{{video.title.es}}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-modal>
  </div>
</template>


<script>
  import { Datetime } from 'vue-datetime'
  import { DateTime as LuxonDateTime } from 'luxon'
  import CardTypeCommon from './CardTypeCommon'
  import LoaderInline from '../../../share/LoaderInline'
  import BaseCardForm from './BaseCardForm'

export default {
    name: 'VideoType',
    components: {
      Datetime,
      LoaderInline,
      BaseCardForm
    },
    props: ['card', 'type'],
    data () {
      const utils = new CardTypeCommon(this)
      return {
        recentAPIURL: '/videos/recent?pagesize=40',
        formData: new FormData(),
        utils: utils,
        video: utils.uploads.video[0],
        imageUpload: utils.uploads.images,
        videos: [],
        videoSelected: null,
        queryVideo: ''
      }
    },
    computed: {
      cardVideo: {
        get () {
          return this.card
        },
        set (value) {
          this.card = value
        }
      }
    },
    mounted () {
      this.utils.setCardDefaultValues()
    },
    methods: {
      addImage () {
        let images = this.imageUpload.filter((img) => {
          return img['750x750'] && img['1000x1100']
        })

        if (images.length === this.imageUpload.length) {
          this.imageUpload.push({
            file750: null,
            '750x750': null,
            placeholder750: 'Image 750 x 750px',
            width750: 750,
            height750: 750,

            file1000: null,
            '1000x1100': null,
            placeholder1000: 'Image 1000 x 1100px',
            width1000: 1000,
            height1000: 1100
          })
        }
      },
      removeImage (index) {
        this.imageUpload.splice(index, 1)
      },
      uploadVideo (event) {
        this.utils.handleVideoUpload(event)
      },
      removeVideoCard () {
        this.utils.handleRemoveVideoCard()
      },
      save (evt) {
        evt.preventDefault()
        this.card.type = 'video'

        if (!this.cardVideo.id) {
          this.$notify({
            group: 'notifications',
            title: 'Video selection is required :(',
            text: '',
            type: 'error'
          })

          return
        }

        if (!this.utils.validateCardBeforeSave()) {
          return
        }

        if (this.type === 'create') {
          this.create()
        } else {
          this.update()
        }
      },
      addVideo () {
        EventHandler.emit('isLoading', true)
        this.$http.get(this.recentAPIURL)
          .then((res) => {
            this.videos = res.data
            EventHandler.emit('isLoading', false)
            this.$refs.addVideo.show()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      selectVideo (video) {
        if (video.selected) {
          video.selected = false
        } else {
          this.videos.forEach((item) => {
            if (item.selected) {
              item.selected = false
            } else {
              this.$set(item, 'selected', false)
            }
          })
          this.$set(video, 'selected', true)
          this.videoSelected = video
        }
      },
      addTocard () {
        if (!this.videoSelected || !this.videoSelected.selected) {
          this.$notify({
            group: 'notifications',
            title: 'No Video selected :(',
            text: '',
            type: 'error'
          })

          return
        }
        this.cardVideo.id = this.videoSelected._id.$oid || this.videoSelected._id
        this.cardVideo.titleEnglish = this.videoSelected.title.en
        this.cardVideo.titleSpanish = this.videoSelected.title.es
        this.cardVideo.subtitleEnglish = this.videoSelected.cardTitle && this.videoSelected.cardTitle.en ? this.videoSelected.cardTitle.en : ''
        this.cardVideo.subtitleSpanish = this.videoSelected.cardTitle && this.videoSelected.cardTitle.es ? this.videoSelected.cardTitle.es : ''
        const images = {
          '750x750': this.videoSelected.images.row['2xImageURL'],
          '1000x1100': this.videoSelected.images.card['3xImageURL'],
          '1296x560': this.videoSelected.images.thumbnail['xxxhdpiImageURL'],
          '1920x850': this.videoSelected.images.landing['3xImageURL']
        }

        const webImage = this.videoSelected.images['landscape_centered']
        if (webImage && webImage['3xImageURL']) {
          images['1920x850_web'] = webImage['3xImageURL']
        } else {
          images['1920x850_web'] = images['1920x850']
        }

        this.utils.setImageURLValues(images)

        if (this.videoSelected.videoCardUrl) {
          this.utils.setVideoURLs({'1000x1100': this.videoSelected.videoCardUrl})
        }

        if (typeof this.videoSelected.available.start === 'string') {
          this.cardVideo.available.start = LuxonDateTime.fromJSDate(new Date(this.videoSelected.available.start))
        } else if (typeof this.videoSelected.available.start === 'object') {
          this.cardVideo.available.start = LuxonDateTime.fromJSDate(new Date(this.videoSelected.available.start.$date))
        }
      },
      create () {
        this.$http.post('/cards', {card: this.cardVideo})
          .then((res) => {
            if (res.success) {
              this.$notify({
                group: 'notifications',
                title: 'Card created successfully :)',
                text: '',
                type: 'success'
              })
              this.$router.push('/admin/cards')
            } else {
              this.$notify({
                group: 'notifications',
                title: 'Images are required :)',
                text: '',
                type: 'error'
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      update () {
        this.utils.handleCardUpdate()
      },
      search () {
        if (this.queryVideo === '') {
          const options = {
            params: {
              limit: 100,
              page: 1,
              sortBy: 'dateCreated',
              timestamp: new Date().getTime()
            }
          }
          this.$http.get('/videos', options).then((res) => {
            this.videos = res.data.videos
          }).catch((err) => {
            console.log(err)
          })
        } else {
          this.$http.get(`/videos/search?q=${this.queryVideo}&type=title&published=true`)
            .then((res) => {
              this.videos = res.data
            })
            .catch((err) => {
              console.log(err)
            })
        }
      }
    }
  }
</script>

<style>
  .card-image video {
    width: 100%;
    margin-bottom: 10px;
  }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .cards-type-playlist .custom-file {
    margin-bottom: 15px;
  }

  .cards-type-playlist .wrapper-btn{
    display: flex;
    justify-content: flex-end;
  }

  .cards-type-playlist .card-btn{
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 12px;
    text-transform: uppercase;
  }

  .cards-type-playlist .btn-add{
    margin-right: 10px;
  }

  .cards-type-playlist .btn-modal{
    height: 45px;
    margin-right: 0;
    z-index: 0;
  }

  ul.playlistUpdated-list{
    list-style-type: none;
    overflow-y: scroll;
    max-height: 400px;
    padding: 3px;
  }

  ul.playlistUpdated-list .playlist-title{
    font-size: 12px;
    font-weight: bold;
  }

  ul.playlistUpdated-list .playlist-tracks{
    font-size: 10px;
    font-weight: bold;
  }

  ul.playlistUpdated-list li {
    list-style-type: none;
    display: flex;
    background-color: #fff;
    margin-bottom: 10px;
    height: 60px;
    align-items: center;
    border: 1px solid #eaeaea;
    cursor: pointer;
  }

  ul.playlistUpdated-list li.selected {
    background-color: #eaeaea;
  }

  ul.playlistUpdated-list li .playlists-list-card-img {
    margin-right: 15px;
  }

  ul.playlistUpdated-list li .playlists-list-card-img img{
    height: 60px;
    width: auto;
  }

  .search-wrapper {
    display: flex;
  }

  .btn-search{
    height: 45px;
    background: #f2494a;
    border-radius: 0;
    color: #fff;
    line-height: 32px;
  }

  .video-labels {
    font-weight: bold;
    font-size: 12px;
  }
  .modal-wrapper p {
    margin: 0em 2em 0.5em 2em;
  }
</style>
