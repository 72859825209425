<template>
  <div>
    <div v-if="hasVideoSource">
      <div class="container-ratio">
        <video-player class="video-player-box"
                      ref="videoPlayer"
                      :options="playerOptions"
                      :playsinline="true">
        </video-player>
      </div>
      <br />
    </div>
  </div>
</template>
<script>
export default {
  name: 'AssetsItemPreviewVideo',
  props:['item'],
  computed: {
    hasVideoSource () {
      return this.playerOptions.sources[0].src
    }
  },
  data () {
    return {
      playerOptions: {
        language: 'en',
        responsive: true,
        sources: [{
          type: 'video/mp4',
          src: null
        }],
        fluid: true
      },
    }
  },
  mounted () {
    this.playerOptions.sources[0].src = this.item.file_url
  }
}
</script>
<style scoped lang="scss">

</style>
