<template>
  <div class="paywall-form" v-if="item.paywall">
    <h4>Paywall Configuration</h4>
    <hr>
    <b-form-row>
      <b-col>
        <b-form-group label="Has Paywall">
          <b-form-checkbox
            id="has_paywall"
            v-model="item.paywall.has_paywall"
            name="has_paywall">
            Content Has Paywall
          </b-form-checkbox>
          <small>If checked, the content will not be authorized to enter unless a user pays given the following settings</small>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Content Price">
          <b-form-input id="product-price"
                        type="number"
                        v-model.number="item.paywall.price"
                        min="50"
                        placeholder=""
                        trim>
          </b-form-input>
          <small>The price, in <strong>cents (not dollars)</strong> of the product. To be extra clear, a price of $20 would be 2000. Please note that this price will have additional credit card processing fees paid on top of this fee</small>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Number of Permitted IPs">
          <b-form-input id="permitted IPs"
                        type="number"
                        v-model.number="item.paywall.number_of_permitted_ips"
                        placeholder=""
                        trim>
          </b-form-input>
          <small>Maximum number of permitted IPs before the app will tell the user to sign out from other devices</small>
        </b-form-group>
      </b-col>
    </b-form-row>
    <br>
    <b-form-row>
      <b-col>
        <b-form-group label="Allow Mobile Payments">
          <b-form-checkbox
            id="allow_mobile_payments"
            v-model="item.paywall.allow_mobile_payments"
            name="allow_mobile_payments">
            Allow Payments on mobile devices
          </b-form-checkbox>
          <small>If checked, the content will be authorized to be charged on mobile devices with a 30% fee</small>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Landing Page URL">
          <b-form-input id="landing_page_url"
                        type="url"
                        v-model="item.paywall.landing_page_url"
                        trim>
          </b-form-input>
          <small>URL for landing page to allow users to pay on web</small>
        </b-form-group>
      </b-col>
    </b-form-row>
    <br/>
    <br/>
    <b-form-row>
      <b-col>
      <b-form-group label="Product Description Spanish">
        <b-form-input id="product-description-es"
                      type="text"
                      v-model="item.paywall.product_description.es"
                      placeholder=""
                      trim>
        </b-form-input>
        <small>The description of the product, in Spanish</small>
      </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Product Description English">
          <b-form-input id="product-description-en"
                        type="text"
                        v-model="item.paywall.product_description.en"
                        placeholder=""
                        trim>
          </b-form-input>
          <small>The description of the product, in English</small>
        </b-form-group>
      </b-col>
    </b-form-row>
    <br/>
    <b-form-row v-if="item.paywall.available">
      <b-col>
        <b-form-group label="Paywall Period Start Date">
          <datetime type="datetime"
                    v-model="item.paywall.available.start"
                    class="theme-orange"
                    :minDatetime="minDatetime"
                    :phrases="{ok: 'Continue', cancel: 'Exit'}"
                    use12-hour>
          </datetime>
          <small>If paywall is active, it will only be enforced for this period</small>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Paywall Period End Date">
          <datetime type="datetime"
                    v-model="item.paywall.available.end"
                    class="theme-orange"
                    :minDatetime="minDatetime"
                    :phrases="{ok: 'Continue', cancel: 'Exit'}"
                    use12-hour>
          </datetime>
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
/*
 * To add settings to a collection, run
 db.livestreams.updateMany({},{
   $set: {
     paywall: {
       has_paywall: false,
       price: 2000,
       product_description: {
         es: '',
         en: ''
       },
       number_of_permitted_ips: 2,
       available: {
         start: new Date(),
         end: new Date()
       }
      }
    }
  })
 */
  import {DateTime as LuxonDateTime} from "luxon";

export default {
    name: 'PaywallConfigurationForm',
    props: {
      item: {
        type: Object
      }
    },
    computed: {
      minDatetime () {
        return LuxonDateTime.local().toISO()
      }
    }
  }
</script>

<style lang="scss">
  .paywall-form {
    .custom-checkbox {
      margin-bottom: 5px;
    }

    .form-group legend {
      font-size: 15px;
    }

    small {
      font-size: 13px;
    }
  }
</style>
