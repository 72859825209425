<template>
    <div class="builder-component">
      <router-view :key="$route.fullPath"/>
    </div>
</template>


<script>

export default {
  name: 'Livestreams',
  data () {
    return {
      query: '',
      livestreams: []
    }
  },

  mounted () {
  },

  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
