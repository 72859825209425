<template>
  <div class="row row-cards">
    <div v-for="(item, index) in row.items" :key="index" class="col-md-4 grid-item-wrapper">
      <row-preview-item :item="item" :displayTitles="row.displayTitles"></row-preview-item>
    </div>
  </div>
</template>
<script>
import RowPreviewItem from './RowPreviewItem'
export default {
  name: 'RowPreviewGrid',
  components: {RowPreviewItem},
  props: ['row']
}
</script>
<style scoped lang="scss">
  .row-cards {
    margin: 0 0 40px 0;

    .grid-item-wrapper {
      padding: 12px;
    }
  }
</style>
