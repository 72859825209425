export default class AudioTest {
  constructor (url) {
    this.audio = document.getElementById('audio')
    this.audio.crossOrigin = 'anonymous'
    this.audio.src = url
    this.canvas = document.getElementById('canvas')
  }

  addEqualizer () {
    var context = new AudioContext()
    var src = context.createMediaElementSource(this.audio)
    var analyser = context.createAnalyser()

    this.canvas.width = window.innerWidth
    this.canvas.height = 350
    var ctx = this.canvas.getContext('2d')

    src.connect(analyser)
    analyser.connect(context.destination)

    analyser.fftSize = 256

    var bufferLength = analyser.frequencyBinCount
    // console.log('bufferLength', bufferLength)

    var dataArray = new Uint8Array(bufferLength)

    var WIDTH = this.canvas.width
    var HEIGHT = this.canvas.height

    var barWidth = (WIDTH / bufferLength) * 2.5
    var barHeight
    var x = 0

    function renderFrame () {
      requestAnimationFrame(renderFrame)
      x = 0
      analyser.getByteFrequencyData(dataArray)

      ctx.fillStyle = '#f7f7f7'
      ctx.fillRect(0, 0, WIDTH, HEIGHT)

      for (var i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i]

        var r = barHeight + (25 * (i / bufferLength))
        var g = 250 * (i / bufferLength)
        var b = 50

        ctx.fillStyle = 'rgb(' + r + ',' + g + ',' + b + ')'
        ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight)

        x += barWidth + 1
      }
    }

    // this.audio.play()
    renderFrame()
  }
}
