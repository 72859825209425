<template>
    <div class="tests-component">
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col-5">
            <div class="title">
              <router-link to="/admin/tests">ONE SIGNAL TESTS </router-link>/
              <span v-if="test && test.title">
                <input type="text" v-model="test.title" />
              </span>
            </div>
          </div>
          <div class="col-7 pull-right">
            <div class="typeahead-wrapper float-right">
              <button class="typeahead-btn" @click="updateTest">Update</button>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content">
        <div class="test-title">
          <!-- <span>One Signal Integration</span> -->
          <span class="send" @click="manageDevices">Manage Devices</span>
          <span class="send" @click="sendAll">SEND ALL</span>
        </div>

        <transition-group tag="div" name="list-animation">
          <div v-for="(test, index) in test.tests" v-bind:key="test.type" class="card">
            <div class="card-title">
              <span>{{test.name}}</span>
              <div class="test-device">
                <span>To open app</span>
                <input type="checkbox" v-model="test.toAppOpen" />
                <span class="send" @click="send(test)">SEND</span>
              </div>
            </div>
            <div class="form">
              <section class="test-message row">
                <div class="col-6">
                  <label>Title</label>
                  <input type="text" class="test-input" v-model="test.title" />
                </div>
                <div class="col-6">
                  <label>Subtitle</label>
                  <input type="text"  class="test-input" v-model="test.message" />
                </div>
              </section>
              <div class="test-message row">
                <div class="test-resource col-8">
                  <span class='test-placeholder' v-if="test.type !== 'web'">{{test.placeholder}}</span>
                  <input type="text" class="test-input test-resource" v-model="test.id" />
                </div>
                <div class="test-resource col-4">
                  <input type="text" class="test-input" :value="test.device.name" disabled />
                  <div class="button-resource" @click="getDevices(index)">Select Device</div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>

      <b-modal id="manageDevices" ref="manageDevices" size="lg" title="Manage OneSignal Devices">
        <div class="row modal-wrapper">
          <div class="col-6">
            <h5>View Devices</h5>
            <ul class="device-list">
              <li v-for="device in devices" v-bind:key="device._id">
                <div class="device-label">
                  {{device.name}}<br/>
                  <span><strong>Created:</strong> {{turnObjectIdToFormattedDate(device._id)}}</span><br/>
                  <span><strong>OneSignal Player ID:</strong><br/>{{device.playerId}}</span>
                </div>
                <b-button variant="outline-secondary" size="sm" @click="deleteDevice(device)">Delete</b-button>
              </li>
            </ul>
          </div>
          <div class="col-6">
            <h5>Add a New Device</h5>
            <p>Use the <a href="https://app.onesignal.com/apps/bcde33bb-3830-4e35-8f75-9a8ce02da5ee/segments" target="_blank">OneSignal segments</a> to find your OneSignal player ID, or lookup your device ID on the
              <a href="https://analytics.amplitude.com/lamusica/activity"> <s> Amplitude User ID Lookup Tool</s> </a>.</p>
            <br/>
            <input type="text" placeholder="Add device name" v-model="device.name"/>
            <input type="text" placeholder="Add device player ID from One Signal" v-model="device.playerId"/>
            <b-button @click="addDevice">Add Device</b-button>
          </div>
        </div>
      </b-modal>

      <b-modal id="selectDevice" ref="selectDevice" :hide-footer="true" size="md" title="Select Device">
        <div class="row modal-wrapper">
          <div class="col-12">
            <ul class="device-list">
              <li v-for="device in devices" v-bind:key="device._id" @click="selectDevice(device)">
                <div class="device-label">
                  {{device.name}}<br/>
                  <span>Created {{turnObjectIdToFormattedDate(device._id)}}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </b-modal>
    </div>
</template>


<script>
  import moment from 'moment'
  export default {
    name: 'OneSignalEdit',
    data () {
      return {
        devices: [],
        test: {},
        device: {
          name: null,
          playerId: null
        },
        testIndexToAddDevice: null
      }
    },

    mounted () {
      this.testID = this.$route.params.id

      this.$http.get(`/tests/one-signal/${this.testID}`)
        .then((res) => {
          this.test = res.data
        })
    },

    methods: {
      turnObjectIdToFormattedDate (objectId) {
        const date = new Date(parseInt(objectId.substring(0, 8), 16) * 1000)
        return moment(date).format('MMMM Do [at] h:mm a')
      },
      sendAll () {
        this.sendNotification(this.test.tests)
          .then((res) => {
            if (res.success) {
              this.$notify({
                group: 'notifications',
                title: 'Notifications successfully sent :)',
                text: '',
                type: 'success'
              })
            }
          })
          .catch(err => {
            console.log(err)
            this.$notify({
              group: 'notifications',
              title: 'There was an error sending notifications :(',
              text: '',
              type: 'error'
            })
          })
      },
      send (test) {
        const singleTest = [test]
        this.sendNotification(singleTest)
          .then((res) => {
            if (res.success) {
              this.$notify({
                group: 'notifications',
                title: 'Notification successfully sent :)',
                text: '',
                type: 'success'
              })
            }
          })
          .catch(err => {
            console.log(err)
            this.$notify({
              group: 'notifications',
              title: 'There was an error sending notification :(',
              text: '',
              type: 'error'
            })
          })
      },
      sendNotification (test) {
        return this.$http.post(`/tests/one-signal/notification`, {tests: test})
      },
      getDevices (index) {
        EventHandler.emit('isLoading', true)
        this.testIndexToAddDevice = index
        this.$http.get('/tests/one-signal/devices')
          .then((res) => {
            this.devices = res.data
            EventHandler.emit('isLoading', false)
            this.$refs.selectDevice.show()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      selectDevice (device) {
        this.test.tests[this.testIndexToAddDevice].device.playerId = device.playerId
        this.test.tests[this.testIndexToAddDevice].device.name = device.name
        this.$refs.selectDevice.hide()
      },
      manageDevices () {
        EventHandler.emit('isLoading', true)
        this.$http.get('/tests/one-signal/devices').then((res) => {
          console.log(res)
          this.devices = res.data
          EventHandler.emit('isLoading', false)
          this.$refs.manageDevices.show()
        }).catch((err) => {
          console.log(err)
        })
      },
      addDevice () {
        this.$http.post('/tests/one-signal/devices', {device: this.device})
          .then((res) => {
            if (res.success) {
              return this.$http.get('/tests/one-signal/devices')
            } else {
              throw new Error('Could not create device')
            }
          }).then((res) => {
            if (res.success) {
              this.devices = res.data
              this.$notify({
                group: 'notifications',
                title: 'Device added successfully :)',
                text: '',
                type: 'success'
              })
            } else {
              throw new Error('Could not retrieve devices')
            }
          }).catch((err) => {
            console.log(err)
            this.$notify({
              group: 'notifications',
              title: 'There was an error adding device :(',
              text: '',
              type: 'error'
            })
          })
      },
      deleteDevice (device) {
        this.$http.delete(`/tests/one-signal/devices/${device._id}`)
          .then((res) => {
            if (res.success) {
              return this.$http.get('/tests/one-signal/devices')
            } else {
              throw new Error('Coud not delete device')
            }
          }).then((res) => {
            if (res.success) {
              this.devices = res.data
              this.$notify({
                group: 'notifications',
                title: `Device "${device.name}" deleted successfully :)`,
                text: '',
                type: 'success'
              })
            } else {
              throw new Error('Coud not list devices after deletion')
            }
          }).catch((err) => {
            console.log(err)
            this.$notify({
              group: 'notifications',
              title: `There was an error deleting device "${device.name}" :(`,
              text: '',
              type: 'error'
            })
          })
      },
      updateTest () {
        this.$http.put('/tests/one-signal', {test: this.test})
          .then((res) => {
            if (res.success) {
              this.$notify({
                group: 'notifications',
                title: 'Test updated successfully :)',
                text: '',
                type: 'success'
              })
            }
          }).catch((err) => {
            console.log(err)
            this.$notify({
              group: 'notifications',
              title: 'There was an error updating this Test :(',
              text: '',
              type: 'error'
            })
          })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.tests-component {
  display: block;
  width: 100%;

  .page-content .test-title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    /* justify-content: flex-end; */
    align-items: center;

    .send {
      background-color: #de4242;
      color: #fff;
      padding: 5px 15px;
      font-size: 12px;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .page-header .title {
    align-items: center;
    span {
      flex: 1;
      input {
        width: 100%;
        padding: 0 10px;
        font-size: 14px;
        border-radius: 0;
        height: 45px;
        border: 1px solid #eaeaea;
      }
    }
  }

  .page-content .card {
    position: relative;
    background-color: #fff;
    margin-bottom: 10px;
    border: 1px solid #eaeaea;
    padding: 20px;

    .card-title {
      margin-bottom: 10px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .send {
        background-color: #de4242;
        color: #fff;
        padding: 5px 15px;
        font-size: 10px;
        cursor: pointer;
      }
    }

    .form .test-message {
      display: flex;
      margin-bottom: 15px;
    }
  }
}

.test-message {
  label {
    display: block;
    font-weight: bold;
  }
  input {
    height: 40px;
    padding: 0 10px;
    flex: 1;
  }
}

.test-input {
  width: 100%;
}

.test-resource {
  display: flex;
  align-items: center;
}

.button-resource {
  font-size: 12px;
  padding: 11px;
  background-color: #de4242;
  color: #fff;
  cursor: pointer;
}

.tests-component .page-content .test-title .send:hover,
.button-resource:hover {
  background-color: #c52626;
}

.first-column {
  margin-right: 15px;
}

.test-device input{
  margin-right: 15px;
}

.test-placeholder {
  padding: 10px;
  background-color: #e8e8e8;
}

ul.device-list {
  list-style-type: none;
  overflow-y: scroll;
  max-height: 400px;
  padding: 3px;

  .device-label {
    width: 80%;
  }

  li {
    list-style-type: none;
    display: flex;
    background-color: #fff;
    margin-bottom: 5px;
    padding: 15px;
    align-items: center;
    border: 1px solid #eaeaea;
    font-weight: bold;

    span {
      font-weight: normal;
      font-size: 90%;
    }
  }
}
</style>
