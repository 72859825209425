<template>
  <div class="chart-component">
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-6">
          <div class="title">
            <router-link to="/admin/charts">Charts</router-link>
            <span v-if="chart && chart.title"> / {{chart.title}}</span>
          </div>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <div class="btn" @click="saveChart">Update</div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <b-alert variant="warning" :show="this.showTracksUpdatedNotification">The tracks list was updated. You should update the chart.</b-alert>
      <div class="row" v-if="chart">
        <div class="col-8" v-if="chart.tracks && chart.tracks.length">
          <h5>Tracks</h5>
          <hr />
          <div class="legend">
            <div><b-badge variant="info">LP</b-badge> Last week position</div>
            <div><b-badge variant="primary">WC</b-badge> Weeks on chart</div>
            <div><b-badge variant="success">WP</b-badge> Weeks on this position</div>
          </div>
          <hr />
          <draggable v-model="chart.tracks" group="tracks" @start="handlerStartEvent" @end="handlerEndEvent">
            <div class="track-wrapper" v-for="(track, index) in chart.tracks" :key="track._id">
              <div class="track-content-wrapper">
                <div class="track-position" v-if="track.position">
                  <div class="current">{{ track.position.current_week }}</div>
                </div>
                <div class="track-image">
                  <img :src="track.image" :alt="track.title"/>
                </div>
                <div class="track-position-change" v-if="track.position">
                  <div
                    class="track-position-change-icon-wrapper"
                    v-if="track.position && track.position.last_week"
                    v-bind:class="{'up': track.position.changed > 0, 'down': track.position.changed < 0}">
                    <i class="fas fa-arrow-up" v-if="track.position.changed > 0"></i>
                    <i class="fas fa-arrow-down" v-else-if="track.position.changed < 0"></i>
                    <i class="fas fa-arrow-right" v-else></i>
                  </div>
                  <div class="track-new-icon-wrapper" v-else>
                    <span>New</span>
                  </div>
                </div>
                <div class="track-data">
                  <div class="track-title">{{ track.title }}</div>
                  <div class="track-artist">{{ track.artist }}</div>
                  <div v-if="track.position">
                    <b-badge variant="info" v-if="track.position.last_week">
                      LP: {{track.position.last_week}}
                    </b-badge>
                    <b-badge variant="primary">
                      WC: {{track.position.weeks_on_chart}}
                    </b-badge>
                    <b-badge variant="success">
                      WP: {{track.position.weeks_on_chart_position}}
                    </b-badge>
                  </div>
                </div>
                <div class="icon-wrapper" @click="openModalToEditTrack(index)">
                  <i class="fas fa-edit"></i>
                </div>
                <div class="icon-wrapper" @click="openModalToRemoveTrack(index)">
                  <i class="fas fa-trash-alt"></i>
                </div>
              </div>
            </div>
          </draggable>
        </div>
        <div class="col-8" v-else>
          <div class="no-tracks">
            There are not tracks at this time :(
          </div>
        </div>
        <div class="col-4">
          <h5>Chart Data</h5>
          <hr />
          <b-form-row>
            <b-col class="text-right">
              <div class="status-wrapper">
                <div class="status-label">Published<sup class="text-danger">*</sup></div>
                <label class="switch">
                  <input type="checkbox" v-model="chart.published">
                  <span class="slider"></span>
                </label>
              </div>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <label class="col-form-label">Title<sup class="text-danger">*</sup></label>
              <input class="form-control" type="text" placeholder="Top Hits" v-model="chart.title"/>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <label class="col-form-label">Week<sup class="text-danger">*</sup></label>
              <week-picker :current-week="chart.week" @selected="onWeekSelected($event)"></week-picker>
            </b-col>
          </b-form-row>
        </div>
      </div>

      <div class="row">
        <div class="col-8">
          <div class="add-track" @click="addTrackToList">
            Add Track
          </div>
        </div>

      </div>
    </div>

    <b-modal id="modalToEditTrack" ref="modalToEditTrack" ok-title="ok" size="md" :hide-header="true"
            @ok="handleOkEditTrack"
            @cancel="handleCancelEditTrack"
            :hide-header-close="true"
            :no-close-on-backdrop="true"
            :no-close-on-esc="true">

      <div class="modal-wrapper">
        <div class="row" v-if="!trackToEdit._id">
          <div class="col-12">
            <typeahead @trackSelected="selectTrackToAdd" :text="'Search La Musica Catalogue'" :hide-play-button="true" class="track-search"></typeahead>
          </div>
        </div>
        <div v-if="trackToEdit._id">
          <div class="row">
            <div class="col-12">
              <div class="title-wrapper">
                <label>
                  <strong>Title</strong>
                </label>
                <input type="text" v-model="trackToEdit.title"/>
              </div>

              <div class="title-wrapper">
                <label>
                  <strong>Album</strong>
                </label>
                <input type="text" v-model="trackToEdit.album"/>
              </div>

              <div class="artist-wrapper">
                <label>
                  <strong>Artist</strong>
                </label>
                <input type="text" v-model="trackToEdit.artist"/>
              </div>

              <div class="embed-video">
                <label>
                  <strong>Embed Music Video</strong>
                </label>
                <b-form-input :state="validYtLink" 
                @change="formatYyLink"
                type="url"
                id="input-ytlink"
                aria-describedby="input-ytlink-help input-ytlink-feedback"
                placeholder="https://youtu.be/3inw26U"
                trim
                v-model="trackToEdit.embed_video"/>
                <b-form-text id="input-ytlink-help">(click the share button in youtube and copy the link)</b-form-text>
                <b-form-invalid-feedback id="input-ytlink-feedback">
                  Valid URL is in the format https://youtu.be/3inw26U
                </b-form-invalid-feedback>

                <div class="youtube-wrapper" v-if="trackToEdit.embed_video">
                <LazyYoutube :src="trackToEdit.embed_video" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <label>
                <strong>Related Content</strong>
              </label>

              <select v-model="relatedContentTypeSelected">
                <option value="video">Videos</option>
                <option value="playlist">Playlists</option>
                <option value="podcast">Podcasts</option>
                <option value="contest">Web Link</option>
                <option value="station">Stations</option>
                <option value="media">Episodes</option>
                <option value="livestream">Livestreams</option>
              </select>

              <div class="search-wrapper-related-content">
                <input type="text" placeholder="Find content..." v-model="queryCards" @keyup="getRelatedContent"/>
                <ul v-if="searchCardListResults.length > 0">
                  <li v-for="item in searchCardListResults" v-bind:key="item._id">
                    <div class="item-wrapper" @click="selectRelatedContent(item)">
                      <img :src=" item.img[0]['750x750']" alt="" v-if=" item.img[0]['750x750']"/>
                      <div class="item-title">{{ item.titleSpanish }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-12">
              <div class="placeholder-wrapper">
                <div class="placeholder" v-for="(related,index) in trackToEdit.related_content" v-bind:key="related._id">
                  <div class="remove-related-content"  @click="handleRemoveRelatedContent(index)">
                    <i class="fas fa-times"></i>
                  </div>
                  <img :src="related.img[0]['750x750']" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <label>
                <strong>Playlist</strong>
              </label>

              <div class="search-wrapper-related-content">
                <input type="text" placeholder="Find content..." v-model="queryPlaylists" @keyup="getPlaylists($event)"/>
                <ul v-if="searchPlaylistResults.length > 0">
                  <li v-for="item in searchPlaylistResults" v-bind:key="item._id">
                    <div class="item-wrapper" @click="selectPlaylist(item)">
                      <img :src="item.meta.cardImagery['2xImageURL']" alt="" v-if="item.meta && item.meta.cardImagery && item.meta.cardImagery['2xImageURL']"/>
                      <div class="item-title">{{ item.title }}</div>
                    </div>
                  </li>
                </ul>
              </div>

            </div>

            <div class="col-12">
              <div class="placeholder-wrapper">
                <div class="placeholder" v-if="this.trackToEdit.playlist">
                  <div class="remove-related-content" @click="handleRemovePlaylist">
                    <i class="fas fa-times"></i>
                  </div>
                  <img :src="this.trackToEdit.playlist.image" />
                </div>
              </div>
            </div>

          </div>

          <div class="row row-images">
            <div class="col-6">
              <label>
                <strong>Image</strong>
              </label>
              <img :src="trackToEdit.image" alt="" v-if="trackToEdit.image"/>
            </div>

            <div class="col-6">
              <label>
                <strong>Video Card</strong>
                <span style="color: red;" v-if="trackToEdit.position.current_week===1">(required)</span>
              </label>

              <loader-inline v-if="videoImage.loading"/>

              <div class="video-holder-card">
                <div class="remove-video-card" v-if="trackToEdit.video" @click="handleRemoveVideoImage">
                  <i class="fas fa-times"></i>
                </div>
                <div class="video-image" v-if="trackToEdit.video" v-html="videoHTML"></div>
              </div>
              <b-form-file accept="video/mp4" ref="videoInput" v-model="videoImage.file" :placeholder="videoImage.placeholder" @change.native="handleUploadVideoImage($event)"></b-form-file>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal id="modalToDeleteTrack" ref="modalToDeleteTrack" size="md" title="Delete Track" @ok="handleOkRemoveTrack">
      <div class="row modal-wrapper">
        <div class="col-12">
          Are you sure you want to remove this track?
        </div>
      </div>
    </b-modal>
  </div>
</template>


<script>
  import draggable from 'vuedraggable'
  import typeahead from '../../share/typeahead'
  import LoaderInline from '../../share/LoaderInline'
  import { LazyYoutube } from 'vue-lazytube'
  import ChartCommon from './chart-common'
  import WeekPicker from '../../share/week-picker.vue'

  export default {
    name: 'Charts',
    components: {
      WeekPicker,
      draggable,
      typeahead,
      LoaderInline,
      LazyYoutube
    },
    data () {
      return {
        chartId: null,
        chart: null,
        previousChart: null,
        chartCommon: new ChartCommon(this),
        trackToEditIndex: null,
        trackToRemoveIndex: null,
        trackToEdit: {
          _id: null,
          title: null,
          artist: null,
          album: null,
          image: null,
          video: null,
          embed_video: null,
          position: {
            last_week: null,
            current_week: 1,
            changed: 0,
            weeks_on_chart: 1,
            weeks_on_chart_position: 1
          },
          related_content: [],
          playlist: null
        },
        videoImage:  {
          placeholder: '1000 x 1100px',
          loading: false,
          width: 1000,
          height: 1100
        },
        relatedContentTypeSelected: 'video',
        queryCards: '',
        queryPlaylists: '',
        searchCardListResults: [],
        searchPlaylistResults: [],
        showTracksUpdatedNotification: false
      }
    },

    computed: {
      validYtLink(){
        if(this.trackToEdit && this.trackToEdit.embed_video){
          return this.trackToEdit.embed_video.length>0 && this.trackToEdit.embed_video.includes('youtu.be')
        }
        return false
      },
      videoHTML: function () {
        return `<video controls><source src="${this.trackToEdit.video}" type="video/mp4"></video>`
      }
    },

    async mounted () {
      this.chartId = this.$route.params.id
      const currentChartData = await this.$http.get(`/charts/${this.chartId}`)
      const previousChartData = await this.$http.get(`/charts/${this.chartId}/previous`)

      if (currentChartData && currentChartData.data) {
        this.chart = currentChartData.data
      }

      if(previousChartData && previousChartData.data) {
        this.previousChart = previousChartData.data
      }

      this.updateTrackIndexes()
    },

    methods: {
      formatYyLink(){
        const idx= this.trackToEdit.embed_video.indexOf('?')
        if(this.validYtLink && idx>1){
          const formattedEmbed = this.trackToEdit.embed_video.slice(0,idx)
          this.trackToEdit.embed_video = formattedEmbed
          this.$notify({
              group: 'notifications',
              title: 'Youtube URL updated to correct format',
              text: '',
              type: 'success'
            })
        }
      },
      handlerStartEvent () {
        this.drag = true
      },
      handlerEndEvent () {
        this.drag = false
        this.updateTrackIndexes()
      },
      selectTrackToAdd (track) {
        let isValid = true

        if (this.trackToEdit._id) {
          isValid = false
        }

        const exists = this.chart.tracks.filter((item) => {
          return item._id === track._id
        }).length > 0

        if(exists) {
          isValid = false
          this.$notify({
            group: 'notifications',
            title: 'This track is already added :(',
            text: '',
            type: 'error'
          })
        }

        if(isValid) {
          this.trackToEdit._id = track._id
          this.trackToEdit.title = track.title
          this.trackToEdit.artist = track.artist.name
          this.trackToEdit.album = track.release.title
          this.trackToEdit.image = 'https://playlists.lamusica.com/api/media/artworks/' + track.image + '.jpg'
        }

        EventHandler.emit('close-typeahead')
      },
      updateTrackIndexes() {
        for(let i = 0; i < this.chart.tracks.length; i++) {
          let previousTrack = null
          if(this.previousChart && this.previousChart.tracks.length > 0) {
            for(let j = 0; j < this.previousChart.tracks.length && !previousTrack; j++) {
              if(this.previousChart.tracks[j]._id === this.chart.tracks[i]._id) {
                previousTrack = this.previousChart.tracks[j]
              }
            }
          }

          const currentPosition = i + 1

          if(this.chart.tracks[i].position.current_week !== currentPosition) {
            this.showTracksUpdatedNotification = true
          }

          this.chart.tracks[i].position.current_week = currentPosition

          if (previousTrack) {
            this.chart.tracks[i].position.last_week = previousTrack.position.current_week
            const changed = previousTrack.position.current_week - this.chart.tracks[i].position.current_week

            if(this.chart.tracks[i].position.changed !== changed) {
              this.showTracksUpdatedNotification = true
            }

            this.chart.tracks[i].position.changed = changed
            this.chart.tracks[i].position.weeks_on_chart = previousTrack.position.weeks_on_chart + 1

            if(this.chart.tracks[i].position.changed === 0) {
              this.chart.tracks[i].position.weeks_on_chart_position = previousTrack.position.weeks_on_chart_position + 1
            } else {
              this.chart.tracks[i].position.weeks_on_chart_position = 1
            }
          } else {
            this.chart.tracks[i].position.last_week = null
            this.chart.tracks[i].position.changed = 0
            this.chart.tracks[i].position.weeks_on_chart = 1
            this.chart.tracks[i].position.weeks_on_chart_position = 1
          }
        }
      },
      addTrackToList () {
        this.$refs.modalToEditTrack.show()
      },
      openModalToEditTrack (index) {
        this.trackToEdit = JSON.parse(JSON.stringify(this.chart.tracks[index]))
        this.trackToEditIndex = JSON.parse(JSON.stringify(index))

        this.$refs.modalToEditTrack.show()
      },
      handleOkEditTrack (evt) {
        evt.preventDefault()

        if (!this.trackToEdit.title || this.trackToEdit.title === '') {
          this.$notify({
            group: 'notifications',
            title: 'Missing track Title :(',
            text: '',
            type: 'error'
          })
          return
        }

        if (!this.trackToEdit.artist || this.trackToEdit.artist === '') {
          this.$notify({
            group: 'notifications',
            title: 'Missing track Artist :(',
            text: '',
            type: 'error'
          })
          return
        }

        if (!this.trackToEdit.image || this.trackToEdit.image === '') {
          this.$notify({
            group: 'notifications',
            title: 'Missing track Image :(',
            text: '',
            type: 'error'
          })
          return
        }

        const track = JSON.parse(JSON.stringify(this.trackToEdit))
        const index = JSON.parse(JSON.stringify(this.trackToEditIndex))

        if (!index && (typeof index === 'object')) {
          this.chart.tracks.push(track)
        } else {
          this.chart.tracks[index] = track
        }

        this.updateTrackIndexes()

        this.$nextTick(() => {
          this.$refs.modalToEditTrack.hide()
        })

        this.resetTrackToEdit()
      },
      handleCancelEditTrack () {
        this.resetTrackToEdit()
      },
      resetTrackToEdit () {
        this.trackToEdit._id = null
        this.trackToEdit.title = null
        this.trackToEdit.artist = null
        this.trackToEdit.image = null
        this.trackToEdit.video = null
        this.trackToEdit.embed_video = null
        this.trackToEdit.related_content = []
        this.trackToEdit.position.current_week = 1
        this.trackToEdit.position.last_week = null
        this.trackToEdit.position.changed = 0
        this.trackToEdit.position.weeks_on_chart = 1
        this.trackToEdit.position.weeks_on_chart_position = 1
        this.trackToEdit.playlist = null

        this.trackToEditIndex = null
      },
      openModalToRemoveTrack (index) {
        this.trackToRemoveIndex = index

        this.$refs.modalToDeleteTrack.show()
      },
      handleOkRemoveTrack () {
        this.chart.tracks.splice(this.trackToRemoveIndex, 1)
        this.updateTrackIndexes()
        this.showTracksUpdatedNotification = true
      },
      handleCancelRemoveTrack () {
        this.trackToRemoveIndex = null
      },
      handleUploadVideoImage (event) {
        const input = event.target
        const formData = new FormData()
        const file = input.files && input.files[0]
        if (!file) {
          return
        }

        const hasValidExtension = /\.(mp4)$/i.test(file.name)
        if (!hasValidExtension) {
          return $this.$notify({
            group: 'notifications',
            title: 'Video should be mp4 format :(',
            text: '',
            type: 'error'
          })
        }

        const fileSize = file.size / 1024 / 1024
        const isTooLargeUpload = fileSize > 3
        if (isTooLargeUpload) {
          return this.$notify({
            group: 'notifications',
            title: 'Video File size exceeds 3 MB :)',
            text: '',
            type: 'error'
          })
        }

        formData.append('file', file)

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }

        this.videoImage.loading = true

        this.$http.post('/cards/video', formData, config).then((res) => {
          if (res.success) {
            this.trackToEdit.video = res.data[0]
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Video file is required :)',
              text: '',
              type: 'error'
            })
          }
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          this.videoImage.loading = false
        })
      },
      handleRemoveVideoImage () {
        this.trackToEdit.video = null
        this.videoImage.html = null
        this.videoImage.placeholder = '1000 x 1100px'
        this.$refs.videoInput.reset()
      },
      getRelatedContent (event) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(async () => {
          if (event.target.value === '') {
            this.searchCardListResults = []
            this.isLoading = false
            return
          }

          const cards = await this.$http.get('/cards', {
            params: {
              type: this.relatedContentTypeSelected,
              size: '750x750',
              page_size: 50,
              q: event.target.value
            }
          })

          this.searchCardListResults = cards.data
        }, 250)
      },
      async selectRelatedContent (item) {
        let isValid = true
        if (this.trackToEdit.related_content.length === 4) {
          isValid = false
          this.$notify({
            group: 'notifications',
            title: 'Not more that 4 cards are allowed :(',
            text: '',
            type: 'error'
          })
        }

        const exists = this.trackToEdit.related_content.filter((innerItem) => {
          return innerItem.id === item.id
        }).length > 0

        if(exists) {
          isValid = false
          this.$notify({
            group: 'notifications',
            title: 'This item already exists :(',
            text: '',
            type: 'error'
          })
        }

        if(isValid) {
          try {
            const slugResponse = await this.$http.get(`/cards/${item._id}/resource-slug`)
            if (slugResponse.success) {
              item.slug = slugResponse.data.slug
            } else {
              item.slug = null
            }
          } catch (error) {
            console.log(error)
            item.slug = null
          }
          this.trackToEdit.related_content.push(item)
        }

        this.searchCardListResults = []
        this.queryCards = ''
      },
      handleRemoveRelatedContent(index) {
        this.trackToEdit.related_content.splice(index, 1)
      },
      getPlaylists(event) {
        clearTimeout(this.playlistTimeout)
        this.playlistTimeout = setTimeout(async () => {
          if (event.target.value === '') {
            this.searchPlaylistResults = []
            this.isLoading = false
            return
          }

          const response = await this.$http.get('/playlists/search', {
            params: {
              q: event.target.value,
              published: true
            }
          })

          this.searchPlaylistResults = response.data
        }, 250)
      },
      selectPlaylist(item) {
        this.trackToEdit.playlist = {
          _id: item._id,
          slug: item.meta.slug,
          title: {
            en: item.meta.titleEnglish,
            es: item.meta.titleSpanish
          },
          image: item.meta && item.meta.cardImagery && item.meta.cardImagery['2xImageURL'] ? item.meta.cardImagery['2xImageURL'] : null
        }
        this.searchPlaylistResults = []
        this.queryPlaylists = ''
      },
      handleRemovePlaylist() {
        this.trackToEdit.playlist = null
      },
      saveChart () {
        if(this.chart.published && !this.chartCommon.validateChart(this.chart, true)) {
          return
        }

        this.$http.put(`/charts/${this.chartId}`, {
          chart: this.chart
        }).then((response) => {
          if(response.success) {
            this.$notify({
              group: 'notifications',
              title: 'Success',
              text: 'Chart successfully updated :)',
              type: 'success'
            })

            this.showTracksUpdatedNotification = false
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Error',
              text: response.message,
              type: 'error'
            })
          }
        }).catch((error) => {
          this.$notify({
            group: 'notifications',
            title: 'Error',
            text: error.message || 'Something went wrong, please try again.',
            type: 'error'
          })
        })
      },
      onWeekSelected(week) {
        this.chart.week = week
      },
    }
  }
</script>

<style>
  .video-image video {
    width: 100%;
    margin-bottom: 10px;
  }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.chart-component {
  .page-header {
    top: 32px;

    .btn {
      width: 150px;
      line-height: 45px;
      text-align: center;
      background: #f2494a;
      color: #fff;
      text-transform: uppercase;
      cursor: pointer;
      border-radius: 0;
      padding: 0;
      font-size: 14px;
    }
  }

  .page-content {
    margin-top: 90px;

    .no-tracks {
      text-align: center;
      display: block;
      margin-bottom: 30px;
      font-size: 18px;
      font-weight: 900;
    }
  }

  .track-wrapper {
    position: relative;
    margin-bottom: 2px;
    background-color: #fff;

    .track-content-wrapper {
      display: flex;

      .track-image {
        img {
          width: 87px;
        }
      }

      .track-position {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #101119;

        .current {
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 30px;
          color: #fff;
          font-weight: 900;
          border-radius: 50%;
        }
      }

      .track-position-change {
        width: 70px;
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;

        .track-position-change-icon-wrapper {
          background-color: rgb(130, 137, 161);
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          font-size: 16px;
          margin-right: 5px;

          &.up {
            background-color: rgb(68, 129, 24);
          }

          &.down {
            background-color: rgb(185, 27, 32);
          }

        }

        .track-new-icon-wrapper {
          background-color: #fff100;
          text-align: center;
          width: 2.5rem;
          color: #2b2e3c;
          font-weight: 600;
          text-transform: uppercase;
        }

        .previous-position {
          font-weight: 600;
        }
      }

      .track-data {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;

        .track-title {
          font-size: 16px;
          font-weight: 900;
          color: #101119;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 300px;
        }
      }

      .icon-wrapper {
        display: flex;
        align-items: center;
        width: 80px;
        border-left: 1px solid #eaeaea;
        justify-content: center;
        cursor: pointer;
        transition: all .3s ease;
      }

      .icon-wrapper:hover {
        background-color: #eaeaea;
      }
    }
  }

  .add-track {
    text-transform: uppercase;
    text-align: center;
    line-height: 70px;
    height: 70px;
    background-color: #eaeaea;
    font-weight: 500;
    cursor: pointer;
  }

  .legend {
    display: flex;
    justify-content: space-between;
    font-weight: 600;

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      .badge{
        margin-right: 3px;
      }
    }
  }
}

.track-search {
  margin-bottom: 20px;
}

.col-form-label {
  font-size: 14px;
}

.status-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .switch {
    width: 60px;
  }

  .status-label {
    margin-right: 10px;
    padding: 10px 0;
    width: 100%;
    display: block;
    font-size: 14px;
    font-weight: bold;
  }
}

#modalToEditTrack .modal-wrapper {
  .row-images {
    margin-bottom: 15px;
  }

  img {
    width: 100%;
  }

  .title-wrapper,
  .artist-wrapper,
  .embed-video {
    margin-bottom: 10px;
  }

  .search-wrapper-related-content {
    position: relative;
    display: flex;
    flex: 1;
    margin-bottom: 10px;

    input {
      flex: 1;
      padding: 0 10px;
      margin-bottom: 0;
    }

    .btn-search-card {
      height: 45px;
      background: #f2494a;
      border-radius: 0;
      color: #fff;
      line-height: 32px
    }

    ul {
      position: absolute;
      width: 100%;
      background: #fff;
      max-height: 350px;
      top: 45px;
      overflow-y: scroll;
      list-style: none;
      padding: 0;
      z-index: 4;
      border-top: 1px solid #dcdcdc;
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

      li {
        :hover {
          background-color: #eaeaea;
          color: #2c3e50;
        }

        .item-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            max-width: 60px;
            margin-right: 10px;
          }

          .item-title {
            text-transform: capitalize;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .placeholder-wrapper {
    display: flex;
    margin-bottom: 30px;
  }

  .placeholder {
    max-width: 24%;
    margin-right: 1%;
    position: relative;

    .remove-related-content {
      position: absolute;
      top: -10px;
      right: -10px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      z-index: 1;
      background: #fff;
      border: 1px solid #eaeaea;
      text-align: center;
      line-height: 20px;
      font-size: 10px;
      cursor: pointer;
      display: none;
    }
  }

  .placeholder:hover .remove-related-content {
    display: block;
  }
}

</style>
