<template>
  <div>
    <b-modal
      id="assetSongsModal"
      ref="assetSongsModal"
      ok-title="Encode"
      title="Media"
      :ok-only="true"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      @ok="handleOkButton($event)"
      @close="$emit('closed')">
      <div class="modal-wrapper">
        <assets-songs-modal ref="assetSongsModal" @nodeSelected="handleSelectedNodes($event)"></assets-songs-modal>
      </div>
    </b-modal>
  </div>
</template>
<script>
import VJstree from 'vue-jstree'
import AssetsSongsModal from './AssetsSongsModal'

export default {
  name: 'AssetsSongs',
  components: {AssetsSongsModal, VJstree},
  data () {
    return {
      selectedNodes: []
    }
  },
  mounted () {
    this.$refs.assetSongsModal.show()
  },
  methods: {
    handleSelectedNodes(nodes) {
      this.selectedNodes = nodes
    },
    handleOkButton($event) {
      $event.preventDefault()

      if(this.selectedNodes.length == 0) {
        return this.$notify({
          group: 'notifications',
          title: 'Error',
          text: 'In order to encode media file, you must select at least one file',
          type: 'error'
        })
      }

      this.$emit('closed')
    }
  }
}
</script>
<style scoped lang="scss">

</style>
