<template>
  <div class="pt-5 pb-5 pl-2 pr-2">
    <audio id="audio" controls controlsList="nodownload" style="width: 100%"></audio>
  </div>
</template>
<script>
import AudioTest from '../../../share/audio'

export default {
  name: 'AssetsItemPreviewAudio',
  props:['item'],
  data () {
    return {
      audio: null
    }
  },
  mounted () {
    this.audio = new AudioTest(this.item.file_url)
  }
}
</script>
<style scoped lang="scss">

</style>
