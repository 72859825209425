<template>
  <div class="stations-all-component">

    <div class="page-header">
      <div class="typeahead-wrapper">
        <div class="typeahead">
          <div class="title">Configurations</div>
        </div>
        <div class="add-btn" @click="addNew">Add new</div>
      </div>
    </div>

    <div class="page-content">
      <div class="config-all-list">
        <transition-group tag="div" class="row" name="list-animation">
          <div class="col-md-6" v-for="(config, index) in configurations" v-bind:key="config.title">
            <div class="config-list-card">
              <div class="config-list-card-content">
                <div class="config-list-card-title">
                  <h3 @click="navigate(config)">{{config.title}}</h3>
                  <span>
                    <strong>Preroll increment:</strong> every {{Math.round(config.advertisements.seconds_between_preroll_ads / 60)}} minutes<br/>
                    <strong>Live versions</strong>: {{config.live_versions.join(', ')}}<br/>
                    <strong>Last updated</strong>: {{getFormattedUpdatedDate(config.updated_at)}}
                  </span>
                </div>
                <div class="submenu-actions">
                  <div class="icon">
                    <i class="fas fa-ellipsis-h"></i>
                  </div>
                  <div class="actions">
                    <ul>
                      <li @click="navigate(config)">Edit</li>
                      <li @click="viewOnLaMusicaConfigURL(config.title)">View On LaMusica Config</li>
                      <li @click="viewOnCloudfront(config.title)">View On Cloudfront</li>
                      <li @click="viewOnS3(config.title)">View On S3</li>
                      <!-- <li @click="confirm(config, index)">Delete</li>-->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'ConfigurationsAll',
    data () {
      return {
        configurations: [],
        toDelete: {
          index: 0,
          config: ''
        }
      }
    },
    async mounted () {
      this.$http.get('/configurations')
        .then((res) => {
          console.log(res.data)
          this.configurations = res.data.sort((a, b) => {
            return a.title.localeCompare(b.title)
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    methods: {
      viewOnS3 (key) {
        window.open(`https://lamusica-remote-config.s3.amazonaws.com/${key}.json`, '_blank')
      },
      viewOnCloudfront (key) {
        window.open(`https://duml1xmjo56t1.cloudfront.net/${key}.json`, '_blank')
      },
      viewOnLaMusicaConfigURL (key) {
        window.open(`https://config.lamusica.com/${key}.json`, '_blank')
      },
      navigate (config) {
        this.$router.push('/admin/configurations/edit/' + config.title)
      },
      addNew () {
        this.$router.push('/admin/configurations/new')
      },
      confirm (config, index) {
        this.$refs.confirmRemoveConfig.show()
        this.toDelete = {
          index: index,
          config: config
        }
      },
      handleRemove (toDelete) {
        this.$http.delete(`/configurations/${toDelete.config._id}`)
          .then((res) => {
            this.$notify({
              group: 'notifications',
              title: 'Configuration successfully removed :(',
              text: '',
              type: 'success'
            })
            this.configurations.splice(toDelete.index, 1)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$notify({
                group: 'notifications',
                title: `${err.response.data} :(`,
                text: '',
                type: 'error'
              })
            } else {
              console.log(err.response)
            }
          })
      },
      getFormattedUpdatedDate: function (date) {
        return moment(date).fromNow()
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.submenu-actions .actions {
  width: 200px;
}

.config-list-card {
  background-color: #fff;
  margin-bottom: 15px;
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;

  .config-list-card-img {
    cursor: pointer;
    width: 50px;
    img {
      width: 100%;
    }
  }

  .config-list-card-content {
    padding: 15px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    width: 100%;
  }

  .config-list-card-title {
    font-size: 14px;
    font-weight: 900;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;

    h3 {
      font-size: 18px;
      text-decoration: underline;
      color: #f34949;
      cursor: pointer;
    }

    span {
      font-weight: normal;
    }
  }
}
</style>
