<template>
  <div>
    <label class="section-title">General</label>
    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group
          label="Title *"
          description="(For internal use) the name of this configuration">
          <b-form-input id="Name"
                        type="text"
                        required
                        v-model="config.title"
                        placeholder="Add title..."
                        :disabled="!!(config.updated_at || config._id)"
                        trim>
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6">
        <b-form-group
          label="App Name *"
          description="The name of the application"
        >
          <b-input-group>
            <b-form-input id="app_name"
                          type="text"
                          required
                          v-model="config.app_name"
                          placeholder="Add App name..."
                          trim>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>

    <div class="spacer"></div>
    <label class="section-title">Live Versions</label>
    <b-form-row class="row-form">
      <b-col sm="12" md="12">
        <b-form-group
          label="Add the semantic version of the builds you wish to maintain live."
        >
          <b-input-group>
            <b-form-input id="live_versions"
                          class="live_versions"
                          type="text"
                          v-model="live_versions_input"
                          placeholder="Live versions..."
                          trim>
            </b-form-input>
            <b-input-group-append>
              <b-btn @click="addVersion()">Add Version</b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-button class="tags-btn" v-for="(version, index) in config.live_versions" v-bind:key="index">
          {{version}} <b-badge variant="light" @click.stop="removeVersion(version, index)">x</b-badge>
        </b-button>

      </b-col>
    </b-form-row>

     <div class="spacer"></div>
    <label class="section-title">Feature Toggle</label>
    <b-form-row class="row-form">
      <b-col sm="12" md="12">
       <b-form-checkbox
                id="radio_recording"
                v-model="config.feature.radio_recording">
               <p><strong>Radio Recording</strong></p>
              </b-form-checkbox>


      </b-col>
    </b-form-row>

    <div class="spacer"></div>
    <label class="section-title">Ad Macros</label>

    <b-form-row class="row-form">
      <b-col sm="12" md="12">
        <b-form-group
          label="Macros that are used in pixel tracking of google ads and wide-orbit ads"
        >
          <b-input-group>
            <b-form-input id="ad_macros"
                          class="ad_macros"
                          type="text"
                          v-model="ad_macros_input"
                          placeholder="%%TIMESTAMP%%"
                          trim>
            </b-form-input>
            <b-input-group-append>
              <b-btn @click="addMacro()">Add Macro</b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-button class="tags-btn" v-for="(macro, index) in config.ad_macros" v-bind:key="index">
          {{macro}} <b-badge variant="light" @click.stop="removeMacro(macro, index)">x</b-badge>
        </b-button>

      </b-col>
    </b-form-row>

    <div class="spacer"></div>
    <label class="section-title">Advertisements</label>

    <b-form-row class="row-form">
      <b-col sm="12" md="12">
        <b-form-group
          label="Adwizz metadata keyword"
        >
          <b-input-group>
            <b-form-input id="adwizz_metadata"
                          class="adwizz_metadata"
                          type="text"
                          v-model="adwizz_metadata"
                          placeholder="CM, COM, etc"
                          trim>
            </b-form-input>
            <b-input-group-append>
              <b-btn @click="addKeyword()">Add Keyword</b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-button class="tags-btn" v-for="(keyword, index) in config.advertisements.adwizz_keywords" v-bind:key="index">
          {{keyword}} <b-badge variant="light" @click.stop="removeKeyword(keyword, index)">x</b-badge>
        </b-button>

      </b-col>
    </b-form-row>

    <b-form-row class="row-form">
      <b-col sm="12" md="4">
        <b-form-group label="Ad placeholder image*">
          <b-form-input id="placeholder_image"
                        type="url"
                        required
                        v-model="config.advertisements.placeholder_image"
                        placeholder="Placeholder images..."
                        trim>
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="4">
        <b-form-group label="Interstatial Ads Key">
          <b-form-input id="interstatial_ads"
                        type="text"
                        v-model="config.advertisements.interstatial_ads"
                        placeholder="Interstatial Ads Key..."
                        trim>
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="4">
        <b-form-group label="Seconds Between Interstatial Ads"
          description="This parameter in seconds override the frequency cap in google ad manager."
        >
          <b-form-input id="interstatial_ads"
                        type="number"
                        v-model="config.advertisements.interstatial_ads_seconds"
                        placeholder="10000"
                        trim>
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group
          label="Seconds between preroll ads *"
          description="This controls the amount of seconds that the app will wait before showing a non-sponsored pre-roll advertisement"
        >
          <b-form-input id="seconds_between_preroll_ads"
                        type="number"
                        number
                        required
                        v-model="config.advertisements.seconds_between_preroll_ads"
                        placeholder="Seconds between pre-roll ads...">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6">
        <b-form-group
          label="Playlist companion ad frequency *"
          description="This controls how many playlist tracks have to be played before a companion ad is requested"
        >
          <b-input-group>
            <b-form-input id="playlist_companion_ad_frequency"
                          type="number"
                          number
                          required
                          v-model="config.advertisements.playlist_companion_ad_frequency"
                          placeholder="Playlist companion ad frequency...">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group
          label="Playlist companion ad amount *"
          description="This controls how many companion ads are requested on a playlist companion ad break"
        >
          <b-form-input id="playlist_companion_ad_amount"
                        type="number"
                        number
                        required
                        v-model="config.advertisements.playlist_companion_ad_amount"
                        placeholder="Playlist companion ad amount...">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6">
        <b-form-group label="Playlist default station id *">
          <b-input-group>
            <b-form-input id="playlist_default_station_id"
                          type="text"
                          required
                          v-model="config.advertisements.playlist_default_station_id"
                          placeholder="Playlist default station id..."
                          trim>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>

    <div class="spacer"></div>
    <label class="section-title">Base Urls</label>

    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group
          label="Share URL *"
          description="The base URL that the application should share to."
        >
          <b-form-input id="share_url"
                        type="url"
                        required
                        v-model="config.base_urls.share_url"
                        placeholder="Share url..."
                        trim>
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6">
        <b-form-group label="Core API *">
          <b-input-group>
            <b-form-input id="core_api"
                          type="url"
                          required
                          v-model="config.base_urls.core_api"
                          placeholder="Core api..."
                          trim>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group
          label="Recommendation Engine *"
          description="The base URL for the recommendation engine API"
        >
          <b-input-group>
            <b-form-input id="rec_engine"
                          type="url"
                          required
                          v-model="config.base_urls.rec_engine"
                          placeholder="Core api..."
                          trim>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6">
        <b-form-group
          label="Chat API base URL *"
          description="The base URL for the chat API.  Should be one that directly communicates to the Node.js instance."
        >
          <b-input-group>
            <b-form-input id="chat_api"
                          type="url"
                          required
                          v-model="config.base_urls.chat_api"
                          placeholder="Core api..."
                          trim>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group
          label="Gateway API *"
          description="The base URL for the Wide Orbit gateway API, used to deliver gateway ads in between playlists."
        >
          <b-input-group>
            <b-form-input id="gateway_api"
                          type="url"
                          required
                          v-model="config.base_urls.gateway_api"
                          placeholder="Core api..."
                          trim>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>


      <b-col sm="12" md="6">
        <b-form-group
          label="Cards API *"
          description="The base URL for the homepage cards API."
        >
          <b-input-group>
            <b-form-input id="cards_api"
                          type="url"
                          required
                          v-model="config.base_urls.cards_api"
                          placeholder="Core api..."
                          trim>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col sm="12" md="6">
        <b-form-group
          label="Authentication API *"
          description="The base URL for the Authentication API."
        >
          <b-input-group>
            <b-form-input id="auth_api"
                          type="url"
                          required
                          v-model="config.base_urls.auth_api"
                          placeholder="Authentication API..."
                          trim>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
            <b-col sm="12" md="6">
        <b-form-group
          label="Recommendation Engine V1*"
          description="The base URL for the Recommendation Engine V1."
        >
          <b-input-group>
            <b-form-input id="rec_engine_v1"
                          type="url"
                          required
                          v-model="config.base_urls.rec_engine_v1"
                          placeholder="Rec Engine API..."
                          trim>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>

    <div class="spacer"></div>
    <label class="section-title">Chat</label>

    <b-form-row class="row-form">

      <b-col sm="12" md="6">
        <b-form-group
          label="Max total messages period duration *"
          description="This sets, in seconds, the window of time which the app will check before sending a message.">

          <b-input-group>
            <b-form-input id="max_total_messages_period_length"
                          :type="'number'"
                          required
                          v-model.number="config.chat.max_total_messages_period_duration"
                          placeholder="Number...">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6">
        <b-form-group
          label="Max total messages during period *"
          description="This defines, as a number, the amount of maximum total messages that can be send during the "
        >
          <b-input-group>
            <b-form-input id="max_total_messages_per_period"
                          type="number"
                          number
                          required
                          v-model="config.chat.max_total_messages_per_period"
                          placeholder="Number...">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row class="row-form">

      <b-col sm="12" md="6">
        <b-form-group
          label="Time window for repeated messages *"
          description="This controls how large of a window, in seconds, a user has to repeat a phrase">

          <b-input-group>
            <b-form-input id="time_window_for_repeated_messages"
                          type="number"
                          number
                          v-model.number="config.chat.time_window_for_repeated_messages"
                          placeholder="Number...">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6">
        <b-form-group
          label="Max amount of messages that can be repeated *"
          description="This controls how many times a user can type the same word in a chat, preventing spam repeat posts"
        >
          <b-input-group>
            <b-form-input id="time_window_for_repeated_messages"
                          type="number"
                          number
                          required
                          v-model="config.chat.max_amount_repeated_messages"
                          placeholder="Number...">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>


    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group
          label="Users required for automatic ban *"
          description="This determines how many people the app requires to report for an automatic ban to be set"
        >
          <b-form-input id="number_of_reports_for_automatic_ban"
                        type="number"
                        number
                        required
                        v-model="config.chat.number_of_reports_for_automatic_ban"
                        placeholder="Number...">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6">
        <b-form-group
          label="Automatic ban duration *"
          description="This describes in seconds the amount of time in seconds a automatic ban will last"
        >
          <b-input-group>
            <b-form-input id="automatic_ban_duration"
                          type="number"
                          number
                          required
                          v-model="config.chat.automatic_ban_duration"
                          placeholder="Number...">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group
          label="Time window for reports before automatic ban *"
          description="This determines how long of a period before X amount of reports result in an automatic ban"
        >
          <b-form-input id="time_window_for_reports_to_automatic_ban"
                        type="number"
                        number
                        required
                        v-model="config.chat.time_window_for_reports_to_automatic_ban"
                        placeholder="Number...">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6">
        <b-form-group
          label="Time window for user counter*"
          description="This determines how long until a request is made to query number of users in a livestream(in seconds)"
        >
          <b-form-input id="counter_refresh_second"
                        type="number"
                        number
                        required
                        v-model="config.chat.counter_refresh_second"
                        placeholder="Number...">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <div class="spacer"></div>
    <label class="section-title">Playlist</label>

    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group label="Number of tracks allowed to skip in an hour in a playlist">
          <b-form-input id="skip-track-hour"
                        type="number"
                        number
                        required
                        v-model="config.playlist.tracks_skip_per_hour"
                        placeholder="6"
                        trim>
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6">
        <b-form-group label="Number of tracks allowed to skip in an day in a playlist">
          <b-input-group>
            <b-form-input id="skip-track-day"
                          type="number"
                          number
                          required
                          v-model="config.playlist.tracks_skip_per_day"
                          placeholder="24"
                          trim>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>

    <div class="spacer"></div>
    <label class="section-title">Store Urls</label>

    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group label="Ios *">
          <b-form-input id="ios"
                        type="url"
                        required
                        v-model="config.store_urls.ios"
                        placeholder="ios..."
                        trim>
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6">
        <b-form-group label="Android *">
          <b-input-group>
            <b-form-input id="android"
                          type="url"
                          required
                          v-model="config.store_urls.android"
                          placeholder="Android..."
                          trim>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group label="Fire tv *">
          <b-form-input id="fire_tv"
                        type="url"
                        required
                        v-model="config.store_urls.fire_tv"
                        placeholder="Fire tv..."
                        trim>
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6">
        <b-form-group label="Roku *">
          <b-input-group>
            <b-form-input id="Roku"
                          type="url"
                          required
                          v-model="config.store_urls.roku"
                          placeholder="Roku..."
                          trim>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group label="Alexa *">
          <b-form-input id="alexa"
                        type="url"
                        required
                        v-model="config.store_urls.alexa"
                        placeholder="Alexa..."
                        trim>
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <div class="spacer"></div>
    <label class="section-title">Pages</label>

    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group label="Terms & Conditions *">
          <b-form-input id="terms_conditions"
                        type="url"
                        required
                        v-model="config.pages.terms_conditions"
                        placeholder="Terms & Conditions..."
                        trim>
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6">
        <b-form-group label="DMCA compliance *">
          <b-input-group>
            <b-form-input id="dmca_compliance"
                          type="url"
                          required
                          v-model="config.pages.dmca_compliance"
                          placeholder="DMCA compliance..."
                          trim>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group label="Privacy policy *">
          <b-form-input id="privacy_policy"
                        type="url"
                        required
                        v-model="config.pages.privacy_policy"
                        placeholder="Privacy policy..."
                        trim>
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6">
        <b-form-group label="CCPA Data *">
          <b-form-input id="ccpa_link"
                        type="url"
                        required
                        v-model="config.pages.ccpa_link"
                        placeholder="CCPA Data"
                        trim>
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <div class="spacer"></div>
    <label class="section-title">Support email</label>

    <b-form-row class="row-form">
      <b-col sm="12" md="12">
        <b-form-group label="Support email *">
          <b-form-input id="support_email"
                        type="text"
                        required
                        v-model="config.support_email"
                        placeholder="Support email..."
                        trim>
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <div class="spacer"></div>
    <label class="section-title">Voicenotes</label>

    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group>
        <b-form-checkbox
                id="voicenotes_active"
                v-model="config.voicenotes.active">
               <p><strong>Active</strong></p>
              </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6">
        <b-form-group label="Frequency">
          <b-input-group>
            <b-form-input id="voicenotes_frequency"
                          type="number"
                          required
                          v-model="config.voicenotes.frequency"
                          placeholder="Amount of second allowed in between voicenotes"
                          trim>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>

    <div class="spacer"></div>
    <label class="section-title">Recovery Section</label>

    <b-form-row class="row-form">
      <b-col sm="12" md="6">
        <b-form-group>
        <b-form-checkbox
                id="recovery_active"
                v-model="config.recovery.active">
               <p><strong>Active</strong></p>
              </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6">
        <b-form-group label="Recovery Mode URL">
          <b-input-group>
            <b-form-input id="recovery_url"
                          type="text"
                          required
                          v-model="config.recovery.url"
                          placeholder="The URL for the recovery mode"
                          trim>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
    export default {
      data () {
        return {
          live_versions_input: '',
          ad_macros_input : '',
          adwizz_metadata: ''
        }
      },
      props: {
        config: {
          type: Object,
          required: true
        }
      },
      mounted () {
        console.log('config',this.config);
      },
      methods: {
        addVersion () {
          if (!this.live_versions_input) {
            return this.$notify({
              group: 'notifications',
              title: 'A version value is required :(',
              text: '',
              type: 'error'
            })
          }

          const isDuplicateVersion = this.config.live_versions.some((element) => {
            return element === this.live_versions_input
          })

          if (isDuplicateVersion) {
            return this.$notify({
              group: 'notifications',
              title: 'Duplicate version are not allowed :(',
              text: '',
              type: 'error'
            })
          }
          this.config.live_versions.push(this.live_versions_input)
          this.live_versions_input = ''
        },
        /**
         * Remove version
         * @param {string} version
         * @param {number} index
         */
        removeVersion (version, index) {
          this.config.live_versions.splice(index, 1)
          return this.$notify({
            group: 'notifications',
            title: `Removed version "${version}" from this list`,
            text: '',
            type: 'success'
          })
        },
        addKeyword(){
          if (!this.adwizz_metadata) {
                    return this.$notify({
              group: 'notifications',
              title: 'Keyword cannot be blank :(',
              text: '',
              type: 'error'
            })
          }
          if(!this.config.advertisements.adwizz_keywords){
              this.config.advertisements.adwizz_keywords = [];
          }

          const isDuplicateVersion = this.config.advertisements.adwizz_keywords.some((element) => {
            return element === this.adwizz_metadata
          })

          if (isDuplicateVersion) {
            return this.$notify({
              group: 'notifications',
              title: 'Duplicate keywords are not allowed :(',
              text: '',
              type: 'error'
            })
          }
          this.config.advertisements.adwizz_keywords.push(this.adwizz_metadata)
          this.adwizz_metadata = ''
        },
        removeKeyword (keyword, index) {
          this.config.advertisements.adwizz_keywords.splice(index, 1);
          return this.$notify({
            group: 'notifications',
            title: `Removed "${keyword}" from this list`,
            text: '',
            type: 'success'
          })
        },
        addMacro () {
          if (!this.ad_macros_input) {
            return this.$notify({
              group: 'notifications',
              title: 'A version value is required :(',
              text: '',
              type: 'error'
            })
          }
          if(!this.config.ad_macros){
              this.config.ad_macros = [];
          }

          const isDuplicateVersion = this.config.ad_macros.some((element) => {
            return element === this.ad_macros_input
          })

          if (isDuplicateVersion) {
            return this.$notify({
              group: 'notifications',
              title: 'Duplicate version are not allowed :(',
              text: '',
              type: 'error'
            })
          }
          this.config.ad_macros.push(this.ad_macros_input)
          this.ad_macros_input = ''
        },
        /**
         * Remove macro
         * @param {string} macro
         * @param {number} index
         */
        removeMacro (macro, index) {
          this.config.ad_macros.splice(index, 1);
          return this.$notify({
            group: 'notifications',
            title: `Removed macro "${macro}" from this list`,
            text: '',
            type: 'success'
          })
        }
      }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .row-form legend {
    font-size: 14px;
  }
</style>
<style scoped>
  .config-component .card {
    padding: 30px;
  }

  .config-component .card input {
    margin-bottom: 0 !important;
  }

  .config-component .card label.section-title {
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .spacer {
    margin-top: 40px;
  }

  .row-form {
    margin-bottom: 20px;
    font-size: 16px;
  }

  .bootstrap-form button {
    display: initial;
  }

  .bootstrap-form input.live_versions {
    margin-bottom: 10px !important;
  }

  .config-component .card .tags-btn {
    width: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 4px;
    color: #fff;
    font-style: oblique;
    background-color: #f2494a;
  }

  .bootstrap-form button {
    font-size: 12px;
    line-height: 25px;
    text-transform: uppercase;
  }

  .btn-secondary {
    margin-left: 10px;
  }
</style>
