<template>
  <div id="app">
    <!-- routes will be rendered here -->
    <router-view :key="$route.fullPath" />

    <notifications group="notifications" classes="lamusica-notification" position="bottom right"/>
    <loading></loading>
  </div>
</template>


<script>
import loading from './components/share/loading'

  export default {
    name: 'app',
    components: {
      loading
    },
    data () {
      return {}
    }
  }
</script>

<style src="vue-select/dist/vue-select.css">

</style>

<style>

.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected > span > span,
.theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #f2494a;
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  color: #f2494a;
}

@media print {
  .new-playlist ul.list {
    height: 100% !important;
  }

  .admin-sidebar,
  .welcome-user,
  .latest-playlist-list,
  .playlistUpdated-list,
  .dmca-wrapper,
  .playlist-subtitle,
  .header,
  .controls-wrapper {
    display: none !important;
  }

  .playlist-list-wrapper {
    max-width: 100%;
    flex: 1
  }

  .main-general-wrapper {
    margin-left: 0
  }
}

body, ul, p {
  margin: 0;
  padding: 0;
  font-size: 14px !important;
  color: #495057;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.page-header{
  position: fixed;
  top: 20px;
  left: 200px;
  right: 0;
  padding: 30px 15px;
  background-color: #f7f7f7;
  z-index: 4;
  /* border-bottom: 1px solid #eaeaea; */
}

.page-content {
  margin-top: 80px;
}

.title{
  /* margin-bottom: 15px; */
  font-size: 16px;
  text-transform: uppercase;
  display: flex;
}

.title a, .title a:hover{
  color: #2c3e50;
  text-decoration: none;
  margin-right: 3px;
}

.title span{
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 3px;
}

.pagination .page-link{
  color: #495057;
  background-color: #fff;
  font-size: 12px;
  font-weight: 900;
}

.pagination .page-link:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus{
  box-shadow: none;
}

.pagination .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #de4242;
  border-color: #de4242;
}

.pagination .page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #f2494a;
  border-color: #f2494a;
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link{
  border-radius: 0;
}

.pagination .page-item:first-child .page-link:hover,
.pagination .page-item:last-child .page-link:hover{
  border: 1px solid #de4242;
}

.lamusica-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.lamusica-switch input {display:none;}

.lamusica-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.lamusica-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.lamusica-switch input:checked + .lamusica-slider {
  background-color: #de4242;
}

.lamusica-switch input:focus + .lamusica-slider {
  box-shadow: 0 0 1px #de4242;
}

.lamusica-switch input:checked + .lamusica-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.lamusica-notification {
  padding: 10px;
  margin: 10px;

  font-size: 14px;

  color: #2c3e50;
  background: #fff;
  border-left: 5px solid #187FE7;
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.lamusica-notification.warn {
  background: #fff;
  border-left-color: #f48a06;
}

.lamusica-notification.error {
  background: #fff;
  border-left-color: #de4242;
}

.lamusica-notification.success {
  background: #fff;
  border-left-color: #42A85F;
}

.controls-wrapper{
  display: flex;
  justify-content: flex-end;
}

.controls-wrapper ul{
  list-style-type: none;
  display: flex;
  justify-content: center;
  border: 1px solid #eaeaea;
  max-width: 750px;
  margin-bottom: 15px !important;
}

.controls-wrapper ul li{
  padding: 5px 30px;
  border-right: 1px solid #eaeaea;
  cursor: pointer;
  background-color: #fff;
}

/* .controls-wrapper ul li:hover{
  background-color: #de4242
} */

.controls-wrapper ul li:last-child{
  border-right: none;
}

.controls-wrapper .modal-btn{
  background: #fff;
  border: none;
  font-size: 14px;
  padding: 5px 30px;
  border-right: 1px solid #eaeaea;
  cursor: pointer;
  border-radius: 0;
  color: #2c3e50;
}

.controls-wrapper .modal-btn:focus{
  box-shadow: none;
}

.modal-content{
  border-radius: 0 !important;
  background-color: #f7f7f7 !important;
}

.modal-content .modal-footer button{
  padding: 0;
  font-size: 14px;
  width: 130px;
  line-height: 40px;
  text-align: center;
  background: #f2494a;
  margin-left: 15px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  border-radius: 0;
}

.modal-wrapper input{
  height: 45px;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 15px;
  border: 1px solid #eaeaea;
}

.modal-wrapper select{
  height: 45px;
  width: 100%;
  border-radius: 0;
  border: 1px solid #eaeaea;
  background: white;
  margin-bottom: 10px;
}

.add-btn{
  width: 150px;
  line-height: 45px;
  text-align: center;
  background: #f2494a;
  margin-left: 15px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.typeahead-wrapper{
  display: flex;
}

.typeahead-wrapper .name{
  flex: 1;
  height: 45px;
  padding: 0 30px;
  border: none;
  font-size: 14px;
  border: 1px solid #eaeaea;
}

.typeahead-wrapper .typeahead-btn{
  width: 150px;
  line-height: 45px;
  text-align: center;
  background: #f2494a;
  margin-left: 15px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.typeahead{
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
}

.typeahead input{
  padding: 0 30px;
  font-size: 14px;
  border-radius: 0;
  height: 45px;
  border: 1px solid #eaeaea;
}

.typeahead ul{
  position: absolute;
  width: 100%;
  background: #fff;
  max-height: 250px;
  top: 45px;
  overflow-y: scroll;
  list-style: none;
  padding: 0;
  z-index: 4;
  border-top: 1px solid #dcdcdc;

  -webkit-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  -moz-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.typeahead ul li{
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0;
  cursor: pointer;
}

.typeahead ul li:hover{
  background-color:#f2494a;
  color: #fff;
}

.typeahead ul li .playlist-img img{
  width: auto;
  max-height: 45px;
  margin-right: 15px;
}

.submenu-actions{
  position: relative;
  color: #2c3e50;
  cursor: pointer;
}

.submenu-actions ul{
  margin-bottom: 0;
}

.submenu-actions:hover .actions{
  display: block;
}

.submenu-actions .icon{
  font-size: 20px;
  line-height: 20px;
  width: 30px;
  text-align: center;
}

.submenu-actions .actions{
  display: none;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 20px;
  width: 100px;
  z-index: 1;
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.submenu-actions .actions ul li{
  list-style-type: none;
  padding: 6px 10px;
  text-align: right;
  border-bottom: 1px solid #eaeaea;
  font-size: 12px;
  font-weight: 900;
  color: #2c3e50;
}

.submenu-actions .actions ul li:last-child{
  border: none;
}

.submenu-actions .actions ul li:hover{
  background: #f3f3f3;
}

.file-upload-form input{
  border: none;
}

.vdp-datepicker__calendar .cell.selected {
  background: #de4242 !important;
  color: #fff !important;
}

.bootstrap-form input,
.bootstrap-form .dateInput,
.bootstrap-form select, .dateInput {
  display: block;
  width: 100%;
  height: 45px !important;
  padding: 0 10px;
  margin-bottom: 15px !important;
  border-radius: 0;
  font-size: 14px;
  border:1px solid #eaeaea;;
}

.bootstrap-form textarea{
  display: block;
  width: 100%;
  /* padding: 0 10px; */
  margin-bottom: 15px;
  border-radius: 0;
  font-size: 14px;
  border:1px solid #eaeaea;;
}

.bootstrap-form button{
  color: #fff;
  display: block;
  padding: 10px;
  text-align: center;
  margin-bottom: 15px;
  background-color: #f2494a;
  border: none;
  border-radius: 0;
  width: 100%;
}

.bootstrap-form button:hover,
.bootstrap-form button:active,
.bootstrap-form button:active:focus{
  background-color: #de4242 !important;
  border: none !important;
}

.bootstrap-form .custom-file-label {
  border-radius: 0;
}

.col-form-label {
  font-weight: bold;
  font-size: 12px;
}

fieldset.form-group{
  margin: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 49px;
  height: 25px;
  margin-bottom: 0;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 19px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #8cd200;
}

input:focus + .slider {
  box-shadow: 0 0 1px #8cd200;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.list-animation {
  backface-visibility: hidden;
  z-index: 1;
}

/* moving */
.list-animation-move {
  transition: all 600ms ease-in-out 50ms;
}

/* appearing */
.list-animation-enter-active {
  transition: all 400ms ease-out;
}

/* disappearing */
.list-animation-leave-active {
  transition: all 200ms ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disappear to */
.list-animation-enter,
.list-animation-leave-to {
  opacity: 0;
  margin-top: 40px;
}

.video-holder-card {
  position: relative;
}

.video-holder-card .remove-video-card {
  position: absolute;
  top: -10px;
  right: -10px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  z-index: 1;
  background: #fff;
  border: 1px solid #eaeaea;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
}

code {
  color: #f2494a;
}


/************************** Mobile Menu ************************/

.main-general-wrapper.mobile {
  margin-left: 0;
}

.mobile .page-header {
  left: 0
}

.admin-sidebar.mobile {
  display: none;
}

.v-select .vs__dropdown-toggle .vs__selected-options  .vs__search {
  border: none !important;
  margin-bottom: 0 !important;
  height: auto !important;
  width: auto;
  font-size: inherit;
}

</style>
