import {DateTime as LuxonDateTime} from 'luxon'
export default class PodcastsCommon {
  constructor (props) {
    this.context = props.context
  }

  getPodcastEpisodeArtworkURL (id) {
    let url
    if (id) {
      url = 'https://playlists.lamusica.com/api/media/artworks/' + id + '.jpg'
      if (window.location.host.indexOf('localhost') > -1) {
        url = 'http://localhost:3005/api/media/artworks/' + id + '.jpg'
      }
    } else if (this.context.podcast.meta.detailViewImagery.mdpiImageURL) {
      url = this.context.podcast.meta.detailViewImagery.mdpiImageURL
    } else {
      url = '/static/img/LaMusica-Icon-podcast-placeholder.jpg'
    }
    return url
  }

  validateEpisodeTitle (episodeTitle) {
    if (!episodeTitle) {
      return false
    }

    const title = episodeTitle.trim()

    if (title.toUpperCase() === title) {
      return false
    }

    if (title.match(/!{2,}/g)) {
      return false
    }

    return !(title.length < 40 || title.length > 200)
  }

  createNewEpisodeWithEmptySchema () {
    return {
      title: '',
      meta: {
        description: '',
        artist_guest: '',
        tags: ''
      },
      duration: 0,
      id_7digital: null,
      image: null,
      new_image: false,
      image_url: null,
      artist: {
        _id: null,
        name: null,
        id7digital: null
      },
      type: 'podcast',
      isrc: null,
      audio_url: null,
      video_url: null,
      status: 'draft',
      ads: { audio: { prerolls: 1, postrolls: 1 } },
      created_at: LuxonDateTime.utc().toISO(),
      published_at: LuxonDateTime.utc().toISO(),
      showId: null,
      file_status: {
        audio: {
          code: 'no_audio',
          label: 'No audio file selected yet'
        },
        video: {
          code: 'no_video',
          label: 'No video file selected yet'
        }
      },
      files: {
        audio: {
          url: null,
          is_new: false,
          options: {}
        },
        video: {
          url: null,
          is_new: false,
          options: {
            extract_audio: true
          }
        }
      }
    }
  }

  validateEpisode (episode) {
    const $this = this.context
    if (!episode.title) {
      $this.$notify({
        group: 'notifications',
        title: 'Episode Title is required :(',
        text: '',
        type: 'error'
      })
      return false
    }

    if (!this.validateEpisodeTitle(episode.title)) {
      $this.$notify({
        group: 'notifications',
        title: ' Title must be between 40-200 characters, and cannot be all-caps or contain repeating exclamation points.',
        text: '',
        type: 'error'
      })
      return false
    }

    const episodeCopy = JSON.parse(JSON.stringify(episode))
    episodeCopy.meta.artist_guest = episodeCopy.meta.artist_guest && typeof episodeCopy.meta.artist_guest === 'string' ? episodeCopy.meta.artist_guest.split(',') : ''
    episodeCopy.meta.tags = episodeCopy.meta.tags && typeof episodeCopy.meta.tags === 'string' ? episodeCopy.meta.tags.split(',') : ''

    const podcast = $this.podcast

    episodeCopy.artist = {
      _id: null,
      name: podcast.title,
      id7digital: null
    }

    return episodeCopy
  }
}
