<template>
  <div class="media-edit-component">
    <div class="row align-items-center header">
      <div class="col-6">
        <div class="title">
          <router-link to="/admin/media">Media </router-link>/
          <span v-if="media && media.title">{{media.title}}</span>
        </div>
      </div>
      <div class="col-6">
        <div class="typeahead-wrapper">
          <input class="name" type="text" placeholder="Media Title..." v-model="media.title"/>
          <div class="typeahead-btn" @click="save()">Create</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="controls-wrapper">
          <ul>
              <b-btn v-b-modal.modal5 class="add-playlist modal-btn">Add Meta</b-btn>
              <!-- <b-btn v-b-modal.modal6 class="add-playlist modal-btn">Add Image</b-btn> -->
              <!-- <b-btn v-b-modal.modal7 class="add-playlist modal-btn">Add Audio</b-btn> -->
              <li class="add-playlist" @click="media.explicitContent = !media.explicitContent">{{media.explicitContent ? 'Explicit': 'No explicit'}}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" v-if="media">
      <div class="col-4">
        <div class="media-image" v-if="media.image && imageData.length === 0">
          <img :src="$apiUrl + '/media/artworks/' + media.image + '.jpg'" alt="" v-if="media.image"/>
          <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!media.image"/>
        </div>

        <div class="media-image" v-if="imageData.length > 0">
          <img :src="imageData" alt="" />
        </div>
      </div>

      <div class="col-8">
        <div class="media-info">

          <div class="general">
            <div class="artist" v-if="media.artist.name !== ''">
              {{media.artist.name}}
            </div>
            <div class="title" v-if="media.release.title !== ''">
              Album: {{media.release.title}}
            </div>
          </div>

          <div class="other">
            <div class="type" v-if="media.type !== ''">
              Type: {{media.type}}
            </div>
            <div class="" v-if="media.isrc !== ''">
              isrc: {{media.isrc}}
            </div>
            <div class="date" v-if="media.release.title !== ''">
              Relase Date: {{formatDate(media.release.date)}}
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="wavesurfer-wrapper-edit" v-show="wavesurfer">
      <div class="controls">
        <div class="playPause" @click="playPause">
          <div class="playPause-wrapper">
            <i class="fas fa-play" v-if="!play"></i>
            <i class="fas fa-pause"  v-if="play"></i>
          </div>
        </div>
        <div class="duration">
          {{process}} / {{duration}}
        </div>
      </div>
      <div class="wavesurfer" :id="'wavesurfer-media'"></div>
    </div>

    <b-modal id="modal5" :ok-only="true" ok-title="close"	 size="lg" title="Metadata">
      <div class="modal-wrapper">
          <div class="row">
              <div class="col-6">
                  <input type="text" placeholder="Media title" v-model="media.title"/>
              </div>
              <div class="col-6">
                <typeahead @trackSelected="selectRelease" :resource="releaseResource" @noResults="noReleaseHandler" text="Release Title" v-if="media.release.title === '' && !newRelease"></typeahead>
                <div class="new-item" @click="addNewRelease" v-if="notReleaseData !== '' && media.release.title === ''">Add New Release</div>

                <div class="selectedItem" v-if="media.release.title !== ''">
                  <span>{{media.release.title}}</span>
                  <i class="fas fa-times" @click="cleanReleaseSearch"></i>
                </div>
              </div>
          </div>
          <div class="row">
              <div class="col-6">
                  <input type="text" placeholder="Media ISRC" v-model="media.isrc"  />
              </div>
              <div class="col-6">
                <typeahead @trackSelected="selectArtist" :resource="artistResource" @noResults="noArtistHandler" text="Artist Name" v-if="media.artist.name === '' && !newArtist"></typeahead>
                <div class="new-item" @click="addNewArtist" v-if="notArtistData !== '' && media.artist.name === ''">Add New Artist</div>

                <div class="selectedItem" v-if="media.artist.name !== ''">
                  <span>{{media.artist.name}}</span>
                  <i class="fas fa-times" @click="cleanArtistSearch"></i>
                </div>
              </div>
          </div>
          <div class="row">
              <div class="col-6">
                  <select v-model="media.type">
                    <option value="">Select Media Type</option>
                    <option v-for="type in mediaTypes" v-bind:key="type.value" :value="type.value">{{type.label}}</option>
                  </select>
                </div>
              <div class="col-6">
                <datepicker v-model="media.release.date"></datepicker>
              </div>
          </div>
      </div>
    </b-modal>

    <!-- <b-modal id="modal6" :ok-only="true" ok-title="close" size="md" title="Images">
      <div class="row modal-wrapper">
        <div class="col-12">
          <div class="image-preview" v-if="media.image && imageData.length === 0">
            <img :src="$apiUrl + '/media/artworks/' + media.image + '.jpg'" alt="" v-if="media.image"/>
            <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!media.image"/>
          </div>
          <div class="image-preview" v-if="imageData.length > 0">
            <img class="preview" :src="imageData">
          </div>
          <div class="file-upload-form">
            <div class="label">Upload image - 800x800px</div>
            <input type="file" @change="previewImage" accept="image/*">
          </div>
        </div>
      </div>
    </b-modal> -->

    <!-- <b-modal id="modal7" :ok-only="true" ok-title="close" size="sm" title="Audio">
      <div class="row modal-wrapper">
        <div class="col-12">
          <div class="file-upload-form">
            <div class="label">Upload Media File - MP3 Format</div>
            <input type="file" @change="previewAudio" accept="audio/*">
          </div>
        </div>
      </div>
    </b-modal> -->
  </div>
</template>


<script>
import typeahead from '../../share/typeahead'
import WaveSurfer from 'wavesurfer.js'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'MediaNew',
  components: {
    Datepicker,
    typeahead
  },
  data () {
    return {
      media: {
        artist: {
          _id: null,
          name: '',
          id7digital: null
        },
        duration: null,
        explicitContent: false,
        id_7digital: null,
        image: null,
        isrc: '',
        release: {
          id: null,
          title: '',
          date: moment().toISOString()
        },
        title: null,
        type: '',
        _id: null
      },
      mediaTypes: [
        {value: 'audio', label: 'Audio'},
        {value: 'video', label: 'Video'}
      ],
      wavesurfer: null,
      process: '0:00',
      duration: null,
      play: false,
      imageData: '',
      audioData: '',
      formData: new FormData(),
      releaseResource: 'release',
      artistResource: 'artist',
      newRelease: false,
      newReleaseTitle: '',
      notReleaseData: '',
      newArtist: false,
      newArtistName: '',
      notArtistData: ''
    }
  },

  mounted () {
  },
  beforeDestroy () {
    if (this.wavesurfer) {
      this.wavesurfer.destroy()
    }
  },
  methods: {
    noReleaseHandler (data) {
      if (data) {
        this.notReleaseData = data
      } else {
        this.notReleaseData = ''
      }
    },
    addNewRelease () {
      this.media.release.title = this.notReleaseData
    },
    selectRelease (release) {
      this.media.release = release
    },
    cleanReleaseSearch () {
      this.media.release = {
        title: '',
        id: null,
        date: new Date()
      }
      this.notReleaseData = ''
      this.newReleaseTitle = ''
    },
    noArtistHandler (data) {
      if (data) {
        this.notArtistData = data
      } else {
        this.notArtistData = ''
      }
    },
    addNewArtist () {
      this.media.artist.name = this.notArtistData
    },
    selectArtist (artist) {
      this.media.artist = artist
    },
    cleanArtistSearch () {
      this.media.artist = {
        name: '',
        _id: null,
        id7digital: null
      }
      this.notArtistData = ''
      this.newArtistName = ''
    },
    playPause () {
      this.play = !this.play
      this.wavesurfer.playPause()
    },
    save () {
      EventHandler.emit('isLoading', true)
      this.formData.append('media', JSON.stringify(this.media))
      this.$http.post('/media', this.formData).then((res) => {
        this.$notify({
          group: 'notifications',
          title: 'Media created successfully :)',
          text: '',
          type: 'success'
        })
        EventHandler.emit('isLoading', false)
        this.$router.push('/admin/media')
      })
        .catch((err) => {
          console.log(err)
        })
    },
    previewImage (event) {
      let input = event.target

      if (input.files && input.files[0]) {
        if (/\.(jpe?g|png|gif)$/i.test(input.files[0].name)) {
          let reader = new FileReader()
          reader.readAsDataURL(input.files[0])

          reader.onload = (e) => {
            const URL = window.URL || window.webkitURL
            const image = new Image()
            image.src = URL.createObjectURL(input.files[0])
            image.onload = () => {
              let height = image.height
              let width = image.width

              if (height !== 800 || width !== 800) {
                this.$notify({
                  group: 'notifications',
                  title: 'Image size allowed 800 x 800px :(',
                  text: '',
                  type: 'error'
                })

                return false
              } else {
                this.imageData = e.target.result
                this.formData.append('cover', input.files[0])
              }
            }
          }
        } else {
          this.$notify({
            group: 'notifications',
            title: 'Image should be jpg or png format :(',
            text: '',
            type: 'error'
          })
        }
      }
    },
    previewAudio (event) {
      let input = event.target

      if (input.files && input.files[0]) {
        if (/\.(mp3|m4a)$/i.test(input.files[0].name)) {
          let audio = new Audio()
          audio.src = URL.createObjectURL(input.files[0])

          if (!this.wavesurfer) {
            this.wavesurfer = WaveSurfer.create({
              container: `#wavesurfer-media`,
              height: '130',
              backend: 'MediaElement',
              waveColor: '#FD8283',
              progressColor: '#de4242',
              responsive: true,
              barWidth: 2
            })
          }

          this.wavesurfer.on('audioprocess', () => {
            this.process = this.formatTime(this.wavesurfer.getCurrentTime())
          })

          this.wavesurfer.on('ready', () => {
            this.media.duration = parseInt(this.wavesurfer.getDuration())
            this.duration = this.formatTime(this.wavesurfer.getDuration())
          })

          this.wavesurfer.on('destroy', () => {
            this.wavesurfer = null
          })

          this.wavesurfer.load(audio)

          this.formData.append('track', input.files[0])
        } else {
          this.$notify({
            group: 'notifications',
            title: 'Audio file should be Mp3 or M4a format :(',
            text: '',
            type: 'error'
          })
        }
      }
    },
    formatTime (time) {
      return [
        Math.floor((time % 3600) / 60), // minutes
        ('00' + Math.floor(time % 60)).slice(-2) // seconds
      ].join(':')
    },
    formatDate (date) {
      return moment(date).format('MMMM Do YYYY')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.media-edit-component .header{
  margin-bottom: 30px;
}

.wavesurfer-wrapper-edit{
  position: fixed;
  bottom: 0;
  right: 0;
  left: 200px;
  height: 185px;
}

.wavesurfer-wrapper-edit .controls {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  margin-bottom: 15px;
}

.wavesurfer-wrapper-edit .controls .playPause{
  cursor: pointer;
}

.media-image{
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border: 1px solid #eaeaea;
}

.media-image img{
  width: 100%
}

.media-edit-component .controls-wrapper{
  margin-bottom: 30px;
}

.media-info{
  text-transform: uppercase;
  font-weight: bold;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
}

.media-info .general{
  font-size: 26px;
}

.media-info .general .title{
  font-size: 14px;
  margin: 0;
  text-transform: capitalize;
}

.media-info .other {
  text-transform: capitalize;
  font-size: 12px;
}

#modal6 .image-preview {
  margin-bottom: 15px;
}

#modal6 .image-preview img{
  width: 100%;
}

.playPause-wrapper{
  font-size: 16px;
  text-align: center;
  line-height: 42px;
  height: 40px;
  width: 40px;
  background-color: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

#modal6 .label, #modal7 .label{
  margin-bottom: 10px;
}

#modal6 input, #modal7 input{
  padding: 0;
}

.selectedItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  font-weight: bold;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 15px;
}

.selectedItem i{
  cursor: pointer;
}

.new-release-wrapper {
  display:flex;
}

.new-release-wrapper button{
  background-color: #f2494a;
  height: 45px;
  color: #fff;
  font-size: 12px;
  padding: 0 15px;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.new-item{
  position: absolute;
  top: 0;
  height: 45px;
  line-height: 45px;
  padding: 0 10px;
  cursor: pointer;
  font-weight: bold;
  right: 16px;
  font-size: 10px;
  background-color: #f2494a;
  color: #fff;
}
</style>
