<template>
  <div class="tests-all-component">

    <div class="page-header">
      <div class="typeahead-wrapper">
        <div class="typeahead">
          <div class="title">Ads Tests</div>
        </div>
        <div class="add-btn" @click="addNew">Add Test</div>
      </div>
    </div>

    <div class="page-content">
      <div class="tests-all-list">
        <transition-group tag="div" class="row" name="list-animation">
          <div class="col-md-6" v-for="(test, index) in tests" v-bind:key="test._id">
            <div class="tests-list-card">
              <div class="tests-list-card-img" @click="navigate(test._id)">
                <img src="/static/img/256x256.png" alt=""/>
              </div>
              <div class="tests-list-card-content">
                <div class="tests-list-card-title">
                  {{test.title}}
                </div>
                <div class="submenu-actions">
                  <div class="icon">
                    <i class="fas fa-ellipsis-h"></i>
                  </div>
                  <div class="actions">
                    <ul>
                      <li @click="navigate(test._id)">Edit</li>
                      <li @click="confirm(test, index)">Delete</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>

    <b-modal id="confirmRemoveTest" ref="confirmRemoveTest" size="md" title="Confirm" @ok="handleRemove(toDelete)">
      <div class="row modal-wrapper">
        <div class="col-12">
          Are you sure want to remove <strong>{{toDelete.test.title}}</strong> test?
        </div>
      </div>
    </b-modal>

    <b-modal id="addTest" ref="addTest" ok-title="Create" size="md" title="New Test" @ok="createTest">
      <div class="row modal-wrapper">
        <div class="col-12">
          <input type="text" placeholder="Add Test title" v-model="test.title"/>
        </div>
      </div>
    </b-modal>
  </div>
</template>


<script>

export default {
  name: 'AdsAll',
  data () {
    return {
      test: {
        title: '',
        createdAt: null,
        updatedAt: null,
        tests: [
          {
            resourceId: null,
            type: 'videos',
            name: 'Videos',
            isSponsored: false,
            disableAds: false,
            SpotXPrerollVideoAdUnitId: null,
            DFPPrerollVideoAdUnitId: null
          },
          {
            resourceId: null,
            type: 'playlists',
            name: 'Playlists',
            isSponsored: false,
            disableAds: false,
            SpotXPrerollVideoAdUnitId: null,
            DFPPrerollVideoAdUnitId: null
          },
          {
            resourceId: null,
            type: 'podcasts',
            name: 'Podcasts',
            isSponsored: false,
            disableAds: false,
            SpotXPrerollVideoAdUnitId: null,
            DFPPrerollVideoAdUnitId: null
          },
          {
            resourceId: null,
            type: 'stations',
            name: 'Stations',
            isSponsored: false,
            disableAds: false,
            SpotXPrerollVideoAdUnitId: null,
            DFPPrerollVideoAdUnitId: null
          },
          {
            resourceId: null,
            type: 'livestreams',
            name: 'Livestreams',
            isSponsored: false,
            disableAds: false,
            SpotXPrerollVideoAdUnitId: null,
            DFPPrerollVideoAdUnitId: null
          }
        ]
      },
      tests: [],
      toDelete: {
        index: 0,
        test: ''
      },
      query: ''
    }
  },

  mounted () {
    this.$http.get('/tests/ads')
      .then((res) => {
        this.tests = res.data
      })
      .catch((err) => {
        console.log(err)
      })
  },

  methods: {
    addNew (id) {
      this.$refs.addTest.show()
    },
    createTest () {
      this.$http.post('/tests/ads', {test: this.test})
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: 'Test created successfully :)',
            text: '',
            type: 'success'
          })
          this.$router.push('/admin/tests/ads/edit/' + res.data.id)
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$notify({
              group: 'notifications',
              title: `${err.response.data} :(`,
              text: '',
              type: 'error'
            })
          } else {
            console.log(err.response)
          }
        })
    },
    confirm (test, index) {
      this.$refs.confirmRemoveTest.show()
      this.toDelete = {
        index: index,
        test: test
      }
    },
    handleRemove (toDelete) {
      this.$http.delete(`/tests/ads/${toDelete.test._id}`)
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: 'Test successfully removed :(',
            text: '',
            type: 'success'
          })
          this.tests.splice(toDelete.index, 1)
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$notify({
              group: 'notifications',
              title: `${err.response.data} :(`,
              text: '',
              type: 'error'
            })
          } else {
            console.log(err.response)
          }
        })
    },
    navigate (id) {
      this.$router.push('/admin/tests/ads/edit/' + id)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.tests-all-list {
  padding: 5px 0;
  margin-bottom: 25px;
}

.tests-list-card {
  background-color: #fff;
  margin-bottom: 15px;
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.tests-list-card .tests-list-card-img{
  cursor: pointer;
  width: 50px;
}

.tests-list-card .tests-list-card-img img {
  width: 100%;
}

.tests-list-card .tests-list-card-content {
  padding: 15px;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.tests-list-card .tests-list-card-title {
  font-size: 12px;
  font-weight: 900;
  text-transform: capitalize;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
