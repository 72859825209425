<template>
  <div class="preview-wrapper">
    <div v-for="(row, index) in location.rows" v-bind:key="index">
      <row-preview :row="row"></row-preview>
    </div>
  </div>
</template>
<script>
import RowPreview from './RowPreview'
export default {
  name: 'BuilderPreview',
  components: {RowPreview},
  props:['location'],
  data () {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">
  .preview-wrapper{
    height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin: -1em;
  }
</style>


<style lang="scss">
.owl-carousel .owl-carousel {
  position: relative;
}

.owl-theme-preview {
  &.carousel, &.cards, &.regular-row{
    .owl-carousel {
      .owl-item{
        padding: 12px;

        img{
          border-radius: 10px
        }
      }
    }
  }

  &.cards{
    .owl-nav{
      &.disabled {
        &+.owl-dots{
          position: absolute;
          right: 10px;
          bottom: 15px;
        }
      }
    }
  }

  .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    .owl-dot {
      display: inline-block;
      zoom: 1;

      &.active {
        span {
          margin: 3px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: #f2494a !important;
        }
      }
    }
  }

  .owl-nav{
    &.disabled {
      &+.owl-dots{
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
