<template>
    <div>
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col-3">
            <div class="title">
              <router-link to="/admin/livestreams">Livestream </router-link>/
              <span>{{formTitle}}</span>
            </div>
          </div>
          <div class="col-9 pull-right">
            <div class="typeahead-wrapper float-right">
              <button type="submit" form="livestreamForm" class="typeahead-btn">{{submitButtonLabel}}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content">
        <div class="livestream-content-wrapper">
          <div class="container">
            <div class="col-12">
              <b-form @submit="onSubmit" v-on:keydown.13.prevent.stop id="livestreamForm" class="bootstrap-form">
                <b-form-row align-h="end">
                  <b-col style="text-align: left;" v-if="canUpdateRunningStatus">
                    <div class="status-wrapper">
                      <div class="video-labels" style="width: 85px">Running Status</div>
                      <label class="switch">
                        <input type="checkbox" v-model="livestream.is_running" @click="handleRunningStatus">
                        <span class="slider"></span>
                      </label>
                    </div>
                  </b-col>
                  <b-col style="text-align: right;">
                    <div class="status-wrapper">
                      <div class="video-labels">Published / Status</div>
                      <label class="switch">
                        <input type="checkbox" v-model="livestream.published">
                        <span class="slider"></span>
                      </label>
                    </div>
                  </b-col>
                </b-form-row>

                <hr/>

                <b-form-row>
                  <b-col>
                    <strong class="video-labels">Title Spanish <strong class='required'>*</strong></strong>
                    <b-form-group>
                      <small>The title of the show, in Spanish, appearing underneath the row item on the app, or the featured title on the AppleTV/FireTV device.</small>
                      <b-form-input id="titleSpanish"
                                    type="text"
                                    required
                                    v-model="livestream.titleEnglish"
                                    placeholder="Add spanish text...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <strong class="video-labels">Title English <strong class='required'>*</strong></strong>
                    <b-form-group>
                      <small>The title of the show, in English, appearing underneath the row item on the app, or the featured title on the AppleTV/FireTV device.</small>
                      <b-form-input id="titleEnglish"
                                    type="text"
                                    required
                                    v-model="livestream.titleSpanish"
                                    placeholder="Add english text...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <hr/>

                <b-form-row>
                  <b-col>
                    <strong class="video-labels">Subtitle Spanish <strong class='required'>*</strong></strong>
                    <b-form-group>
                      <small>The subtitle of the show, in Spanish, appearing as a description underneath the title on the AppleTV/FireTV device.</small>
                      <b-form-input id="subtitleSpanish"
                                    type="text"
                                    required
                                    v-model="livestream.subtitleSpanish"
                                    placeholder="Add spanish text...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <strong class="video-labels">Subtitle English <strong class='required'>*</strong></strong>
                    <b-form-group>
                      <small>The subtitle of the show, in English, appearing as a description underneath the title on the AppleTV/FireTV device.</small>
                      <b-form-input id="subtitleEnglish"
                                    type="text"
                                    required
                                    v-model="livestream.subtitleEnglish"
                                    placeholder="Add english text...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <hr/>

                <b-form-row>
                  <b-col>
                    <b-form-group>
                      <div class="alert alert-info">
                        To livestream on LaMusica, do the following:
                        <ol class="livestream-instructions">
                          <li>Please download <strong>the Wowza GoCoder app</strong><a target="_blank" href="https://itunes.apple.com/us/app/wowza-gocoder/id640338185"> on the iOS App Store</a> or on
                            <a target="_blank"  href="https://play.google.com/store/apps/details?id=com.wowza.gocoder&hl=en_US">on the Google Play Store for Android</a></li>
                          <li>Once installed and opened, select the Wowza on the top right, and connect to <strong>Wowza Streaming Engine</strong></li>
                          <li>
                            For the settings, use the following:
                            <ul>
                              <li>
                                For Host:
                                <ul>
                                  <li>For Server: <strong>216.52.92.97</strong></li>
                                  <li>For Port: <strong>1935</strong></li>
                                </ul>
                              </li>
                              <li>
                                For Application (in this example, we are using lamusicalive):
                                <ul>
                                  <li>For Application: <strong>lamusicalive</strong></li>
                                  <li>For Stream Name: <strong>lamusicalive</strong></li>
                                </ul>
                              </li>
                              <li>
                                For Source authentication, unless told otherwise, you do not need to put a username or password
                              </li>
                            </ul>
                          </li>
                          <li>Before recording, please select the options icon to the right of the menu to ensure you are getting the highest quality video size, that you are auto restarting, and <strong>please be sure that your phone screen has the rotation lock off so that the feed will appear horizontal</strong></li>
                          <li>A few seconds after recording, your stream should now be live at <a href="https://livestream.lamusica.com/lamusicalive/ngrp:lamusicalive_all/playlist.m3u8">https://livestream.lamusica.com/lamusicalive/ngrp:lamusicalive_all/playlist.m3u8</a></li>
                          <li>If this stream is published on the livestream section of the CMS, and has no regional restrictions, it will then appear on LaMusica app</li>
                          <li>For Mac/Windows On Mac/Windows install OBS software, then use <code>http://216.52.92.97:1935/lamusicalive</code> as server and <code>lamusicalive</code> as the stream name.</li>
                        </ol>
                      </div>
<!--                      <video-player class="video-player-box"-->
<!--                                    ref="videoPlayer"-->
<!--                                    :playsinline="true"-->
<!--                                    :options="videoPlayerOptions"-->
<!--                                    v-if="videoPlayerOptions.sources[0].src">-->
<!--                      </video-player>-->
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <br />

                <b-form-row>
                  <b-col cols="6">
                    <strong class="video-labels">Source</strong>
                    <b-form-group>
                      <v-select
                        v-model="livestream.source"
                        placeholder="Choose the source"
                        :options="sources"
                        :reduce="item => item.code"/>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <strong class="video-labels">Mediastream Live Id</strong>
                    <b-form-input id="mediastream-id"
                                  type="text"
                                  v-model="livestream.mediastream_id"
                                  placeholder="Mediastream Live Id">
                    </b-form-input>
                  </b-col>
                </b-form-row>

                <br />

                <b-form-row>
                  <b-col>
                    <strong class="video-labels">Livestream HLS URL<strong class='required'>*</strong></strong>
                    <b-form-group>
                      A HLS feed of content ending in <code>.m3u8</code> which is the source of the content.
                      <div class="d-flex flex-row">
                        <b-form-input id="livestream_url"
                            type="url"
                            required
                            v-model="livestream.livestream_url"
                            placeholder="Add HLS m3u8 url...">
                        </b-form-input>
                        <b-button class="url-adder" v-b-modal.urlAdder>
                          <i class="fas fa-link"></i>
                        </b-button>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group>
                        <strong class="video-labels">Wowza Application Name <strong class='required'>*</strong></strong>
                        <b-form-group>
                          <small>The application name inside Wowza.  We use this data to pull statistics used for the livestream.</small>
                          <b-form-input id="wowzaApplicationName"
                                        type="text"
                                        v-model="livestream.wowzaApplicationName"
                                        placeholder="Add Wowza Stream Name">
                          </b-form-input>
                        </b-form-group>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <strong class="video-labels">Slug<strong class='required'>* (Required to publish)</strong></strong>
                    <b-form-group class="slug-creation">
                      <small>This slug is to uniquely identify, via a pretty URL, a piece of content on LaMusica web. It must be written in English. Once it has been defined it should not change, so that users do not get sent to missing pages</small>
                      <b-input-group prepend="https://www.lamusica.com/livestreams/">
                        <b-form-input id="slug"
                                      type="text"
                                      v-model="livestream.slug">
                        </b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <strong class="video-labels">Station Call Sign <strong class='required'>*</strong></strong>
                    <b-form-group>
                      <small>The single call sign of the station related to this livestream</small>
                      <b-form-input id="station"
                                    type="text"
                                    required
                                    v-model="livestream.station"
                                    placeholder="Add livestream station text...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group label="Podcast Id">
                      <small>Use this to associate this livestream with a podcast, if applicable</small><br/>

                      <b-form-input id="podcastId"
                                    type="text"
                                    @click="podcastPicker"
                                    v-model="selectedPodcastId"
                                    placeholder="Add Podcast ID...">
                      </b-form-input>

                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group label="Primary DMA Id">
                      <small>Use this to associate this livestream with a location, if applicable</small><br/>

                      <b-form-input id="podcastId"
                                    type="text"
                                    @click="dmaPicker"
                                    v-model="selectedLocationId"
                                    placeholder="Add Location ID...">
                      </b-form-input>

                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group label="View Counter Enabled">
                      <small>Activates or deactivates the view counter.  Currently only available on web.</small><br/>
                      <label class="livestream-checkbox" for="viewCounterEnabled">
                        <input type="checkbox" id="viewCounterEnabled" v-model="livestream.viewCounterEnabled" class="inline-checkbox">
                        <span>View counter enabled</span>
                      </label>
                    </b-form-group>
                  </b-col>

                </b-form-row>

                <br/>

                <b-form-row>
                  <b-col>
                    <b-form-group label="Hide on Web page">
                      <small>Decide if the livestream should display on Web page</small><br/>
                      <label class="livestream-checkbox" for="hide_web">
                        <input type="checkbox" id="hide_web" v-model="livestream.hide_web" class="inline-checkbox">
                        <span>Hide on lamusica.com web page</span>
                      </label>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Hide on iOS and Android Apps">
                      <small>Decide if the livestream should display on iOS and Android Apps</small><br/>
                      <label class="livestream-checkbox" for="hide_app">
                        <input type="checkbox" id="hide_app" v-model="livestream.hide_app" class="inline-checkbox">
                        <span>Hide on iOS and Android Apps</span>
                      </label>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Hide on Connected TV">
                      <small>Decide if the livestream should display on connected TV</small><br/>
                      <label class="livestream-checkbox" for="hide_connected_tv">
                        <input type="checkbox" id="hide_connected_tv" v-model="livestream.hide_connected_tv" class="inline-checkbox">
                        <span>Hide on all connected TV's</span>
                      </label>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <br/>

                <b-form-row>
                  <b-col>
                    <b-form-group label="Require age gate">
                      <small>Require age validation to access the content</small><br/>
                      <label class="livestream-checkbox" for="age_gate">
                        <input type="checkbox" id="age_gate" v-model="livestream.age_gate" class="inline-checkbox">
                        <span>Require age validation to access the content</span>
                      </label>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Is Restricted to Puerto Rico">
                      <small>Due to licensing restrictions, is this content only permitted to be seen inside Puerto Rico?</small><br/>
                      <label class="livestream-checkbox" for="is_restricted_to_pr">
                        <input type="checkbox" id="is_restricted_to_pr" v-model="livestream.is_restricted_to_pr" class="inline-checkbox">
                        <span>Permitted only in Puerto Rico</span>
                      </label>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Live allowed" >
                      <b-form-checkbox-group
                        id="checkbox-group-1"
                        v-model="livestream.activeDays"
                        :options="optionsDays"
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <br/>

                <h4>HTML (Under Web Page Player)</h4>
                <hr/>
                <h5>Preview</h5>
                <div v-html="livestream.web_additional_html" class="web-additional-html" />
                <h5>HTML Input</h5>
                <hr />
                <b-textarea v-model="livestream.web_additional_html" class="text-monospace" />
                <br />

                <h4>Images</h4>
                <hr/>

                <b-form-row>
                  <b-col>
                    <div v-for="imageType in platforms" v-bind:key="imageType.platform">
                      <div v-if="imageType.isVideoType">
                        <videos-image-uploader
                            :image-size="imageType"
                            :content="livestream"
                            api-u-r-l="/livestreams/images" />
                      </div>
                      <div v-else>
                        <upload
                            @imgUploaded="imgUploaded"
                            :data="imageType" />
                      </div>
                    </div>
                  </b-col>
                </b-form-row>

                <h4>Chat Settings</h4>
                <hr />

                <b-form-row>

                  <b-col>
                    <b-form-group label="Chat Enabled">
                      <small>Activates or deactivates the chat for the livestream.  User must re-enter the chat to see changes in settings.</small><br/>
                      <label class="livestream-checkbox" for="chatEnabled">
                        <input type="checkbox" id="chatEnabled" v-model="livestream.chatEnabled" class="inline-checkbox">
                        <span>Chat is enabled</span>
                      </label>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <strong class="video-labels">Chat Room ID <strong class='required'>*</strong></strong>
                    <b-form-group>
                      <small>The id of the chatroom connected to this livestream</small>
                      <b-form-input id="chatRoomID"
                                    type="text"
                                    v-model="livestream.chatRoomID"
                                    placeholder="Add Chat Room ID...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>

                  <b-col>
                    <strong class="video-labels">Admin ID</strong>
                    <b-form-group>
                      <small>The id of the profile that is used by admins of the room</small>
                      <b-form-input id="chatRoomID"
                                    type="text"
                                    v-model="livestream.chatAdminId"
                                    placeholder="Add Admin Profile ID...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>


                  <b-col>
                    <strong class="video-labels">Video ID</strong>
                    <b-form-group>
                      <small>To support older versions of LaMusica, place here the ID of a related video ID that contains the same video metadata</small>
                      <b-form-input id="videoID"
                                    type="text"
                                    v-model="livestream.video_id"
                                    placeholder="Add Video ID...">
                      </b-form-input>
                    </b-form-group>
                    <div>
                      <b-button :to="'/admin/videos/edit/'+livestream.video_id" size="sm" target="_blank">
                        View video post
                      </b-button>
                    </div>
                  </b-col>
                </b-form-row>

                <h6>Chat Welcome Message</h6>
                <small>This is a greeting message that users will see when they enter the chat room</small>

                <b-form-row>
                  <b-col>
                    <b-form-group label="English">
                      <b-form-input id="welcomeMessage"
                                    type="text"
                                    v-model="livestream.welcomeMessage.en"
                                    placeholder="Welcome to the lamusica chat!">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Spanish">
                      <b-form-input id="welcomeMessage"
                                    type="text"
                                    v-model="livestream.welcomeMessage.es"
                                    placeholder="Bienvenido al chat de lamusica!">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group label="Chat Banner Ad ID">
                      <small>A DFP tag for an ad that appears above the chat window in mobile</small>
                      <b-form-input id="chatBannerAdId"
                                    type="text"
                                    v-model="livestream.chatBannerAd.tag"
                                    placeholder="LaMusica_App_Radio_Chat_320x50">
                      </b-form-input>

                      <label class="chat-checkbox" for="bannerAdEnabled">
                        <input type="checkbox" id="bannerAdEnabled" v-model="livestream.chatBannerAd.isDisabled" class="inline-checkbox">
                        <span>Disable Banner Ad</span>
                      </label>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <AdConfigurationForm :item="livestream" :has-web-display-ads="true"/>
                <br />
                <br />

                <PaywallConfigurationForm :item="livestream" />
              </b-form>
            </div>
          </div>
        </div>
      </div>

      <b-modal id="addPodcast" :ok-only="true" ok-title="Cancel" size="md" title="Select Podcast">
        <b-row>
          <b-col>
            <b-list-group v-for="eachPodcast in podcastList" :key="eachPodcast._id">

              <b-list-group-item v-if="selectedPodcastId === eachPodcast._id" class="m-1 d-flex align-items-center selected-border" @click="selectPodcast(eachPodcast._id)">
                <b-img :src="eachPodcast.meta.exploreImagery.mdpiImageURL"
                style="width:200px;height:87px"
                />
                  <div style="font-size: large; font-weight: bold; margin-left: 12px;" inline>
                    {{eachPodcast.title}}
                  </div>
              </b-list-group-item>

              <b-list-group-item v-else class="m-1 d-flex align-items-center" @click="selectPodcast(eachPodcast._id)">
                <b-img :src="eachPodcast.meta.exploreImagery.mdpiImageURL"
                style="width:200px;height:87px"
                />
                  <div style="font-size: large; font-weight: bold; margin-left: 12px;" inline>
                    {{eachPodcast.title}}
                  </div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-modal>

      <b-modal id="addLocation" :ok-only="true" ok-title="Cancel" size="md" title="Select Location">
        <b-row>
          <b-col>
            Location List
            <b-list-group v-for="eachLocation in locationList" :key="eachLocation._id">

              <b-list-group-item v-if="selectedLocationId === eachLocation._id" class="m-1 d-flex align-items-center selected-border" @click="selectLocation(eachLocation._id)">
                  <div style="font-size: large; font-weight: bold; margin-left: 12px;" inline>
                    {{eachLocation.regionName}}
                  </div>
              </b-list-group-item>

              <b-list-group-item v-else class="m-1 d-flex align-items-center" @click="selectLocation(eachLocation._id)">
                  <div style="font-size: large; font-weight: bold; margin-left: 12px;" inline>
                    {{eachLocation.regionName}}
                  </div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-modal>

      <b-modal id="urlAdder" size="lg" title="Additional Stream URLs" ok-title="Save Changes" @ok="handleAddUrlSave">
        <div>
        </div>
        <b-row v-if="additionalUrlList.length>0">
        <b-col>
          <div v-for="[index, urlList] of additionalUrlList.entries()" :key="index" class="d-flex flex-row justify-content-between">
            <div class="d-flex align-items-center my-1">Location: {{ getLocationName(urlList.dma) }}</div>
            <div class="d-flex align-items-center my-1">Stream Url: {{ urlList.hls }}</div>
            <div class="d-flex align-items-center my-1"> <b-button variant="danger" @click="removeUrl(index)">X</b-button> </div>
          </div>
        </b-col>
      </b-row>
      <div class="row modal-wrapper">
        <b-col>
          <label class="modal-label" for="input-content-type">Select DMA:</label>
          <b-form-select v-model="additionalUrl.dma" placeholder="select DMA" :options="additionalUrlDmaList"></b-form-select>
        </b-col>
        <b-col>
          <label class="modal-label" for="input-amp-id">Stream Url:</label>
          <b-form-input
            id="input-amp-id"
            v-model="additionalUrl.hls"
            placeholder="Enter Stream Url here"
            trim
          ></b-form-input>
        </b-col>
      </div>
      <b-row>
        <b-col>
          <b-button variant="danger" class="btn-block" @click="addAdditionalUrl">Add URL</b-button>
        </b-col>
      </b-row>
        
      </b-modal>
    </div>

</template>

<script>
import upload from '../../share/UploadImage'
import VideosImageUploader from "../videos/VideosImageUploader"
import AdConfigurationForm from '../../share/AdConfigurationForm'
import PaywallConfigurationForm from "../../share/PaywallConfigurationForm"
import { VueEditor } from 'vue2-editor'
import axios from 'axios'

export default {
  components: {
    VideosImageUploader,
    PaywallConfigurationForm,
    AdConfigurationForm,
    upload,
    VueEditor
  },
  data () {
    return {
      additionalUrlList:[],
      additionalUrl:{
          dma:'',
          hls:''
      },
      additionalUrlDmaList:[],
      selectedPodcastId:'',
      selectedLocationId:'',
      podcastList:[],
      locationList:[],
      videoPlayerOptions: {
        language: 'en',
        responsive: true,
        sources: [{
          type: 'application/x-mpegURL',
          src: null
        }],
        techOrder: ['html5'],
        sourceOrder: true,
        flash: {
          hls: { withCredentials: false },
          swf: '/static/media/video-js.swf'
        },
        html5: { hls: { withCredentials: false } },
        fluid: true
      },
      sources: [
        {code: 'mediastream', label: 'Media Stream'},
        {code: 'wowza', label: 'Wowza'}
      ],
      authorizedUsers: ['alberto@lamusica.com', 'jcarbajal@sbscorporate.com', 'ricky@lamusica.com', 'ramim@lamusica.com', 'ygarcia@lamusica.com'],
      canUpdateRunningStatus: false,
      optionsDays: [
          { text: 'Sunday', value: 0 },
          { text: 'Monday', value: 1 },
          { text: 'Tuesday', value: 2 },
          { text: 'Wednesday', value: 3 },
          { text: 'Thursday', value: 4 },
          { text: 'Friday', value: 5 },
          { text: 'Saturday', value: 6 }
        ]
    }
  },
  props: {
    livestream: Object,
    submitButtonLabel: String,
    platforms: Object
  },
  async mounted () {
    await this.loadLocation()
    this.canUpdateRunningStatus = this.livestream._id && this.authorizedUsers.includes(localStorage.getItem('user'))

    for (let key in this.platforms) {
      this.platforms[key].uploadURL = '/livestreams/image'
    }
    this.selectedPodcastId = this.livestream.podcast_id
    this.selectedLocationId = this.livestream.location_id

    if(this.livestream && this.livestream.additionalStreamUrl){
      this.additionalUrlList = this.livestream.additionalStreamUrl
    }

  },
  // updated () {
  //   if (this.livestream && this.livestream.livestream_url) {
  //     this.videoPlayerOptions.sources[0].src = this.livestream.livestream_url
  //   }
  // },
  computed: {
    formTitle: function () {
      const { livestream } = this
      if (livestream && livestream.titleSpanish) {
        return livestream.titleSpanish
      }
      return 'NEW'
    }
  },
  methods: {
    async handleAddUrlSave(){
      const url = `${this.$http.url}/livestreams/${this.livestream._id}/urls`
      const response = await axios.put(url,this.additionalUrlList)
      if(response.data.success){
        this.$notify({
          group: 'notifications',
          title: 'Links are saved',
          text: '',
          type: 'success'
        })
      }
    },
    getLocationName(dmaId){
      const listLoc =this.locationList.find( (e) => e._id === dmaId)
      return listLoc.regionName
    },
    removeUrl(index){
      this.additionalUrlList.splice(index,1)
    },
    addAdditionalUrl(){
      const data = this.additionalUrl
      if(data.hls==='' || data.dma===''){
        return this.$notify({
          group: 'notifications',
          title: 'Both inputs are required',
          text: '',
          type: 'error'
        })
      }
      this.additionalUrl = {hls:'', dma:''}
      this.$nextTick(() => {
        this.$set(this.additionalUrlList,this.additionalUrlList.length,data)
      });
    },
    async loadLocation(){
      try {
        const locationResponse =  await this.getLocations()
        this.locationList = locationResponse.data
        this.additionalUrlDmaList = this.locationList.map( (e) => ({value:e._id, text: e.regionName}) )
        console.log('loclist', this.locationList);
      } catch (error) {
        console.log(error);
      }
    },
    selectPodcast(podcastId){
      this.$bvModal.hide('addPodcast')
      this.selectedPodcastId = podcastId
      this.$set(this.livestream, 'podcast_id', podcastId)
    },
    selectLocation(locationId){
      this.$bvModal.hide('addLocation')
      this.selectedLocationId = locationId
      this.$set(this.livestream, 'location_id', locationId)
    },
    async podcastPicker(){
      this.$bvModal.show('addPodcast')
      try {
        const podcastsResponse =  await this.getPodcasts()
        this.podcastList = podcastsResponse.data.podcasts

      } catch (error) {
        console.log(error);
      }

    },
    async dmaPicker(){
      this.$bvModal.show('addLocation')
    },
    getLocations () {
        return this.$http.get('/locations')
    },
    getPodcasts () {
        return this.$http.get('/podcasts?published=true', {params: {limit: 50, page: 1}})
    },
    imgUploaded (data, serverResponse) {
      if (serverResponse.success && serverResponse.data && serverResponse.data[0]) {
        this.livestream[data.imageKey] = serverResponse.data[0]
        this.platforms[data.imageKey].url = serverResponse.data[0]
      }
    },
    onSubmit: function (event) {
      this.$emit('handle-form-submit', event)
    },
    handleRunningStatus() {
      setTimeout(() => {
        this.$http.post(`/livestreams/${this.livestream._id}/running-status`, {
          status: this.livestream.is_running
        }).then((response) => {
          if(!response.success) {
            return this.$notify({
              group: 'notifications',
              title: 'Error',
              text: response.message,
              type: 'error'
            })
          }

          this.$notify({
            group: 'notifications',
            title: 'Success',
            text: 'Running status changed successfully',
            type: 'success'
          })
        })
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.selected-border {
  border-style: solid;
  border-width: medium;
  border-color: green
}

  pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .livestream-content-wrapper {

    h4 {
      font-weight: bold;
    }

    .input-group-prepend .input-group-text {
      height: 46px;
    }
    .required {
      color: #f2494a;
      font-size: 10px;
    }

    .livestream-checkbox {
      margin-top: 10px;

      input {
        width: auto;
        height: auto !important;
        display: inherit;
        margin-bottom: 0;
      }
    }
  }

  input.inline-checkbox {
    display: inline !important;
    width: auto !important;
    height: auto !important;
  }

  .status-wrapper {
    display: flex;
    align-items: center;

    .video-labels {
      margin-right: 10px;
      padding: 10px 0;
      width: 100%;
      display: block;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .livestream-instructions ul {
    margin-left: 1em;
  }

  .web-additional-html {
    border: 1px solid #e2e2e2;
    padding: 1em;
    background: white;
    margin-bottom: 1em;
  }
</style>

<style lang="scss">
.form-row div.upload-wrapper {
  margin: 0;
  border: 0;
  padding: 0;
  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }

  label {
    font-weight: bolder;
    margin-bottom: 0;
  }
}

.livestream-content-wrapper {
  .input-group-text {
    text-transform: none !important;
  }
}

.vs__actions {
  button {
    margin-right: 6px;
    margin-bottom: 0;
    padding: 0;
    width: auto;
    background: none;

    &:hover {
      background: none !important;
    }
  }
}

.url-adder{
  max-width: 42px;
  max-height: 42px;
}

.modal-label{
  font-weight: bold;
}

</style>
