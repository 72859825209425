<template>
  <div class="livestream-edit-component">
    <editors v-if="livestreamID" :resource="livestreamID" />
    <LivestreamForm
      v-if="livestreamLoaded"
      :livestream="livestream"
      :submitButtonLabel="'Update'"
      :platforms="platforms"
      @handle-form-submit="updateLivestream"/>
  </div>
</template>

<script>
  import LivestreamForm from './LivestreamForm'
  import platforms from './platforms'
  import Editors from '../../share/Editors'

  export default {
    name: 'LivestreamsEdit',
    components: {
      LivestreamForm,
      Editors
    },
    data () {
      return {
        livestreamID: null,
        livestream: {},
        livestreamLoaded: false,
        platforms: JSON.parse(JSON.stringify(platforms))
      }
    },
    mounted () {
      this.livestreamID = this.$route.params.id
      this.$http.get(`/livestreams/${this.livestreamID}`).then((res) => {
        const livestream = res.data
        if (!livestream.images) {
          livestream.images = {}
        }
        for (const key in this.platforms) {
          const platform = this.platforms[key]
          if (platform.isVideoType) {
            if (!livestream.images[key]) {
              livestream.images[key] = {}
            }
          } else {
            // Set the current URL of the preview nodes
            this.platforms[key].url = livestream[key]
          }
        }

        // Set Image URLs for old posts
        if (livestream.img && !livestream.images.thumbnail.xhdpiImageURL) {
          livestream.images.thumbnail.xhdpiImageURL = livestream.img
        }

        if (livestream.square_img && !livestream.images.row['2xImageURL']) {
          livestream.images.row.xhdpiImageURL = livestream.square_img
        }

        if (livestream.large_img && !livestream.images.landing['3xImageURL']) {
          livestream.images.landing.xhdpiImageURL = livestream.large_img
        }

        this.livestream = livestream
        this.livestream.hide_web = this.livestream.hide_web || false
        this.livestream.hide_app = this.livestream.hide_app || false
        this.livestream.hide_connected_tv = this.livestream.hide_connected_tv || false
        this.livestream.age_gate = this.livestream.age_gate || false
        this.livestream.podcast_id = this.livestream.podcast_id || ''
        this.livestream.location_id = this.livestream.location_id || ''
        if(!this.livestream.web_ads){
          this.livestream.web_ads = {
              '300x250': [{
                tag: '',
                disable:false,
                start: null,
                end: null
              }],
              '728x90': [{
                tag: '',
                disable:false,
                start: null,
                end: null
              }]
            }
        }

        if(!this.livestream.chatBannerAd){
          this.livestream.chatBannerAd = {}
          this.livestream.chatBannerAd.tag = ''
          this.livestream.chatBannerAd.isDisabled = false
        }

        if(!this.livestream.welcomeMessage){
          this.livestream.welcomeMessage={
            en:'',
            es:''
          }
        }

        if(!this.livestream.activeDays){
          this.livestream.activeDays=[]
        }
  
        this.livestreamLoaded = true
      }).catch((err) => {
        console.log(err)
        this.livestreamLoaded = true
      })
    },
    methods: {
      updateLivestream (evt) {
        evt.preventDefault()

        // Set Image URLs for old posts
        const livestream = this.livestream
        if (livestream.images.thumbnail.xhdpiImageURL) {
          livestream.img = livestream.images.thumbnail.xhdpiImageURL
        }

        if (livestream.images.row['2xImageURL']) {
          livestream.square_img = livestream.images.row['2xImageURL']
        }

        if (livestream.images.landing['3xImageURL']) {
          livestream.large_img = livestream.images.landing['3xImageURL']
        }

        if (livestream.published) {
          if(!livestream.source) {
            return this.$notify({
              group: 'notifications',
              title: 'The source is required in order to publish this livestream',
              text: '',
              type: 'error'
            })
          }

          if(livestream.livestream_url) {
            const sourceHosts = {
              mediastream: 'mdstrm.com',
              wowza: 'livestream.lamusica.com'
            }

            if(livestream.livestream_url.indexOf(sourceHosts[livestream.source]) == -1) {
              return this.$notify({
                group: 'notifications',
                title: `The HLS url must match with the related source host (${sourceHosts[livestream.source]})`,
                text: '',
                type: 'error'
              })
            }
          }

          const imagesKeys = [
            'img',
            'large_img',
            'live_now_small_img',
            'square_img',
            'placeholder_horizontal_img'
          ]

          for (const key of imagesKeys) {
            if (!livestream[key]) {
              return this.$notify({
                group: 'notifications',
                title: 'All images are required in order to publish this livestream',
                text: '',
                type: 'error'
              })
            }
          }
        }

        this.$http.put('/livestreams', {livestream: livestream}).then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Livestream updated successfully :)',
              text: '',
              type: 'success'
            })
          }
        }).catch((err) => {
          console.log(err)
          this.$notify({
            group: 'notifications',
            title: 'There was an error updating this livestream :(',
            text: '',
            type: 'error'
          })
        })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
