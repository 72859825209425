<template>
  <div class="stations-all-component">

    <div class="page-header">
      <div class="typeahead-wrapper">
        <div class="typeahead">
          <input id="input" class="form-control" type="text" placeholder="Search Stations" v-model="query"  @keyup.13="search">
          <div class="btn btn-search" @click="search">
            <i class="fas fa-search"></i>
          </div>
        </div>
        <div class="programming-grid-btn" @click="getProgrammingGrid">Programming Grid</div>
        <router-link class="add-btn" to="/admin/stations/new" tag="div">Add Station</router-link>
      </div>
    </div>

    <div class="page-content">
      <div class="stations-all-list">
        <transition-group tag="div" class="row" name="list-animation">
          <div class="col-md-4" v-for="station in stations" v-bind:key="station._id">
            <div class="stations-list-card">
              <div class="stations-list-card-img" @click="navigate(station._id)">
                <img :src="station.exploreImagery.hdpiImageURL" alt="" v-if="station.exploreImagery && station.exploreImagery.hdpiImageURL" />
                <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!station.exploreImagery || !station.exploreImagery.hdpiImageURL"/>
              </div>
              <div class="stations-list-card-content">
                <div class="stations-list-card-title">
                  {{station.title}} <span v-if="station.isSponsored" class="stations-list-meta">• Sponsored</span> <span v-if="station.disableAds" class="stations-list-meta">• Ads Disabled</span><br/>
                  <span class="stations-list-meta">Last Updated: {{formatUpdatedAt(station.updatedAt)}}</span>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>

    <b-modal id="getProgrammingGridModal" ref="getProgrammingGridModal" size="lg" title="Programming Grid">
      <div class="row modal-wrapper">
        <div class="col-12 programming-grid-list" v-html="gridContent">
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
  import moment from "moment"
  export default {
    name: 'StationsAll',
    data () {
      return {
        stations: [],
        query: '',
        searchList: [],
        gridContent: ''
      }
    },

    mounted () {
      this.$http.get('/stations')
        .then((res) => {
          this.stations = res.data.sort((a, b) => {
            return a.order - b.order
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    methods: {
      navigate (id) {
        this.searchList = []
        this.$router.push('/admin/stations/edit/' + id)
      },
      search () {
        if (this.query === '') {
          this.pages = 1
          this.$http.get('/stations')
            .then((res) => {
              this.stations = res.data
            })
            .catch((err) => {
              console.log(err)
            })
          return
        }

        this.$http.get(`/stations/search?q=${this.query}`)
          .then((res) => {
            this.stations = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      getProgrammingGrid () {
        this.$refs.getProgrammingGridModal.show()

        const getProgrammingDisplayText = (segment) => {
          return `${segment.from}-${segment.to}: ${segment.radio_name}`
        }

        // Function that reduces programming to a comparable string
        const programmingTimesReducer = (programmingTimes) => {
          const simplifiedProgramming = programmingTimes.map(getProgrammingDisplayText)
          return JSON.stringify(simplifiedProgramming)
        }

        let str = ''

        this.stations.forEach((station) => {
          str += '<br/>'
          str += `<h4>${station.callSign.toUpperCase()}</h4>`
          str += '<hr/>'

          const programmingMonday = programmingTimesReducer(station.programming.times['Monday'])
          const weekDaysTheSameAsMonday = ['Tuesday', 'Wednesday', 'Thursday', 'Friday'].every((day) => {
            return programmingMonday === programmingTimesReducer(station.programming.times[day])
          })

          for (let weekday in station.programming.times) {
            if (weekDaysTheSameAsMonday && weekday !== 'Saturday' && weekday !== 'Sunday') {
              if (weekday === 'Monday') {
                str += `<h5>Monday-Friday</h5>`
              } else {
                continue
              }
            } else {
              str += `<h5>${weekday}</h5>`
            }
            str += '<ul style="margin-left: 2em">'
            station.programming.times[weekday].forEach((dailyProgramming) => {
              str += `<li>${getProgrammingDisplayText(dailyProgramming)}</li>`
            })
            str += '</ul>'
          }
        })

        this.gridContent = str
      },
      formatUpdatedAt (date) {
        if (!date) {
          return 'N/A'
        }
        return moment(date).format('ddd, MMMM Do, h:mma')
      },
    }
  }
</script>

<style scoped>

  .stations-all-list {
    padding: 5px 0 20px;
    margin-bottom: 25px;
  }

  .stations-list-card {
    background-color: #fff;
    margin-bottom: 30px;
    border: 1px solid #eaeaea;
  }

  .stations-list-card .stations-list-card-img img {
    width: 100%;
    cursor: pointer;
  }

  .stations-list-card .stations-list-card-content {
    padding: 15px;
    display: flex;
    justify-content: space-between;
  }

  .stations-list-card .stations-list-card-title {
    font-size: 12px;
    font-weight: 900;
    text-transform: capitalize;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btn-search{
    height: 45px;
    background: #f2494a;
    border-radius: 0;
    color: #fff;
    line-height: 32px;
  }

  .stations-all-list {
    padding: 5px 0 20px;
    margin-bottom: 25px;
  }

  .stations-list-card {
    background-color: #fff;
    margin-bottom: 30px;
    border: 1px solid #eaeaea;
  }

  .stations-list-card .stations-list-card-img img {
    width: 100%;
    cursor: pointer;
  }

  .categories-list-card .categories-list-card-content {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #de4242
  }

  .stations-list-meta {
    font-weight: normal;
    text-transform: initial;
  }

  .programming-grid-btn {
    width: 186px;
    line-height: 45px;
    text-align: center;
    background: #f2494a;
    margin-left: 15px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
  }

</style>
