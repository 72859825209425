<template>
  <div class="ads-wrapper">
    <a :href="row.url" target="_blank" :style="{'background-image': 'url('+row.bg_img+')'}">
      <img :src="row.overlay_img" alt=""/>
    </a>
  </div>
</template>
<script>
export default {
  name: 'RowPreviewAds',
  props: ['row'],
  data () {
    return {}
  },
  methods: {}
}
</script>
<style scoped lang="scss">
.ads-wrapper {
  padding: 12px 0;
  a {
    background-size: cover;
    background-repeat: no-repeat;
    display: block;

    img {
      width: 100%;
      margin: auto;
    }
  }
}
</style>
