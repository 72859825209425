<template>
  <div>
    <b-form>
      <b-form-row>
          <b-col>
            <legend class="col-form-label pt-0">Select DMA</legend>
            <b-form-select required id="dma-selection" v-model="dmaSelection" :options="dmaOptions"></b-form-select>
          </b-col>
          <b-col>
            <legend class="col-form-label pt-0">Select Image</legend>
            <!-- {{ adImage }} -->
            <b-file id="image-selection" v-model="adImage" @change="uploadAdImage($event)"/>
            <b-img v-if="adImage" :src="adImage" width="267" height="66"></b-img>
          </b-col>
      </b-form-row>

      <b-form-row class="justify-content-md-center">
        <b-col>
          <legend class="col-form-label pt-0">Start Date</legend>
          <b-form-datepicker required value-as-date v-model="startDate" :min="new Date()" class="mb-2">
          </b-form-datepicker>
        </b-col>

        <b-col>
          <legend class="col-form-label pt-0">End Date</legend>
          <b-form-datepicker required value-as-date v-model="endDate" :min="new Date()" class="mb-2">
          </b-form-datepicker>
        </b-col>
      </b-form-row>

      <b-form-row>
          <b-col>
            <legend class="col-form-label pt-0">Pixel Tracker URL</legend>
            <b-form-input id="tracker-input" v-model="trackerUrl" />
          </b-col>
          <b-col>
            <legend class="col-form-label pt-0">Enable Ad</legend>
            <b-form-checkbox v-model="enable" switch/>
          </b-col>
      </b-form-row>

      <b-form-row>
        <b-col class="d-flex justify-content-center mt-2">
          <b-button type="submit" @click.prevent="saveRule">{{ btnText }}</b-button>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
    name:'AdRuleItem',
    async mounted(){
      this.isUpdating = false
      console.log(this.dmaList);
      if(this.dmaList){
        this.dmaOptions = this.dmaList.map((e)=> ({'value': e._id, 'text':e.regionName}) )
        this.dmaOptions.unshift({'value': 'all', 'text':'Run of Network'})
        this.dmaSelection = this.dmaOptions[0].value
      }
      console.log('dmaop', this.dmaOptions);
      if(this.rule){
        this.isUpdating = true
        this.dmaSelection = this.rule.dmaId
        this.enable = this.rule.enabled
        this.trackerUrl = this.rule.pixelTrackerUrl
        this.adImage = this.rule.image
        this.startDate = new Date(this.rule.start)
        this.endDate = new Date(this.rule.end)
      }
    },
    computed:{
      btnText (){
        return this.isUpdating? 'Update Ad' : 'Create Ad'
      }
    },
    props:{
      rule:{
        type: Object,
        default(){
          return null
        }
      },
      dmaList:{
        type: Array,
        default(){
          return []
        }
      }
    },
    data(){
        return {
            enable:false,
            trackerUrl:'',
            adImage:'',
            dmaSelection:'',
            startDate:'',
            endDate:'',
            dmaOptions:[],
            isUpdating:false
        }
    },
    methods: {
    uploadAdImage (event) {
      setTimeout(() => {
        const file = event.target.files[0]
        if (!file) {
          return
        }
        const isValidFileType = /\.(jpe?g|png|gif)$/i.test(file.name)

        if (!isValidFileType) {
          this.$notify({
            group: 'notifications',
            title: 'Image should be jpg or png format :(',
            text: '',
            type: 'error'
          })
          return
        }

        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = (e) => {
          const URL = window.URL || window.webkitURL
          const image = new Image()
          image.src = URL.createObjectURL(file)
          image.onload = () => {
            // const width = image.width
            // const height = image.height
            // const iWidth = imageSizeDefinition.width
            // const iHeight = imageSizeDefinition.height
            // const isInvalidWidthHeight = width !== iWidth || height !== iHeight

            // if (isInvalidWidthHeight) {
            //   this.$notify({
            //     group: 'notifications',
            //     title: `Image size allowed ${imageSizeDefinition.width}x${imageSizeDefinition.height}px, you uploaded one with the following size: ${width}x${height}px :(`,
            //     text: '',
            //     type: 'error'
            //   })

            //   imageSizeDefinition.valid = false
            //   return
            // }

            this.formData = new FormData()
            this.formData.append('file', file)
            // imageSizeDefinition.loading = true

            const imageResizeAPIURL = `${this.$http.url}/videos/ads/image`
            const $config = {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }

            this.$http.post(imageResizeAPIURL, this.formData, $config).then((res) => {
              if(res.success){
                this.adImage = res.data[0]
              }
              console.log(res)
            }).catch((err) => {
              console.log(err)
              this.$notify({
                group: 'notifications',
                title: `There was an error while uploading this file`,
                text: '',
                type: 'error'
              })
            }).finally(() => {
              // imageSizeDefinition.loading = false
            })
          }
        }
      }, 1000)
    },
    async saveRule(){
      let url = `${this.$http.url}/videos/ads`

      if(this.startDate ===''){
        this.$notify({
              group: 'notifications',
              title: 'Must have a start date',
              text: '',
              type: 'error'
            })
            return
      }
      if(this.endDate ===''){
        this.$notify({
              group: 'notifications',
              title: 'Must have an end date',
              text: '',
              type: 'error'
            })
            return
      }

      const newRule = {
        dmaId: this.dmaSelection,
        image: this.adImage,
        start: this.startDate.toISOString(),
        end: this.endDate.toISOString(),
        pixelTrackerUrl: this.trackerUrl,
        enabled: this.enable
      }

      let response =null
      if(this.isUpdating){
        url += `/${this.rule._id}`
        response = await axios.put(url,newRule)
      }else{
        response = await axios.post(url,newRule)
      }

      if(response && response.data.success){
        this.$notify({
              group: 'notifications',
              title: 'Ad Saved',
              text: '',
              type: 'success'
            })
            this.$bvModal.hide('create-rule-modal')
      }else{
        this.$notify({
              group: 'notifications',
              title: 'Failed to save ad',
              text: '',
              type: 'error'
            })
      }
    }
  }
}
</script>
