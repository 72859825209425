<template>
  <div>
    <v-select
      v-model="selectedCategories"
      multiple
      placeholder="Choose up to 3 categories"
      label="name"
      :options="categoriesList"
      :reduce="item => item.name"
      :selectable="() => selectedCategories.length < 3"
      apend-to-body
    >
      <template #option="{ name, header }">
        <div v-if="header" style="display: inline-block; font-weight: 700; margin-left: -10px">{{name}}</div>
        <div v-else style="display: inline-block">{{name}}</div>
      </template>
    </v-select>
  </div>
</template>
<script>
export default {
  name: 'ItunesCategories',
  props: {
    categories: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      search: '',
      categoriesList: [
        {name: 'Arts', header: true},
        {name: 'Books', header: false},
        {name: 'Design', header: false},
        {name: 'Fashion & Beauty', header: false},
        {name: 'Food', header: false},
        {name: 'Performing Arts', header: false},
        {name: 'Visual Arts', header: false},
        {name: 'Business', header: true},
        {name: 'Careers', header: false},
        {name: 'Entrepreneurship', header: false},
        {name: 'Investing', header: false},
        {name: 'Management', header: false},
        {name: 'Marketing', header: false},
        {name: 'Non-Profit', header: false},
        {name: 'Comedy', header: true},
        {name: 'Comedy Interviews', header: false},
        {name: 'Improv', header: false},
        {name: 'Stand-Up', header: false},
        {name: 'Education', header: true},
        {name: 'Courses', header: false},
        {name: 'How To', header: false},
        {name: 'Language Learning', header: false},
        {name: 'Self-Improvement', header: false},
        {name: 'Fiction', header: true},
        {name: 'Comedy Fiction', header: false},
        {name: 'Drama', header: false},
        {name: 'Science Fiction', header: false},
        {name: 'Government', header: true},
        {name: 'History', header: true},
        {name: 'Health & Fitness', header: true},
        {name: 'Alternative Health', header: false},
        {name: 'Fitness', header: false},
        {name: 'Medicine', header: false},
        {name: 'Mental Health', header: false},
        {name: 'Nutrition', header: false},
        {name: 'Sexuality', header: false},
        {name: 'Kids & Family', header: true},
        {name: 'Education for Kids', header: false},
        {name: 'Parenting', header: false},
        {name: 'Pets & Animals', header: false},
        {name: 'Stories for Kids', header: false},
        {name: 'Leisure', header: true},
        {name: 'Animation & Manga', header: false},
        {name: 'Automotive', header: false},
        {name: 'Aviation', header: false},
        {name: 'Crafts', header: false},
        {name: 'Games', header: false},
        {name: 'Hobbies', header: false},
        {name: 'Home & Garden', header: false},
        {name: 'Video Games', header: false},
        {name: 'Music', header: true},
        {name: 'Music Commentary', header: false},
        {name: 'Music History', header: false},
        {name: 'Music Interviews', header: false},
        {name: 'News', header: true},
        {name: 'Business News', header: false},
        {name: 'Daily News', header: false},
        {name: 'Entertainment News', header: false},
        {name: 'News Commentary', header: false},
        {name: 'Politics', header: false},
        {name: 'Sports News', header: false},
        {name: 'Tech News', header: false},
        {name: 'Religion & Spirituality', header: true},
        {name: 'Buddhism', header: false},
        {name: 'Christianity', header: false},
        {name: 'Hinduism', header: false},
        {name: 'Islam', header: false},
        {name: 'Judaism', header: false},
        {name: 'Religion', header: false},
        {name: 'Spirituality', header: false},
        {name: 'Science', header: true},
        {name: 'Astronomy', header: false},
        {name: 'Chemistry', header: false},
        {name: 'Earth Sciences', header: false},
        {name: 'Life Sciences', header: false},
        {name: 'Mathematics', header: false},
        {name: 'Natural Sciences', header: false},
        {name: 'Nature', header: false},
        {name: 'Physics', header: false},
        {name: 'Social Sciences', header: false},
        {name: 'Society & Culture', header: true},
        {name: 'Documentary', header: false},
        {name: 'Personal Journals', header: false},
        {name: 'Philosophy', header: false},
        {name: 'Places & Travel', header: false},
        {name: 'Relationships', header: false},
        {name: 'Sports', header: true},
        {name: 'Baseball', header: false},
        {name: 'Basketball', header: false},
        {name: 'Cricket', header: false},
        {name: 'Fantasy Sports', header: false},
        {name: 'Football', header: false},
        {name: 'Golf', header: false},
        {name: 'Hockey', header: false},
        {name: 'Rugby', header: false},
        {name: 'Soccer', header: false},
        {name: 'Swimming', header: false},
        {name: 'Tennis', header: false},
        {name: 'Volleyball', header: false},
        {name: 'Wilderness', header: false},
        {name: 'Wrestling', header: false},
        {name: 'Technology', header: true},
        {name: 'True Crime', header: true},
        {name: 'TV & Film', header: true},
        {name:  'After Shows', header: false},
        {name: 'Film History', header: false},
        {name: 'Film Interviews', header: false},
        {name: 'Film Reviews', header: false},
        {name: 'TV Reviews', header: false}
      ],
      selectedCategories: []
    }
  },
  computed: {

  },
  watch: {
    selectedCategories(newValue) {
      this.$emit('updated', newValue)
    }
  },
  mounted () {
    this.selectedCategories = this.categories
  },
  methods: {
    onCategoryOptionClick ({option, addTag}) {
      addTag(option.name)
      this.search = ''
    }
  }
}
</script>
