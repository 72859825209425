<template>
  <div class="podcasts-all-component">

    <div class="page-header">
      <div class="typeahead-wrapper">
        <div class="typeahead">
          <input id="input" class="form-control" type="text" placeholder="Search Podcasts" v-model="query"  @keyup.13="search">
          <div class="btn btn-search" @click="search">
            <i class="fas fa-search"></i>
          </div>
        </div>
        <div class="add-btn" @click="addShow">Add Show</div>
      </div>
    </div>

    <div class="page-content">
      <div class="podcasts-all-list">
        <draggable :list="podcasts" :options="{group:'podcasts', animation:150}" @start="drag=true" @end="updateOrder" :no-transition-on-drag="true" :move="cancelDragAndDrop">
          <transition-group :name="!drag? 'list-animation' : null" :css="true" class="row">
            <div class="col-md-3" v-for="(podcast, index) in podcasts" v-bind:key="podcast._id">
              <div class="podcasts-list-card">
                <div class="podcasts-list-card-img" @click="navigate(podcast._id)">
                  <img :src="podcast.meta.exploreImagery.hdpiImageURL" alt="" v-if="podcast.meta && podcast.meta.exploreImagery && podcast.meta.exploreImagery.hdpiImageURL" />
                  <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!podcast.meta || !podcast.meta.exploreImagery || !podcast.meta.exploreImagery.hdpiImageURL"/>
                </div>
                <div class="podcasts-list-card-content" :class="{published: podcast.published}">
                  <div class="podcasts-list-card-title">
                    <span class="podcast-list-card-order">{{podcast.order}}</span>
                    <div class="podcast-list-title-container">
                      {{podcast.title}}
                      <span class="podcasts-list-card-last-updated">{{getLastUpdated(podcast)}}</span>
                    </div>
                  </div>
                  <div class="submenu-actions">
                    <div class="icon">
                      <i class="fas fa-ellipsis-h"></i>
                    </div>
                    <div class="actions">
                      <ul>
                        <li @click="podcast.published = !podcast.published; updateStatus(podcast)">{{podcast.published ? 'Unpublish' : 'Publish'}}</li>
                        <li @click="confirm(podcast, index)">Delete</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>

      <p class="help-prompt">
        <strong>¿Tienes preguntas?</strong> Env&iacute;a un correo a <a href="mailto:gvilato@sbscorporate.com" target="_blank">gvilato@sbscorporate.com</a>.</p>
      <b-pagination v-if="pages > 1 && displayPaging" align="right" size="md" :total-rows="limit * pages" v-model="current" :per-page="limit" @change="getResults"></b-pagination>
    </div>

    <b-modal id="confirmRemovePodcast" ref="confirmRemovePodcast" size="md" title="Confirm" @ok="handleRemove(toDelete)">
      <div class="row modal-wrapper">
        <div class="col-12">
          Are you sure want to remove <strong>{{toDelete.podcast.title}}</strong> podcast?
        </div>
      </div>
    </b-modal>

    <b-modal id="addShow" ref="addShow" ok-title="Create" size="lg" title="New show" @ok="createShow">
      <div class="modal-wrapper">
        <b-form-row>
          <b-col>
            <label class="col-form-label">English Title<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Title used for LaMusica.com single page and on card rows.</span>
            <input type="text" placeholder="Top Hits" v-model="podcast.meta.titleEnglish"/>
          </b-col>
          <b-col>
            <label class="col-form-label">Spanish Title<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Title used for LaMusica.com single page and on card rows.</span>
            <input type="text" placeholder="Los Exitos de Ahora" v-model="podcast.meta.titleSpanish"/>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <label class="col-form-label">English Description<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Please provide a high-level summary about this podcast.</span>
            <textarea class="input-textarea" placeholder="" v-model="podcast.meta.descriptionEnglish" rows="3"></textarea>
          </b-col>
          <b-col>
            <label class="col-form-label">Spanish Description<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Please provide a high-level summary about this podcast.</span>
            <textarea class="input-textarea" placeholder="" v-model="podcast.meta.descriptionSpanish" rows="3"></textarea>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <label class="col-form-label">Slug<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Please add a unique, lowercase slug for sharing & for LaMusica app/web.</span>
            <input type="text" placeholder="top-hits" v-model="podcast.meta.slug"/>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <label class="col-form-label">Language<sup class="text-danger">*</sup></label>
            <v-select
              v-model="podcast.meta.language"
              placeholder="Choose the language"
              :options="languages"
              :reduce="item => item.code"
            />
          </b-col>
          <b-col>
            <label class="col-form-label">Itunes Categories<sup class="text-danger">*</sup></label>
            <itunes-categories :categories="podcast.meta.categories" @updated="podcast.meta.categories = $event"></itunes-categories>
          </b-col>
        </b-form-row>
      </div>
    </b-modal>
  </div>
</template>


<script>
  import axios from 'axios'
  import typeaheadCategories from '../../share/TypeaheadCategories'
  import draggable from 'vuedraggable'
  import { DateTime as LuxonDateTime } from 'luxon'
  import ItunesCategories from './itunes-categories.vue'

  export default {
    name: 'PodcastsAll',
    components: {
      typeaheadCategories,
      draggable,
      ItunesCategories
    },
    data () {
      return {
        podcast: {
          title: '',
          published: false,
          publishDate: null,
          unPublishDate: null,
          meta: {
            updatedAt: null,
            createdAt: null,
            titleEnglish: '',
            titleSpanish: '',
            secondaryTitleEnglish: '',
            secondaryTitleSpanish: '',
            descriptionEnglish: '',
            descriptionSpanish: '',
            authors: '',
            slug: '',
            type: 'podcast',
            devices: [
              'ios',
              'android',
              'web',
              'appletv',
              'androidtv'
            ],
            order: '',
            hideTitle: true,
            isFeatured: false,
            isSponsored: false,
            podcastID: '',
            facebookShareImageLink: '',
            companionAdStationId: '',
            iOSVideoInterstitialAdUnitId: '',
            iOSImageInterstitialAdUnitId: '',
            androidVideoInterstitialAdUnitId: '',
            androidImageInterstitialAdUnitId: '',
            hasCompanionAdMidroll: false,
            hasCompanionAdPreroll: false,
            inclusionLocations: [],
            exploreImagery: {
              '2xImageURL': null,
              '3xImageURL': null,
              mdpiImageURL: null,
              hdpiImageURL: null,
              xhdpiImageURL: null,
              xxhdpiImageURL: null,
              xxxhdpiImageURL: null
            },
            detailViewImagery: {
              '2xImageURL': null,
              '3xImageURL': null,
              mdpiImageURL: null,
              hdpiImageURL: null,
              xhdpiImageURL: null,
              xxhdpiImageURL: null,
              xxxhdpiImageURL: null
            },
            cardImagery: {
              '2xImageURL': null,
              '3xImageURL': null
            },
            megaphoneImage: '',
            categories: [],
            language: null,
            link: null,
            ownerName: null,
            ownerEmail: null,
            copyright: null,
          },
          tracks: []
        },
        timeout: null,
        searchList: [],
        podcasts: [],
        limit: 100,
        pages: 1,
        current: 1,
        toDelete: {
          index: 0,
          podcast: ''
        },
        query: '',
        displayPaging: true,
        drag: false,
        languages: [
          {code: 'en', label: 'English'},
          {code: 'es', label: 'Spanish'}
        ]
      }
    },

    mounted () {
      this.$http.get('/podcasts', {params: {limit: this.limit, page: this.page}})
        .then((res) => {
          this.podcasts = res.data.podcasts
          this.pages = res.data.pages
          this.current = res.data.current
        })
        .catch((err) => {
          console.log(err)
        })
    },

    methods: {
      addShow () {
        this.$refs.addShow.show()
      },
      createShow () {

        if (this.podcast.meta.titleEnglish === '') {
          this.$notify({
            group: 'notifications',
            title: 'Missing podcast English Title :(',
            text: '',
            type: 'error'
          })
          return
        }

        if (this.podcast.meta.titleSpanish === '') {
          this.$notify({
            group: 'notifications',
            title: 'Missing podcast Spanish Title :(',
            text: '',
            type: 'error'
          })
          return
        }

        if (this.podcast.meta.descriptionEnglish === '') {
          this.$notify({
            group: 'notifications',
            title: 'Missing podcast English Description :(',
            text: '',
            type: 'error'
          })
          return
        }

        if (this.podcast.meta.descriptionSpanish === '') {
          this.$notify({
            group: 'notifications',
            title: 'Missing podcast Spanish Description :(',
            text: '',
            type: 'error'
          })
          return
        }

        if (this.podcast.meta.slug === '') {
          this.$notify({
            group: 'notifications',
            title: 'Missing podcast slug :(',
            text: '',
            type: 'error',
            duration: -1
          })
          return
        }

        if(this.podcast.meta.categories.length === 0) {
          this.$notify({
            group: 'notifications',
            title: 'Missing podcast categories :(',
            text: '',
            type: 'error',
            duration: -1
          })
          return
        }

        if(!this.podcast.meta.language) {
          this.$notify({
            group: 'notifications',
            title: 'Missing podcast language :(',
            text: '',
            type: 'error',
            duration: -1
          })
          return
        }

        this.podcast.order = this.podcasts.length

        this.$http.post('/podcasts', {podcast: this.podcast})
          .then((res) => {
            if(!res.success) {
              return this.$notify({
                group: 'notifications',
                title: res.message,
                text: '',
                type: 'error'
              })
            }

            this.$notify({
              group: 'notifications',
              title: 'Show created successfully :)',
              text: '',
              type: 'success'
            })

            this.$router.push('/admin/podcasts/edit/' + res.data.id)

          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$notify({
                group: 'notifications',
                title: `${err.response.data} :(`,
                text: '',
                type: 'error'
              })
            } else {
              console.log(err.response)
            }
          })
      },
      search () {
        if (this.query === '') {
          this.pages = 1
          this.$http.get('/podcasts', {params: {limit: this.limit, page: this.pages}})
            .then((res) => {
              this.displayPaging = true
              this.podcasts = res.data.podcasts
              this.pages = res.data.pages
              this.current = res.data.current
            })
            .catch((err) => {
              console.log(err)
            })
          return
        }
        this.$http.get(`/podcasts?q=${this.query}`)
          .then((res) => {
            this.podcasts = res.data.podcasts
            this.displayPaging = false
          })
          .catch((err) => {
            console.log(err)
          })
      },
      confirm (podcast, index) {
        this.$refs.confirmRemovePodcast.show()
        this.toDelete = {
          index: index,
          podcast: podcast
        }
      },
      handleRemove (toDelete) {
        this.$http.delete(`/podcasts/${toDelete.podcast._id}`)
          .then((res) => {
            this.$notify({
              group: 'notifications',
              title: 'Podcast successfully removed :(',
              text: '',
              type: 'success'
            })
            this.podcasts.splice(toDelete.index, 1)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$notify({
                group: 'notifications',
                title: `${err.response.data} :(`,
                text: '',
                type: 'error'
              })
            } else {
              console.log(err.response)
            }
          })
      },
      getResults (page) {
        this.$http.get('/podcasts', {params: {limit: this.limit, page: page}})
          .then((res) => {
            // this.podcasts = res.data.podcasts.sort(this.dynamicSort('order'))
            this.podcasts = res.data.podcasts
            this.pages = res.data.pages
            this.current = res.data.current
          })
          .catch((err) => {
            console.log(err)
          })
      },
      updateStatus (podcast) {
        this.$http.put(`/podcasts`, {podcast: podcast})
          .then((res) => {
            this.$notify({
              group: 'notifications',
              title: `Podcast ${podcast.title} status updated :)`,
              text: '',
              type: 'success'
            })
          })
          .catch((err) => {
            console.log(err)
          })
      },
      navigate (id) {
        this.$router.push('/admin/podcasts/edit/' + id)
      },
      onSelected (podcast) {
        this.searchList = []
        this.$router.push('podcasts/edit/' + podcast._id)
      },
      updatePodcast (podcast) {
        return axios.put(`${this.$http.url}/podcasts/${podcast._id}/sorting`, {order: podcast.order })
      },
      cancelDragAndDrop () {
        return this.podcasts.length > 15
      },
      updateOrder () {
        let allPodcasts = []

        if (this.podcasts.length < 15) {
          return
        }

        this.podcasts.forEach((podcast, index) => {
          podcast.order = index

          let newPodacast = this.updatePodcast(podcast)
          allPodcasts.push(newPodacast)
        })

        axios.all(allPodcasts)
          .then((res) => {
            this.$notify({
              group: 'notifications',
              title: 'Podcasts order updated :)',
              text: '',
              type: 'success'
            })
          })
          .catch((err) => {
            console.log(err)
          })
      },
      dynamicSort (property) {
        var sortOrder = 1
        if (property[0] === '-') {
          sortOrder = -1
          property = property.substr(1)
        }
        return function (a, b) {
          var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
          return result * sortOrder
        }
      },
      getLastUpdated (podcast) {
        if (podcast.meta && podcast.meta.updatedAt) {
          const date = new Date(podcast.meta.updatedAt)
          const timeAgoString = LuxonDateTime.fromJSDate(date).toRelative()
          return `Last updated: ${timeAgoString}`
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .podcasts-all-list {
    padding: 5px 0;
    margin-bottom: 25px;
  }

  .podcasts-list-card {
    background-color: #fff;
    margin-bottom: 30px;
    border: 1px solid #eaeaea;
  }

  .podcasts-list-card .podcasts-list-card-img{
    cursor: pointer;
  }

  .podcasts-list-card .podcasts-list-card-img img {
    width: 100%;
  }

  .podcasts-list-card .podcasts-list-card-content {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f2494a
  }

  .podcasts-list-card .podcasts-list-card-content.published{
    border-bottom: 1px solid #8cd200
  }

  .podcasts-list-card .podcasts-list-card-title {
    font-size: 12px;
    font-weight: 900;
    text-transform: capitalize;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btn-search {
    height: 45px;
    background: #f2494a;
    border-radius: 0;
    color: #fff;
    line-height: 32px;
  }

  .help-prompt {
    padding: 1em;
    background: white;
    margin-top: 0.5em;
    font-style: italic;
    border: 1px solid #e6e6e6;
  }

  .podcast-list-title-container {
    display: inline-block;
  }

  .podcasts-list-card .podcasts-list-card-title span.podcast-list-card-order {
    background: #ccc;
    padding: 0 6px;
    color: #fff;
    border-radius: 5px;
    margin-right: 5px;
    margin-top: 5px;
    display: inline-block;
    vertical-align: top;
  }

  .podcasts-list-card-last-updated {
    display: block;
    font-weight: normal;
    text-transform: initial;
  }

  .modal-wrapper label {
    margin-bottom: 0;
    padding: 0;
  }

  .input-textarea{
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #eaeaea;
  }

</style>
