<template>
  <div class="charts-all-component">
    <div class="page-header">
      <div class="typeahead-wrapper">
        <div class="typeahead">
          <input id="input" class="form-control" type="text" placeholder="Search Charts" v-model="query"  @keyup.13="search">
          <div class="btn btn-search" @click="search">
            <i class="fas fa-search"></i>
          </div>
        </div>
        <div class="add-btn" @click="showNewChartModal">Add Chart</div>
      </div>
    </div>

    <div class="page-content">
      <div class="chart-all-list">
        <transition-group tag="div" class="row" name="list-animation">
          <div class="col-md-6" v-for="(chart) in charts" v-bind:key="chart._id">
            <div class="chart-card" :class="{published: chart.published}">
              <div class="image-chart" @click="navigate(chart._id)">
                <img :src="resolveChartImage(chart)" alt=""/>
              </div>
              <div class="chart-info">
                <div>
                  <span class="chart-title" @click="navigate(chart._id)">{{chart.title}}</span>
                  <div class="chart-meta">
                    <span style="font-style: normal"><strong>Week {{chart.week.number}}</strong>: {{formatDate(chart.week.start)}} - {{formatDate(chart.week.end)}}</span> <br />
                    <span style="font-style: normal">{{chart.tracks.length}} tracks</span>
                  </div>
                </div>
                <div>
                  <div class="submenu-actions">
                    <div class="icon">
                      <i class="fas fa-ellipsis-h"></i>
                    </div>
                    <div class="actions">
                      <ul>
                        <li @click="publish(chart)">{{chart.published ? 'Unpublish' : 'Publish'}}</li>
                        <li @click="duplicateChart(chart)">Duplicate</li>
                        <li @click="showDeleteConfirmModal(chart)">Delete</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>

    <b-modal id="addChart" ref="addChart" ok-title="Create" size="md" title="New Chart" @ok="createChart" @close="resetChart" @cancel="resetChart">
      <div class="modal-wrapper">
        <b-form-row>
          <b-col>
            <label class="col-form-label">Title<sup class="text-danger">*</sup></label>
            <input type="text" placeholder="Top Hits" v-model="chart.title"/>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <label class="col-form-label">Start Date<sup class="text-danger">*</sup></label>
            <week-picker :current-week="chart.week" @selected="onWeekSelected($event)"></week-picker>
          </b-col>
        </b-form-row>
      </div>
    </b-modal>

    <b-modal id="confirmRemoveChart" ref="confirmRemoveChart" size="md" title="Confirm" @ok="handleDelete">
      <div class="row modal-wrapper">
        <div class="col-12">
          Are you sure want to remove <strong>{{currentChart.title}}</strong> chart?
        </div>
      </div>
    </b-modal>

  </div>
</template>


<script>
 import WeekPicker from '../../share/week-picker.vue'
 import ChartCommon from './chart-common'
  export default {
    name: 'ChartsAll',
    components: {WeekPicker},
    data () {
      return {
        chartCommon: new ChartCommon(this),
        chart: {
          title: '',
          week: {
            start: null,
            end: null,
            number: null
          },
          tracks: [],
          published: false
        },
        query: '',
        charts: [],
        currentChart: {}
      }
    },

    mounted () {
      this.search()
    },

    methods: {
      resolveChartImage(chart) {
        return chart.tracks.length > 0 ? chart.tracks[0].image : '/static/img/256x256.png'
      },

      search () {
        this.$http.get(`/charts${this.query ? '?query=' + this.query : ''}` ).then((response) => {
          this.charts = response.data
        }).catch((error) => {
          this.$notify({
            group: 'notifications',
            title: 'Error',
            text: error.message || 'Something went wrong, please try again.',
            type: 'error'
          })
        })
      },
      showNewChartModal () {
        this.$refs.addChart.show()
      },
      createChart (bvModalEvent) {
        bvModalEvent.preventDefault()

        if(!this.chartCommon.validateChart(this.chart)) {
          return
        }

        this.$http.post('/charts', {
          chart: this.chart
        }).then((response) => {
          if(response.success) {
            this.$refs.addChart.hide()
            this.resetChart()
            this.search()
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Error',
              text: response.message,
              type: 'error'
            })
          }
        }).catch((error) => {
          this.$notify({
            group: 'notifications',
            title: 'Error',
            text: error.message || 'Something went wrong, please try again.',
            type: 'error'
          })
        })
      },
      showDeleteConfirmModal(chart) {
        this.currentChart = chart
        this.$refs.confirmRemoveChart.show()
      },
      handleDelete () {
        this.$http.delete(`/charts/${this.currentChart._id}`)
          .then((response) => {
            if(response.success) {
              this.$notify({
                group: 'notifications',
                title: 'Success',
                text: 'Chart successfully removed',
                type: 'success'
              })

              this.search()
            } else {
              this.$notify({
                group: 'notifications',
                title: 'Error',
                text: response.message,
                type: 'error'
              })
            }
          })
          .catch((error) => {
            this.$notify({
              group: 'notifications',
              title: 'Error',
              text: error.message || 'Something went wrong, please try again.',
              type: 'error'
            })
          })
      },

      duplicateChart(chart) {
        this.chart.title = chart.title ? `${chart.title}-Copy` : null
        this.chart.tracks = chart.tracks
        this.chart.published = false

        this.showNewChartModal()
      },

      onWeekSelected(week) {
        this.chart.week = week
      },

      publish(chart) {
        if(!chart.published) {
          if(!this.chartCommon.validateChart(chart, true)) {
            return
          }
        }

        this.$http.post(`/charts/${chart._id}/publish`, {
          published: !chart.published
        }).then((response) => {
          if(response.success) {
            this.search()
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Error',
              text: response.message,
              type: 'error'
            })
          }
        }).catch((error) => {
          this.$notify({
            group: 'notifications',
            title: 'Error',
            text: error.message || 'Something went wrong, please try again.',
            type: 'error'
          })
        })
      },

      resetChart() {
        this.chart.title = ''
        this.chart.week = {start: null, end: null}
        this.chart.tracks = []
        this.chart.published = false
      },

      navigate (id) {
        this.$router.push(`charts/edit/${id}`)
      },
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.btn-search {
  height: 45px;
  background: #f2494a;
  border-radius: 0;
  color: #fff;
  line-height: 32px;
}

.chart-card {
  border: 1px solid #eaeaea;
  border-left:2px solid #de4242;
  background-color: #fff;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &.published{
    border-left: 2px solid #8cd200
  }

  .image-chart {
    padding: 5px;
    width: 85px;
    margin-right: 15px;
    cursor: pointer;

    img {
      width: 100%
    }
  }

  .chart-title{
    cursor: pointer;
    text-transform: uppercase;
    display: block;
  }

  .chart-info {
    display: flex;
    flex: 1;
    justify-content: space-between;

    .submenu-actions{
      margin-right: 10px;
      display: inline-block;
      position: relative;
      top: 2px;
    }
  }

  .chart-meta {
    font-weight: normal;
    font-style: italic;
  }

  .icon-wrapper {
    display: inline-block;
  }

}
</style>
