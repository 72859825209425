<template>
  <div class="loading-wrapper" v-show="isLoading">
    <div class="content-wrapper">
      <div class="progress" v-if="this.uploadPercentage > 0">
        {{uploadPercentage}}%
        <div class="message" v-show="message">File uploaded, it is currently processing</div>
      </div>
      <div class="spinner">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5"></div>
      </div>
    </div>
  </div>
</template>


<script>
  export default {
    name: 'loading',
    data () {
      return {
        isLoading: false,
        message: false,
        uploadPercentage: 0,
        timeStarted: -1
      }
    },
    mounted () {
      EventHandler.listen('isLoading', (data) => {
        this.isLoading = data
      })
    },
    created () {
      EventHandler.listen('uploadPercentage', (data) => {
        this.uploadPercentage = data

        try {
          if (this.timeStarted === -1) {
            this.timeStarted = Date.now()
          }
          // calculate diff as a basis of time
          const timeDiff = Date.now() - this.timeStarted
          const timeLeft = timeDiff / (this.uploadPercentage / 100)
          const estimatedTimeToCompletion = new Date(Date.now() + timeLeft)
          console.log('progress', estimatedTimeToCompletion.toLocaleString())
        } catch (e) {
          console.error(e)
        }

        if (this.uploadPercentage === 100) {
          this.message = true
        }
      })
    },
    methods: {
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .loading-wrapper{
    z-index: 9999;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(247, 247, 247, 0.95)
  }

  .loading-wrapper .content-wrapper{
    margin: 30px auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  .loading-wrapper .content-wrapper .progress{
    color: #de4242;
    font-size: 60px !important;;
    overflow:auto !important;
    height: auto !important;
    background-color: transparent !important;
    display: block;
    text-align: center;
    font-weight: bold;
  }

  .loading-wrapper .content-wrapper .progress .message{
    font-size: 16px;
    color: #de4242;
    margin-bottom: 10px
  }

  .loading-wrapper .spinner {
    margin: 0 auto;
    width: 50px;
    height: 40px;
  }

  .loading-wrapper .spinner > div {
    background-color: #f2494a;
    height: 100%;
    width: 6px;
    display: inline-block;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .loading-wrapper .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .loading-wrapper .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .loading-wrapper .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .loading-wrapper .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
  }

  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }  20% {
         transform: scaleY(1.0);
         -webkit-transform: scaleY(1.0);
       }
  }
</style>
