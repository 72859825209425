<template>
  <div class="login-wrapper">
    <form class="login-form" @submit="submit">
      <label>Create New Password?</label>
      <input type="password" placeholder="Enter new password" v-model="password.first" required/>
      <input type="password" placeholder="Re-type new password" v-model="password.second" required/>
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>
</template>


<script>
  export default {
    name: 'newPassword',
    data () {
      return {
        token: '',
        userID: '',
        password: {
          first: '',
          second: ''
        }
      }
    },

    mounted () {
      this.userID = this.$route.params.userID
      this.token = this.$route.params.token

      this.$http.post('/auth/validate-link', {userID: this.userID, token: this.token})
        .then((res) => {
          console.log(res)
          if (res.success) {
            // Let user change his password
          } else {
            // Token no longer valid Redirect user to login screen
            this.$router.push('/')
          }
        })
        .catch((err) => {
          console.log(err)
          this.$router.push('/')
        })
    },

    methods: {
      submit (evt) {
        evt.preventDefault()
        if (this.password.first !== this.password.second) {
          this.$notify({
            group: 'notifications',
            title: 'Passwords should match. Please try again :(',
            text: '',
            type: 'error'
          })
          return
        }

        this.$http.post('/auth/change-password', {userID: this.userID, password: this.password.first})
          .then((res) => {
            console.log(res)
            if (res.success) {
              // Redirect user to login screen
              this.$router.push(`/`)
              this.$notify({
                group: 'notifications',
                title: 'Password successfully updated :)',
                text: '',
                type: 'success'
              })
            } else {
              this.$notify({
                group: 'notifications',
                title: 'There was a problem with your request. Please try again :(',
                text: '',
                type: 'error'
              })
            }
          })
          .catch((err) => {
            console.log(err)
            this.$notify({
              group: 'notifications',
              title: 'There was a problem with your request. Please try again :(',
              text: '',
              type: 'error'
            })
          })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .login-wrapper label{
    color: #2c3e50;
    text-transform: uppercase;
    font-weight: 900
  }

  .login-wrapper .login-form input{
    display: block;
    width: 100%;
    height: 45px;
    padding: 0 10px;
    margin-bottom: 15px;
    border: 1px solid #eaeaea;
  }

  .login-wrapper .login-form a{
    color: #fff;
    display: block;
    padding: 10px;
    text-align: center;
    margin-bottom: 15px;
    background-color: #f2494a;
  }

  .login-wrapper .login-form a:hover{
    background-color: #de4242;
    text-decoration: none;
  }

  .login-wrapper .register{
    text-align: center;
  }

  .login-wrapper .register a{
    color: #f2494a;
    font-size: 12px;
  }

  .login-wrapper .register.forgot a{
    font-size: 10px;
  }

  .login-form button{
    color: #fff;
    display: block;
    padding: 10px;
    text-align: center;
    margin-bottom: 15px;
    background-color: #f2494a;
    border: none;
    border-radius: 0;
    width: 100%;
  }

  .login-form button:hover,
  .login-form button:active,
  .login-form button:active:focus{
    background-color: #de4242 !important;
    border: none !important;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus{
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

</style>
