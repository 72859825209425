<template>
  <router-link :to="'/admin/cards/edit/' + item.id" target="_blank">
    <div class="item-wrapper" v-bind:class="'item-' + item.type">
      <div class="item-image">
        <img :src="item.desktop_image" />
      </div>
      <div v-if="displayTitles" class="item-text"
           v-b-tooltip.hover.topright
           :title="item.title.en || item.title.es">
        {{item.title.en || item.title.es}}
      </div>
    </div>
  </router-link>
</template>
<script>
export default {
  name: 'RowPreviewItem',
  props: {
    item: {
      type: Object
    },
    displayTitles: {
      type: Boolean,
      default: false
    },
  }
}
</script>
<style scoped lang="scss">
  a{
    color: #000;
    text-decoration: none;
    &:hover{
      color: #000;
      text-decoration: none;
    }
  }
  .item-wrapper{
    .item-image{
      img{
        width: 100%;
        border-radius: 10px;
      }
    }

    .item-text{
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
      margin-top: 5px;
      text-align: center;
    }
  }
</style>
