<template>
    <div class="cards-type-playlist">
      <div class="container">
        <div class="col-8 offset-2">
          <b-form @submit="save" class="bootstrap-form">

             <b-form-row>
              <b-col>
                <b-input-group>
                  <b-form-input readonly required v-model="cardPlaylist.id"></b-form-input>
                  <b-input-group-append>
                    <b-btn variant="info" class="card-btn btn-add btn-modal" @click="addPlaylist()">Add Playlist</b-btn>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-form-row>

            <BaseCardForm :card="cardPlaylist" :imageSizes="imageUpload" :video="video" />

            <div class="wrapper-btn">
              <!-- <b-button variant="primary" class="card-btn btn-add btn-add-img" @click="addImage">Add Images</b-button> -->
              <b-button type="submit" variant="primary" class="card-btn">{{type === 'create' ? 'Create Card' : 'Update Card'}}</b-button>
            </div>
          </b-form>
        </div>
      </div>

      <b-modal id="addPlaylist" ref="addPlaylist" :ok-only="true" ok-title="Add" @ok="addTocard" size="md" title="Select Playlist">
        <div class="row modal-wrapper">
          <div class="col-12">
            <input type="text" placeholder="Find Playlist..." v-model="queryPlaylist" />
          </div>
          <div class="col-12">
            <ul class="playlistUpdated-list">
              <li v-for="playlist in queryResults" v-bind:key="playlist._id" @click="selectPlaylist(playlist)" v-bind:class="{selected: playlist.selected}">
                <div class="playlists-list-card-img">
                    <img :src="playlist.meta.exploreImagery.hdpiImageURL" alt="" v-if="playlist.meta && playlist.meta.exploreImagery && playlist.meta.exploreImagery.hdpiImageURL" />
                    <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!playlist.meta || !playlist.meta.exploreImagery || !playlist.meta.exploreImagery.hdpiImageURL"/>
                </div>
                <div class="playlist-info">
                  <div class="playlist-title">{{playlist.title}}</div>
                  <div class="playlist-tracks">{{playlist.tracks.length}} Tracks</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </b-modal>

    </div>

</template>


<script>
import { Datetime } from 'vue-datetime'
import CardTypeCommon from './CardTypeCommon'
import LoaderInline from '../../../share/LoaderInline'
import BaseCardForm from './BaseCardForm'

export default {
  name: 'PlaylistType',
  components: {
    Datetime,
    LoaderInline,
    BaseCardForm
  },
  props: ['card', 'type'],
  data () {
    const utils = new CardTypeCommon(this)
    return {
      formData: new FormData(),
      utils: utils,
      video: utils.uploads.video[0],
      imageUpload: utils.uploads.images,
      minDatetime: utils.configuration.minDatetime,
      playlists: [],
      playlistSelected: null,
      queryPlaylist: ''
    }
  },
  computed: {
    cardPlaylist: {
      get () {
        return this.card
      },
      set (value) {
        this.card = value
      }
    },
    queryResults () {
      return this.playlists.filter(playlist => {
        return playlist.title.toLowerCase().includes(this.queryPlaylist.toLowerCase())
      })
    }
  },
  mounted () {
    this.utils.setCardDefaultValues()
  },
  methods: {
    addImage () {
      let images = this.imageUpload.filter((img) => {
        return img['750x750'] && img['1000x1100']
      })

      if (images.length === this.imageUpload.length) {
        this.imageUpload.push({
          file750: null,
          '750x750': null,
          placeholder750: 'Image 750 x 750px',
          width750: 750,
          height750: 750,

          file1000: null,
          '1000x1100': null,
          placeholder1000: 'Image 1000 x 1100px',
          width1000: 1000,
          height1000: 1100
        })
      }
    },
    removeImage (index) {
      this.imageUpload.splice(index, 1)
    },
    uploadVideo (event) {
      this.utils.handleVideoUpload(event)
    },
    removeVideoCard () {
      this.utils.handleRemoveVideoCard()
    },
    save (evt) {
      evt.preventDefault()
      this.card.type = 'playlist'

      if (!this.cardPlaylist.id) {
        this.$notify({
          group: 'notifications',
          title: 'Playlist selection is required :(',
          text: '',
          type: 'error'
        })

        return
      }

      if (!this.utils.validateCardBeforeSave()) {
        return
      }

      if (this.type === 'create') {
        this.create()
      } else {
        this.update()
      }
    },
    addPlaylist () {
      EventHandler.emit('isLoading', true)
      this.$http.get('/playlists', {params: {limit: 0, page: 1, published: 1}})
        .then((res) => {
          this.playlists = res.data.playlists
          EventHandler.emit('isLoading', false)
          this.$refs.addPlaylist.show()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    selectPlaylist (playlist) {
      if (playlist.selected) {
        playlist.selected = false
      } else {
        this.playlists.forEach((item) => {
          if (item.selected) {
            item.selected = false
          } else {
            this.$set(item, 'selected', false)
          }
        })
        this.$set(playlist, 'selected', true)
        this.playlistSelected = playlist
      }
    },
    addTocard () {
      if (!this.playlistSelected || !this.playlistSelected.selected) {
        this.$notify({
          group: 'notifications',
          title: 'No Playlist selected :(',
          text: '',
          type: 'error'
        })

        return
      }
      this.cardPlaylist.id = this.playlistSelected._id
      this.cardPlaylist.titleEnglish = this.playlistSelected.meta.titleEnglish
      this.cardPlaylist.titleSpanish = this.playlistSelected.meta.titleSpanish
      this.utils.setImageURLValues({
        '750x750': this.playlistSelected.meta.detailViewImagery['2xImageURL'],
        '1000x1100': this.playlistSelected.meta.cardImagery['3xImageURL'],
        '1296x560': this.playlistSelected.meta.exploreImagery['xxxhdpiImageURL'],
        '1920x850': this.playlistSelected.meta.landing['3xImageURL']
      })
    },
    create () {
      this.$http.post('/cards', {card: this.cardPlaylist})
        .then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Card created successfully :)',
              text: '',
              type: 'success'
            })
            this.$router.push('/admin/cards')
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Images are required :)',
              text: '',
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    update () {
      this.utils.handleCardUpdate()
    }
  }
}
</script>

<style>
  .card-image video {
    width: 100%;
    margin-bottom: 10px;
  }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .cards-type-playlist {
  margin-top: 6%;
  height: calc(100vh - 210px);
  overflow: scroll;
} */

.cards-type-playlist .custom-file {
  margin-bottom: 15px;
}

.cards-type-playlist .wrapper-btn{
  display: flex;
  justify-content: flex-end;
}

.cards-type-playlist .card-btn{
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  text-transform: uppercase;
}

.cards-type-playlist .btn-add{
  margin-right: 10px;
}

.cards-type-playlist .btn-modal{
  height: 45px;
  margin-right: 0;
}

.card-image img, .card-image video{
  width: 100%;
  margin-bottom: 10px;
}

ul.playlistUpdated-list{
    list-style-type: none;
    overflow-y: scroll;
    max-height: 400px;
    padding: 3px;
}

ul.playlistUpdated-list .playlist-title{
  font-size: 12px;
  font-weight: bold;
}

ul.playlistUpdated-list .playlist-tracks{
  font-size: 10px;
  font-weight: bold;
}

ul.playlistUpdated-list li {
  list-style-type: none;
  display: flex;
  background-color: #fff;
  margin-bottom: 10px;
  height: 60px;
  align-items: center;
  border: 1px solid #eaeaea;
  cursor: pointer;
}

ul.playlistUpdated-list li.selected {
  background-color: #eaeaea;
}

ul.playlistUpdated-list li .playlists-list-card-img {
  margin-right: 15px;
}

ul.playlistUpdated-list li .playlists-list-card-img img{
  height: 60px;
  width: auto;
}

.image-group{
  display: flex;
  flex: 1;
  border: 1px solid #eaeaea;
  padding-top: 15px;
  margin-bottom: 15px;
  position: relative;
  flex-wrap: wrap;
}

.image-group .remove-img{
    position: absolute;
    top: -10px;
    right: -10px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #eaeaea;
    background-color: #eaeaea;
    text-align: center;
    cursor: pointer;
}

.video-labels {
  font-weight: bold;
  font-size: 12px;
}

</style>
