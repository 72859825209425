
export default class ChartCommon {
  constructor (context) {
    this.context = context
  }

  validateChart (chart, validateTracks = false) {
    if (!chart.title) {
      this.context.$notify({
        group: 'notifications',
        title: 'Error',
        text: 'Chart title is required',
        type: 'error'
      })

      return false
    }

    if (!chart.week.start) {
      this.context.$notify({
        group: 'notifications',
        title: 'Error',
        text: 'Chart week is required',
        type: 'error'
      })

      return false
    }

    if (validateTracks) {
      if (!chart.tracks.length) {
        this.context.$notify({
          group: 'notifications',
          title: 'Error',
          text: 'Chart tracks list must have at least one track',
          type: 'error'
        })

        return false
      }
    }

    // validate 1st song of chart contains video
    if(chart.tracks && chart.tracks.length){
      if(chart.tracks[0].video === null || chart.tracks[0].video ===''){
        this.context.$notify({
          group: 'notifications',
          title: 'Error',
          text: '1st Song in the chart must have video card.',
          type: 'error'
        })

        return false
      }
    }

    return true
  }
}
