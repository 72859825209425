<template>
  <div class="config-component">
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-5">
          <div class="title">
            <router-link to="/admin/configurations">Configurations </router-link> / {{config.title}}
          </div>
        </div>
        <div class="col-7 pull-right">
          <div class="typeahead-wrapper float-right">
            <button class="btn btn-secondary" @click="viewOnLaMusicaConfigURL(config.title)">View On LaMusica Config</button>
            <button class="btn btn-secondary" @click="viewOnCloudfront(config.title)">View On Cloudfront</button>
            <button class="btn btn-secondary" @click="viewOnS3(config.title)">View On S3</button>
            <button type="submit" form="configForm" class="typeahead-btn">Update</button>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="card" v-if="config.live_versions">
        <b-form @submit="update" id="configForm" class="bootstrap-form">
          <ConfigurationForm :config="config" />
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
  import ConfigurationForm from './ConfigurationForm'
  export default {
    name: 'ConfigurationsEdit',
    components: {
      ConfigurationForm
    },
    data () {
      return {
        config: {
        }
      }
    },

    mounted () {
      this.configID = this.$route.params.id

      this.$http.get(`/configurations/${this.configID}`)
        .then((res) => {
          if (!res.data.chat) {
            res.data.chat = {}
          }
          this.config = res.data

          if(!this.config.feature){ //if we add more feature, check each feature individually
            this.config.feature = {
              radio_recording:false
            }
          }
          if(!this.config.playlist){
            this.config.playlist={}
          }

          if (!this.config.voicenotes) {
            this.config.voicenotes = {
              active: true,
              frequency: 60 * 15 // seconds
            }
          }

          if (this.config.advertisements && !this.config.advertisements.interstatial_ads_seconds) {
          this.config.advertisements.interstatial_ads_seconds = 14440
          }

          if (!this.config.recovery) {
            this.config.recovery = {
              active: false,
              url:''
            }
          }
  
        })
        .catch((e) => {
          console.log(e)
        })
    },

    methods: {
      viewOnS3 (key) {
        window.open(`https://lamusica-remote-config.s3.amazonaws.com/${key}.json`, '_blank')
      },
      viewOnCloudfront (key) {
        window.open(`https://duml1xmjo56t1.cloudfront.net/${key}.json`, '_blank')
      },
      viewOnLaMusicaConfigURL (key) {
        window.open(`https://config.lamusica.com/${key}.json`, '_blank')
      },
      update (evt) {
        evt.preventDefault()
        if (this.config.chat) {
          for (const key in this.config.chat) {
            if (this.config.chat[key]) {
              this.config.chat[key] = parseInt(this.config.chat[key])
            }
          }
        }

        if (this.config.voicenotes && this.config.voicenotes.frequency) {
          this.config.voicenotes.frequency = parseInt(this.config.voicenotes.frequency)
        }

        if (this.config.advertisements && this.config.advertisements.interstatial_ads_seconds) {
          this.config.advertisements.interstatial_ads_seconds = parseInt(this.config.advertisements.interstatial_ads_seconds)
        }

        const params = {
          config: this.config
        }
        console.log('Saving Configuration', params.config)
        this.$http.put('/configurations', params)
          .then((res) => {
            if (res.success) {
              this.$notify({
                group: 'notifications',
                title: 'Configuration file successfully updated :)',
                text: '',
                type: 'success'
              })
            } else {
              this.$notify({
                group: 'notifications',
                title: 'Something went wrong :(',
                text: '',
                type: 'error'
              })
            }
          })
          .catch((err) => {
            console.log(err)
            this.$notify({
              group: 'notifications',
              title: 'Sorry, something went wrong :(',
              text: '',
              type: 'error'
            })
          })
      },
      removeConfig (collection, index) {
        collection.splice(index, 1)
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .row-form legend {
    font-size: 14px;
  }
</style>
<style scoped>
  .config-component .card {
    padding: 30px;
  }

  .config-component .card input {
    margin-bottom: 0 !important;
  }

  .config-component .card label.section-title {
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .spacer {
    margin-top: 40px;
  }

  .row-form {
    margin-bottom: 20px;
    font-size: 16px;
  }

  .bootstrap-form button {
    display: initial;
  }

  .bootstrap-form input.live_versions {
    margin-bottom: 10px !important;
  }

  .config-component .card .tags-btn {
    width: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 4px;
    color: #fff;
    font-style: oblique;
    background-color: #f2494a;
  }

  .bootstrap-form button {
    font-size: 12px;
    line-height: 25px;
    text-transform: uppercase;
  }

  .btn-secondary {
    margin-left: 10px;
  }
</style>
