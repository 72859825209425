<template>
  <h1>Oauth2 Test Page</h1>
</template>
<script>
export default {
  name: 'Oauth2',
  data () {
    return {
      vendor: null,
      code: null
    }
  },
  mounted () {
    this.vendor = this.$route.params.vendor || null
    this.code = this.$route.query.code || null

    this.updateVendorToken()
  },
  methods: {
    async updateVendorToken () {
      const permissions = (localStorage.getItem('permissions') || '').split(',')
      try {
        const response = await this.$http.post(`/oauth2/${this.vendor}`, {code: this.code})

        if(!response.success) {
          console.log(response.message)
          return
        }

        this.$router.push(`/admin/${permissions[0]}`)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
