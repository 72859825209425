<template>
  <div class="podcast-edit-component" v-if="podcast">
    <editors v-if="podcastId" :resource="podcastId" />
    <div class="row align-items-center header">
      <div class="col-8">
        <div class="title">
          <router-link to="/admin/podcasts">podcasts </router-link>/
          <span>{{podcast.title}}</span>
        </div>
      </div>
      <div class="col-4 text-right">
        <div class="add-podcast" @click="save()">Update</div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <div class="controls-wrapper">
          <ul>
<!--            <b-btn class="modal-btn">Videos Encoding Queue</b-btn>-->
            <b-btn v-b-modal.modalMetaPodcast class="modal-btn">Metadata</b-btn>
            <b-btn v-b-modal.modalImagesPodcast class="modal-btn">Images</b-btn>
            <!-- <b-btn v-b-modal.modalNotifications class="modal-btn">Notify Subscribers</b-btn> -->
            <label class="modal-switch">
              Notify Subscribers
              <span> <b-btn v-b-modal.modalNotifications size="sm"> <i class="fas fa-cog"></i> </b-btn> </span>
              <span> <b-btn @click="sendNotification" size="sm"> <i class="fas fa-paper-plane"></i> </b-btn> </span>
            </label>
            <!-- <b-form-checkbox v-model="notification.notifySubscribers" class="modal-switch" switch>
              <span> <b-btn v-b-modal.modalNotifications size="sm"> <i class="fas fa-cog"></i> </b-btn> </span>
              Notify Subscribers
            </b-form-checkbox> -->
            <b-btn class="modal-btn" @click="podcast.published = !podcast.published">{{podcast.published ? 'Unpublish' : 'Publish'}}</b-btn>
          </ul>
        </div>
        <!-- <div v-if="notifySubscribers" style="text-align: end;">This will send an update notifications to all subscribers</div> -->
      </div>
    </div>
    <hr />

    <div class="podcast-wrapper" v-if="podcast.meta">
      <div class="image-wrapper">
        <img :src="resolveHeaderImage()" alt="" class="shadow"/>
      </div>

      <div class="content-wrapper">
        <div class="description">{{ podcast.meta.descriptionEnglish || podcast.meta.descriptionSpanish }}</div>
        <hr class="mt-2" />
        <template v-if="podcast.meta.categories.length">
          <div>
            <b-badge pill variant="info" class="mr-1" v-for="(category) in podcast.meta.categories" :key="category">{{ category }}</b-badge>
          </div>
          <hr class="mt-2" />
        </template>
        <podcast-stats :stats="stats" v-if="stats"></podcast-stats>
      </div>
    </div>

    <episodes-manager :podcast="podcast" v-if="podcast._id" :episodes-count="stats.published" :reload-episodes="reloadEpisodes" @updated="onEpisodesUpdated"></episodes-manager>

    <b-modal id="modalMetaPodcast" :ok-only="true" ok-title="close" size="lg" title="Metadata">
      <div class="modal-wrapper" v-if="podcast._id">
        <div class="row mt-2">
          <div class="col-6">
            <label>English Title<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Title used for LaMusica.com single page and on card rows.</span>
            <input type="text" placeholder="Top Hits" v-model="podcast.meta.titleEnglish"/>
          </div>
          <div class="col-6">
            <label>Spanish Title<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Title used for LaMusica.com single page and on card rows.</span>
            <input type="text" placeholder="Los Exitos de Ahora" v-model="podcast.meta.titleSpanish"/>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label>English Secondary Title</label>
            <input type="text" placeholder="" v-model="podcast.meta.secondaryTitleEnglish"/>
          </div>
          <div class="col-6">
            <label>Spanish Secondary Title</label>
            <input type="text" placeholder="" v-model="podcast.meta.secondaryTitleSpanish"/>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label>English Description</label>
            <span class="form-text text-muted">Please provide a high-level summary about this podcast.</span>
            <textarea class="input-textarea" placeholder="" v-model="podcast.meta.descriptionEnglish" rows="3"></textarea>
          </div>
          <div class="col-6">
            <label>Spanish Description</label>
            <span class="form-text text-muted">Please provide a high-level summary about this podcast.</span>
            <textarea class="input-textarea" placeholder="" v-model="podcast.meta.descriptionSpanish" rows="3"></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label>Slug<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Please add a unique, lowercase slug for sharing & for LaMusica app/web.</span>
            <input type="text" placeholder="top-hits" v-model="podcast.meta.slug"/>
          </div>
          <div class="col-6">
            <label>Wide Orbit Companion Ad Station ID</label>
            <span class="form-text text-muted">This is the station ID on Wide Orbit that the app will use to fetch pre-roll/mid-roll advertisements.  An example value would be "Curated-61".</span>
            <input type="text" placeholder="" v-model="podcast.meta.companionAdStationId"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <input type="text" placeholder="Facebook Share Image Link:" v-model="podcast.meta.facebookShareImageLink"/>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label>Language<sup class="text-danger">*</sup></label>
            <v-select
              v-model="podcast.meta.language"
              placeholder="Choose the language"
              :options="languages"
              :reduce="item => item.code"
            />
          </div>
          <div class="col-6">
            <label for="categories">Categories<sup class="text-danger">*</sup></label>
            <itunes-categories :categories="podcast.meta.categories" @updated="podcast.meta.categories = $event"></itunes-categories>
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-6">
            <b-form-checkbox id="checkbox1" v-model="podcast.meta.hideTitle">Hide Title</b-form-checkbox>
          </div>
          <div class="col-6">
            <b-form-checkbox id="checkbox2" v-model="podcast.meta.isFeatured">Is Featured</b-form-checkbox>
          </div>
        </div>
        <br/>

        <section>
          <h4>Author Details</h4>
          <hr />
          <div class="row">
            <div class="col-12">
              <label>Authors</label>
              <span class="form-text text-muted">The names of the hosts present in the show (example values "Mauricio Londoño" or "Rocky the Kid y La Burbu".</span>
              <input type="text" placeholder="" v-model="podcast.meta.authors"/>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label>Website Link</label>
              <input type="text" placeholder="" v-model="podcast.meta.websiteLink"/>
            </div>
            <div class="col-6">
              <label>Owner Name</label>
              <input type="text" placeholder="" v-model="podcast.meta.ownerName"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label>Email</label>
              <input type="email" placeholder="" v-model="podcast.meta.ownerEmail"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label>Copyright</label>
              <textarea class="input-textarea" placeholder="" v-model="podcast.meta.copyright" rows="3"></textarea>
            </div>
          </div>
        </section>

        <br />

        <AdConfigurationForm
          @validateCheckBox="validateCheckBox"
          :item="podcast.meta"
          :has-wide-orbit-companion-ads="false"
          :has-web-display-ads="true"
        />
        <br>

        <b-form-row>
          <b-col>
            <b-form-group label="Wide Orbit On Demand Channel ID">
              <small>On <a target="_blank" href="https://console.woondemand.com/content/channels">Wide Orbit On Demand</a> this indicates the channel ID associated with this podcast</small>
              <b-form-input id="wideOrbitOnDemandChannelID"
                            type="text"
                            v-model="podcast.meta.wideOrbitOnDemand.channelID"
                            placeholder="">
              </b-form-input>
            </b-form-group>

            <b-form-group label="Wide Orbit On Demand Content Producer ID">
              <small>On <a target="_blank" href="https://console.woondemand.com/content/content-producers">Wide Orbit On Demand</a> this indicates the content producer ID associated with this podcast</small>
              <b-form-input id="wideOrbitOnDemandContentProducerID"
                            type="text"
                            v-model="podcast.meta.wideOrbitOnDemand.contentProducerID"
                            placeholder="">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col>

            <b-form-group label="Wide Orbit On Demand Permalink URL">
              <small>This defines the permalink placed on the wide orbit episode.</small><br/>
              <b-form-input id="wideOrbitOnDemandPermalink"
                            type="text"
                            v-model="podcast.meta.wideOrbitOnDemand.permalink"
                            placeholder="">
              </b-form-input>
            </b-form-group>
          </b-col>

        </b-form-row>

        <section class="mt-2" v-if="podcast.meta.audio_settings">
          <h4>Audio Settings</h4>
          <hr />
          <b-form-row>
            <b-col>
              <label>Intro Audio</label>
              <audio-settings :audio="podcast.meta.audio_settings.intro" v-on:audioSelected="onAudioSettingsSelected('intro', $event)"></audio-settings>
            </b-col>
          </b-form-row>
          <b-form-row class="mt-2">
            <b-col>
              <label>Outro Audio</label>
              <audio-settings :audio="podcast.meta.audio_settings.outro" v-on:audioSelected="onAudioSettingsSelected('outro', $event)"></audio-settings>
            </b-col>
          </b-form-row>
        </section>

      </div>
    </b-modal>

    <b-modal id="modalImagesPodcast" ref="modalImagesPodcast" :ok-only="true" ok-title="close" size="lg" title="Images">
      <div class="row modal-wrapper">
        <div class="col-6" v-for="platform in platforms" v-bind:key="platform.platform">
          <upload @imgUploaded="imgUploaded" :data="platform"></upload>
        </div>
      </div>
      <div class="row modal-wrapper">
        <div class="col-6">
          <upload @imgUploaded="overwriteEpisodeImages" :data="placeholderImage"></upload>
        </div>
      </div>
      <div class="row modal-wrapper">
        <div class="col-6">
          <upload @imgUploaded="overwriteMegaphoneImages" :data="megaphoneImage"></upload>
        </div>
      </div>
    </b-modal>

    <b-modal id="modalNotifications" ref="modalNotificationsmodalNotifications" :ok-only="true" ok-title="close" size="lg" title="Notification Settings">
      <div class="modal-wrapper" v-if="notification">
      <div class="row">
        <b-col>
          <b-link target="_blank" href="https://www.emojicopy.com/">Copy-paste emojis from here</b-link>
        </b-col>
      </div>
      <div class="row mt-2">
          <div class="col-6">
            <label>English Title<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Title On the notification</span>
            <input type="text" placeholder="New Episodes Out Now!!!" v-model="notification.title.en"/>
          </div>
          <div class="col-6">
            <label>Spanish Title<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Title On the notification</span>
            <input type="text" placeholder="¡¡¡Nuevos episodios ya disponibles!!!" v-model="notification.title.es"/>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label>English Message<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Message on the notification.</span>
            <textarea class="input-textarea" placeholder="" v-model="notification.description.en" rows="3"></textarea>
          </div>
          <div class="col-6">
            <label>Spanish Message<sup class="text-danger">*</sup></label>
            <span class="form-text text-muted">Message on the notification.</span>
            <textarea class="input-textarea" placeholder="" v-model="notification.description.es" rows="3"></textarea>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>
<script>
import PodcastStats from './podcast-stats.vue'
import EpisodesManager from './episodes/episodes-manager.vue'
import AssetsPodcasts from '../assets/postcasts/AssetsPodcasts.vue'
import Editors from '../../share/Editors.vue'
import AdConfigurationForm from '../../share/AdConfigurationForm.vue'
import ItunesCategories from './itunes-categories.vue'
import AudioSettings from './audio-settings.vue'
import upload from '../../share/UploadImage.vue'
import axios from 'axios'

export default {
  name: 'PodcastsEdit',
  components: {
    upload,
    AudioSettings,
    ItunesCategories,
    AdConfigurationForm,
    Editors, AssetsPodcasts, EpisodesManager, PodcastStats},
  data () {
    return {
      podcastId: '',
      podcast: {},
      stats: {
        draft: 0,
        encoding: 0,
        failed: 0,
        scheduled: 0,
        published: 0
      },
      reloadEpisodes: false,
      reloadInterval: null,
      languages: [
        {code: 'en', label: 'English'},
        {code: 'es', label: 'Spanish'}
      ],
      platforms: {
        android: {
          label: 'Android - 1296x560px',
          description: 'This will be used on the podcasts section of LaMusica app/web',
          platform: 'android',
          isLoading: false,
          width: 1296,
          height: 560,
          url: ''
        },
        ios: {
          label: 'iOS - 1092x432px',
          description: 'This will be used on the podcasts section of LaMusica app/web',
          platform: 'ios',
          isLoading: false,
          width: 1092,
          height: 432,
          url: ''
        },
        exploreSquare: {
          label: 'Detail - 1440x1440px',
          platform: 'exploreSquare',
          description: 'This will be used on the detail view (app/web) and homepage card rows (app)',
          isLoading: false,
          width: 1440,
          height: 1440,
          url: ''
        },
        card: {
          label: 'Card - 1000x1100px',
          platform: 'card',
          description: 'This will be used on the featured card row (app)',
          isLoading: false,
          width: 1000,
          height: 1100,
          url: ''
        },
        landing: {
          label: 'Featured Header - 1920x850',
          platform: 'landing',
          description: 'This will be used on the tv and homepage website rotators',
          isLoading: false,
          width: 1920,
          height: 850,
          url: ''
        }
      },
      placeholderImage: {
        label: 'Placeholder - 800x800px',
        description: 'This will overwrite all episodes images across LaMusica App.',
        isLoading: false,
        width: 800,
        height: 800,
        url: ''
      },
      megaphoneImage: {
        label: 'Megaphone Image - 1440x1440px',
        description: 'This will be uploaded to Megaphone platform. This image will be used by Megaphone and all podcast platforms on each episode that does no have a specific image',
        isLoading: false,
        width: 1440,
        height: 1440,
        url: ''
      },
      notification:{
        title:{
          en:'',
          es:''
        },
        description:{
          en:'',
          es:''
        },
        lastNotified:''
      }
    }
  },
  mounted () {
    this.podcastId = this.$route.params.id
    this.getPodcast()
    this.getStats()

    this.reloadInterval = setInterval(() => {
      this.getReloadStatus()
    }, 5000)
  },
  beforeDestroy () {
    if(this.reloadInterval) {
      clearInterval(this.reloadInterval)
    }
  },
  methods: {
    sendNotification(){
      if(this.validateNotifications()){
        const notify = confirm('Are you sure you want to send notification to all subscribers?')
        if(notify){
          this.sendUpdateNotification()
        }
      }
    },
    validateNotifications(){
      if(!this.notification.title.en || this.notification.title.en ===''
      && !this.notification.description.en || this.notification.description.en ===''){
        return this.$notify({
                group: 'notifications',
                title: 'Error',
                text: 'All notification fields are required to send a notification',
                type: 'error'
              })
      }
      return true
    },
    async sendUpdateNotification(){
      const onesignalSegment = `(Mixpanel) podcast_${this.podcastId}`
      const url = `${this.$http.url}/notifications/segment?oss=${onesignalSegment}`
      const podcastSlug = this.podcast.meta.slug
      const inputBody = {
        slug : podcastSlug,
        ...this.notification,
      }
      const response = await axios.post(url,inputBody)
      console.log('sun',response);

      if(response.data.success){
        this.$notify({
          group: 'notifications',
          title: response.data.message,
          text: '',
          type: 'success'
        })
      }else{
        this.$notify({
          group: 'notifications',
          title: response.data.message,
          text: '',
          type: 'error'
        })
      }

      // this.showNotification(response)
    },
    validateCheckBox (object) {
      if(object.btn===0 && object.value){
        this.podcast.meta.isSponsored = false
      }else if(object.btn===1 && object.value) {
        this.podcast.meta.hasCompanionAdPreroll = false
      }
    },
    getPodcast () {
      this.$http.get(`/podcasts/${this.podcastId}`).then((response) => {
        this.podcast = response.data
        const meta = this.podcast.meta

        this.platforms.android.url = (meta.exploreImagery && meta.exploreImagery.xxxhdpiImageURL) ? meta.exploreImagery.xxxhdpiImageURL : ''
        this.platforms.ios.url = (meta.exploreImagery && meta.exploreImagery['3xImageURL']) ? meta.exploreImagery['3xImageURL'] : ''
        this.platforms.exploreSquare.url = (meta.detailViewImagery && meta.detailViewImagery.xxxhdpiImageURL) ? meta.detailViewImagery.xxxhdpiImageURL : ''
        this.platforms.card.url = (meta.cardImagery && meta.cardImagery['3xImageURL']) ? meta.cardImagery['3xImageURL'] : ''
        this.platforms.landing.url = (meta.landing && meta.landing['3xImageURL']) ? meta.landing['3xImageURL'] : ''
        this.megaphoneImage.url = meta.megaphoneImage
        this.placeholderImage.url = meta.placeholderImage

        if (!this.podcast.meta.wideOrbitOnDemand) {
          this.podcast.meta.wideOrbitOnDemand = {}
        }

        if(!this.podcast.meta.web_ads){
          this.podcast.meta.web_ads = {
            '320x100': [{
              tag: '',
              disable:false,
              start: null,
              end: null
            }],
            '300x250': [{
              tag: '',
              disable:false,
              start: null,
              end: null
            }],
            '728x90': [{
              tag: '',
              disable:false,
              start: null,
              end: null
            }],
            '728x180': [{
              tag: '',
              disable:false,
              start: null,
              end: null
            }],
          }
        }

        if (!this.podcast.meta.web_ads['320x100']) {
          this.podcast.meta['web_ads']['320x100'] = [{
            tag: '',
            disable:false,
            start: null,
            end: null
          }]
        }
        if (!this.podcast.meta.web_ads['728x180']) {
          this.podcast.meta['web_ads']['728x180'] = [{
            tag: '',
            disable:false,
            start: null,
            end: null
          }]
        }

        if (!this.podcast.meta.web_ads['468x60']) {
          this.podcast.meta['web_ads']['468x60'] = [{
            tag: '',
            disable:false,
            start: null,
            end: null
          }]
        }

        if(!this.podcast.meta.categories) {
          this.podcast.meta.categories = []
        }

        if(!this.podcast.meta.audio_settings) {
          this.podcast.meta.audio_settings = {
            intro: {file_name: '', file_url: ''},
            outro: {file_name: '', file_url: ''}
          }
        }

        if(this.podcast.meta.notification){
           this.notification = this.podcast.meta.notification
        }

      })
    },

    resolveHeaderImage() {
      if(this.podcast.meta && this.podcast.meta.megaphoneImage) {
        return this.podcast.meta.megaphoneImage
      } else {
        if(this.podcast.meta && this.podcast.meta.placeholderImage) {
          return this.podcast.meta.placeholderImage
        } else if (this.podcast.meta && this.podcast.meta.detailViewImagery && this.podcast.meta.detailViewImagery.xxhdpiImageURL) {
          return this.podcast.meta.detailViewImagery.xxhdpiImageURL
        }
      }

      return '/static/img/LaMusica-Icon-playlist-placeholder.jpg'
    },

    getStats () {
      this.$http.get(`/podcasts/${this.$route.params.id}/stats`)
        .then( (response)=> {
          this.stats = response.data
        })
        .catch( function (err) {console.log(err);} )
    },

    getReloadStatus() {
      this.$http.get(`/podcasts/${this.$route.params.id}/reload-status`)
        .then( (response)=> {
          if(response.success) {
            this.reloadEpisodes = response.data.reload_episodes === true
            this.getStats()
          } else {
            return this.$notify({
              group: 'notifications',
              title: 'Error',
              text: response.message,
              type: 'error'
            })
          }
        })
        .catch( function (err) {console.log(err);} )
    },

    onEpisodesUpdated() {
      this.getStats()
    },

    save () {
      if (this.podcast.title === '') {
        this.$notify({
          group: 'notifications',
          title: 'Missing podcast Title :(',
          text: '',
          type: 'error'
        })
        return
      }

      if (this.podcast.meta.titleEnglish === '') {
        this.$notify({
          group: 'notifications',
          title: 'Missing podcast English Title :(',
          text: '',
          type: 'error'
        })
        return
      }

      if (this.podcast.meta.titleSpanish === '') {
        this.$notify({
          group: 'notifications',
          title: 'Missing podcast Spanish Title :(',
          text: '',
          type: 'error'
        })
        return
      }

      if (this.podcast.meta.descriptionEnglish === '') {
        this.$notify({
          group: 'notifications',
          title: 'Missing podcast English Description :(',
          text: '',
          type: 'error'
        })
        return
      }

      if (this.podcast.meta.descriptionSpanish === '') {
        this.$notify({
          group: 'notifications',
          title: 'Missing podcast Spanish Description :(',
          text: '',
          type: 'error'
        })
        return
      }

      if (this.podcast.meta.slug === '') {
        this.$notify({
          group: 'notifications',
          title: 'Missing podcast slug :(',
          text: '',
          type: 'error',
          duration: -1
        })
        return
      }

      if(this.podcast.meta.categories.length === 0) {
        this.$notify({
          group: 'notifications',
          title: 'Missing podcast categories :(',
          text: '',
          type: 'error',
          duration: -1
        })
        return
      }

      if(!this.podcast.meta.language) {
        this.$notify({
          group: 'notifications',
          title: 'Missing podcast language :(',
          text: '',
          type: 'error',
          duration: -1
        })
        return
      }

      this.podcast.meta.notification = this.notification

      EventHandler.emit('isLoading', true)

      this.savePodcast()
        .then(async (res) => {
          if(!res.success) {
            EventHandler.emit('isLoading', false)
            return this.$notify({
              group: 'notifications',
              title: 'Error',
              text: res.message,
              type: 'error',
              duration: -1
            })
          }

          if (!this.podcast.published && ((this.stats.published === 0) || (!this.podcast.meta.slug || this.podcast.meta.slug === ''))) {
            this.$notify({
              group: 'notifications',
              title: 'Podcast updated but NOT published. Missing Audio file or Slug meta',
              text: '',
              type: 'warning',
              duration: -1
            })
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Podcast updated successfully :)',
              text: '',
              type: 'success'
            })
          }
        })
        .catch((err) => {
          console.log(err)

          this.$notify({
            group: 'notifications',
            title: 'Something went wrong when we tried to save the podcast.  Please reopen this page and try again.',
            text: '',
            type: 'error',
            duration: -1
          })
        })
        .finally(() => {
          EventHandler.emit('isLoading', false)
        })
    },

    savePodcast () {
      if (this.stats.published === 0) {
        this.podcast.published = false
      }

      if (!this.podcast.meta.slug || this.podcast.meta.slug === '') {
        this.podcast.published = false
      }

      let podcast = JSON.parse(JSON.stringify(this.podcast))

      return this.$http.put('/podcasts', { podcast, isUpdate: true })
    },
    onAudioSettingsSelected(setting, $event) {
      this.$nextTick(() => {
        this.$set(this.podcast.meta.audio_settings, setting, $event)
      })
    },
    imgUploaded (formData, data) {
      this.image = formData
      this.platforms[data.platform].isLoading = true
      this.$http.post(`/common/image?resize=${data.platform}`,
        this.image,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then((res) => {
          this.platforms[res.data.device]['url'] = res.data.images[0]
          switch (res.data.device) {
            case 'android':
              this.podcast.meta.exploreImagery.xxxhdpiImageURL = res.data.images[0]
              this.podcast.meta.exploreImagery.xxhdpiImageURL = res.data.images[1]
              this.podcast.meta.exploreImagery.xhdpiImageURL = res.data.images[2]
              this.podcast.meta.exploreImagery.hdpiImageURL = res.data.images[3]
              this.podcast.meta.exploreImagery.mdpiImageURL = res.data.images[4]
              break
            case 'ios':
              this.podcast.meta.exploreImagery['3xImageURL'] = res.data.images[0]
              this.podcast.meta.exploreImagery['2xImageURL'] = res.data.images[1]
              break
            case 'exploreSquare':
              this.podcast.meta.detailViewImagery.xxxhdpiImageURL = res.data.images[0]
              this.podcast.meta.detailViewImagery.xxhdpiImageURL = res.data.images[1]
              this.podcast.meta.detailViewImagery.xhdpiImageURL = res.data.images[2]
              this.podcast.meta.detailViewImagery.hdpiImageURL = res.data.images[3]
              this.podcast.meta.detailViewImagery.mdpiImageURL = res.data.images[4]
              this.podcast.meta.detailViewImagery['3xImageURL'] = res.data.images[5]
              this.podcast.meta.detailViewImagery['2xImageURL'] = res.data.images[6]
              break
            case 'card':
              this.podcast.meta.cardImagery['3xImageURL'] = res.data.images[0]
              this.podcast.meta.cardImagery['2xImageURL'] = res.data.images[1]
              break
            case 'landing':
              this.podcast.meta.landing = {}
              this.podcast.meta.landing['3xImageURL'] = res.data.images[0]
              this.podcast.meta.landing['2xImageURL'] = res.data.images[1]
              this.podcast.meta.landing['1xImageURL'] = res.data.images[2]
              break
          }

          this.platforms[res.data.device].isLoading = false
          // data.isLoading = false
        })
        .catch((e) => {
          console.log('FAILURE!!', e)
        })
    },
    overwriteEpisodeImages (formData, data) {
      EventHandler.emit('isLoading', true)
      const image = formData
      this.$http.post('/podcasts/images',
        image,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            EventHandler.emit('uploadPercentage', this.uploadPercentage)
          }
        })
        .then((res) => {
          this.placeholderImage.url = res.data.url
          this.podcast.meta.placeholderImage = res.data.url
          const imageID = res.data.object_id
          return this.$http.put(`/podcasts/${this.podcast._id}/episodes`, { image: imageID })
        })
        .then((res) => {
          this.$refs.modalImagesPodcast.hide()
          EventHandler.emit('isLoading', false)
          this.reloadEpisodes = true
        })
        .catch((err) => {
          console.log(err)
          EventHandler.emit('isLoading', false)
        })
    },
    overwriteMegaphoneImages (formData, data) {
      EventHandler.emit('isLoading', true)
      const image = formData
      this.$http.post('/podcasts/images',
        image,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            EventHandler.emit('uploadPercentage', this.uploadPercentage)
          }
        })
        .then((res) => {
          this.megaphoneImage.url = res.data.url
          this.podcast.meta.megaphoneImage = res.data.url
          EventHandler.emit('isLoading', false)
        })
        .catch((err) => {
          console.log(err)
          EventHandler.emit('isLoading', false)
        })
    },
  }
}
</script>
<style scoped lang="scss">
  .podcast-edit-component {
    hr {
      margin: .5rem 0;
    }

    .controls-wrapper{
      ul {
        max-width: 1045px;
        margin-bottom: 0 !important;
      }
    }

    .add-podcast{
      width: 150px;
      line-height: 45px;
      text-align: center;
      background: #f2494a;
      margin-left: 15px;
      color: #fff;
      text-transform: uppercase;
      cursor: pointer;
      float: right;
    }

    .podcast-wrapper {
      display: flex;
      justify-content: center;
      column-gap: 15px;

      .image-wrapper {
        flex: 0 0 250px;

        img {
          width: 100%;
          height: auto;
          border-radius: 5px;
          margin: auto;
        }
      }

      .content-wrapper {
        flex: 0 0 calc(100% - 265px);

        .description {
          height: 125px;
          overflow-x: hidden;
          overflow-y: auto;
        }
      }
    }
  }

  .input-textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #eaeaea;
  }
.modal-switch{
  background: #fff;
  font-size: 14px;
  margin: 0px;
  padding: 10px;
  border-right: 1px solid #eaeaea;
  color: #2c3e50;
}
</style>
