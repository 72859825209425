
<template>
  <div class="editors-wrapper" v-if="!editorIsCurrentUser && hasLoaded">
    <div class="editor-text">
      <div class="alert alert-danger">
        Sorry <strong>{{ currentEditor.email }}</strong> is currently editing this content.<strong>You cannot edit this content until this user leaves.</strong>  Please contact this user.
      </div>
      <br/>
      <table class="table table-bordered table-striped">
        <tbody>
        <tr>
          <th>Opened</th>
          <td>
            {{currentEditorOpenedTime}}
          </td>
        </tr>
        <tr>
          <th>Opened Ago</th>
          <td>
            {{currentEditorOpenedTimeAgo}}
          </td>
        </tr>
        <tr>
          <th>User Agent</th>
          <td>{{currentEditor.user_agent}}</td>
        </tr>
        <tr>
          <th>IP</th>
          <td>{{currentEditor.request_ip}}</td>
        </tr>
        </tbody>
      </table>
      <button class="btn btn-override text-center" v-if="is_admin" @click="forceOverride">Force Override</button>
    </div>
  </div>
</template>


<script>
import {DateTime as LuxonDateTime} from "luxon";

export default {
  name: 'Editors',
  props: {
    resource: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      is_admin: null,
      activeUserEmail: null,
      hasLoaded: false,
      currentEditor: {
        email: '',
        request_ip: '',
        user_agent: '',
        _id: ''
      },
      beforeUnloadListener: (event) => {
        this.deleteCurrentlyEditingUser().then(console.log)
        event.preventDefault();
        event.returnValue = '';
      }
    }
  },
  computed: {
    editorIsCurrentUser () {
      return this.currentEditor.email === this.activeUserEmail
    },
    currentEditorOpenedTime () {
      if (!this.currentEditor._id) {
        return ''
      }
      return this.objectIDToDate(this.currentEditor._id)
    },
    currentEditorOpenedTimeAgo () {
      if (!this.currentEditor._id) {
        return ''
      }

      return LuxonDateTime.fromJSDate(this.objectIDToDate(this.currentEditor._id)).toRelative()
    }
  },
  async mounted () {
    window.addEventListener('beforeunload', this.beforeUnloadListener)

    try {
      this.activeUserEmail = localStorage.getItem('user')
      const userPermissions = localStorage.getItem('permissions').split(',')
      this.is_admin = userPermissions.indexOf('users') !== -1

      const editorResponse = await this.$http.get('/editors', { params: { resource: this.resource } })
      const hasEditors = Object.keys(editorResponse.data).length
      if (hasEditors) {
        this.currentEditor = editorResponse.data
      } else {
        await this.$http.post('/editors', { email: this.activeUserEmail, resource: this.resource })
        this.currentEditor.email = this.activeUserEmail
        this.currentEditor.resource = this.resource
      }
      this.hasLoaded = true
    } catch (err) {
      console.log(err)
    }
  },
  async beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeUnloadListener)
    const editorResponse = await this.$http.get('/editors', { params: { resource: this.resource } })
    if (editorResponse && editorResponse.data && editorResponse.data.email === this.activeUserEmail) {
      await this.deleteCurrentlyEditingUser()
    }
  },
  methods: {
    async deleteCurrentlyEditingUser () {
      if (this.editorIsCurrentUser) {
        await this.$http.delete(`/editors/${this.resource}`)
        this.currentEditor.email = null
        this.isEditing = false
      }
    },
    objectIDToDate ($oid) {
      return new Date(parseInt($oid.substring(0, 8), 16) * 1000)
    },
    async forceOverride () {
      await this.$http.delete(`/editors/${this.resource}`)
      window.location.reload()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .editors-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 200px;
    right: 0;
    background-color: #fff;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-override {
    width: 100%;
    padding: 14px 20px;
    text-align: center;
    background: #f2494a;
    color: #fff;
    text-transform: uppercase;
    border: none;
    border-radius: 6px;
    font-weight: bold;
  }

  @media (max-width: 1199px) {
    .editors-wrapper {
      left: 0;
    }
  }
</style>
