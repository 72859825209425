<template>
  <div>
    <div class="loading" v-if="loading">
      <div class="content-wrapper">
        <div class="progress-status" :class="{ complete: uploadPercentage === 100 }">
          {{loadingText}}
        </div>
        <LoaderInline/>
      </div>
    </div>

    <form class="bootstrap-form">
      <b-form-file
        placeholder="Choose a song file(s)"
        v-model="songFiles"
        @change.native="uploadSongFiles"
        accept=".mp3, .m4a"
        :multiple="true"
        :directory="true"
      >
      </b-form-file>
    </form>
    <hr class="divider"/>
    <div class="list-items">
      <v-jstree :data="songs" show-checkbox multiple allow-batch whole-row @item-click="handleNodeClick" ref="tree"></v-jstree>
      <b-button block @click="loadMore" v-if="loadMoreVisible">Load More</b-button>
    </div>

    <b-modal id="confirmEncodeSong" ref="confirmEncodeSong" size="md" title="Start Encoding Process"
             :hide-header-close="true"
             :no-close-on-backdrop="true"
             :no-close-on-esc="true"
             @ok="handleEncode()"
             @cancel="currentSongFile = null">
      <div class="row modal-wrapper">
        <div class="col-12" v-if="currentSongFile">
          <div>Confirm start the encoding process for this media file:</div>
          <div class="confirm-filename" v-b-tooltip :title="currentSongFile.file_name"><strong> {{currentSongFile.file_name}}</strong></div>
        </div>
      </div>
    </b-modal>

  </div>
</template>
<script>
import VJstree from 'vue-jstree'
import LoaderInline from '../../../share/LoaderInline'

export default {
  name: 'AssetsSongsModal',
  components: {VJstree, LoaderInline},
  data () {
    return {
      songs: [],
      endpointUrl: `${process.env.VUE_APP_TRANSCODING_API}/songs`,
      songUploadFormData: null,
      uploadPercentage: 0,
      loadingText: '',
      loading: false,
      pagination: {
        limit: 10,
        offset: 0
      },
      loadMoreVisible: false,
      songFiles: null,
      currentSongFile: null,
      selectedSongs: []
    }
  },
  mounted () {
    this.search()
  },
  methods: {
    search () {
      this.loading = true
      this.loadingText = 'Retrieving media files'
      this.$http.get(`${this.endpointUrl}/uploads?offset=${this.pagination.offset}&limit=${this.pagination.limit}`)
        .then((response) => {
          this.loading = false
          this.loadingText = ''

          if (response.success) {
            const processedItems = response.data.items.map((node) => {
              return this.processNode(node)
            })
            this.songs = [...this.songs, ...processedItems]
            this.loadMoreVisible = response.data.hasMore
          } else {
            this.loadMoreVisible = false
            this.$notify({
              group: 'notifications',
              title: 'Error',
              text: response.message,
              type: 'error'
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          this.loadingText = ''
          this.songs = []
        })
    },
    loadMore () {
      this.pagination.offset += this.pagination.limit
      this.search()
    },
    uploadSongFiles (event) {
      const input = event.target
      const hasFiles = input.files && input.files.length
      if (!hasFiles) {
        return
      }

      const files = this.prepareFilesToUpload(input.files)

      if(!Object.keys(files).length) {
        this.songFiles = null

        return this.$notify({
          group: 'notifications',
          title: 'Error',
          text: 'No valid files selected :(',
          type: 'error'
        })
      }

      console.log(files)

      this.songUploadFormData = new FormData()

      for(const key of Object.keys(files)) {
        for(const i in files[key] ) {
          this.songUploadFormData.append(`${key}[]`, files[key][i])
        }
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
          this.loadingText = `Uploading File - ${this.uploadPercentage} %`
        }
      }

      this.loading = true

      this.$http.post(`${this.endpointUrl}/uploads`, this.songUploadFormData, config)
        .then((res) => {
          this.uploadPercentage = 0
          this.loading = false
          this.loadingText = ''
          this.songFiles = null

          if (!res.success) {
            return this.$notify({
              group: 'notifications',
              title: 'Error',
              text: `${res.message} :(`,
              type: 'error'
            })
          }

          this.$notify({
            group: 'notifications',
            title: 'Success',
            text: 'Media uploaded successfully, select your file below :)',
            type: 'success'
          })

          this.songs.unshift(res.data)
        })
        .catch((err) => {
          this.uploadPercentage = 0
          this.loading = false
          this.loadingText = ''
          this.songFiles = null

          if (err) {
            this.$notify({
              group: 'notifications',
              title: 'Error',
              text: 'There was an error uploading this media file',
              type: 'error'
            })
          }
        })
    },
    prepareFilesToUpload(files) {
      const result = {}

      for(const file of files) {
        const isValidFilename = /\.(mp3|m4a)$/i.test(file.name)

        if(isValidFilename) {
          const relativePath = file.webkitRelativePath
          const index = relativePath ? relativePath.replace(`/${file.name}`, '') : 0
          result[index] = [...(result[index] || []), file]
        }
      }

      return result
    },
    processNode(item) {
      const node = {}
      node.text = item.name || item.file_name
      node.value = (item.parent ? item.parent + '/' : '') + node.text
      node.opened = false
      node.selected = false
      node.type = item.type

      if(node.type == 'dir') {
        node.icon = 'fas fa-folder'
        node.children = item.items.map((innerItem) => {
          return this.processNode(innerItem)
        })
      } else {
        node.icon = 'fas fa-file-audio'
      }

      return node
    },
    handleNodeClick() {
      this.selectedSongs = []
      this.processNodes(this.songs)
      this.$emit('nodeSelected', this.selectedSongs)
    },
    processNodes (nodes) {
      for(const node of nodes) {
        if(node.type == 'file') {
          if(node.selected) {
            this.selectedSongs = [...new Set([...this.selectedSongs, ...[node.value]])]
          }
        } else {
          this.processNodes(node.children)
        }
      }
    },
    showEncodeConfirmation(file){
      this.currentSongFile = file
      this.$refs.confirmEncodeSong.show()
    },
    handleEncode () {
      this.$http.post(`${this.endpointUrl}`, {
        fileName: this.currentSongFile.file_name
      })
        .then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Success',
              text: res.message,
              type: 'success'
            })
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Error',
              text: res.message,
              type: 'error'
            })
          }
        })
        .catch((err) => {
          if (err) {
            this.$notify({
              group: 'notifications',
              title: 'Error',
              text: 'There was an error encoding this media file',
              type: 'error'
            })
          }
        })
    },
  }
}
</script>
<style scoped lang="scss">
.list-items {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 -15px -15px;
  padding: 15px 15px 0;
}

hr {
  &.divider {
    margin: 1em -15px 0;
  }
}

.controls-wrapper {
  margin-top: 15px;

  ul {
    margin-bottom: 0 !important;

    li {
      &.active {
        background-color: #f2494a;
        color: #fff;
      }
    }
  }
}

.confirm-filename {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.loading {
  position: absolute;
  z-index: 100;
  left: 0;
  top: -63px;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255, .5);

  .content-wrapper {
    position: absolute;
    width: 300px;
    height: 150px;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -125px;
    border: 1px solid #777;
    background: #fff;
    box-shadow: 0px 5px 10px rgba(0,0,0, .3);

    .progress-status {
      text-align: center;
      margin-top: 20px;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }
}
</style>
