<template>
  <div>
    <div class="page-header">
      <div class="typeahead-wrapper">
        <b-button class="add-btn" size="sm" v-b-modal="'create-rule-modal'">Create An Ad</b-button>
      </div>
    </div>

    <div class="page-content">
      <div v-if="ads.length>0">
        <div v-for="[eachAdIdx,eachAd] of ads.entries()" :key="eachAdIdx">
          <div class="d-flex flex-row card-body" @click="editRule(eachAdIdx)">
            <b-img class="ad-image p-2" fluid :src="eachAd.image" />
            <div class="d-flex flex-column flex-grow-1 p-2 card-clickable">
              <h5> {{ getDmaName(eachAd.dmaId) }}</h5>
              <p>Start Date: {{ formatDate(eachAd.start) }}</p>
              <p>End Date: {{ formatDate(eachAd.end) }}</p>
              <p>trackerUrl: {{ eachAd.pixelTrackerUrl }}</p>
              <p>enabled: {{ eachAd.enabled }}</p>
            </div>
            <div class="">
              <i class="fa fa-trash fa-lg p-2 icon-clickable" @click.stop="deleteRule(eachAdIdx)" style="color: red;"></i>
            </div>
          </div>
          <hr>
        </div>
      </div>
    </div>

    <b-modal id="create-rule-modal" hide-footer size="lg">
      <AdRuleItem :rule="selectedRule" :dma-list="dmaList"/>
    </b-modal>
  </div>
</template>

<script>
import AdRuleItem from './AdRuleItem';
import axios from 'axios'

export default {
  name: 'AdRules',
  components: {
    AdRuleItem
  },
  async mounted() {
    await this.getLocations()
    await this.getAds()
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId==='create-rule-modal'){
        this.selectedRule= null
        this.getAds()
      }
    })

  },
  data () {
    return {
      ads: [],
      dmaList:[],
      selectedRule:null
    }
  },
  methods:{
    formatDate(date){
      return new Date(date).toLocaleDateString()
    },
    getDmaName(dmaId){
      if(dmaId==='all'){
        return 'Run of Network'
      }
      const dma =this.dmaList.find((e)=> e._id === dmaId)
      return dma.regionName
    },
    async getLocations(){
      const url = `${this.$http.url}/videos/locations`
      const locations = await axios.get(url)
      this.dmaList = locations.data.data
    },
    editRule(ruleIdx){
      this.selectedRule = this.ads[ruleIdx]
      this.$bvModal.show('create-rule-modal')
    },
    async deleteRule(ruleIdx){
      const ruleId = this.ads[ruleIdx]._id
      if(confirm('Do you want to delete the ad?')){
        let url = `${this.$http.url}/videos/ads/${ruleId}`
        const res = await axios.delete(url)
        if(res.data.success){
          this.getAds()
        }
      }
    },
    async getAds(){
      const url = `${this.$http.url}/videos/ads`
      const ads = await axios.get(url)
      this.ads = ads.data.data
    }
  }
}
</script>

<style scoped>
.card-body{
  background: white;
}

.card-clickable{
  cursor: pointer;
}
.icon-clickable{
  cursor: pointer;
  width: 42px;
  height: 42px;
}
.ad-image{
  cursor: pointer;
  background: silver;
}
</style>