<template>
  <div v-if="transformedRow && transformedRow.type && transformedRow.type != 'none'" style="margin-bottom: 20px">
    <div class="preview-row-title">{{ transformedRow.title.en || transformedRow.title.es}}</div>
    <div class="preview-row-content">
      <row-preview-cards v-if="transformedRow.type === 'cards'" :row="transformedRow"></row-preview-cards>
      <row-preview-grid v-else-if="transformedRow.type === 'card' || transformedRow.type === 'grid'" :row="transformedRow"></row-preview-grid>
      <row-preview-carousel v-else-if="transformedRow.type === 'circular' || transformedRow.type === 'carousel'" :row="transformedRow"></row-preview-carousel>
      <row-preview-ads v-else-if="transformedRow.type === 'ads'" :row="transformedRow"></row-preview-ads>
    </div>
  </div>
</template>
<script>
import RowPreviewCards from './RowPreviewCards'
import RowPreviewCarousel from './RowPreviewCarousel'
import RowPreviewAds from './RowPreviewAds'
import RowPreviewGrid from './RowPreviewGrid'

export default {
  name: 'RowPreview',
  components: {RowPreviewGrid, RowPreviewAds, RowPreviewCarousel, RowPreviewCards},
  props: ['row'],
  data () {
    return {
      transformedRow: null
    }
  },
  async mounted () {
    await this.transformRowData()
  },
  methods: {
    async transformRowData() {
      this.transformedRow = {
        type: this.row.type,
        displayTitles: this.row.displayTitles,
        title: {
          es: this.row.titleSpanish || '',
          en: this.row.titleEnglish || '',
        },
        subtitle: {
          es: this.row.subtitleSpanish || '',
          en: this.row.subtitleEnglish || '',
        },
        items_mobile_count: this.row.items_mobile_count,
        items_desktop_count: this.row.items_desktop_count,
        items: (this.row.automated && this.row.automated !== 'none') ? await this.getAutomatedRowItems(this.row.automated, this.row.image_type || 'square') : this.transformRegularRowItems(this.row.items, this.row.image_type || 'square')
      }

      if(this.row.type === 'ads'){
        this.transformedRow['bg_img'] = this.row.bg_img;
        this.transformedRow['overlay_img'] = this.row.overlay_img;
        this.transformedRow['url'] = this.row.url;
      }
    },
    async getAutomatedRowItems (type, imageType) {
      const response = await this.$http.post('/locations/automated_row', {
        type: type,
        imageType: imageType
      });
      return response.data || []
    },
    transformRegularRowItems (items, imageType) {
      return items.map((item) => {
        const cardImages = item.img[0]
        return {
          id: item._id,
          type: item.type,
          title: {
            en: item.titleEnglish,
            es: item.titleSpanish
          },
          desktop_image: imageType === 'square' ? cardImages['750x750'] : cardImages['1920x850_web'] || cardImages['1920x850'],
          mobile_image: cardImages['1000x1100'],
          path: null
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
  .preview-row-title {
    text-align: center;
    font-weight: 700;
  }
</style>
