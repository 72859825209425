import moment from 'moment-timezone'

export default {
  methods: {
    formatDate (date) {
      return moment(date).format('MMMM Do, YYYY')
    },
    formatTimestamp (timestamp) {
      const item = moment(timestamp)
      return `${item.format('ddd, MMM Do YYYY, h:mm a')} - ${item.fromNow()}`
    },
    toHHMMSS (seconds) {
      var date = new Date(1970, 0, 1)
      date.setSeconds(seconds)
      return date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1')
    },
    toSeconds (string) {
      return string.split(':').reduce((acc, time) => (60 * acc) + +time)
    },
    secondsToMinutes (secs, useDays = false) {
      let secNum = secs ? parseInt(secs, 10) : 0
      let days = 0

      if (useDays) {
        days = Math.floor(secNum / (3600 * 24))
      }

      let hours = Math.floor(secNum / 3600) % 24
      let minutes = Math.floor(secNum / 60) % 60
      let seconds = secNum % 60
      return ((days > 0) ? (days + ' days ') : '') + [hours, minutes, seconds]
        .map(v => v < 10 ? '0' + v : v)
        .filter((v, i) => v !== '00' || i > 0)
        .join(':')
    },
    formatDateWithHour (date) {
      return moment(date).format('MMMM Do, YYYY [at] h:mm a')
    }
  }
}
