<template>
  <div class="row tracks-list">
    <div class="col-12">
      <draggable class="tracks-list-draggable" :list="playlistList" :options="{group:'playlistList'}" element="ul" @start="drag=true" @end="onEnd">
        <li v-for="(playlist, index) in playlistList" v-bind:key="playlist._id">
          <div class="track-list-card">
            <div class="track-list-feature" v-if="playlist.playlist.meta.isFeatured">Featured</div>
            <div class="track-list-card-img" @click="navigate(playlist.playlist._id)">
                <img :src="playlist.playlist.meta.exploreImagery.hdpiImageURL" alt="" v-if="playlist.playlist.meta && playlist.playlist.meta.exploreImagery && playlist.playlist.meta.exploreImagery.hdpiImageURL" />
                <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!playlist.playlist.meta || !playlist.playlist.meta.exploreImagery || !playlist.playlist.meta.exploreImagery.hdpiImageURL"/>
            </div>

            <div class="track-list-card-wrapper" :class="{published: playlist.playlist.published}">
                <div class="track-list-card-title">
                  <span>{{playlist.order}}</span>
                  {{playlist.playlist.title}}
                </div>
                <div class="submenu-actions">
                  <div class="icon">
                    <i class="fas fa-ellipsis-h"></i>
                  </div>
                  <div class="actions">
                    <ul>
                      <li @click="playlist.playlist.published = !playlist.playlist.published; updateStatus(playlist.playlist)">{{playlist.playlist.published ? 'Unpublish' : 'Publish'}}</li>
                      <li @click="remove(index)">Delete</li>
                    </ul>
                  </div>
                </div>
                <!-- <label class="lamusica-switch">
                    <input type="checkbox" v-model="playlist.playlist.published" @change="updateStatus(playlist.playlist)">
                    <span class="lamusica-slider round"></span>
                </label> -->
            </div>
          </div>
        </li>
      </draggable>
    </div>
  </div>
</template>


<script>
import draggable from 'vuedraggable'

export default {
  name: 'playlists',
  components: {
    draggable
  },
  props: ['list'],
  data () {
    return {
    }
  },

  computed: {
    playlistList () {
      return this.list
    }
  },

  methods: {
    onEnd () {
      this.playlistList.forEach((playlist, index) => {
        playlist.order = index
      })
      this.$emit('onSave', this.playlistList)
    },
    updateStatus (playlist) {
      this.$emit('onUpdate', playlist)
    },
    remove (index) {
      this.$emit('onRemove', index)
    },
    navigate (id) {
      this.$emit('onNavigate', id)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .tracks-list ul.tracks-list-draggable{
    list-style-type: none;
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    justify-content: left;
  }

  .tracks-list ul.tracks-list-draggable > li{
    width: 24%;
    margin: 0.5%;
  }

  .tracks-list ul.tracks-list-draggable > li .track-list-card{
    background-color: #fff;
    border: 1px solid #eaeaea;
    position: relative;
  }

  .tracks-list ul.tracks-list-draggable > li .track-list-card .track-list-feature {
    background-color: rgba(242, 73, 74, 0.95);
    color: #fff;
    padding: 5px 10px;
    position: absolute;
    left: 0;
    width: 70px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 1.2;
}

  .tracks-list ul.tracks-list-draggable > li .track-list-card-wrapper{
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f2494a
  }

  .tracks-list ul.tracks-list-draggable > li .track-list-card-wrapper.published{
    border-bottom: 1px solid #8cd200
  }

  .tracks-list ul.tracks-list-draggable > li .track-list-card-title{
    font-size: 12px;
    font-weight: 900;
    text-transform: capitalize;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tracks-list ul.tracks-list-draggable > li .track-list-card-title span {
    background: #ccc;
    padding: 0 6px;
    color: #fff;
    border-radius: 5px;
    margin-right: 5px;
  }

  .tracks-list ul.tracks-list-draggable > li .track-list-card-img{
    cursor: pointer;
  }

  .tracks-list ul.tracks-list-draggable > li .track-list-card-img img{
    width: 100%
  }

</style>
