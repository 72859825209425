<template>
    <div class="categories-edit-component">
      <div class="row align-items-center header">
        <div class="col-3">
          <div class="title">
            <router-link to="/admin/categories">Categories </router-link>/
            <span v-if="category && category.title">{{category.title}}</span>
          </div>
        </div>
        <div class="col-4">
          <typeahead-categories @playlistSelected="selectPlaylist"></typeahead-categories>
        </div>
        <div class="col-5">
          <div class="typeahead-wrapper">
            <input class="name" type="text" placeholder="Category Title..." v-model="category.title"/>
            <div class="typeahead-btn" @click="save()">Update</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="controls-wrapper">
            <ul>
                <b-btn v-b-modal.modal3 class="add-playlist modal-btn">Add Meta</b-btn>
                <b-btn v-b-modal.modal4 class="add-playlist modal-btn">Add Images</b-btn>
                <li class="add-playlist" @click="addTop = !addTop">{{addTop ? 'Add bottom': 'Add top'}}</li>
                <li class="add-playlist" @click="category.published = !category.published">{{category.published ? 'Unpublish' : 'Publish'}}</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="subtitle-wrapper">
              <div class="subtitle" v-if="!category.playlists.length">No playlists added yet :(</div>
              <div class="subtitle" v-if="category.playlists.length">{{category.playlists.length}} Playlists Added</div>
          </div>
        </div>
      </div>

      <div class="categories-all-list">
        <playlist-list :list="playlists" @onSave="saveOrder" @onUpdate="updateStatus" @onNavigate="navigate" @onRemove="remove"></playlist-list>
      </div>

       <b-modal id="modal3" :ok-only="true" ok-title="close" size="lg" title="Metadata">
        <div class="modal-wrapper">
            <div class="row">
                <div class="col-6">
                    <input type="text" placeholder="English Title" v-model="category.titleEnglish"/>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Spanish Title" v-model="category.titleSpanish"/>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <input type="text" placeholder="Slug" v-model="category.slug"/>
                </div>
            </div>
        </div>
      </b-modal>

      <b-modal id="modal4" :ok-only="true" ok-title="close" size="lg" title="Images">
        <div class="row modal-wrapper">
          <div class="col-6" v-for="platform in platforms" v-bind:key="platform.platform">
            <upload @imgUploaded="imgUploaded" :data="platform"></upload>
          </div>
        </div>
      </b-modal>

    </div>
</template>


<script>
import typeaheadCategories from '../../share/TypeaheadCategories'
import PlaylistList from '../../share/PlaylistList'
import upload from '../../share/UploadImage'

export default {
  name: 'CategoryEdit',
  components: {
    typeaheadCategories,
    PlaylistList,
    upload
  },
  data () {
    return {
      category: {
        title: null,
        titleEnglish: null,
        titleSpanish: null,
        slug: null,
        type: [''],
        published: false,
        updatedAt: null,
        createdAt: null,
        order: 0,
        isFeatured: false,
        tags: [],
        exploreImagery: {
          '2xImageURL': null,
          '3xImageURL': null,
          mdpiImageURL: null,
          hdpiImageURL: null,
          xhdpiImageURL: null,
          xxhdpiImageURL: null,
          xxxhdpiImageURL: null
        },
        detailViewImagery: {
          '2xImageURL': null,
          '3xImageURL': null,
          mdpiImageURL: null,
          hdpiImageURL: null,
          xhdpiImageURL: null,
          xxhdpiImageURL: null,
          xxxhdpiImageURL: null
        },
        cardImagery: {
          '2xImageURL': null,
          '3xImageURL': null
        },
        iOSVideoInterstitialAdUnitId: null,
        iOSImageInterstitialAdUnitId: null,
        androidVideoInterstitialAdUnitId: null,
        androidImageInterstitialAdUnitId: null,
        playlists: []
      },
      playlists: [],
      searchList: [],
      categoryID: null,
      query: null,
      timeout: null,
      addTop: false,
      categoryTypes: [
        {value: 'mood', label: 'Mood/Activity'},
        {value: 'genre', label: 'Genre'},
        {value: 'featured', label: 'Featured'},
        {value: 'dj', label: 'Dj'}
      ],
      platforms: {
        android: {
          label: 'Android - 1296x560px',
          platform: 'android',
          isLoading: false,
          width: 1296,
          height: 560,
          url: ''
        },
        ios: {
          label: 'iOS - 1092x432px',
          platform: 'ios',
          isLoading: false,
          width: 1092,
          height: 432,
          url: ''
        },
        exploreSquare: {
          label: 'Detail - 1440x1440px',
          platform: 'exploreSquare',
          isLoading: false,
          width: 1440,
          height: 1440,
          url: ''
        },
        card: {
          label: 'Card - 1000x1100px',
          platform: 'card',
          isLoading: false,
          width: 1000,
          height: 1100,
          url: ''
        }
      }
    }
  },

  mounted () {
    this.categoryID = this.$route.params.id
    this.$http.get(`/categories/${this.categoryID}`)
      .then((res) => {
        this.category = res.data[0]
        this.playlists = res.data[0].playlists
        this.platforms.android.url = (this.category.exploreImagery && this.category.exploreImagery.xxxhdpiImageURL) ? this.category.exploreImagery.xxxhdpiImageURL : ''
        this.platforms.ios.url = (this.category.exploreImagery && this.category.exploreImagery['3xImageURL']) ? this.category.exploreImagery['3xImageURL'] : ''
        this.platforms.exploreSquare.url = (this.category.detailViewImagery && this.category.detailViewImagery.xxxhdpiImageURL) ? this.category.detailViewImagery.xxxhdpiImageURL : ''
        this.platforms.card.url = (this.category.cardImagery && this.category.cardImagery['3xImageURL']) ? this.category.cardImagery['3xImageURL'] : ''
      })
      .catch((err) => {
        console.log(err)
      })
  },

  methods: {
    saveOrder (list) {
      this.category.playlists = list

      this.$http.put(`/categories`, {category: this.category})
        .then((res) => {
          console.log(res.data)
          this.$notify({
            group: 'notifications',
            title: 'Playlist order updated :)',
            text: '',
            type: 'success'
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateStatus (playlist) {
      this.$http.put('/playlists', {playlist})
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: `Playlist ${playlist.title} updated :)`,
            text: '',
            type: 'success'
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    navigate (id) {
      this.searchList = []
      this.$router.push('/admin/playlists/edit/' + id)
    },
    imgUploaded (formData, data) {
      // TODO Validate image sizes to make sure the upload is correct
      this.image = formData
      data.isLoading = true
      this.$http.post(`/common/image?resize=${data.platform}`,
        this.image,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then((res) => {
          this.platforms[res.data.device]['url'] = res.data.images[0]
          if (res.data.device === 'android') {
            this.category.exploreImagery.xxxhdpiImageURL = res.data.images[0]
            this.category.exploreImagery.xxhdpiImageURL = res.data.images[1]
            this.category.exploreImagery.xhdpiImageURL = res.data.images[2]
            this.category.exploreImagery.hdpiImageURL = res.data.images[3]
            this.category.exploreImagery.mdpiImageURL = res.data.images[4]
          }
          if (res.data.device === 'ios') {
            this.category.exploreImagery['3xImageURL'] = res.data.images[0]
            this.category.exploreImagery['2xImageURL'] = res.data.images[1]
          }
          if (res.data.device === 'exploreSquare') {
            this.category.detailViewImagery.xxxhdpiImageURL = res.data.images[0]
            this.category.detailViewImagery.xxhdpiImageURL = res.data.images[1]
            this.category.detailViewImagery.xhdpiImageURL = res.data.images[2]
            this.category.detailViewImagery.hdpiImageURL = res.data.images[3]
            this.category.detailViewImagery.mdpiImageURL = res.data.images[4]
            this.category.detailViewImagery['3xImageURL'] = res.data.images[5]
            this.category.detailViewImagery['2xImageURL'] = res.data.images[6]
          }
          if (res.data.device === 'card') {
            this.category.cardImagery['3xImageURL'] = res.data.images[0]
            this.category.cardImagery['2xImageURL'] = res.data.images[1]
          }
          data.isLoading = false
        })
        .catch((e) => {
          console.log('FAILURE!!', e)
        })
    },
    remove (index) {
      this.playlists.splice(index, 1)
    },
    save () {
      this.category.playlists = this.playlists
      if (this.category.title === '') {
        this.$notify({
          group: 'notifications',
          title: 'Missing Category Title :(',
          text: '',
          type: 'error'
        })
        return
      }

      this.$http.put('/categories', {category: this.category})
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: 'Category updated successfully :)',
            text: '',
            type: 'success'
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    selectPlaylist (playlist) {
      if (!this.contains(this.playlists, playlist)) {
        let playlistToAdd = {
          order: 0,
          playlist: playlist
        }
        if (this.addTop) {
          this.playlists.forEach((element, index) => {
            this.playlists[index] = {
              order: index + 1,
              playlist: element.playlist
            }
          })
          console.log(this.playlists)
          this.playlists.unshift(playlistToAdd)
        } else {
          playlistToAdd.order = this.playlists.length
          this.playlists.push(playlistToAdd)
        }
      } else {
        this.$notify({
          group: 'notifications',
          title: 'Playlist already added to category',
          text: '',
          type: 'error'
        })
      }

      // console.log(this.playlists)
      EventHandler.emit('close-typeahead')
    },
    contains (arr, obj) {
      let findIt = false
      arr.forEach(function (element, index) {
        if (arr[index].playlist._id === obj._id) {
          findIt = true
        }
      })

      if (findIt) {
        return true
      }

      return false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.categories-edit-component{
  display: block;
  width: 100%;
}

.categories-edit-component .header{
  margin-bottom: 30px;
}

.categories-edit-component .header .title{
  margin-bottom: 0;
}

.categories-edit-component .header .controls-wrapper ul{
  margin-bottom: 0;
}

.categories-all-list {
  overflow-y: scroll;
  height: -moz-calc(100vh - 240px);
  height: -webkit-calc(100vh - 240px);
  height: calc(100vh - 240px);
  margin-bottom: 25px;
}

.categories-edit-component .subtitle-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.categories-edit-component .subtitle-wrapper .subtitle {
  text-transform: uppercase;
  margin-right: 0
}
</style>
