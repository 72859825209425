<template>
  <div class="tests-all-component">

    <div class="page-header">
      <div class="typeahead-wrapper">
        <div class="typeahead">
          <div class="title">One Signal Tests</div>
        </div>
        <div class="add-btn" @click="addNew">Add Test</div>
      </div>
    </div>

    <div class="page-content">
      <div class="tests-all-list">
        <transition-group tag="div" class="row" name="list-animation">
          <div class="col-md-6" v-for="(test, index) in tests" v-bind:key="test._id">
            <div class="tests-list-card">
              <div class="tests-list-card-img" @click="navigate(test._id)">
                <img src="/static/img/256x256.png" alt=""/>
              </div>
              <div class="tests-list-card-content">
                <div class="tests-list-card-title">
                  {{test.title}}
                </div>
                <div class="submenu-actions">
                  <div class="icon">
                    <i class="fas fa-ellipsis-h"></i>
                  </div>
                  <div class="actions">
                    <ul>
                      <li @click="navigate(test._id)">Edit</li>
                      <li @click="confirm(test, index)">Delete</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>

    <b-modal id="confirmRemoveTest" ref="confirmRemoveTest" size="md" title="Confirm" @ok="handleRemove(toDelete)">
      <div class="row modal-wrapper">
        <div class="col-12">
          Are you sure want to remove <strong>{{toDelete.test.title}}</strong> test?
        </div>
      </div>
    </b-modal>

    <b-modal id="addTest" ref="addTest" ok-title="Create" size="md" title="New Test" @ok="createTest">
      <div class="row modal-wrapper">
        <div class="col-12">
          <input type="text" placeholder="Add Test title" v-model="test.title"/>
        </div>
      </div>
    </b-modal>
  </div>
</template>


<script>

export default {
  name: 'OneSignalAll',
  data () {
    return {
      test: {
        title: '',
        createdAt: null,
        updatedAt: null,
        tests: [
          {
            id: 'wxdj',
            placeholder: 'http://app.lamusica.com/?simulcastStation=',
            type: 'radio',
            name: 'Radio Stations',
            title: 'Escucha El Zol 106.7FM',
            message: 'El líder en variedad',
            toAppOpen: true,
            device: {
              name: null,
              playerId: null
            }
          },
          {
            id: '56169d370ad0569a3a006c35',
            placeholder: 'http://app.lamusica.com/?genreStation=',
            type: 'genres',
            name: 'Playlist (Genres)',
            title: 'Reggaeton VIP',
            message: 'Tu destino para lo mas pegado en urbano',
            toAppOpen: true,
            device: {
              name: null,
              playerId: null
            }
          },
          {
            id: '56ec59e67f43676e6200025a',
            placeholder: 'http://app.lamusica.com/?moodActivityStation=',
            type: 'mood',
            name: 'Playlist (Mood)',
            title: 'New Music Friday',
            message: 'Escucha solo los nuevos lanzamientos',
            toAppOpen: true,
            device: {
              name: null,
              playerId: null
            }
          },
          {
            id: '5c9d4523076e070c96f843e1',
            placeholder: 'http://app.lamusica.com/?videoIdKey=',
            type: 'videos',
            name: 'Videos',
            title: 'Exclusiva: Yandel Estrena Su Álbum',
            message: 'Aquí en Los Reyes de la Punta',
            toAppOpen: true,
            device: {
              name: null,
              playerId: null
            }
          },
          {
            id: 'http://lamegafm.lamusica.com/los-reyes-de-la-punta-live',
            placeholder: null,
            type: 'web',
            name: 'Web Link',
            title: 'Los Reyes De la Punta',
            message: 'Puro gozadera y diversión con Molusco',
            toAppOpen: true,
            device: {
              name: null,
              playerId: null
            }
          },
          {
            id: '5c4798ed04d99f4422a7f0c8',
            placeholder: 'http://app.lamusica.com/?livestream=',
            type: 'livestream',
            name: 'Livestream',
            title: 'What\'s Up',
            message: 'Check out the hottest afternoon show in Puerto Rico',
            toAppOpen: true,
            device: {
              name: null,
              playerId: null
            }
          },
          {
            id: '5bc4bba2754f09c53223213d&djId=5b5760486275ae1587faf228',
            placeholder: 'http://app.lamusica.com/?djPlaylist=',
            type: 'djPlaylist',
            name: 'DJ Playlist',
            title: 'Nueva Entrevista Exclusiva',
            message: 'Escucha las historias de tus artistas favoritos',
            toAppOpen: true,
            device: {
              name: null,
              playerId: null
            }
          },
          {
            id: '5bc4bba2754f09c53223213d&podcast-episode=5e9f237282812cf282342c42&start=30',
            placeholder: 'http://app.lamusica.com/?podcast=',
            type: 'podcast-episode',
            name: 'Episode Time',
            title: 'Live Podcast - Ivy Queen desde Miami',
            message: 'Relajada, tranquila desde la cuarentena en casa y con la originalidad que la caracteriza, Ivy Queen se abre conmigo a tratar muchos temas de interés para sus fans. No te pierdas el momento en el que habla de una posible colaboración con...',
            toAppOpen: true,
            device: {
              name: null,
              playerId: null
            }
          }
        ]
      },
      tests: [],
      toDelete: {
        index: 0,
        test: ''
      },
      query: ''
    }
  },

  mounted () {
    this.$http.get('/tests/one-signal')
      .then((res) => {
        this.tests = res.data
      })
      .catch((err) => {
        console.log(err)
      })
  },

  methods: {
    addNew (id) {
      this.$refs.addTest.show()
    },
    createTest () {
      this.$http.post('/tests/one-signal', {test: this.test})
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: 'Test created successfully :)',
            text: '',
            type: 'success'
          })
          this.$router.push('/admin/tests/onesignal/edit/' + res.data.id)
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$notify({
              group: 'notifications',
              title: `${err.response.data} :(`,
              text: '',
              type: 'error'
            })
          } else {
            console.log(err.response)
          }
        })
    },
    confirm (test, index) {
      this.$refs.confirmRemoveTest.show()
      this.toDelete = {
        index: index,
        test: test
      }
    },
    handleRemove (toDelete) {
      this.$http.delete(`/tests/one-signal/${toDelete.test._id}`)
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: 'Test successfully removed :(',
            text: '',
            type: 'success'
          })
          this.tests.splice(toDelete.index, 1)
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$notify({
              group: 'notifications',
              title: `${err.response.data} :(`,
              text: '',
              type: 'error'
            })
          } else {
            console.log(err.response)
          }
        })
    },
    navigate (id) {
      this.$router.push('/admin/tests/onesignal/edit/' + id)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.tests-all-list {
  padding: 5px 0;
  margin-bottom: 25px;
}

.tests-list-card {
  background-color: #fff;
  margin-bottom: 15px;
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.tests-list-card .tests-list-card-img{
  cursor: pointer;
  width: 50px;
}

.tests-list-card .tests-list-card-img img {
  width: 100%;
}

.tests-list-card .tests-list-card-content {
  padding: 15px;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.tests-list-card .tests-list-card-title {
  font-size: 12px;
  font-weight: 900;
  text-transform: capitalize;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
