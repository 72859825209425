<template>
  <div class="livestream-new-component">
    <LivestreamForm
      :livestream="livestream"
      :submitButtonLabel="'Create'"
      :platforms="platforms"
      @handle-form-submit="createLivestream"/>
  </div>
</template>


<script>
import LivestreamForm from './LivestreamForm'
import platforms from './platforms'

export default {
  name: 'LivestreamsNew',
  components: {
    LivestreamForm
  },
  data () {
    return {
      livestream: {
        video_id: null,
        station: null,
        titleEnglish: null,
        titleSpanish: null,
        subtitleEnglish: null,
        subtitleSpanish: null,
        topicEnglish: null,
        topicSpanish: null,
        website_url: null,
        livestream_url: null,
        img: null,
        slug: null,
        square_img: null,
        large_img: null,
        placeholder_horizontal_img: null,
        live_now_small_img: null,
        images: {
          thumbnail: {},
          row: {},
          landing: {}
        },
        DFPPrerollVideoAdUnitId: null,
        SpotXPrerollVideoAdUnitId: null,
        chatRoomID: null,
        wowzaApplicationName: null,
        isSponsored: false,
        disableAds: false,
        published: false,
        is_restricted_to_pr: false,
        chatBannerAd:{
          tag: '',
          isDisabled: false
        },
        paywall: {
          has_paywall: false,
          price: 2000,
          product_description: {
            es: "",
            en: ""
          },
          number_of_permitted_ips: 2,
          available: {
            start: new Date(),
            end: new Date()
          },
          hide_web: false,
          hide_app: false,
          hide_connected_tv: false,
          age_gate: false
        },
        web_ads: {
          '300x250': [{
            tag: '',
            disable:false,
            start: null,
            end: null
          }],
          '728x90': [{
            tag: '',
            disable:false,
            start: null,
            end: null
          }]
        },
        podcast_id:'',
        location_id:'',
        source: null,
        mediastream_id:'',
        welcomeMessage:{
          en:'',
          es:''
        },
        activeDays: []
      },
      platforms: JSON.parse(JSON.stringify(platforms))
    }
  },
  mounted () {
  },
  methods: {
    createLivestream (event) {
      event.preventDefault()

      if (this.livestream.published) {
        // Set Image URLs for old posts
        if (this.livestream.images.thumbnail.xhdpiImageURL) {
          this.livestream.img = this.livestream.images.thumbnail.xhdpiImageURL
        }

        if (this.livestream.images.row['2xImageURL']) {
          this.livestream.square_img = this.livestream.images.row['2xImageURL']
        }

        if (this.livestream.images.landing['3xImageURL']) {
          this.livestream.large_img = this.livestream.images.landing['3xImageURL']
        }

        const imagesKeys = [
          'img',
          'large_img',
          'live_now_small_img',
          'square_img',
          'placeholder_horizontal_img'
        ]

        for (const key of imagesKeys) {
          if (!this.livestream[key]) {
            return this.$notify({
              group: 'notifications',
              title: 'All images are required in order to publish this livestream',
              text: '',
              type: 'error'
            })
          }
        }
      }

      this.$http.post('/livestreams', {livestream: this.livestream})
        .then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Livestream created successfully :)',
              text: '',
              type: 'success'
            })
            this.$router.push(`/admin/livestreams/edit/${res.data.id}`)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
