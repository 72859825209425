<template>
    <div class="playlists-all-component">

      <div class="page-header">
        <div class="typeahead-wrapper">
          <div class="typeahead">
            <input id="input" class="form-control" type="text" placeholder="Search Playlists" v-model="query"  @keyup.13="search">
            <div class="btn btn-search" @click="search">
              <i class="fas fa-search"></i>
            </div>
          </div>
          <router-link class="add-btn" to="/admin/playlists/new" tag="div">Add Playlist</router-link>
        </div>
      </div>

      <!-- <div class="title">Playlists</div> -->

      <div class="page-content">
        <div class="playlists-all-list">
          <transition-group tag="div" class="row" name="list-animation">
            <div class="col-md-3" v-for="(playlist, index) in playlists" v-bind:key="playlist._id">
              <div class="playlists-list-card">
                  <div class="playlist-feature" v-if="playlist.meta.isFeatured">Featured</div>
                  <div class="playlists-list-card-img" @click="navigate(playlist._id)">
                      <img :src="playlist.meta.exploreImagery.hdpiImageURL" alt="" v-if="playlist.meta && playlist.meta.exploreImagery && playlist.meta.exploreImagery.hdpiImageURL" />
                      <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!playlist.meta || !playlist.meta.exploreImagery || !playlist.meta.exploreImagery.hdpiImageURL"/>
                  </div>

                  <div class="playlists-list-card-content" :class="{published: playlist.published}">
                      <div class="playlists-list-card-title">
                        {{playlist.title}}
                      </div>
                      <div class="submenu-actions">
                        <div class="icon">
                          <i class="fas fa-ellipsis-h"></i>
                        </div>
                        <div class="actions">
                          <ul>
                            <li @click="duplicate(playlist)">Duplicate</li>
                            <li @click="playlist.published = !playlist.published; updateStatus(playlist)">{{playlist.published ? 'Unpublish' : 'Publish'}}</li>
                            <li @click="confirm(playlist, index)">Delete</li>
                          </ul>
                        </div>
                      </div>
                      <!-- <label class="lamusica-switch">
                          <input type="checkbox" v-model="playlist.published" @change="updateStatus(playlist)">
                          <span class="lamusica-slider round"></span>
                      </label> -->
                  </div>
              </div>
            </div>
          </transition-group>
        </div>

        <b-pagination v-if="pages > 1 && displayPaging" align="right" size="md" :total-rows="limit * pages" v-model="current" :per-page="limit" @change="getResults"></b-pagination>
      </div>

      <b-modal id="confirmRemovePlaylist" ref="confirmRemovePlaylist" size="md" title="Confirm" @ok="handleRemove(toDelete)">
        <div class="row modal-wrapper">
          <div class="col-12">
            Are you sure want to remove <strong>{{toDelete.playlist.title}}</strong> playlist?
          </div>
        </div>
      </b-modal>
    </div>
</template>


<script>
import typeaheadCategories from '../../share/TypeaheadCategories'

export default {
  name: 'PlaylistsAll',
  components: {
    typeaheadCategories
  },
  data () {
    return {
      timeout: null,
      searchList: [],
      playlists: [],
      limit: 24,
      pages: 1,
      current: 1,
      toDelete: {
        index: 0,
        playlist: ''
      },
      query: '',
      displayPaging: true
    }
  },

  mounted () {
    this.$http.get('/playlists', {params: {limit: this.limit, page: this.page}})
      .then((res) => {
        this.playlists = res.data.playlists
        this.pages = res.data.pages
        this.current = res.data.current
      })
      .catch((err) => {
        console.log(err)
      })
  },

  methods: {
    search () {
      if (this.query === '') {
        this.pages = 1
        this.$http.get('/playlists', {params: {limit: this.limit, page: this.pages}})
          .then((res) => {
            this.displayPaging = true
            this.playlists = res.data.playlists
            this.pages = res.data.pages
            this.current = res.data.current
          })
          .catch((err) => {
            console.log(err)
          })
        return
      }
      this.$http.get(`/playlists/search?q=${this.query}`)
        .then((res) => {
          this.playlists = res.data
          this.displayPaging = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    duplicate (playlist) {
      let playlistCopy = JSON.parse(JSON.stringify(playlist))
      playlistCopy.title = `${playlistCopy.title} - (copy)`
      playlistCopy.published = false
      delete playlistCopy._id

      this.$http.post('/playlists', {playlist: playlistCopy})
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: 'Playlist duplicated successfully :)',
            text: '',
            type: 'success'
          })
          this.$router.push('/admin/playlists/edit/' + res.data.id)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    confirm (playlist, index) {
      this.$refs.confirmRemovePlaylist.show()
      this.toDelete = {
        index: index,
        playlist: playlist
      }
    },
    handleRemove (toDelete) {
      this.$http.delete('/playlists', {params: {playlistID: toDelete.playlist._id}})
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: 'Playlist successfully removed :(',
            text: '',
            type: 'success'
          })
          this.playlists.splice(toDelete.index, 1)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    keymonitor (event) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (event.target.value === '') {
          this.searchList = []
          this.query = null
          return
        }
        this.query = event.target.value
        this.$http.get(`/playlists/search?q=${this.query}`)
          .then((res) => {
            this.searchList = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      }, 250)
    },
    getResults (page) {
      this.$http.get('/playlists', {params: {limit: this.limit, page: page}})
        .then((res) => {
          this.playlists = res.data.playlists
          this.pages = res.data.pages
          this.current = res.data.current
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateStatus (playlist) {
      this.$http.put(`/playlists`, {playlist: playlist})
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: `Playlist ${playlist.title} status updated :)`,
            text: '',
            type: 'success'
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    navigate (id) {
      this.$router.push('playlists/edit/' + id)
    },
    playlistSelected (playlist) {
      this.navigate(playlist._id)
    },
    onSelected (playlist) {
      this.searchList = []
      this.$router.push('playlists/edit/' + playlist._id)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.playlists-all-list {
  padding: 5px 0;
  margin-bottom: 25px;
}

.playlists-list-card {
  position: relative;
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid #eaeaea;
}

.playlists-list-card .playlist-feature {
  background-color: rgba(242, 73, 74, 0.95);
  color: #fff;
  padding: 5px 10px;
  position: absolute;
  left: 0;
  width: 70px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 1.2;
}

.playlists-list-card .playlists-list-card-img{
  cursor: pointer;
}

.playlists-list-card .playlists-list-card-img img {
  width: 100%;
}

.playlists-list-card .playlists-list-card-content {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f2494a
}

.playlists-list-card .playlists-list-card-content.published{
  border-bottom: 1px solid #8cd200
}

.playlists-list-card .playlists-list-card-title {
  font-size: 12px;
  font-weight: 900;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-search{
  height: 45px;
  background: #f2494a;
  border-radius: 0;
  color: #fff;
  line-height: 32px;
}

</style>
