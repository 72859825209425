<template>
  <div class="videos-categories-component">
    <div class="page-header">
      <div class="row align-items-center header">
        <div class="col-6">
          <div class="title">
            <router-link to="/admin/videos">Videos </router-link>/
            <span>Categories</span>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-5">
            <b-input-group prepend="Name">
              <b-form-input v-model="category.title"></b-form-input>
              <b-input-group-append>
                <b-btn @click="saveCategory">Save</b-btn>
              </b-input-group-append>
            </b-input-group>

            <br/>

            <chrome-picker v-model="category.color" />
          </div>

          <div class="col-sm-12 col-md-7">
            <div class="note">
              <strong>Note: Drag and Drop categories to reorder how will appear on the app</strong>
            </div>
            <draggable :list="categories" :options="{group:'categories', animation:150}" @start="drag=true" @end="updateOrder" :no-transition-on-drag="true">
              <b-button class="tags-btn" v-for="(category, index) in categories" v-bind:key="index"
                        v-bind:style="{ 'background-color': category.color, border: category.color }"
                        @click="editCategory(category, index)">
                {{category.title}} <b-badge variant="light" @click.stop="removeCategory(category, index)">x</b-badge>
              </b-button>
            </draggable>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import { Chrome } from 'vue-color'
import axios from 'axios'
import draggable from 'vuedraggable'

export default {
  name: 'VideosCategories',
  components: {
    'chrome-picker': Chrome,
    draggable
  },
  data () {
    return {
      categories: [],
      category: {
        title: '',
        color: '#2c3e50',
        slug: '',
        order: null
      },
      indexCategoryToEdit: 0,
      isEdit: false
    }
  },

  mounted () {
    this.$http.get('/videos/categories')
      .then((res) => {
        this.categories = res.data
      })
      .catch((err) => {
        console.log(err)
      })
  },

  methods: {
    saveCategory () {
      if (!this.category.title || this.category.title === '') {
        this.$notify({
          group: 'notifications',
          title: 'Category name is required :(',
          text: '',
          type: 'error'
        })
        return
      }

      if (this.category.color.hex) {
        this.category.color = this.category.color.hex
      }

      this.category.slug = this.category.title
      if (!this.isEdit) {
        this.category['order'] = this.categories.length

        this.$http.post('/videos/categories', {category: this.category})
          .then((res) => {
            this.categories.push(res.data)
            this.category.title = ''
            this.category.color = '#2c3e50'

            this.$notify({
              group: 'notifications',
              title: 'Video category successfully created :)',
              text: '',
              type: 'success'
            })
          })
          .catch((err) => {
            console.log(err)
            this.$notify({
              group: 'notifications',
              title: 'Sorry, something went wrong :(',
              text: '',
              type: 'error'
            })
          })
      } else {
        this.updateCategory(this.category)
          .then((res) => {
            this.categories[this.indexCategoryToEdit] = JSON.parse(JSON.stringify(this.category))
            this.category.title = ''
            this.category.color = '#2c3e50'
            this.isEdit = false

            this.$notify({
              group: 'notifications',
              title: 'Video category successfully updated :)',
              text: '',
              type: 'success'
            })
          })
          .catch((err) => {
            console.log(err)
            this.$notify({
              group: 'notifications',
              title: 'Sorry, something went wrong :(',
              text: '',
              type: 'error'
            })
          })
      }
    },
    editCategory (category, index) {
      this.isEdit = true
      this.category = JSON.parse(JSON.stringify(category))
      this.indexCategoryToEdit = index
    },
    removeCategory (category, index) {
      this.$http.delete(`/videos/categories/${category._id}`)
        .then((res) => {
          this.categories.splice(index, 1)

          this.$notify({
            group: 'notifications',
            title: 'Video category successfully removed :)',
            text: '',
            type: 'success'
          })
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            group: 'notifications',
            title: 'Sorry, something went wrong :(',
            text: '',
            type: 'error'
          })
        })
    },
    updateCategory (category) {
      return axios.put(`${this.$http.url}/videos/categories`, {category})
    },
    updateOrder () {
      let allCategories = []

      this.categories.forEach((category, index) => {
        category.order = index

        let newCategory = this.updateCategory(category)
        allCategories.push(newCategory)
      })

      axios.all(allCategories)
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: 'Categories order updated :)',
            text: '',
            type: 'success'
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style>
.input-group-append .btn {
  background: #f2494a;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  border-radius: 0;
  border-color: #f2494a;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.input-group-append .btn:focus {
  box-shadow: none;
  background: #f2494a;
  border-color: #f2494a;
}

.input-group-prepend .input-group-text {
  border-radius: 0;
  font-size: 12px;
  text-transform: uppercase;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.videos-categories-component .tags-btn {
  width: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
  color: #000;
  font-style: oblique;
}

.vc-chrome {
  width: 100%;
}

.note {
  margin-bottom: 20px;
  background-color: #f2494a;
  color: #fff;
  padding: 9px;
}

@media (max-width: 768px) {
  .vc-chrome {
    margin-bottom: 20px
  }
}

</style>
