<template>
  <div class="concerts-component">
    <router-view :key="$route.fullPath"/>
    <div class="page-header">
      <div class="typeahead-wrapper">
        <div class="typeahead">
          <input id="input" class="form-control" type="text" placeholder="Search Concerts" v-model="query"  @keyup.13="search">
          <div class="btn btn-search" @click="search">
            <i class="fas fa-search"></i>
          </div>
        </div>
        <div class="add-btn" @click="showEditOrCreateContestModal({})">Add Concert</div>
      </div>
    </div>

    <draggable class="concerts-draggable" :list="concerts" :options="{group:'concerts', animation:150}" @start="drag=true" @end="updateOrder" :no-transition-on-drag="true">
      <transition-group tag="div" class="row card-concert-container" :css="true">
        <div v-for="(concert, index) in concerts" v-bind:key="concert._id" class="col-md-4">
          <article :class="{published: concert.published}">
            <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!concert.thumbnail"/>
            <img :src="concert.thumbnail" alt="" @click="showEditOrCreateContestModal(concert)" v-if="concert.thumbnail">
            <aside>
              <p>
                <span class="index">{{index + 1}}</span>
              </p>
              <p class="text-left">
                {{concert.title}}<br/>
                <span style="font-weight: normal">{{formatDate(concert.date)}} {{concert.city}}</span>
              </p>
              <div class="submenu-actions">
                <div class="icon">
                  <i class="fas fa-ellipsis-h"></i>
                </div>
                <div class="actions">
                  <ul>
                    <li @click="toggleContestPublishedStatus(concert)">
                      <span v-if="concert.published">Unpublish</span>
                      <span v-if="!concert.published">Publish</span>
                    </li>
                    <li @click="deleteContest(concert)">Delete</li>
                    <li @click="showEditOrCreateContestModal(concert)">Edit</li>
                  </ul>
                </div>
              </div>
            </aside>
          </article>
        </div>
      </transition-group>
    </draggable>

    <b-modal id="editOrCreateConcertModal" ref="editOrCreateConcertModal" size="lg" title="Edit/Create Concert" @ok="save()" :no-close-on-backdrop="true" :no-close-on-esc="true">
      <div class="row modal-wrapper concert-component-edit">
        <div class="col-12">

          <b-form-row>
            <strong class="video-labels">Concert Title <strong class='required'>*</strong></strong>
            <b-form-group>
              <small>We use this mainly for internal labeling and search.</small>
              <b-form-input id="title"
                            type="text"
                            required
                            v-model="concert.title"
                            placeholder="Add Concert Title...">
              </b-form-input>
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group label="Concert City">
              <small>The city/state of the venue (example: Miami, FL).</small>
              <b-form-input id="title"
                            type="text"
                            v-model="concert.city"
                            placeholder="Add Concert City...">
              </b-form-input>
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group label="Concert Venue">
              <small>The location of the venue (example: American Airlines Arena).</small>
              <b-form-input id="title"
                            type="text"
                            v-model="concert.venue"
                            placeholder="Add Concert Venue...">
              </b-form-input>
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group label="Landing Page URL">
              <small>The page for the webview.</small>
              <b-form-input id="title"
                            type="text"
                            v-model="concert.landing_page_url"
                            placeholder="Add Concert Landing Page URL...">
              </b-form-input>
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group label="Video m3u8">
              <small>The URL for the video.</small>
              <b-form-input id="title"
                            type="text"
                            v-model="concert.video_url"
                            placeholder="Add Concert Video m3u8...">
              </b-form-input>
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <b-form-group label="Concert Date/Time">
              <datetime type="datetime"
                        v-model="concert.date"
                        class="theme-orange"
                        :minDatetime="minDatetime"
                        :phrases="{ok: 'Continue', cancel: 'Exit'}"
                        use12-hour>
              </datetime>
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <div v-for="platform in platforms" v-bind:key="platform.platform">
              <upload @imgUploaded="imgUploaded" :data="platform"></upload>
            </div>
          </b-form-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>


<script>
  import draggable from 'vuedraggable'
  import axios from 'axios'
  import { Datetime } from 'vue-datetime'
  import { DateTime as LuxonDateTime } from 'luxon'
  import upload from '../../share/UploadImage'
  export default {
    name: 'Concerts',
    components: {
      draggable,
      axios,
      Datetime,
      upload
    },
    data () {
      return {
        minDatetime: LuxonDateTime.local().startOf('day').toISO(),
        query: '',
        concert: {
          _id: '',
          title: '',
          city: '',
          thumbnail: '',
          landing_page_url: '',
          published: false,
          order: 0,
          date: new Date().toString(),
          venue: ''
        },
        concerts: [],
        platforms: {
          thumbnail: {
            label: 'Android - 1296x560px',
            description: 'The image that is used on the list view of the LaMusica iOS & Android concerts section',
            platform: 'thumbnail',
            key: 'thumbnail',
            width: 1296,
            height: 560,
            url: '',
            uploadURL: '/concerts/image'
          }
        }
      }
    },

    mounted () {
      this.list()
    },

    methods: {
      list () {
        this.$http.get(`/concerts`).then((res) => {
          console.log(res)
          this.concerts = res.data.sort((a, b) => {
            if (a.order < b.order) {
              return -1
            }
            if (a.order > b.order) {
              return 1
            }
            return 0
          })
        }).catch((err) => {
          console.error(err)
        })
      },
      search () {
        this.$http.get(`/concerts/search?q=${this.query}`)
          .then((res) => {
            this.concerts = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      toggleContestPublishedStatus (concert) {
        this.setActiveConcert(concert)
        this.concert.published = !this.concert.published
        this.save()
      },
      save () {
        let query
        const params = {concert: this.concert}
        if (this.concert._id) {
          query = this.$http.put(`/concerts`, params)
        } else {
          if (!params.concert.order) {
            params.concert.order = this.concerts.length + 1
          }
          query = this.$http.post(`/concerts`, params)
        }
        query.then((res) => {
          console.log(res)
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: `Your concert "${this.concert.title}" was updated successfully :)`,
              text: '',
              type: 'success'
            })
          }
          this.setActiveConcert({})
          this.list()
        }).catch((err) => {
          console.log(err)
          this.$notify({
            group: 'notifications',
            title: `Something went wrong when saving the concert "${this.concert.title}" :(`,
            text: '',
            type: 'error'
          })
          this.setActiveConcert({})
        })
      },
      clearFormData () {
        this.formData = new FormData()
      },
      setActiveConcert (concert) {
        if (!concert) {
          concert = {}
        }
        this.concert.title = concert.title || ''
        this.concert.order = concert.order || 0
        this.concert.city = concert.city || ''
        this.concert.landing_page_url = concert.landing_page_url || ''
        this.concert.thumbnail = concert.thumbnail || ''
        this.platforms.thumbnail.url = concert.thumbnail || ''
        this.concert.published = concert.published || false
        this.concert.date = concert.date || new Date().toDateString()
        this.concert.venue = concert.venue || ''

        if (concert && concert._id) {
          this.concert._id = concert._id
        }
      },
      showEditOrCreateContestModal (concert) {
        this.setActiveConcert(concert)
        this.$refs.editOrCreateConcertModal.show()
      },
      deleteContest (concert) {
        this.$http.delete(`/concerts/${concert._id}`).then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: `Your concert "${concert.title}" was deleted successfully :)`,
              text: '',
              type: 'success'
            })
          }
          this.list()
        }).catch((err) => {
          console.log(err)
          this.$notify({
            group: 'notifications',
            title: `Something went wrong when deleting the concert "${concert.title}" :(`,
            text: '',
            type: 'error'
          })
        })
      },
      updateOrder () {
        let requests = []

        this.concerts.forEach((concert, index) => {
          concert.order = index
          requests[index] = (function (concert, http) {
            return http.put('/concerts', {concert})
          }(concert, this.$http))
        })

        axios.all(requests).then(axios.spread((...args) => {
          this.$notify({
            group: 'notifications',
            title: 'Concerts successfully updated :)',
            text: '',
            type: 'success'
          })
        })).catch((e) => {
          console.error(e)
          this.$notify({
            group: 'notifications',
            title: 'Sorry, there was an error. Please try again :(',
            text: '',
            type: 'error'
          })
        })
      },
      imgUploaded (data, serverResponse) {
        if (serverResponse.success && serverResponse.data && serverResponse.data[0]) {
          this.concert[data.key] = serverResponse.data[0]
          this.platforms[data.platform].url = serverResponse.data[0]
        }
      },
      formatDate (date) {
        if (!date) {
          return ''
        }
        return LuxonDateTime.fromJSDate(new Date(date)).toFormat('LLLL d • ha •')
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .concerts-component article {
    cursor: pointer;
    margin-top: 1em;
    border-bottom: 1px solid #de4242;
  }

  .concerts-component article.published {
    border-bottom: 1px solid #8cd200;
  }

  .concerts-component aside {
    padding: 15px;
    background-color: white;
    display: flex;
    justify-content: space-between;
  }

  .concerts-component article p {
    font-size: 12px;
    max-width: 80%;
    font-weight: 900;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .concerts-component article img {
    max-width: 100%;
    height: auto;
  }

  .concerts-component span.index {
    background: #ccc;
    padding: 0 6px;
    color: #fff;
    border-radius: 5px;
    margin-right: 5px;
  }

  .card-concert-container {
    margin-top: 100px;
  }

  .concert-component-edit fieldset {
    width: 100%;
  }

  .concert-image {
    max-width: 100%;
  }

  .concerts-draggable {
    display: inline-block;
  }

  .form-row  div.upload-wrapper img {
    max-width: 100%;
    width: auto;
    height: auto;
  }

  .form-row div.upload-wrapper {
    margin: 0;
    border: 0;
    padding: 0;
  }

  .form-row div.upload-wrapper label {
    font-weight: bolder;
    margin-bottom: 0;
  }
</style>
