<template>
  <div class="playlist-component">
    <router-view :key="$route.fullPath"/>
  </div>
</template>


<script>

export default {
  name: 'playlists',
  data () {
    return {
    }
  },

  mounted () {
  },

  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
