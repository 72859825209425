<template>
  <span>
    <span v-if="status.audio" v-b-tooltip :title="status.audio.label">
      <b-badge :variant="getVariant(status.audio.code)"  class="p-1">
        <i class="fas fa-music mr-1"></i>
        {{ status.audio.code }}
      </b-badge>
    </span>

    <span v-if="status.video" v-b-tooltip :title="status.video.label">
      <b-badge :variant="getVariant(status.video.code)"  class="p-1">
      <i class="fas fa-video mr-1"></i>
      {{ status.video.code }}
    </b-badge>
    </span>
  </span>
</template>
<script>

export default {
  name: 'FileStatus',
  props: {
    status: {
      type: Object,
      required: true
    }
  },
  methods: {
    getVariant(code) {
      const variants = {
        no_audio: 'warning',
        no_video: 'warning',
        processing: 'info',
        error: 'danger',
        success: 'success'
      }

      return variants[code] || ''
    }
  }
}
</script>
