<template>
  <div>
    <b-overlay :show="episode.status === 'encoding'" variant="dark" opacity="0.8" rounded="sm" spinner-type="null" style="padding: 1rem">
      <b-row>
      <b-col cols="8">
        <b-form-group>
          <label class="has-form-text" for="episode-title">Episode Title<span>*</span></label>
          <small class="form-text">
            A short, specific title that describes the entire episode. Here's an example: <em>"Karol G y Molusco Sacan sus Trapitos Sexuales en Por Culpa del Celular"</em>
          </small>
          <b-form-input id="episode-title" v-model="episode.title"></b-form-input>
          <div class="form-text text-right">{{ titleCharacterCount }} characters</div>
          <div class="alert alert-danger" role="alert" v-if="!utilities.validateEpisodeTitle(episode.title) && episode.title && episode.title.length > 0">
            <strong>Note:</strong> Title must be between 40-200 characters, and cannot be all-caps or contain repeating exclamation points.
          </div>

          <div class="alert alert-warning" role="alert" v-if="episode.title && episode.title.length > 90">
            <strong>Note:</strong> We recommend to keep titles to 40-90 characters, if possible.
          </div>
        </b-form-group>

        <b-form-group>
          <label class="has-form-text" for="episode-description">Episode Description</label>
          <small class="form-text">
            A longer text that describes the entire episode. Here's an example: <em>"En una divertida discusión, Karol G y Molusco hacen público sus secretos sexuales.  Todo comenzó por una foto que Ali le vió a Molusco en su celular."</em>
          </small>
          <b-textarea id="episode-description" rows="5" v-model="episode.meta.description" />
        </b-form-group>

        <b-form-group>
          <label class="has-form-text" for="episode-guest">Guest/Artist</label>
          <small class="form-text">
            A comma separated list of guests of tags describing the episode. Here's an example: <em>"Karol G, Reggaetón, Sexo"</em>
          </small>
          <b-form-input id="episode-guest" placeholder="The artists or guests in the show" v-model="episode.meta.artist_guest"></b-form-input>
        </b-form-group>

        <b-form-group>
          <label class="has-form-text" for="episode-tags">Tags</label>
          <small class="form-text">
            A comma separated list of guests of tags describing the episode. Here's an example: <em>"Karol G, Reggaetón, Sexo"</em>
          </small>
          <b-textarea id="episode-tags" placeholder="Separate by comma"  rows="3" v-model="episode.meta.tags" />
        </b-form-group>

        <b-form-group>
          <label for="episode-created-date">Created Date</label>
          <datepicker v-model="episode.created_at" :bootstrap-styling="true" input-class="dateInput" :disabled="true"></datepicker>
        </b-form-group>

      </b-col>
      <b-col cols="4">
        <b-card header-tag="header">
          <template #header>
            <h6 class="mb-0">Episode Image</h6>
          </template>
          <img v-if="episode.image_url || episode.image" class="episode-artwork img-fluid" alt="" :src="episode.image_url || utilities.getPodcastEpisodeArtworkURL(episode.image)">
          <b-form-file placeholder="Episode artwork 800x800" @change.native="uploadPodcastEpisodeImage($event)"></b-form-file>
          <small class="form-text">This image will override the podcast placeholder image.</small>
        </b-card>

        <br />

        <b-card header-tag="header">
          <template #header>
            <h6 class="mb-0">Original Audio File</h6>
            <ul class="card-actions">
              <li class="item" v-if="episode.status !== 'published'" @click="showAssetAudiosManager"><i class="fas fa-plus"></i></li>
              <li class="item" v-if="episode.files && episode.files.audio && episode.files.audio.url" @click="download(episode.files.audio.url, $event)"><i class="fas fa-download"></i></li>
              <li class="item" v-if="episode.files && episode.files.audio && episode.files.audio.url && episode.status !== 'published'" @click="removeAudioFile"><i class="fas fa-trash"></i></li>
            </ul>
          </template>
          <div v-if="episode.files && episode.files.audio && episode.files.audio.url">
            <audio id="episode_audio" controls style="width: 100%">
              <source :src="episode.files.audio.url" type="audio/mpeg">
            </audio>
          </div>
          <b-alert variant="info" show v-else>No file selected yet</b-alert>
        </b-card>

        <br />

        <b-card header-tag="header">
          <template #header>
            <h6 class="mb-0 float-left">Original Video File</h6>
            <ul class="card-actions">
              <li class="item" v-if="episode.status !== 'published'" @click="showAssetVideosManager"><i class="fas fa-plus"></i></li>
              <li class="item" v-if="episode.files && episode.files.video && episode.files.video.url" @click="download(episode.files.video.url, $event)"><i class="fas fa-download"></i></li>
              <li class="item" v-if="episode.files && episode.files.video && episode.files.video.url && episode.status !== 'published'" @click="removeVideoFile"><i class="fas fa-trash"></i></li>
            </ul>
          </template>

          <div v-if="episode.files && episode.files.video && episode.files.video.url && videoPlayerOptions.sources[0].src">
            <video-player
              class="video-player-box"
              ref="videoPlayer"
              :options="videoPlayerOptions"
              :playsinline="true"
              v-if="videoPlayerOptions.sources[0].src">
            </video-player>

            <template v-if="episode.status !== 'published'">
              <hr />
              <div>
                <b-form-checkbox name="check-button" switch v-model="episode.files.video.options.extract_audio">
                  Extract audio from this video
                </b-form-checkbox>
                <small class="form-text">Uncheck this option if you prefer upload the audio file instead.</small>
              </div>
            </template>
          </div>
          <b-alert variant="info" show v-else>No file selected yet</b-alert>
        </b-card>

        <br />

        <b-overlay :show="episode.status === 'published' || episode.status === 'failed'" variant="dark" opacity="0.8" rounded="sm" spinner-type="null">
          <b-card header-tag="header">
            <template #header>
              <h6 class="mb-0">Publish Options</h6>
            </template>

            <b-form-group>
              <label for="episode-status">Status</label>
              <b-form-radio-group
                id="episode-status"
                v-model="episode.status"
                name="radio-sub-component"
              >
                <b-form-radio value="draft">Draft</b-form-radio>
                <b-form-radio value="scheduled">Scheduled</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <br />

            <b-form-group>
              <label for="episode-status">Publish Date</label>
              <datetime type="datetime"
                        v-model="episode.published_at"
                        class="theme-orange"
                        :phrases="{ok: 'Continue', cancel: 'Exit'}"
                        use12-hour>
              </datetime>
            </b-form-group>
          </b-card>
          <template #overlay>
            <div class="text-center" style="color: #fff">This area cannot be edited if the episode is published or the encoding process was failed.</div>
          </template>
        </b-overlay>

        <br />

        <b-overlay :show="episode.status === 'published' || episode.status === 'failed'" variant="dark" opacity="0.8" rounded="sm" spinner-type="null">
          <b-card header-tag="header">
            <template #header>
              <h6 class="mb-0">Episode Ads</h6>
            </template>

            <b-form-row v-if="episode.ads && episode.ads.audio">
              <b-col>
                <div class="status-wrapper-stations">
                  <label>Audio Pre Rolls</label>
                  <b-form-spinbutton v-model="episode.ads.audio.prerolls" min="0" max="5"></b-form-spinbutton>
                </div>
              </b-col>
              <b-col>
                <div class="status-wrapper-stations">
                  <label>Audio Post Rolls</label>
                  <b-form-spinbutton v-model="episode.ads.audio.postrolls" min="0" max="5"></b-form-spinbutton>
                </div>
              </b-col>
            </b-form-row>
          </b-card>
          <template #overlay>
            <div class="text-center" style="color: #fff">This area cannot be edited if the episode is published or the encoding process was failed.</div>
          </template>
        </b-overlay>
      </b-col>
    </b-row>
      <template #overlay>
        <div class="text-center" style="color: #fff">The episode cannot be updated during the encoding process.</div>
      </template>
    </b-overlay>
    <footer class="modal-footer">
      <button type="button" class="btn btn-secondary" :disabled="saving" @click="close">Cancel</button>
      <button type="button" class="btn btn-primary" :disabled="saving || episode.status === 'encoding'" @click="save">Save</button>
    </footer>

    <b-modal id="assetAudiosModal" ref="assetAudiosModal" size="xl" title="Podcast Files Manager" :hide-footer="true"  :no-close-on-backdrop="true" :no-close-on-esc="true">
      <div class="modal-wrapper">
        <assets-podcasts :asset="'audios'" :show-encode="false" :show-select="true" :show-download="true" v-on:audioSelected="onAudioSelected($event)"></assets-podcasts>
      </div>
    </b-modal>

    <b-modal id="assetVideosModal" ref="assetVideosModal" size="xl" title="Podcast Videos Manager" :hide-footer="true"  :no-close-on-backdrop="true" :no-close-on-esc="true">
      <div class="modal-wrapper">
        <assets-podcasts :asset="'videos'" :show-encode="false" :show-select="true" :show-download="true" v-on:audioSelected="onVideoSelected($event)"></assets-podcasts>
      </div>
    </b-modal>
  </div>
</template>
<script>
import * as lodash from 'lodash'
import Datepicker from 'vuejs-datepicker'
import PodcastsCommon from '../PodcastsCommon'
import AssetsPodcasts from '../../assets/postcasts/AssetsPodcasts.vue'
export default {
  name: 'EpisodeForm',
  components: { AssetsPodcasts, Datepicker },
  props: {
    podcast: {
      required: true,
      type: Object
    },
    data: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      lodash,
      utilities: new PodcastsCommon({context: this}),
      episode: this.data,
      videoPlayerOptions: {
        language: 'en',
        responsive: true,
        sources: [{
          type: 'video/mp4',
          src: null
        }],
        fluid: true
      },
      saving: false
    }
  },
  computed: {
    titleCharacterCount () {
      return this.episode && typeof this.episode.title === 'string' ? this.episode.title.length : 0
    },
  },
  mounted () {
    this.videoPlayerOptions.sources[0].src = _.get(this.episode, 'files.video.url', null)
  },
  methods: {
    save() {
      const validatedEpisode = this.utilities.validateEpisode(this.episode)

      if(!validatedEpisode) {
        return false
      }

      this.saving = true

      const requestPromise = this.episode._id
        ? this.$http.put(`/podcasts/${this.podcast._id}/episodes/${this.episode._id}`, { episode: validatedEpisode })
        : this.$http.post(`/podcasts/${this.podcast._id}/episodes`, { episode: validatedEpisode })

      requestPromise.then((response) => {
        if(response.success) {
          this.$notify({
            group: 'notifications',
            title: 'Success',
            text: response.message,
            type: 'success'
          })
          this.$emit('updated', validatedEpisode)
        } else {
          this.$notify({
            group: 'notifications',
            title: 'Error',
            text: response.message,
            type: 'error'
          })
        }
      }).catch((error) => {
        console.log(error)
        this.$notify({
          group: 'notifications',
          title: 'something went wrong :(',
          text: '',
          type: 'error'
        })
      }).finally(() => {
        this.saving = false
      })
    },

    close() {
      this.$emit('close')
    },

    showAssetAudiosManager () {
      this.$refs.assetAudiosModal.show()
    },

    showAssetVideosManager () {
      this.$refs.assetVideosModal.show()
    },

    onAudioSelected($event) {
      console.log($event)
      this.$set(this.episode.files.audio, 'url', $event.file_url)
      this.$set(this.episode.files.audio, 'is_new', true)
      this.$refs.assetAudiosModal.hide()
    },

    onVideoSelected($event) {
      this.$set(this.episode.files.video, 'url', $event.file_url)
      this.$set(this.episode.files.video, 'is_new', true)
      this.episode.video_url = $event.video_hls_url
      this.videoPlayerOptions.sources[0].src = $event.file_url
      this.$refs.assetVideosModal.hide()
    },

    removeAudioFile () {
      this.$set(this.episode.files.audio, 'url', null)
    },

    removeVideoFile () {
      this.$set(this.episode.files.video, 'url', null)
    },

    download (url, $event) {
      $event.preventDefault()
      $event.stopPropagation()
      window.open(url, 'blank')
    },

    uploadPodcastEpisodeImage (event) {
      const input = event.target
      const fileExists = input.files && input.files[0]
      const fileIsJPGOrPNG = /\.(jpe?g|png)$/i.test(input.files[0].name)

      if (!fileExists || !fileIsJPGOrPNG) {
        return this.$notify({
          group: 'notifications',
          title: 'Image file should be JPG or PNG format :(',
          text: '',
          type: 'error'
        })
      }

      let reader = new FileReader()
      reader.readAsDataURL(input.files[0])

      reader.onload = (e) => {
        const URL = window.URL || window.webkitURL
        const image = new Image()
        image.src = URL.createObjectURL(input.files[0])
        image.onload = () => {
          let height = image.height
          let width = image.width

          if (width !== 800 || height !== 800) {
            return this.$notify({
              group: 'notifications',
              title: `Image size allowed is 800x800px :(`,
              text: '',
              type: 'error'
            })
          }

          const imageFormData = new FormData()
          imageFormData.append('image', input.files[0])
          const requestOptions = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }

          this.$http.post('/podcasts/images', imageFormData, requestOptions).then((res) => {
            if (res.success) {
              this.episode.image = res.data.object_id
              this.episode.new_image = true
              this.$notify({
                group: 'notifications',
                title: 'Image uploaded successfully :)',
                text: '',
                type: 'success'
              })
            } else {
              this.$notify({
                group: 'notifications',
                title: `${res.message} :(`,
                text: '',
                type: 'error'
              })
            }
          }).catch((err) => {
            console.log(err)
            this.$notify({
              group: 'notifications',
              title: `We experienced an error trying to upload this image :(`,
              text: '',
              type: 'error'
            })
          })
        }
      }
    },
  }
}
</script>
<style scoped lang="scss">
  .form-control {
    margin-bottom: 10px;
  }

  label {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 5px;

    span {
      color: #f2494a;
    }

    .has-form-text {
      margin-bottom: .125em;
    }
  }

  .form-text {
    margin-top: 0.25rem;
    font-size: 90%;
    margin-bottom: 10px;
  }

  .card-header {
    padding: 0.75rem 0.5rem;
  }

  .card-body {
    padding: 0.5rem;

    .alert {
      margin-bottom: 0;
    }
  }

  .episode-artwork {
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .card-actions {
    position: absolute;
    right: 5px;
    top: 9px;
    .item {
      vertical-align: top;
      display: inline-block;
      width: auto;
      color: #6a6e73;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      padding: 0 5px;
      border: 1px solid rgba(0, 0, 0, 0.125)
    }
  }


  footer {
    margin: 1rem -1rem 0;
    padding: 1rem 1rem 0;
  }
</style>
