<template>
  <div class="home-wrapper">
    <div class="home">
      <div class="overlay"></div>
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col">
              <img src="/static/img/lamusica-darkbg.png" alt="La Musica" />
              <div class="text">
                System to administer playlists & podcast content on LaMusica.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar">
      <div class="sidebar-wrapper">
        <router-view />
      </div>
    </div>

  </div>
</template>


<script>
  export default {
    name: 'homepage',
    data () {
      return {}
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .home-wrapper{
    display: flex;
    height: 100vh;
  }

  .home-wrapper .home{
    background-image: url('/static/img/Download-DJ-Backgrounds-Free.jpg');
    background-position: center;
    background-size: cover;
    flex: 1;
    position: relative;
  }

  .home-wrapper .home .content{
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .home-wrapper .home .content img{
    max-width: 350px;
  }

  .home-wrapper .home .content .text{
    color: #fff;
    font-size: 18px;
    font-weight: bold;
  }

  .home-wrapper .sidebar{
    flex: 1;
    max-width: 400px;
    position: relative;
    background-color: #f7f7f7;
  }

  .home-wrapper .sidebar .sidebar-wrapper{
    position: relative;
    padding: 60px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .overlay{
    position: absolute;
    top:0;
    bottom:0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6)
  }

  @media (max-width: 991px) {
    .home-wrapper .home {
      display: none;
    }

    .home-wrapper .sidebar {
      max-width: 100%;
    }
  }
</style>
