<template>
    <div class="categories-all-component">

      <div class="page-header">
        <div class="typeahead-wrapper">
          <div class="typeahead">
              <input id="input" class="form-control search-input" type="text" placeholder="Search Categories" v-model="query" @keyup="keymonitor">
              <ul v-if="searchList.length > 0">
                <li v-for="category in searchList" v-bind:key="category._id" @click="navigate(category._id)">
                  <div class="playlist-img">
                    <img :src="category.exploreImagery.hdpiImageURL" alt="" v-if="category && category.exploreImagery && category.exploreImagery.hdpiImageURL" />
                    <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!category || !category.exploreImagery || !category.exploreImagery.hdpiImageURL"/>
                  </div>
                  {{category.title}}
                </li>
              </ul>
          </div>
          <router-link class="typeahead-btn" to="/admin/categories/new" tag="div">Add Category</router-link>
        </div>
      </div>

      <!-- <div class="title">Categories</div> -->

      <div class="page-content">
        <div class="categories-all-list">
          <draggable class="tracks-list-draggable" :list="categories" :options="{group:'categories', animation:150}" @start="drag=true" @end="updateOrder" :no-transition-on-drag="true">
          <transition-group tag="div" class="row list-animation" :css="true">
            <div class="col-md-3" v-for="(category, index) in categories" v-bind:key="category._id">
              <div class="categories-list-card">
                  <div class="categories-list-card-img" @click="navigate(category._id)">
                      <img :src="category.exploreImagery.hdpiImageURL" alt="" v-if="category.exploreImagery && category.exploreImagery.hdpiImageURL" />
                      <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!category.exploreImagery || !category.exploreImagery.hdpiImageURL"/>
                  </div>

                  <div class="categories-list-card-content" :class="{published: category.published}">
                      <div class="categories-list-card-title">
                        <span>{{category.order}}</span>
                        {{category.title}}
                      </div>
                      <div class="submenu-actions">
                        <div class="icon">
                          <i class="fas fa-ellipsis-h"></i>
                        </div>
                        <div class="actions">
                          <ul>
                            <li @click="category.published = !category.published; updateStatus(category)">{{category.published ? 'Unpublish' : 'Publish'}}</li>
                            <li @click="confirm(category, index)">Delete</li>
                          </ul>
                        </div>
                      </div>
                      <!-- <label class="lamusica-switch">
                          <input type="checkbox" v-model="category.published" @change="updateStatus(category)">
                          <span class="lamusica-slider round"></span>
                      </label> -->
                  </div>
              </div>
            </div>
          </transition-group>
          </draggable>
        </div>

        <b-pagination v-if="pages > 1" align="right" size="md" :total-rows="limit * pages" v-model="current" :per-page="limit" @change="getResults"></b-pagination>
      </div>

      <b-modal id="confirmRemoveCategory" ref="confirmRemoveCategory" size="md" title="Confirm" @ok="handleRemove(toDelete)">
        <div class="row modal-wrapper">
          <div class="col-12">
            Are you sure want to remove <strong>{{toDelete.category.title}}</strong> category?
          </div>
        </div>
      </b-modal>
    </div>
</template>


<script>
import draggable from 'vuedraggable'
import axios from 'axios'

export default {
  name: 'CategoriesAll',
  components: {
    draggable,
    axios
  },
  data () {
    return {
      query: null,
      timeout: null,
      searchList: [],
      categories: [],
      limit: 100,
      pages: 1,
      current: 1,
      toDelete: {
        index: 0,
        category: ''
      }
    }
  },

  mounted () {
    this.$http.get('/categories', {params: {limit: this.limit, page: this.pages}})
      .then((res) => {
        this.categories = res.data.categories
        this.pages = res.data.pages
        this.current = res.data.current

        this.categories.sort((a, b) => {
          if (a.order > b.order) return 1
          if (b.order > a.order) return -1

          return 0
        })
      })
      .catch((err) => {
        console.log(err)
      })
  },

  methods: {
    confirm (category, index) {
      this.$refs.confirmRemoveCategory.show()
      this.toDelete = {
        index: index,
        category: category
      }
    },
    handleRemove (toDelete) {
      this.$http.delete('/categories', {params: {categoryID: toDelete.category._id}})
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: 'Category successfully removed :(',
            text: '',
            type: 'success'
          })
          this.categories.splice(toDelete.index, 1)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    keymonitor (event) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (event.target.value === '') {
          this.searchList = []
          this.query = null
          return
        }
        this.query = event.target.value
        this.$http.get(`/categories/search?q=${this.query}`)
          .then((res) => {
            this.searchList = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      }, 250)
    },
    getResults (page) {
      this.$http.get('/categories', {params: {limit: this.limit, page: page}})
        .then((res) => {
          this.categories = res.data.categories
          this.pages = res.data.pages
          this.current = res.data.current
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateStatus (category) {
      this.$http.put(`/categories`, {category: category})
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: `Category ${category.title} status updated :)`,
            text: '',
            type: 'success'
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    navigate (id) {
      this.searchList = []
      this.$router.push('/admin/categories/edit/' + id)
    },
    updateOrder () {
      let requests = this.getRequests(this.page)

      axios.all(requests)
        .then(axios.spread((...args) => {
          this.$notify({
            group: 'notifications',
            title: 'Categories successfully updated :)',
            text: '',
            type: 'success'
          })
        }))
        .catch((e) => {
          console.log(e)
          this.$notify({
            group: 'notifications',
            title: 'Sorry, there was an error. Please try again :(',
            text: '',
            type: 'error'
          })
        })
    },
    getRequests () {
      let requests = []

      this.categories.forEach((category, index) => {
        category.order = index
        requests[index] = (function (category, http) {
          return http.put('/categories', {category})
        }(category, this.$http))
      })

      return requests
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.categories-all-list {
  padding: 5px 0 20px;
  margin-bottom: 25px;
}

.categories-list-card {
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid #eaeaea;
}

.categories-list-card .categories-list-card-img img {
  width: 100%;
  cursor: pointer;
}

.categories-list-card .categories-list-card-content {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #de4242
}

.categories-list-card .categories-list-card-content.published{
  border-bottom: 1px solid #8cd200
}

.categories-list-card .categories-list-card-title {
  font-size: 12px;
  font-weight: 900;
  text-transform: capitalize;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.categories-list-card .categories-list-card-title span {
  background: #ccc;
  padding: 0 6px;
  color: #fff;
  border-radius: 5px;
  margin-right: 5px;
}

</style>
