<template>
  <div>
    <div class="audio-container" v-if="currentAudio">
      <div>{{currentAudio.file_name}}</div>
      <b-form-row>
        <b-col cols="10">
          <audio controls style="width: 100%" v-if="currentAudio.file_url">
            <source  :src="currentAudio.file_url" type="audio/mpeg">
          </audio>
          <b-input :disabled="true" v-else placeholder="No audio selected"></b-input>
        </b-col>
        <b-col cols="2">
          <b-button variant="danger" v-if="currentAudio.file_name" @click="removeFile">Remove</b-button>
          <b-button variant="danger" @click="openAssetsModal" v-else>Select</b-button>
        </b-col>
      </b-form-row>
    </div>

    <b-modal id="assetPodcastsModal" ref="assetPodcastsModal" size="xl" title="Podcast Files Manager" :hide-footer="true"  :no-close-on-backdrop="true" :no-close-on-esc="true">
      <div class="modal-wrapper">
        <assets-podcasts :asset="'audio-settings'" :show-encode="false" :show-select="true" v-on:audioSelected="onAudioSelected($event)"></assets-podcasts>
      </div>
    </b-modal>


  </div>
</template>
<script>
import AssetsPodcasts from '../assets/postcasts/AssetsPodcasts.vue'
export default {
  name: 'AudioSettings',
  components: {AssetsPodcasts},
  props: {
    audio: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      currentAudio: null
    }
  },
  mounted () {
    this.currentAudio = this.audio
  },
  methods: {
    openAssetsModal() {
      this.$refs.assetPodcastsModal.show()
    },

    onAudioSelected($event) {
      this.currentAudio.file_name = $event.file_name
      this.currentAudio.file_url = $event.file_url
      this.$emit('audioSelected', this.currentAudio)
      this.$refs.assetPodcastsModal.hide()
    },

    removeFile() {
      this.currentAudio.file_name = ''
      this.currentAudio.file_url = ''
      this.$emit('audioSelected', this.currentAudio)
    }
  }
}
</script>
<style scoped lang="scss">
button {
  padding: 12px 10px;
  margin: 0;
  text-align: center;
  background: #f2494a;
  border: 1px solid #f2494a;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 13px;
  width: 100%;
  border-radius: 0;

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
