<template>
  <div class="videos-edit-component" v-if="Object.keys(video).length > 0">
    <editors v-if="videoID" :resource="videoID" />
    <div class="page-header" :class="{shadowHeader: showShadow}">
      <div class="row align-items-center header">
        <div class="col-6">
          <div class="title">
            <router-link to="/admin/videos">Videos </router-link>/
            <span>{{video.title.es}}</span>
          </div>
        </div>
        <div class="col-6">
          <div class="typeahead-wrapper float-right">
            <button type="submit" form="videoForm" id="updateButton" class="typeahead-btn">Update</button>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="videos-all-list">
        <div class="container">
          <div class="col-12">
            <b-form @submit="update" v-on:keydown.13.prevent.stop id="videoForm" class="bootstrap-form">
              <VideosForm :video="video" v-if="video._id" />
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios'
import { Datetime } from 'vue-datetime'
import VideosForm from './VideosForm'
import VideosCommon from './VideosCommon'
import Editors from '../../share/Editors'

export default {
  name: 'VideosEdit',
  components: {
    VideosForm,
    axios,
    Datetime,
    Editors
  },
  data () {
    const utilities = new VideosCommon({ context: this })
    return {
      videoID: null,
      video: {},
      utilities,
      ...utilities.getDefaultInitialState()
    }
  },

  mounted () {
    this.videoID = this.$route.params.id
    axios.all([this.getVideo()])
      .then(axios.spread((video) => {
        this.video = video.data
        if(!this.video.web_ads){
          this.video.web_ads = {
            '300x250': [{
              tag: '',
              disable:false,
              start: null,
              end: null
            }],
            '728x90': [{
              tag: '',
              disable:false,
              start: null,
              end: null
            }]
          }
        }

        if(!this.video.vendor) {
          this.video.vendor = {
            landscape: {
              job_id:'',
              mediastream_id:''
            },
            portrait: {
              job_id:'',
              mediastream_id:''
            }
          }
        }
      }))
  },

  created () {
    this.utilities.handleCreated()
  },

  destroyed () {
    this.utilities.handleDestroyed()
  },

  methods: {
    getVideo () {
      return this.$http.get(`/videos/${this.videoID}`)
    },
    update (evt) {
      const isValid = this.utilities.validateContent(evt)
      if (!isValid) {
        return
      }

      const video = this.utilities.mapVideoForSave(this.video)
      const displayError = () => {
        this.$notify({
          group: 'notifications',
          title: 'Sorry, something went wrong :(',
          text: '',
          type: 'error'
        })
      }

      this.$http.put('/videos', {video}).then((res) => {
        if (res.success) {
          this.$notify({
            group: 'notifications',
            title: 'Video updated successfully :)',
            text: '',
            type: 'success'
          })
        } else {
          displayError()
        }
      }).catch((err) => {
        console.log(err)
        displayError()
      })
    },
    openNewArtist () {
      this.$refs.createArtist.show()
    }
  }
}
</script>

<style>
</style>
