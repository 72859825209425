<template>
  <div class="media-edit-component">
    <div class="media-edit-content">
      <div class="row align-items-center header">
        <div class="col-6">
          <div class="title">
            <router-link to="/admin/media">Media </router-link>/
            <span v-if="media && media.title">{{media.title}}</span>
          </div>
        </div>
        <div class="col-6">
          <div class="typeahead-wrapper">
            <input class="name" type="text" placeholder="Media Title..." v-model="media.title"/>
            <div class="typeahead-btn" @click="save()">Update</div>
          </div>
        </div>
      </div>

      <div class="row" v-if="media">
        <div class="col-3">
          <div class="media-image">
            <img :src="$apiUrl + '/media/artworks/' + media.image + '.jpg'" alt="" v-if="media.image"/>
            <img src="/static/img/LaMusica-Icon-artist-placeholder.png" alt="" v-if="!media.image"/>
          </div>

          <div class="file-upload-form">
            <div class="label">Upload image - 800x800px</div>
            <input type="file" @change="previewImage" accept="image/*">
          </div>

          <div class="file-upload-form">
            <a v-if="media.lyrics" target="_blank" :href="media.lyrics">Download the lyrics file</a>
            <div class="label">Upload Lyrics File(.txt)</div>
            <input type="file" @change="uploadLyrics" accept=".txt" >
          </div>
        </div>

        <div class="col-9">
          <div class="media-info">

            <div class="general">
              <div class="artist">
                <span>Artist:</span>
                <input type="text" v-model="media.artist.name" />
              </div>
              <div class="title">
                <span>Album:</span>
                <input type="text" v-model="media.release.title" />
              </div>
              <div class="explicit">
                <input type="checkbox" v-model="media.explicitContent" />
                Explicit Content
              </div>
            </div>

            <div class="other">
              <div class="type">
                Type: {{media.type}}
              </div>
              <div class="">
                isrc: {{media.isrc || '-'}}
              </div>
              <div class="date">
                Relase Date: {{formatDate(media.release.date) || '-'}}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <canvas id="canvas"></canvas>
    <audio id="audio" controls controlsList="nodownload"></audio>
  </div>
</template>


<script>
import typeahead from '../../share/typeahead'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import AudioTest from '../../share/audio.js'

export default {
  name: 'MediaEdit',
  components: {
    Datepicker,
    typeahead
  },
  data () {
    return {
      media: {
        artist: {
          _id: null,
          name: '',
          id7digital: null
        },
        duration: null,
        explicitContent: false,
        id_7digital: null,
        image: null,
        isrc: null,
        release: {
          id: null,
          title: '',
          date: null
        },
        lyrics: null,
        title: null,
        type: null,
        _id: null
      },
      mediaTypes: [
        {value: 'audio', label: 'Audio'},
        {value: 'video', label: 'Video'}
      ],
      wavesurfer: null,
      process: '0:00',
      duration: null,
      play: false,
      imageData: '',
      audioData: '',
      formData: new FormData(),
      releaseResource: 'release',
      artistResource: 'artist',
      newRelease: false,
      newReleaseTitle: '',
      notReleaseData: '',
      newArtist: false,
      newArtistName: '',
      notArtistData: '',
      audio: null
    }
  },

  mounted () {
    this.mediaID = this.$route.params.id
    if (this.wavesurfer) {
      this.wavesurfer.destroy()
    }
    this.$http.get(`/media/${this.mediaID}`)
      .then((res) => {
        this.media = res.data

        this.audio = new AudioTest(`${this.$apiUrl}/media/tracks/${this.mediaID}.m4a`)
        this.audio.addEqualizer()
      })
      .catch((err) => {
        console.log(err)
      })
  },
  beforeDestroy () {
    if (this.wavesurfer) {
      this.wavesurfer.destroy()
    }
  },
  methods: {
    uploadLyrics(event){
      let input = event.target
      this.formData = new FormData()
      if (input.files && input.files[0]) {

        this.formData.append('file', input.files[0])
        this.formData.append('trackId', this.mediaID)
        this.$http.post(`/media/lyrics`,
          this.formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then((res) => {
          const lrics = res.data
          this.$set(this.media,'lyrics',lrics)
          console.log('lr', lrics);
        }).catch((err) => {
          console.log(err)
          this.formData = new FormData()
        })

      }
    },
    playTrack () {
      this.audio.play()
    },
    pauseTrack () {
      this.audio.pause()
    },
    noReleaseHandler (data) {
      if (data) {
        this.notReleaseData = data
      } else {
        this.notReleaseData = ''
      }
    },
    addNewRelease () {
      this.media.release.title = this.notReleaseData
    },
    selectRelease (release) {
      this.media.release = release
    },
    cleanReleaseSearch () {
      this.media.release = {
        title: '',
        id: null,
        date: new Date()
      }
      this.notReleaseData = ''
      this.newReleaseTitle = ''
    },
    noArtistHandler (data) {
      if (data) {
        this.notArtistData = data
      } else {
        this.notArtistData = ''
      }
    },
    addNewArtist () {
      this.media.artist.name = this.notArtistData
    },
    selectArtist (artist) {
      this.media.artist = artist
    },
    cleanArtistSearch () {
      this.media.artist = {
        name: '',
        _id: null,
        id7digital: null
      }
      this.notArtistData = ''
      this.newArtistName = ''
    },
    playPause () {
      this.play = !this.play
      this.wavesurfer.playPause()
    },
    save () {
      EventHandler.emit('isLoading', true)
      this.$http.put('/media', {media: this.media}).then((res) => {
        this.$notify({
          group: 'notifications',
          title: 'Media updated successfully :)',
          text: '',
          type: 'success'
        })
        EventHandler.emit('isLoading', false)
        this.$router.push('/admin/media')
      })
        .catch((err) => {
          console.log(err)
        })
    },
    previewImage (event) {
      let input = event.target
      this.formData = new FormData()
      if (input.files && input.files[0]) {
        if (/\.(jpe?g|png|gif)$/i.test(input.files[0].name)) {
          let reader = new FileReader()
          reader.readAsDataURL(input.files[0])

          reader.onload = (e) => {
            const URL = window.URL || window.webkitURL
            const image = new Image()
            image.src = URL.createObjectURL(input.files[0])
            image.onload = () => {
              let height = image.height
              let width = image.width

              if (height !== 800 || width !== 800) {
                this.$notify({
                  group: 'notifications',
                  title: 'Image size allowed 800 x 800px :(',
                  text: '',
                  type: 'error'
                })

                return false
              } else {
                // this.imageData = e.target.result
                this.formData.append('cover', input.files[0])

                this.$http.post(`/media/upload`,
                  this.formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  }
                ).then((res) => {
                  this.media.image = res.data
                }).catch((err) => {
                  console.log(err)
                  this.formData = new FormData()
                })
              }
            }
          }
        } else {
          this.$notify({
            group: 'notifications',
            title: 'Image should be jpg or png format :(',
            text: '',
            type: 'error'
          })
        }
      }
    },
    previewAudio (event) {
      let input = event.target

      if (input.files && input.files[0]) {
        if (/\.(mp3|m4a)$/i.test(input.files[0].name)) {
          let audio = new Audio()
          audio.src = URL.createObjectURL(input.files[0])
          this.wavesurfer.load(audio)

          this.formData.append('track', input.files[0])
        } else {
          this.$notify({
            group: 'notifications',
            title: 'Audio file should be Mp3 or M4a format :(',
            text: '',
            type: 'error'
          })
        }
      }
    },
    formatTime (time) {
      return [
        Math.floor((time % 3600) / 60), // minutes
        ('00' + Math.floor(time % 60)).slice(-2) // seconds
      ].join(':')
    },
    formatDate (date) {
      return moment(date).format('MMMM Do YYYY')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.media-edit-component{
  position: relative;
}

.media-edit-component .media-edit-content{
    z-index: 1;
    position: relative;
}

.media-edit-component .header{
  margin-bottom: 30px;
}

.wavesurfer-wrapper-edit{
  position: fixed;
  bottom: 0;
  right: 0;
  left: 200px;
  height: 185px;
}

.wavesurfer-wrapper-edit .controls {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  margin-bottom: 15px;
}

.wavesurfer-wrapper-edit .controls .playPause{
  cursor: pointer;
}

.media-image{
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border: 1px solid #eaeaea;
  margin-top: 60px;
}

.media-image img{
  width: 100%
}

.media-edit-component .controls-wrapper{
  margin-bottom: 30px;
}

.media-info{
  text-transform: uppercase;
  font-weight: bold;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
}

.media-info .general {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 10px;
}

.media-info .general .explicit {
  margin-bottom: 10px;
  text-transform: capitalize;
}

.media-info .general .explicit input{
  width: auto;
  margin-right: 5px;
}

.media-info .general span {
  width: 55px;
  display: block;
}

.media-info .general input {
  padding: 5px 10px;
  width: 300px;
}

.media-info .general .artist {
  margin-bottom: 10px;
  text-transform: capitalize;
}

.media-info .general .title{
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  text-transform: capitalize;
}

.media-info .other {
  text-transform: capitalize;
  font-size: 12px;
}

#modal6 .image-preview {
  margin-bottom: 15px;
}

#modal6 .image-preview img{
  width: 100%;
}

.playPause-wrapper{
  font-size: 16px;
  text-align: center;
  line-height: 42px;
  height: 40px;
  width: 40px;
  background-color: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

#modal6 .label, #modal7 .label{
  margin-bottom: 10px;
}

#modal6 input, #modal7 input{
  padding: 0;
}

.selectedItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  font-weight: bold;
  border-bottom: 1px solid #eaeaea;
}

.selectedItem i{
  cursor: pointer;
}

.new-release-wrapper {
  display:flex;
}

.new-release-wrapper button{
  background-color: #f2494a;
  height: 45px;
  color: #fff;
  font-size: 12px;
  padding: 0 15px;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.new-item{
  position: absolute;
  top: 0;
  height: 45px;
  line-height: 45px;
  padding: 0 10px;
  cursor: pointer;
  font-weight: bold;
  right: 16px;
  font-size: 10px;
  background-color: #f2494a;
  color: #fff;
}

#canvas {
  position: fixed;
  left: 200px;
  width: 100%;
  height: 350px;
  bottom: 0;
}

audio {
  position: fixed;
  left: 220px;
  bottom: 20px;
  width: calc(100% - 240px);
}

.file-upload-form {
  margin-top: 20px
}
</style>
