<template>
    <div>
      <div class="row">
        <div class="col">
          <div class="card card-stats published shadow">
            <div class="card-body">
              <div class="icon-wrapper">
                <i class="fas fa-check"></i>
              </div>

              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase mb-1">Published</h5>
                  <span class="h2 mb-0">{{stats.published}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card card-stats scheduled shadow">
            <div class="card-body">
              <div class="icon-wrapper">
                <i class="fas fa-calendar-alt"></i>
              </div>
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase mb-1">Scheduled</h5>
                  <span class="h2 mb-0">{{stats.scheduled}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card card-stats encoding shadow">
            <div class="card-body">
              <div class="icon-wrapper">
                <i class="fas fa-sync"></i>
              </div>
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase mb-1">Encoding</h5>
                  <span class="h2 mb-0">{{stats.encoding}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card card-stats failed shadow">
            <div class="card-body">
              <div class="icon-wrapper">
                <i class="fas fa-exclamation-circle"></i>
              </div>
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase mb-1">Failed</h5>
                  <span class="h2 mb-0">{{stats.failed}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card card-stats draft shadow">
            <div class="card-body">
              <div class="icon-wrapper">
                <i class="fas fa-file"></i>
              </div>
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase mb-1">Draft</h5>
                  <span class="h2 mb-0">{{stats.draft}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: 'PodcastStats',
  props: {
    stats: {
      type: Object,
      required: true
    }
  }
}
</script>
<style scoped lang="scss">
.card-stats {
  h5 {
    font-size: .7125rem;
    color: #fff;
    font-weight: 700;
  }

  .h2 {
    font-size: 1rem;
    font-weight: 700;
  }

  &.published {
    background: linear-gradient(87deg, #2dcecc, #2dce89)!important;
  }

  &.scheduled {
    background: linear-gradient(87deg, #11cdef, #1171ef)!important;
  }

  &.encoding {
    background: linear-gradient(87deg, #fbb140, #fb6340)!important;
  }

  &.failed {
    background: linear-gradient(87deg, #f56036, #f5365c)!important;
  }

  &.draft {
    background: linear-gradient(87deg, #cdc8c6, #33334a)!important;
  }

  .card-body {
    padding: 1em;
  }

  .icon-wrapper {
    position: absolute;
    right: 10px;
    top: 15px;

    .fas {
      font-size: 2.5rem;
      color: rgba(255, 255, 255, .6);
    }
  }
}
</style>
