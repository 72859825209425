<template>
  <div>
    <div class="loading" v-if="loading">
      <div class="content-wrapper">
        <div class="progress-status" :class="{ complete: uploadPercentage === 100 }">
          {{ loadingText }}
        </div>
        <LoaderInline/>
      </div>
    </div>

    <template v-if="currentAsset !== 'videos' ">
      <form class="bootstrap-form">
        <b-form-file :disabled="['recording', 'videos'].includes(currentAsset)"
                     placeholder="Upload file"
                     v-model="file"
                     @change.native="uploadFile"
        >
        </b-form-file>
        <div class="alert alert-info mt-3" v-if="currentAsset === 'audios' || currentAsset === 'audio-settings'">
          <strong>Please Note:</strong> Please ensure that when you upload an audio file, that it is a mp3 file.
        </div>

        <div class="alert alert-info mt-3" v-if="currentAsset === 'recording'">
          <strong>Please Note:</strong> Choose videos to encode from the console recording. You cannot upload a video to this section.
        </div>
      </form>
      <hr class="divider"/>
    </template>
    <div class="alert alert-info" v-else>
      <strong>Please Note:</strong> You can search videos by ID or keywords, previously uploaded and published on MediaStream platform.
    </div>
    <div class="row">
      <div class="col-12" v-if="currentAsset === 'videos'">
        <b-input-group size="sm" class="mt-2">
          <b-form-input placeholder="Search videos" v-model="searchText" @input="searchByText"></b-form-input>
          <b-input-group-append is-text>
            <b-form-checkbox switch class="mr-n2 mb-n1 pr-2" v-model="searchById" @change="search(true)">
              Search by ID
            </b-form-checkbox>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="col-12" v-else>
        <div class="controls-wrapper pt-2">
          <input class="form-control files-search" type="text" placeholder="Search Uploaded Files ..." v-if="currentAsset !== 'videos'" v-model="searchText" @input="searchByText" />
          <ul>
            <li class="asset-types-navigation"
                v-for="assetItem in assets"
                :key="assetItem.key"
                :class="{ active: currentAsset === assetItem.key }"
                v-if="(!asset && !['audio-settings', 'videos'].includes(assetItem.key)) || (asset && assetItem.key === asset)"
                @click="setAsset(assetItem.key)"
            >
              {{ assetItem.label }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <hr class="divider"/>
    <div class="row">
      <div class="col-lg-8 col-6">
        <div class="list-items">
          <div v-if="items.length > 0">
            <transition-group tag="div" class="row list-items-wrapper" name="list-animation">
              <div class="col-md-3 item-col" v-for="item in items" v-bind:key="item.id ? item.id : item.file_name"
                   @click="preview(item)"
              >
                <div class="list-item" v-bind:class="{'selected': currentItem && (currentItem.id ? currentItem.id === item.id : currentItem.file_name === item.file_name)}">
                  <assets-podcast-item :item="item"></assets-podcast-item>
                </div>
              </div>
            </transition-group>
          </div>
          <div v-else class="pt-2 pr-2 pb-2 pl-2">
            <b-alert variant="info" show v-if="currentAsset === 'videos' && searchText.length < 3">Type a keywords to search videos.</b-alert>
            <b-alert variant="info" show v-else>No items found.</b-alert>
          </div>
          <b-button block @click="loadMore" v-if="loadMoreVisible">
            <span v-if="!items.length">Try Again</span>
            <span v-else>Load More</span>
          </b-button>
        </div>
      </div>
      <div class="col-lg-4 col-6">
        <div class="item-preview">
          <template v-if="items.length > 0">
            <assets-item-preview v-if="currentItem" :item="currentItem" :show-encode="showEncode" :show-select="showSelect" :show-download="showDownload" @encode="showEncodeConfirmation($event)" @selected="handleSelected($event)"></assets-item-preview>
            <div class="pt-5" v-if="!currentItem">
              <LoaderInline/>
            </div>
          </template>
          <div v-else class="pt-2 pr-2 pb-2 pl-2">
            <b-alert variant="info" show>No item selected.</b-alert>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="confirmEncodeVideo" ref="confirmEncodeVideo" size="md" title="Start Encoding Process"
             :hide-header-close="true"
             :no-close-on-backdrop="true"
             :no-close-on-esc="true"
             @ok="handleEncode()"
             @cancel="currentFile = null"
    >
      <div class="row modal-wrapper">
        <div class="col-12" v-if="currentFile">
          <div>Confirm start the encoding process for this file:</div>
          <div class="confirm-filename" v-b-tooltip :title="currentFile.file_name"><strong>
            {{ currentFile.file_name }}</strong></div>
        </div>
      </div>
    </b-modal>

  </div>
</template>
<script>
import AssetsPodcastItem from './AssetsPodcastItem'
import LoaderInline from '../../../share/LoaderInline'
import AssetsItemPreview from '../preview/AssetsItemPreview'

export default {
  name: 'AssetsPodcasts',
  components: {LoaderInline, AssetsPodcastItem, AssetsItemPreview},
  props: {
    asset: {
      type: String,
      required: false,
      default: null
    },
    podcastId: {
      type: String,
      required: false
    },
    showEncode: {
      type: Boolean,
      required: false,
      default: true
    },
    showSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    showDownload: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      currentAsset: null,
      assets: [
        {
          label: 'Audios',
          key: 'audios'
        },
        {
          label: 'Videos',
          key: 'videos'
        },
        {
          label: 'Recording',
          key: 'recording'
        },
        {
          label: 'Audio',
          key: 'audio-settings'
        }
      ],
      items: [],
      currentItem: null,
      endpointUrl: `${process.env.VUE_APP_TRANSCODING_API}/podcasts`,
      videoUploadFormData: null,
      uploadPercentage: 0,
      loadingText: '',
      loading: false,
      pagination: {
        limit: 10,
        offset: 0
      },
      loadMoreVisible: false,
      file: null,
      currentFile: null,
      searchText: '',
      searchTimeOut: null,
      searchById: true
    }
  },
  mounted () {
    this.currentAsset = this.asset || 'audios'
    if(this.currentAsset !== 'videos') {
      this.search()
    }
  },
  methods: {
    searchByText() {
      clearTimeout(this.searchTimeOut)
      this.searchTimeOut = setTimeout(() => {
        this.search(true)
      }, 500)
    },
    search (override = false) {
      if (this.currentAsset === 'videos') {
        this.searchIntegrations(override)
      } else {
        this.searchFiles(override)
      }
    },
    searchFiles (override = false) {
      this.loading = true
      this.loadingText = 'Retrieving podcast files'
      this.$http.get(`${this.endpointUrl}/uploads?asset=${this.currentAsset}&offset=${this.pagination.offset}&limit=${this.pagination.limit}&search=${this.searchText}`)
        .then((response) => {
          this.loading = false
          this.loadingText = ''

          if (response.success) {
            this.items = override ? response.data.items : [...this.items, ...response.data.items]
            this.loadMoreVisible = response.data.hasMore
            if(this.items.length > 0 && !this.currentItem) {
              this.preview(this.items[0])
            }
          } else {
            this.loadMoreVisible = false
            this.$notify({
              group: 'notifications',
              title: 'Error',
              text: response.message,
              type: 'error'
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          this.loadingText = ''
          this.items = []
        })
    },
    searchIntegrations(override = false) {
      if(this.searchText.length < 3) {
        if(this.searchText === '') {
          this.items = []
          this.loadMoreVisible = false
          this.currentItem = null
        }

        return false
      }

      this.loading = true
      this.loadingText = 'Retrieving podcast videos'

      const data = {}

      if(this.searchById) {
        data.id = this.searchText
      } else {
        data.query = this.searchText
      }

      this.$http.post(`/podcasts/integrations/mediastream/search`, data)
        .then((response) => {
          this.loading = false
          this.loadingText = ''

          if (response.success) {
            this.items = override ? response.data.items : [...this.items, ...response.data.items]
            this.loadMoreVisible = response.data.hasMore
            if(this.items.length > 0 && !this.currentItem) {
              this.preview(this.items[0])
            }
          } else {
            this.loadMoreVisible = false
            this.$notify({
              group: 'notifications',
              title: 'Error',
              text: response.message,
              type: 'error'
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          this.loadingText = ''
          this.items = []
        })
    },
    setAsset (asset) {
      this.currentAsset = asset
      this.pagination.offset = 0
      this.items = []
      this.currentItem = null
      this.search()
    },
    loadMore () {
      this.pagination.offset += this.pagination.limit
      this.search()
    },
    reload() {
      this.pagination.offset = 0
      this.currentItem = null
      this.items = []
      this.search()
    },
    preview (item) {
      this.currentItem = null
      setTimeout(() => {
        this.currentItem = item
      }, 500)
    },
    uploadFile (event) {
      const input = event.target
      const hasFile = input.files && input.files[0]
      if (!hasFile) {
        return
      }
      const isValidFilename = (this.currentAsset == 'videos' || this.currentAsset == 'recording') ? /\.(mp4|mov)$/i.test(input.files[0].name) : /\.(mp3)$/i.test(input.files[0].name)
      if (!isValidFilename) {
        this.file = null

        return this.$notify({
          group: 'notifications',
          title: 'Error',
          text: 'Video file should be mp4, mov format for videos, or mp3 for audios :(',
          type: 'error'
        })
      }
      this.videoUploadFormData = new FormData()
      this.videoUploadFormData.append('files', input.files[0])
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
          this.loadingText = `Uploading File - ${this.uploadPercentage} %`
        }
      }

      this.loading = true

      this.$http.post(`${this.endpointUrl}/uploads?asset=${this.currentAsset}`, this.videoUploadFormData, config)
        .then((res) => {
          this.uploadPercentage = 0
          this.loading = false
          this.loadingText = ''
          this.file = null

          if (!res.success) {
            return this.$notify({
              group: 'notifications',
              title: 'Error',
              text: `${res.message} :(`,
              type: 'error'
            })
          }

          this.$notify({
            group: 'notifications',
            title: 'Success',
            text: 'File uploaded successfully, select your file below :)',
            type: 'success'
          })

          this.reload()
        })
        .catch((err) => {
          this.uploadPercentage = 0
          this.loading = false
          this.loadingText = ''
          this.file = null

          if (err) {
            this.$notify({
              group: 'notifications',
              title: 'Error',
              text: 'There was an error uploading this file',
              type: 'error'
            })
          }
        })
    },
    showEncodeConfirmation (file) {
      this.currentFile = file
      this.$refs.confirmEncodeVideo.show()
    },
    handleEncode () {
      if (!this.podcastId) {
        return
      }

      if (['videos', 'recording'].includes(this.currentAsset)) {
        this.$emit('videoSelected', this.currentFile)
      } else {
        this.$emit('audioSelected', this.currentFile)
      }
    },
    handleSelected(file) {
      this.$emit('audioSelected', file)
    }
  }
}
</script>
<style scoped lang="scss">
.list-items {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 -15px -15px;
  padding: 0 15px;

  .list-items-wrapper {
    margin-left: -10px;

    .item-col{
      padding: 0;

      .list-item {
        box-sizing: border-box;
        margin: .5em .15em;
        width: 100%;
        max-width: 171px;
        height: 150px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.4);

        &:hover, &.selected {
          border-color: rgba(0, 0, 0, 0.6);
          color: rgba(0, 0, 0, 0.8);
        }

        &.selected {
          border-width: 2px;
        }
      }
    }
  }
}

.item-preview {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -15px 0 0;
  height: 400px;
}

hr {
  &.divider {
    margin: 1em -15px 0;
  }
}

.controls-wrapper {
  .files-search {
    margin-bottom: 0;
    height: auto;
    font-size: 13px;
  }

  ul {
    margin-bottom: 0 !important;

    li {
      &.active {
        background-color: #f2494a;
        color: #fff;
      }
    }
  }
}

.selected {
  .assets-video-card {
    border-color: #f2494a;
  }
}

.confirm-filename {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.loading {
  position: absolute;
  z-index: 100;
  left: 0;
  top: -63px;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, .9);

  .content-wrapper {
    position: absolute;
    width: 300px;
    height: 150px;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -125px;

    .progress-status {
      text-align: center;
      margin-top: 20px;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }
}
</style>
