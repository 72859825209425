<template>
  <div>
    <b-form-row>
      <b-col>
        <b-form-group label="Title English">
          <b-form-input id="titleEnglish"
                        type="text"
                        required
                        v-model="card.titleEnglish"
                        placeholder="add english text...">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Title Spanish">
          <b-form-input id="titleSpanish"
                        type="text"
                        required
                        v-model="card.titleSpanish"
                        placeholder="add spanish text...">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group label="Subtitle English">
          <b-form-input id="subtitleEnglish"
                        label="Subtitle English"
                        type="text"
                        v-model="card.subtitleEnglish"
                        placeholder="add english text...">
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Subtitle Spanish">
          <b-form-input id="subtitleSpanish"
                        type="text"
                        v-model="card.subtitleSpanish"
                        placeholder="add spanish text...">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row class="justify-content-md-center" v-if="this.card.available">
      <b-col>
        <legend class="col-form-label pt-0">Start Date</legend>
        <datetime type="datetime"
                  v-model="card.available.start"
                  class="theme-orange"
                  :minDatetime="minDatetime"
                  :phrases="{ok: 'Continue', cancel: 'Exit'}"
                  use12-hour>
        </datetime>
      </b-col>

      <b-col>
        <legend class="col-form-label pt-0">End Date</legend>
        <datetime type="datetime"
                  v-model="card.available.end"
                  class="theme-orange"
                  :minDatetime="minDatetime"
                  :phrases="{ok: 'Continue', cancel: 'Exit'}"
                  use12-hour>
        </datetime>
      </b-col>
    </b-form-row>

    <slot></slot>

    <div class="image-group">
      <!--              <div class="remove-img" v-if="imageSizes.length > 1" @click="removeImage(index)">-->
      <!--                <i class="fas fa-times"></i>-->
      <!--              </div>-->
      <b-col :cols="size.columns" v-for="(size, index) in imageSizes" v-bind:key="index" class="image-upload-container">
        <p>
          <strong>{{size.title}}</strong><br/>
          <span>{{size.description}}</span>
        </p>
        <div class="card-image" v-if="size.url">
          <img :src="size.url" alt="" />
        </div>
        <loader-inline v-if="size.loading"/>
        <b-form-file v-model="size.url" :placeholder="size.placeholder" @change.native="utils.handleImageUpload($event, size, index)" />
      </b-col>

      <b-col cols="6">
        <p>
          <strong>{{video.title}}</strong><br/>
          <span>{{video.description}}</span>
        </p>
        <loader-inline v-if="video.loading"/>
        <div class="video-holder-card">
          <div class="remove-video-card" v-if="video.url" @click="utils.handleRemoveVideoCard()">
            <i class="fas fa-times"></i>
          </div>
          <div class="card-image" v-if="video.url" v-html="videoHTML"></div>
        </div>
        <b-form-file accept="video/mp4" ref="videoInput" v-model="video.file" :placeholder="video.placeholder" @change.native="utils.handleVideoUpload($event, video)"></b-form-file>
      </b-col>
    </div>
  </div>
</template>

<script>
import CardTypeCommon from './CardTypeCommon'
import LoaderInline from '../../../share/LoaderInline'
import {Datetime} from 'vue-datetime'

export default {
  name: 'BaseCardForm.vue',
  components: {
    Datetime,
    LoaderInline
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    imageSizes: {
      type: Array,
      required: true
    },
    video: {
      type: Object,
      required: true
    }
  },
  computed: {
    videoHTML: function () {
      return `<video controls><source src="${this.video.url}" type="video/mp4"></video>`
    }
  },
  data () {
    const utils = new CardTypeCommon(this)
    return {
      minDatetime: utils.configuration.minDatetime,
      formData: new FormData(),
      utils
    }
  }
}
</script>

<style scoped lang="scss">
  .image-upload-container {
    margin-bottom: 1em;
  }

  .image-group {
    display: flex;
    flex: 1;
    border: 1px solid #eaeaea;
    padding-top: 15px;
    margin-bottom: 15px;
    position: relative;
    flex-wrap: wrap;
    .remove-img {
      position: absolute;
      top: -10px;
      right: -10px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 1px solid #eaeaea;
      background-color: #eaeaea;
      text-align: center;
      cursor: pointer;
    }
  }

  .card-image img {
    width: 100%;
    margin-bottom: 10px;
  }
</style>
