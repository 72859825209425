<template>
  <div class="assets-item-card">
    <div class="card-content">
      <div v-if="item.image_url">
        <img :src="item.image_url" alt="" />
      </div>
    </div>
    <div class="card-label" :title="item.file_name">
      {{ item.file_name }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'AssetVideoItem',
  props:['item'],
  data () {
    return {}
  }
}
</script>

<style scoped lang="scss">
.assets-item-card {
  width: 100%;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;

  .submenu-actions {
    position: absolute;
    right: 0px;
    top: -10px;
    color: rgba(0, 0, 0, 0.4);

    li{
      color: rgba(0, 0, 0, 0.4);
      font-weight: 600;
    }
  }

  &:hover {
    .submenu-actions {
      color: rgba(0, 0, 0, 0.8);
      li{
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  .card-title {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;

    h3 {
      font-size: 12px;
      font-weight: 700;
      max-width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .card-content {
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    overflow: hidden;

    .content-icon {
      font-size: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 100%;
    }
  }

  .card-label{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5px;
    font-size: 12px;
  }
}
</style>
