<template>
  <div class="livestream-all-component">

    <div class="page-header">
      <div class="typeahead-wrapper">
        <div class="typeahead">
          <input id="input" class="form-control" type="text" placeholder="Search Livestreams" v-model="query"  @keyup.13="search">
          <div class="btn btn-search" @click="search">
            <i class="fas fa-search"></i>
          </div>
        </div>
        <div class="add-btn" @click="addLivestream">Add Livestream</div>
      </div>
    </div>

    <!-- <div class="title">Locations</div> -->
    <div class="page-content">
      <div class="livestream-all-list">
        <transition-group tag="div" class="row" name="list-animation">
          <div class="col-md-6" v-for="(livestream, index) in livestreams" v-bind:key="livestream._id">
            <div class="livestream-card" :class="{published: livestream.published}">
              <div class="image-livestream" @click="navigate(livestream._id)">
                <img :src="livestream.square_img" alt="" />
                <img src="/static/img/256x256.png" alt="" v-if="!livestream.square_img || livestream.square_img === ''"/>
              </div>

              <div class="livestream-info">
                <div class="livestream-title" @click="navigate(livestream._id)">
                  {{livestream.titleSpanish}}<br/>
                  <span class="livestream-meta">
                    {{livestream.isSponsored ? 'Sponsored': ''}}
                    {{livestream.disableAds ? '• Ads Disabled': ''}}
                    {{livestream.chatEnabled ? '• Chat enabled': ''}}
                    <span class="stream-status" v-if="livestream.is_running">• Streaming</span><br/>
                    <span style="font-style: normal">Last Updated: {{formatUpdatedAt(livestream.updatedAt)}}</span>
                  </span>
                </div>
                <div>
                  <div class="icon-wrapper" v-b-tooltip.hover.topright title="Preview Video" v-if="livestream && livestream.livestream_url" @click="openPlayer(livestream)">
                    <i class="fas fa-play"></i>
                  </div>
                  <div class="submenu-actions">
                    <div class="icon">
                      <i class="fas fa-ellipsis-h"></i>
                    </div>
                    <div class="actions">
                      <ul>
                        <li @click="navigate(livestream._id)">Edit</li>
                        <li @click="confirm(livestream, index)">Delete</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>

    <b-modal id="confirmRemoveLivestream" ref="confirmRemoveLivestream" size="md" title="Confirm" @ok="handleRemove(toDelete)">
      <div class="row modal-wrapper">
        <div class="col-12">
          Are you sure want to remove <strong>{{toDelete.livestream.titleSpanish}}</strong> livestream?
        </div>
      </div>
    </b-modal>

    <b-modal id="videoPlayerModal"
             ref="videoPlayerModal"
             size="lg"
             title="Video Player"
             :no-close-on-backdrop="true"
             :hide-header-close="true"
             :ok-only="true"
             ok-title="Close"
             @ok="handleOk">
      <div class="row modal-wrapper">
        <div class="col-12">
          <video-player class="video-player-box"
                        ref="videoPlayer"
                        :options="videoModalOption"
                        :playsinline="true"
                        v-if="videoModalOption.sources[0].src">
          </video-player>
        </div>
      </div>
    </b-modal>

  </div>
</template>


<script>
  import moment from "moment";
  export default {
    name: 'Builder',
    components: {},
    data () {
      return {
        query: '',
        livestreams: [],
        selectedLayout: '',
        toDelete: {
          index: 0,
          livestream: ''
        },
        videoModalOption: {
          language: 'en',
          responsive: true,
          sources: [{
            type: 'application/x-mpegURL',
            src: null
          }],
          techOrder: ['html5'],
          sourceOrder: true,
          flash: {
            hls: { withCredentials: false },
            swf: '/static/media/video-js.swf'
          },
          html5: { hls: { withCredentials: false } },
          fluid: true
        }
      }
    },
    computed: {
    },
    mounted () {
      this.$http.get(`/livestreams`)
        .then((res) => {
          this.livestreams = res.data.sort((a, b) => {
            if (a.published && !b.published) {
              return -1
            }

            if (!a.published && b.published) {
              return 1
            }

            if (a.titleSpanish < b.titleSpanish) {
              return -1
            }

            if (a.titleSpanish > b.titleSpanish) {
              return 1
            }

            return 0
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    methods: {
      openPlayer (video) {
        this.$refs.videoPlayerModal.show()
        this.videoModalOption.sources[0].src = video.livestream_url
      },
      handleOk () {
        this.$refs.videoPlayerModal.hide()
        this.videoModalOption.sources[0].src = null
      },
      formatUpdatedAt (date) {
        if (!date) {
          return 'N/A'
        }
        const formattedDate = moment(date).format('dddd, MMMM Do, h:mma')
        const ago = moment(date).fromNow()
        return `${formattedDate} (${ago})`
      },
      search () {
        if (this.query !== '') {
          this.$http.get(`/livestreams/search?q=${this.query}`)
            .then((res) => {
              this.livestreams = res.data
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          this.$http.get(`/livestreams`)
            .then((res) => {
              this.livestreams = res.data
            })
            .catch((err) => {
              console.log(err)
            })
        }
      },
      addLivestream () {
        this.$router.push('livestreams/new')
      },
      navigate (id) {
        this.$router.push(`livestreams/edit/${id}`)
      },
      confirm (livestream, index) {
        this.$refs.confirmRemoveLivestream.show()
        this.toDelete = {
          index: index,
          livestream: livestream
        }
      },
      handleRemove (toDelete) {
        this.$http.delete(`/livestreams/${toDelete.livestream._id}`)
          .then((res) => {
            this.$notify({
              group: 'notifications',
              title: 'Livestream successfully removed :(',
              text: '',
              type: 'success'
            })
            this.livestreams.splice(toDelete.index, 1)
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$notify({
                group: 'notifications',
                title: `${err.response.data} :(`,
                text: '',
                type: 'error'
              })
            } else {
              console.log(err.response)
            }
          })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .livestream-all-component .btn-search {
    height: 45px;
    background: #f2494a;
    border-radius: 0;
    color: #fff;
    line-height: 32px;
  }

  .livestream-card {
    border: 1px solid #eaeaea;
    border-left:2px solid #de4242;
    background-color: #fff;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &.published{
      border-left: 2px solid #8cd200
    }

    .image-livestream {
      padding: 5px;
      width: 85px;
      margin-right: 15px;
      cursor: pointer;
      img {
        width: 100%
      }
    }
  }

  .livestream-title{
    cursor: pointer;
  }

  .livestream-info {
    display: flex;
    flex: 1;
    justify-content: space-between;

    .submenu-actions{
      margin-right: 10px;
      display: inline-block;
      position: relative;
      top: 2px;
    }
  }

  .note {
    font-size: 10px;
    font-weight: bold;
    margin-top: -10px;
    color: #f2494a;
    text-align: right;
    margin-bottom: 10px;
  }

  .stream-status {
    color: #8cd201;
  }

  .livestream-meta {
    font-weight: normal;
    font-style: italic;
  }

  .icon-wrapper {
    display: inline-block;
  }
</style>
