<template>
    <div class="media-component">
      <router-view :key="$route.fullPath"/>
    </div>
</template>


<script>
export default {
  name: 'Media',
  data () {
    return {
      playlists: [],
      searchList: [],
      query: null,
      timeout: null
    }
  },

  mounted () {
  },

  methods: {
    keymonitor (event) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (event.target.value === '') {
          this.searchList = []
          this.query = null
          return
        }
        this.query = event.target.value
        this.$http.get(`/playlists/search?q=${this.query}`)
          .then((res) => {
            this.searchList = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      }, 250)
    },
    onSelected (playlist) {
      this.searchList = []
      this.$router.push('playlists/edit/' + playlist._id)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .menu-types {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

   .menu-types ul{
    display: flex;
    justify-content: center;
    text-decoration: none;
  }

  .menu-types ul li{
    list-style: none;
    margin-right: 30px;
  }

  .menu-types ul li:last-child{
    margin-right: 0;
  }

  .menu-types ul li a{
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    padding-bottom: 5px;
  }

  .menu-types ul li a.active{
    border-bottom: 2px solid #fff;
  }

  .subtitle-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }

  .subtitle-wrapper .subtitle{
    text-align: center;
    color: #fff;
    text-transform: uppercase;
  }

  .subtitle-wrapper select{
    width: 200px;
    height: 35px;
    color: #495057;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .add-playlist{
      width: 150px;
      line-height: 45px;
      text-align: center;
      background: #f2494a;
      margin-left: 15px;
      color: #fff;
      text-transform: uppercase;
      cursor: pointer;

      -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      -moz-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .typeahead-wrapper{
    display: flex;
    margin-bottom: 30px;
  }

  .typeahead{
      position: relative;
      flex: 1
  }

  .typeahead input{
      padding: 0 45px;
      font-size: 14px;
      border-radius: 0;
      height: 45px;
      border: none;

      -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      -moz-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .typeahead ul{
    position: absolute;
    width: 100%;
    background: #fff;
    max-height: 200px;
    top: 45px;
    overflow-y: scroll;
    list-style: none;
    padding: 10px 45px;
    z-index: 4;
    border-top: 1px solid #dcdcdc;

    -webkit-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    -moz-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

  .typeahead ul li{
    font-size: 14px;
    padding: 10px 0;
    cursor: pointer;
  }
</style>
