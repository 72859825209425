<template>
  <div class="upload-wrapper">
    <label>{{data.label}}</label>
    <span class="form-text text-muted">{{data.description}}</span>
    <div class="spinner" v-if="isLoading">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
    </div>
    <img v-bind:src="data.url" v-if="data.url && data.url !== ''"/>
    <input type="file" id="file" name="file" ref="file" accept="image/*" v-on:change="handleFileUpload()"/>
  </div>
</template>

<script>
  export default {
    props: ['data'],
    data () {
      return {
        file: '',
        isLoading: false
      }
    },
    methods: {
      handleFileUpload () {
        this.file = this.$refs.file.files[0]

        if (!this.file) {
          this.$notify({
            group: 'notifications',
            title: 'Required SVG image :(',
            text: '',
            type: 'error'
          })
          return
        }

        if (/\.(svg)$/i.test(this.file.name)) {
          let formData = new FormData()
          formData.append('file', this.file)

          this.isLoading = true
          const settings = {
            headers:
              {
                'Content-Type': 'multipart/form-data'
              }
          }
          this.$http.post(this.data.uploadURL, formData, settings).then((res) => {
            if (res.success) {
              this.$emit('imgUploaded', res.data)
              this.$notify({
                group: 'notifications',
                title: 'Image uploaded successfully :)',
                text: '',
                type: 'success'
              })
            } else {
              this.$notify({
                group: 'notifications',
                title: `${res.message} :(`,
                text: '',
                type: 'error'
              })
            }

            this.isLoading = false
            formData = new FormData()
          }).catch((err) => {
            console.error(err)
            this.isLoading = false
            formData = new FormData()
          })
        } else {
          this.$notify({
            group: 'notifications',
            title: 'Required SVG image :(',
            text: '',
            type: 'error'
          })
        }
      }
    }
  }
</script>

<style scoped>

  .modal-wrapper label {
    margin-bottom: 0;
    font-weight: bold;
  }

  .form-text {
    margin-bottom: 0.5em;
  }

  .upload-wrapper {
    padding: 0 15px;
    border: 1px solid #dedede;
    margin-bottom: 15px;
  }

  .modal-wrapper input{
    border: none;
    margin: 0;
  }

  .upload-wrapper .label{
    margin-bottom: 10px;
    color: #000;
  }

  .upload-wrapper img{
    width: 100%;
    margin-bottom: 15px;
  }

  .spinner {
    margin: 30px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
  }

  .spinner > div {
    background-color: #f2494a;
    height: 100%;
    width: 6px;
    display: inline-block;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
  }

  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }  20% {
         transform: scaleY(1.0);
         -webkit-transform: scaleY(1.0);
       }
  }
</style>
