<template>
  <div class="typeahead">
    <input id="input" class="form-control" type="text" autocomplete="off" :placeholder="text || 'Explore Tracks Collection...'" v-model="query"  @keyup="keymonitor">
    <div class="loading" v-if="isLoading">
      <div class="spinner">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5"></div>
      </div>
    </div>
    <ul v-if="searchList.length > 0">
      <li v-for="(item, index) in searchList" v-bind:key="item._id">
        <div class="track-img" @click="onSelected(item)">
            <img :src="$apiUrl + '/media/artworks/' + item.image + '.jpg'" alt="" v-if="item.image"/>
            <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!item.image"/>
        </div>
        <div class="icon-wrapper" @click="onPlay(item, index)" v-if="!resource && !hidePlayButton">
          <i class="fas fa-play"></i>
          <!-- <i class="fas fa-pause"></i> -->
        </div>
        <div class="track-info" @click="onSelected(item)">
          <div class="track-title">{{item.title || item.name}}</div>
          <div class="track-artist" v-if="item.artist">By - {{item.artist.name}}</div>
          <div class="track-release" v-if="item.release">Album - {{item.isPodcast ? formatDate(item.release.date) : item.release.title}}</div>
        </div>

        <div class="wavesurfer" :id="'wavesurfer-typeahead-' + index"></div>

      </li>
    </ul>
  </div>
</template>


<script>
  import moment from 'moment'

  export default {
    name: 'typeahead',
    props: ['resource', 'text', 'hidePlayButton'],
    data () {
      return {
        searchList: [],
        isLoading: false,
        timeout: null,
        query: ''
      }
    },

    mounted () {
      EventHandler.listen('close-typeahead', () => {
        this.searchList = []
        this.query = ''
        this.isLoading = false
      })
    },

    methods: {
      onPlay (track, index) {
        this.$emit('onPlay', track, index)
      },
      onSelected (track) {
        this.$emit('trackSelected', track)
      },
      keymonitor (event) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (event.target.value === '') {
            this.searchList = []
            this.isLoading = false
            this.$emit('noResults')
            return
          }
          this.isLoading = true
          let url = this.resource ? `/media/search?q=${event.target.value}&resource=${this.resource}` : `/media/search?q=${event.target.value}`
          this.$http.get(url)
            .then((res) => {
              if (res.data.length === 0) {
                this.$emit('noResults', this.query)
                this.isLoading = false
                this.searchList = []
                return
              }

              if (!this.resource) {
                this.searchList = res.data
              }

              if (this.resource === 'release') {
                let arrayReduced = res.data.map((release) => {
                  return release.release
                })
                this.searchList = this.reduceArray(arrayReduced, 'id')
              }

              if (this.resource === 'artist') {
                let arrayReduced = res.data.map((artist) => {
                  return artist.artist
                })
                this.searchList = this.reduceArray(arrayReduced, '_id')
              }

              this.isLoading = false
            })
            .catch((err) => {
              console.log(err)
              this.isLoading = false
            })
        }, 250)
      },
      reduceArray (a, param) {
        return a.filter(function (item, pos, array) {
          return array.map(function (mapItem) { return mapItem[param] }).indexOf(item[param]) === pos
        })
      },
      formatDate (date) {
        return moment(date).format('dddd MMMM Do, YYYY')
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .typeahead{
    position: relative;
    flex: 1;
  }

  .typeahead input{
    margin-bottom: 0;
    padding: 0 15px;
    border-radius: 0;
    height: 45px;
    border: none;
    font-size: 14px;
    border: 1px solid #eaeaea;
  }

  .typeahead ul{
    position: absolute;
    width: 100%;
    background: #fff;
    max-height: 350px;
    top: 45px;
    overflow-y: scroll;
    list-style: none;
    padding: 0;
    z-index: 4;
    border-top: 1px solid #dcdcdc;

    -webkit-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    -moz-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

  .typeahead ul li{
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0;
    cursor: pointer;
  }

  .typeahead ul li:hover{
    background-color:#eaeaea;
    color: #2c3e50;
  }

  .typeahead ul li .track-img img{
    width: auto;
    max-height: 60px;
  }

  .typeahead .track-info {
    margin-right: 15px;
    width: 100%;
  }

  .typeahead .track-info .track-title{
    text-transform: capitalize;
    font-size: 12px;
    font-weight: bold;
  }

  .typeahead .track-info .track-artist, .typeahead .track-info .track-release {
    font-size: 10px;
    text-transform: capitalize;
  }

  .typeahead .icon-wrapper{
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
  }

  .wavesurfer{
    flex: 1;
  }

  .wavesurfer canvas{
    max-width: none;
  }

  .loading{
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1;
    padding: 30px 0;
    border-top: 1px solid #dcdcdc;
    -webkit-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

  .spinner {
    margin: 0 auto;
    height: 40px;
    font-size: 10px;
  }

  .spinner > div {
    background-color: #f2494a;
    height: 100%;
    width: 6px;
    display: inline-block;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
  }

  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }  20% {
         transform: scaleY(1.0);
         -webkit-transform: scaleY(1.0);
       }
  }

</style>
