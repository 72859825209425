<template>
  <div>
    <b-input-group>
      <b-form-input
        id="example-input"
        v-model="fullDate"
        type="text"
        placeholder="Week Start Day - Week End Day"
        autocomplete="off"
        :disabled="true"
      ></b-form-input>
      <b-input-group-append>
        <b-form-datepicker
          v-model="startDate"
          button-only
          right
          locale="en-US"
          aria-controls="example-input"
          :date-disabled-fn="dateDisabled"
          @context="onContext"
        ></b-form-datepicker>
      </b-input-group-append>
    </b-input-group>
    <small>You can select any friday of the month. If you need the chart released today, you may select the last friday.</small>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'WeekPicker',
  props: {
    currentWeek: {
      type: Object,
      required: false,
      default: function () {
        return {
          start: null,
          end: null,
          number: null,
          year: null
        }
      }
    }
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      weekNumber: null,
      weekYear: null,
      fullDate: '',
      startDay: 1,
      weekUnit: 'isoWeek',
      momentFormat: 'ddd, MMM Do YYYY'
    }
  },
  mounted () {
    this.startDay = moment().startOf(this.weekUnit).add(4,'days').weekday()
    if(this.currentWeek.start) {
      this.startDate = this.currentWeek.start
      this.endDate = this.currentWeek.end
      this.weekNumber = this.currentWeek.number
      this.weekYear = this.currentWeek.year
      this.formatFullDate()
    }
  },
  methods: {
    onContext(ctx) {
      if(ctx.selectedDate) {
        this.processSelectedDate(ctx.selectedDate)
      }
    },
    dateDisabled(ymd, date) {
      const weekday = date.getDay()
      return weekday !== this.startDay
    },
    processSelectedDate(date) {
      this.startDate = date
      const momentDate = moment(date)
      this.endDate = momentDate.endOf(this.weekUnit).add(4,'days')
      this.weekNumber = momentDate.isoWeek()
      this.weekYear = momentDate.year()
      this.formatFullDate()
      this.emitSelectedDate()
    },
    formatFullDate() {
      this.fullDate = `${moment(this.startDate).format(this.momentFormat)} - ${moment(this.endDate).format(this.momentFormat)}`
    },
    emitSelectedDate() {
      this.$emit('selected', {
        start: this.startDate,
        end: this.endDate,
        number: this.weekNumber,
        year: this.weekYear
      })
    }
  }
}
</script>
<style></style>
