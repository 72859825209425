<template>
    <div class="contest-all-component">

      <div class="page-header">
        <div class="typeahead-wrapper">
          <div class="typeahead">
            <input id="input" class="form-control" type="text" placeholder="Find Contest..." v-model="query"  @keyup.13="search">
            <div class="btn btn-search" @click="search">
              <i class="fas fa-search"></i>
            </div>
          </div>
          <div class="add-btn" @click="addContest">Add Contest</div>
        </div>
      </div>

      <!-- <div class="title">Locations</div> -->
      <div class="page-content">
        <div class="contest-all-list">
          <transition-group tag="div" class="row" name="list-animation">
            <div class="col-md-6" v-for="(contest, index) in contests" v-bind:key="contest._id">
              <div class="contest-card" :class="{published: contest.published}">
                <div class="image-contest" @click="navigate(contest._id)">
                  <img :src="contest.img" alt="" />
                  <img src="/static/img/256x256.png" alt="" v-if="!contest.img"/>
                </div>

                <div class="contest-info">
                  <div class="contest-regionName" @click="navigate(contest._id)">
                    {{contest.titleSpanish}}
                  </div>
                  <div class="submenu-actions">
                    <div class="icon">
                      <i class="fas fa-ellipsis-h"></i>
                    </div>
                    <div class="actions">
                      <ul>
                        <li @click="navigate(contest._id)">Edit</li>
                        <li @click="confirm(contest, index)">Delete</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </div>
      </div>

      <b-modal id="confirmRemoveContest" ref="confirmRemoveContest" size="md" title="Confirm" @ok="handleRemove(toDelete)">
        <div class="row modal-wrapper">
          <div class="col-12">
            Are you sure want to remove <strong>{{toDelete.contest.titleSpanish}}</strong> contest?
          </div>
        </div>
      </b-modal>

    </div>
</template>


<script>
export default {
  name: 'Contests',
  data () {
    return {
      query: '',
      contests: [],
      selectedLayout: '',
      toDelete: {
        index: 0,
        contest: ''
      }
    }
  },
  computed: {
  },
  mounted () {
    this.$http.get(`/contests`)
      .then((res) => {
        this.contests = res.data
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    search () {
      if (this.query !== '') {
        this.$http.get(`/contests/search?q=${this.query}`)
          .then((res) => {
            this.contests = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.$http.get(`/contests`)
          .then((res) => {
            this.contests = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    addContest () {
      this.$router.push('contests/new')
    },
    navigate (id) {
      this.$router.push('contests/edit/' + id)
    },
    confirm (contest, index) {
      this.$refs.confirmRemoveContest.show()
      this.toDelete = {
        index: index,
        contest: contest
      }
    },
    handleRemove (toDelete) {
      this.$http.delete(`/contests/${toDelete.contest._id}`)
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: 'Contest successfully removed :(',
            text: '',
            type: 'success'
          })
          this.contests.splice(toDelete.index, 1)
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$notify({
              group: 'notifications',
              title: `${err.response.data} :(`,
              text: '',
              type: 'error'
            })
          } else {
            console.log(err.response)
          }
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.contest-all-component .btn-search {
  height: 45px;
  background: #f2494a;
  border-radius: 0;
  color: #fff;
  line-height: 32px;
}

.contest-card {
  border: 1px solid #eaeaea;
  border-left:2px solid #de4242;
  background-color: #fff;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.contest-card.published{
  border-left: 2px solid #8cd200
}

.contest-regionName{
  cursor: pointer;
}

.contest-card .image-contest {
  padding: 5px;
  width: 60px;
  margin-right: 15px;
  cursor: pointer;
}

.contest-card .image-contest img{
  width: 100%
}

.contest-info {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.contest-info .submenu-actions{
  margin-right: 10px;
}

.note {
  font-size: 10px;
  font-weight: bold;
  margin-top: -10px;
  color: #f2494a;
  text-align: right;
  margin-bottom: 10px;
}

</style>
