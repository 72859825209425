<template>
  <div class="config-component">
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col-5">
          <div class="title">
            <router-link to="/admin/configurations">Configurations </router-link> / New
          </div>
        </div>
        <div class="col-7 pull-right">
          <div class="typeahead-wrapper float-right">
            <button type="submit" form="configForm" class="typeahead-btn">Create</button>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="card">
        <b-form @submit="create" id="configForm" class="bootstrap-form">
          <ConfigurationForm :config="config" />
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
  import ConfigurationForm from './ConfigurationForm'
  export default {
    name: 'ConfigurationsNew',
    components: {
      ConfigurationForm
    },
    data () {
      return {
        config: {
          title: 'Some Title',
          app_name: 'LaMusica',
          live_versions: ['1.1.2'],
          advertisements: {
            seconds_between_preroll_ads: 3600,
            playlist_companion_ad_frequency: 4,
            playlist_companion_ad_amount: 1,
            playlist_default_station_id: 'Algorithmic-01',
            interstatial_ads: '',
            interstatial_ads_seconds: 14440,
          },
          feature:{
            radio_recording: false
          },
          playlist:{},
          base_urls: {
            share_url: 'https://www.lamusica.com',
            core_api: 'https://api.lamusica.com',
            rec_engine: 'https://rec-engine.lamusica.com/api/v2',
            chat_api: 'https://chat-api.lamusica.com',
            gateway_api: 'http://api.clarity.abacast.com/gateway/getmedia.php',
            cards_api: 'https://cards.lamusica.com',
            auth_api: 'https://auth.lamusica.com'
          },
          chat: {
            // Repeated Messages
            time_window_for_repeated_messages: 60,
            max_amount_repeated_messages: 3,
            // Reports to Automatic Ban
            number_of_reports_for_automatic_ban: 5,
            automatic_ban_duration: 60 * 60 * 24,
            time_window_for_reports_to_automatic_ban: 60 * 60 * 24,
            // Max Total Messages
            max_total_messages_period_duration: 60,
            max_total_messages_per_period: 3,
            counter_refresh_second:30
          },
          store_urls: {
            ios: 'https://itunes.apple.com/us/app/lamusica/id466304751',
            android: 'https://play.google.com/store/apps/details?id=com.sbs.lamusica',
            fire_tv: 'https://www.amazon.com/Spanish-Broadcasting-System-LaMusica/dp/B07FFJPFZD',
            roku: 'https://channelstore.roku.com/details/291619/lamusica',
            alexa: 'https://www.amazon.com/Spanish-Broadcasting-System-LaMusica/dp/B07T2TSZG7'
          },
          pages: {
            terms_conditions: 'https://www.lamusica.com/termsofuse',
            dmca_compliance: 'https://www.lamusica.com/dmca',
            privacy_policy: 'https://www.lamusica.com/privacypolicy',
            ccpa_link: 'https://www.lamusica.com/privacy'
          },
          support_email: 'feedback@lamusica.com',
          all_environments: {},
          voicenotes: {
            active: true,
            frequency: 60 * 15 // seconds
          },
          recovery: {
            active: false,
            url: ''
          }
        }
      }
    },

    mounted () {},

    methods: {
      create (evt) {
        evt.preventDefault()
        this.$http.post('/configurations', {config: this.config}).then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Configuration file successfully created :)',
              text: '',
              type: 'success'
            })
            return this.$router.push(`/admin/configurations/edit/${this.config.title}`)
          }
          this.$notify({
            group: 'notifications',
            title: 'Something went wrong :(',
            text: '',
            type: 'error'
          })
        }).catch((err) => {
          console.log(err)
          this.$notify({
            group: 'notifications',
            title: 'Sorry, something went wrong :(',
            text: '',
            type: 'error'
          })
        })
      },
      addVersion (collection, version) {
        let isDuplicated = false
        if (!version || version === '') {
          return this.$notify({
            group: 'notifications',
            title: 'A version value is required :(',
            text: '',
            type: 'error'
          })
        }

        for (let index = 0; index < collection.length; index++) {
          const element = collection[index]
          if (element === version) {
            isDuplicated = true
            break
          }
        }

        if (isDuplicated) {
          return this.$notify({
            group: 'notifications',
            title: 'Duplicated version is not allowed :(',
            text: '',
            type: 'error'
          })
        }
        collection.push(version)
      },
      removeConfig (collection, index) {
        collection.splice(index, 1)
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .config-component .card {
    padding: 30px;
  }

  .config-component .card input {
    margin-bottom: 0 !important;
  }

  .config-component .card label.section-title {
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .spacer {
    margin-top: 40px;
  }

  .row-form {
    margin-bottom: 20px;
  }

  .bootstrap-form button {
    display: initial;
  }

  .bootstrap-form input.live_versions {
    margin-bottom: 10px !important;
  }

  .config-component .card .tags-btn {
    width: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 4px;
    color: #fff;
    font-style: oblique;
    background-color: #f2494a;
  }

  .bootstrap-form button {
    font-size: 12px;
    line-height: 25px;
    text-transform: uppercase;
  }
</style>
