<template>
  <div>
    <img :src="src" :alt="alt" ref="ImageWithResolution" @load="setWidthHeight">
    <small v-if="width && height" class="d-block text-center">{{width}}x{{height}} ({{alt}})</small>
  </div>
</template>

<script>
export default {
  name: "ImageWithResolution",
  props: {
    src: {
      type: String
    },
    alt: {
      type: String
    }
  },
  data () {
    return {
      width: '',
      height: ''
    }
  },
  methods: {
    setWidthHeight () {
      const img = this.$refs.ImageWithResolution
      this.width = img.naturalWidth
      this.height = img.naturalHeight
    }
  }
}
</script>

<style scoped>
  img {
    max-width: 100%;
  }
</style>
