<template>
    <div class="cards-type-playlist">
      <div class="container">
        <div class="col-8 offset-2">
          <b-form @submit="save" class="bootstrap-form">

            <b-form-row>
              <b-col>
                <b-input-group>
                  <b-form-input readonly required v-model="cardEpisode.id"></b-form-input>
                  <b-input-group-append>
                    <b-btn variant="info" class="card-btn btn-add btn-modal" @click="addPodcast()">Add Episode</b-btn>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-form-row>

            <base-card-form :card="cardEpisode" :imageSizes="imageUpload" :video="video">
              <b-form-row>
                <b-col>
                  <b-form-group label="Start Duration (In Seconds)">
                    <b-form-text  style="margin-top: 0;">Please leave blank if you do not want this episode card to start a specific second</b-form-text>
                    <b-form-input
                                  id="startDuration"
                                  label="Start Duration (In Seconds)"
                                  type="number"
                                  number
                                  v-model="cardEpisode.startDuration">
                    </b-form-input>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group label="End Duration (In Seconds)">
                    <b-form-text style="margin-top: 0;">Please leave blank do not want this this episode card to end a specific second</b-form-text>
                    <b-form-input id="startDuration"
                                  label="End Duration (In Seconds)"
                                  type="number"
                                  number
                                  v-model="cardEpisode.endDuration">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </base-card-form>

            <div class="wrapper-btn">
              <!-- <b-button variant="primary" class="card-btn btn-add btn-add-img" @click="addImage">Add Images</b-button> -->
              <b-button type="submit" variant="primary" class="card-btn">{{type === 'create' ? 'Create Card' : 'Update Card'}}</b-button>
            </div>
          </b-form>
        </div>
      </div>

      <b-modal id="addPodcast" ref="addPodcast" :ok-only="true" ok-title="Add" @ok="addTocard" size="md" title="Select Podcast">
        <div class="row modal-wrapper">
          <div class="col-12">
            <input type="text" placeholder="Find Podcast..." v-model="queryPodcast" />
          </div>
          <div class="col-12">
            <ul class="playlistUpdated-list">
              <li v-for="podcast in queryResults" v-bind:key="podcast._id" @click="selectPodcast(podcast)" v-bind:class="{selected: podcast.selected}">
                <div class="playlists-list-card-img">
                    <img :src="podcast.meta.exploreImagery.hdpiImageURL" alt="" v-if="podcast.meta && podcast.meta.exploreImagery && podcast.meta.exploreImagery.hdpiImageURL" />
                    <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt="" v-if="!podcast.meta || !podcast.meta.exploreImagery || !podcast.meta.exploreImagery.hdpiImageURL"/>
                </div>
              </li>
            </ul>
          </div>

          <div class="col-12" v-if="episodes.length">
            <ul class="playlistUpdated-list episodes">
              <li v-for="(episode, index) in episodes" v-bind:key="episode._id" @click="selectEpisode(index, episode)" v-bind:class="{selected: episode.selected}">
                <!-- <div class="playlists-list-card-img">
                    <img :src="podcast.meta.exploreImagery.hdpiImageURL" alt="" v-if="podcast.meta && podcast.meta.exploreImagery && podcast.meta.exploreImagery.hdpiImageURL" />
                    <img src="/static/img/LaMusica-Icon-playlist-placeholder.jpg" alt=""/>
                </div> -->
                <div class="playlist-info">
                  <div class="playlist-title">{{episode.title}}</div>
                </div>
              </li>

              <li v-if="episodesPagination.hasMore" class="load-more-wrapper">
                <div>
                  <b-button v-if="!episodesPagination.loading" type="submit" variant="primary" @click="loadEpisodes">Load More</b-button>
                  <LoaderInline v-if="episodesPagination.loading"/>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </b-modal>

    </div>

</template>


<script>
import { Datetime } from 'vue-datetime'
import CardTypeCommon from './CardTypeCommon'
import LoaderInline from '../../../share/LoaderInline'
import BaseCardForm from './BaseCardForm'
export default {
  name: 'MediaType',
  components: {
    Datetime,
    LoaderInline,
    BaseCardForm
  },
  props: ['card', 'type'],
  data () {
    const utils = new CardTypeCommon(this)
    return {
      formData: new FormData(),
      utils: utils,
      video: utils.uploads.video[0],
      imageUpload: utils.uploads.images,
      minDatetime: utils.configuration.minDatetime,
      podcasts: [],
      episodes: [],
      podcastSelected: null,
      episodeSelected: null,
      queryPodcast: '',
      episodesPagination: {
        offset: 0,
        limit: 100,
        hasMore: false,
        loading: false
      }
    }
  },
  computed: {
    cardEpisode: {
      get () {
        return this.card
      },
      set (value) {
        this.card = value
      }
    },
    queryResults () {
      return this.podcasts.filter(podcast => {
        return podcast.title.toLowerCase().includes(this.queryPodcast.toLowerCase())
      })
    }
  },
  mounted () {
    this.utils.setCardDefaultValues()
  },
  methods: {
    addImage () {
      let images = this.imageUpload.filter((img) => {
        return img['750x750'] && img['1000x1100']
      })

      if (images.length === this.imageUpload.length) {
        this.imageUpload.push({
          file750: null,
          '750x750': null,
          placeholder750: 'Image 750 x 750px',
          width750: 750,
          height750: 750,

          file1000: null,
          '1000x1100': null,
          placeholder1000: 'Image 1000 x 1100px',
          width1000: 1000,
          height1000: 1100
        })
      }
    },
    removeImage (index) {
      this.imageUpload.splice(index, 1)
    },
    uploadVideo (event) {
      this.utils.handleVideoUpload(event)
    },
    removeVideoCard () {
      this.utils.handleRemoveVideoCard()
    },
    addPodcast () {
      EventHandler.emit('isLoading', true)
      this.$http.get('/podcasts', {params: {limit: 0, page: 1, published: 1}})
        .then((res) => {
          this.podcasts = res.data.podcasts
          this.episodes = []
          this.episodesPagination.offset = 0
          EventHandler.emit('isLoading', false)
          this.$refs.addPodcast.show()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    selectPodcast (podcast) {
      this.episodes = []
      this.episodesPagination.offset = 0

      if (podcast.selected) {
        podcast.selected = false
      } else {
        this.podcasts.forEach((item) => {
          if (item.selected) {
            item.selected = false
          } else {
            this.$set(item, 'selected', false)
          }
        })
        this.$set(podcast, 'selected', true)
        this.podcastSelected = podcast

        this.$http.get(`/podcasts/${podcast._id}`)
          .then(() => {
            this.loadEpisodes()
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    loadEpisodes () {
      this.episodesPagination.loading = true
      this.$http.get(`/podcasts/${this.podcastSelected._id}/episodes?limit=${this.episodesPagination.limit}&offset=${this.episodesPagination.offset}&status[]=published`)
        .then((response) => {
          const episodesData = response.data

          this.episodesPagination.offset += this.episodesPagination.limit
          this.episodesPagination.hasMore = episodesData.hasMore
          this.episodesPagination.loading = false

          episodesData.episodes.forEach((episode) => {
            this.$set(episode, 'selected', false)
            this.episodes.push(episode)
          })
        })
    },
    selectEpisode (index, episode) {
      this.episodes.forEach((episode) => {
        episode.selected = false
      })
      this.episodes[index].selected = true
      this.episodeSelected = episode
    },
    addTocard () {
      if (!this.episodeSelected || !this.episodeSelected.selected) {
        this.$notify({
          group: 'notifications',
          title: 'No Episode selected :(',
          text: '',
          type: 'error'
        })

        return
      }

      this.cardEpisode.id = this.episodeSelected._id
      this.cardEpisode.titleEnglish = this.episodeSelected.title
      this.cardEpisode.titleSpanish = this.episodeSelected.title
      this.cardEpisode.podcast_id = this.podcastSelected._id
      if (this.episodeSelected.available &&
          this.episodeSelected.available.start &&
          this.episodeSelected.available.end) {
        this.cardEpisode.available = this.episodeSelected.available
      }
    },
    save (evt) {
      evt.preventDefault()
      this.cardEpisode.type = 'media'

      if (!this.cardEpisode.id) {
        this.$notify({
          group: 'notifications',
          title: 'Episode selection is required :(',
          text: '',
          type: 'error'
        })

        return
      }

      if (!this.utils.validateCardBeforeSave()) {
        return
      }

      if (this.type === 'create') {
        this.create()
      } else {
        this.update()
      }
    },
    create () {
      this.$http.post('/cards', {card: this.cardEpisode})
        .then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Card created successfully :)',
              text: '',
              type: 'success'
            })
            this.$router.push('/admin/cards')
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Images are required :)',
              text: '',
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    update () {
      this.cardEpisode.id = this.cardEpisode.id.toString()
      this.$http.put('/cards', {card: this.cardEpisode})
        .then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Card updated successfully :)',
              text: '',
              type: 'success'
            })
            this.$router.push('/admin/cards')
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Images are required :)',
              text: '',
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style>
  .card-image video {
    width: 100%;
    margin-bottom: 10px;
  }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .cards-type-playlist {
  margin-top: 6%;
  height: calc(100vh - 210px);
  overflow: scroll;
} */

.cards-type-playlist .custom-file {
  margin-bottom: 15px;
}

.cards-type-playlist .wrapper-btn{
  display: flex;
  justify-content: flex-end;
}

.cards-type-playlist .card-btn{
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  text-transform: uppercase;
}

.cards-type-playlist .btn-add{
  margin-right: 10px;
}

.cards-type-playlist .btn-modal{
  height: 45px;
  margin-right: 0;
}

.card-image img, .card-image video{
  width: 100%;
  margin-bottom: 10px;
}

ul.playlistUpdated-list{
    list-style-type: none;
    overflow-y: scroll;
    /* max-height: 400px; */
    padding: 3px;
    display: flex;
}

ul.playlistUpdated-list .playlist-title{
  font-size: 12px;
  font-weight: bold;
}

ul.playlistUpdated-list .playlist-tracks{
  font-size: 10px;
  font-weight: bold;
}

ul.playlistUpdated-list li {
  list-style-type: none;
  display: flex;
  background-color: #fff;
  margin-bottom: 10px;
  height: 65px;
  align-items: center;
  border: 1px solid #eaeaea;
  cursor: pointer;
}

ul.playlistUpdated-list li.selected img {
  transition: all .3s;
  transform: scale(1.2)
}

ul.playlistUpdated-list li .playlists-list-card-img {
  margin-right: 5px;
}

ul.playlistUpdated-list li .playlists-list-card-img img{
  height: 65px;
  width: auto;
}

ul.playlistUpdated-list.episodes {
  display: block;
  max-height: 500px;
  margin-top: 20px;
}

ul.playlistUpdated-list.episodes .selected {
  background-color: #e8e8e8;
}

ul.playlistUpdated-list.episodes .playlist-info {
  padding: 0 15px;
}

ul.playlistUpdated-list li.load-more-wrapper {
  height: auto;
  text-align: center;
  background: transparent;
  border: none;
  align-items: center;
  align-content: center;
}

.load-more-wrapper > div {
  width: 100%;
}

.load-more-wrapper > div button {
  background-color: #f1494a;
  display: inline-block;
  padding: 5px 20px;
  color: #fff;
  cursor: pointer;
  border-radius: 0;
  border: none;
  width: 100%;
}

.image-group{
  display: flex;
  flex: 1;
  border: 1px solid #eaeaea;
  padding-top: 15px;
  margin-bottom: 15px;
  position: relative;
  flex-wrap: wrap;
}

.image-group .remove-img{
    position: absolute;
    top: -10px;
    right: -10px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #eaeaea;
    background-color: #eaeaea;
    text-align: center;
    cursor: pointer;
}

.video-labels {
  font-weight: bold;
  font-size: 12px;
}

</style>
