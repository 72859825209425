<template>
  <div class="cards-all-component">
    <div class="page-header">
      <div class="typeahead-wrapper">
        <div class="typeahead">
          <input id="input" class="form-control" type="text" placeholder="Search cards" v-model="query" @keyup.13="search">
          <div class="btn btn-search" @click="search">
            <i class="fas fa-search"></i>
          </div>
        </div>
        <div class="add-btn" @click="addCard">Add Card</div>
      </div>

      <div class="controls-wrapper">
        <ul>
          <li class="card-types-navigation" v-for="cardType in cardTypes" :key="cardType.type" :class="{active: activeCardType === cardType.type}">
            <router-link :to="'?type=' + cardType.type">
              {{ cardType.label }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="page-content">

      <div class="cards-all-list">
        <transition-group tag="div" class="row" name="list-animation">
          <div class="col-md-3" v-for="(card, index) in cards" v-bind:key="card._id">
            <div class="cards-list-card">
              <router-link class="cards-list-card-img" :to="'/admin/cards/edit/' + card._id">
                <img :src="getCardImage(card.img[0])" alt="" v-if="card.img" :style="getCardStyle(card)"/>
              </router-link>

              <div class="cards-list-card-content">
                <div class="cards-list-card-title" v-if="card.titleSpanish || card.titleEnglish">
                  {{card.titleSpanish ? card.titleSpanish : card.titleEnglish}}
                </div>
                <div class="cards-list-card-title" v-if="!card.titleSpanish && !card.titleEnglish">
                  No title
                </div>
                <div class="submenu-actions">
                  <div class="icon">
                    <i class="fas fa-ellipsis-h"></i>
                  </div>
                  <div class="actions">
                    <ul>
                      <li>
                        <router-link :to="'/admin/cards/edit/' + card._id">Edit</router-link>
                      </li>
                      <li @click="duplicate(card)">Duplicate</li>
                      <li @click="confirm(card, index)">Delete</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>

    <b-modal id="confirmRemoveCard" ref="confirmRemoveCard" size="md" title="Confirm" @ok="handleRemove">
      <div class="row modal-wrapper">
        <div class="col-12">
          Are you sure want to remove it?
        </div>
      </div>
    </b-modal>

  </div>
</template>


<script>
export default {
  name: 'Builder',
  data () {
    return {
      query: '',
      cards: [],
      cardTypes: [
        {
          label: 'Video',
          type: 'video'
        },
        {
          label: 'Playlist',
          type: 'playlist'
        },
        {
          label: 'Podcast',
          type: 'podcast'
        },
        {
          label: 'Web Link',
          type: 'contest'
        },
        {
          label: 'Station',
          type: 'station'
        },
        {
          label: 'Episode',
          type: 'media'
        },
        {
          label: 'Artist',
          type: 'artist'
        },
        {
          label: 'Livestream',
          type: 'livestream'
        }
      ],
      toDelete: {
        index: 0,
        card: ''
      },
      activeCardType: new URLSearchParams(window.location.search).get('type') || 'video'
    }
  },
  mounted () {
    this.getCards()
      .then((res) => {
        this.cards = res.data
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    getCards () {
      const params = {
        params: {
          type: this.activeCardType
        }
      }

      if (this.activeCardType === 'video') {
        params.params.page_size = 80
      }
      return this.$http.get('/cards', params)
    },
    getCardImage (img) {
      let url
      if (typeof img['1000x1100'] === 'string' && img['1000x1100'].indexOf('http') === 0) {
        url = img['1000x1100']
      } else if (typeof img['750x750'] === 'string' && img['750x750'].indexOf('http') === 0) {
        url = img['750x750']
      }

      return url
    },
    getCardStyle (card) {
      const style = {}
      const available = card.available
      if (available && available.start && available.end) {
        const isExpired = new Date(available.end).getTime() < Date.now()
        const willBeAvailableInFuture = new Date(available.start).getTime() > Date.now()
        if (isExpired) {
          style['border-left'] = '3px solid #f1494a'
        } else if (willBeAvailableInFuture) {
          style['border-left'] = '3px solid #e6d141'
        }
      }
      return style
    },
    addCard () {
      let URL = '/admin/cards/new'
      if (this.activeCardType !== 'video') {
        URL += '/' + this.activeCardType
      }
      this.$router.push(URL)
    },
    search () {
      if (this.query !== '') {
        this.$http.get(`/cards/search?q=${this.query}`)
          .then((res) => {
            this.cards = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.getCards()
          .then((res) => {
            this.cards = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    confirm (card, index) {
      this.$refs.confirmRemoveCard.show()
      this.toDelete = {
        index: index,
        card: card
      }
    },
    handleRemove () {
      this.$http.delete('/cards', {params: {cardID: this.toDelete.card._id}})
        .then((res) => {
          if (res.success) {
            this.$notify({
              group: 'notifications',
              title: 'Card successfully removed :(',
              text: '',
              type: 'success'
            })
            this.cards.splice(this.toDelete.index, 1)
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Sorry something went wrong. Please try again :(',
              text: '',
              type: 'error'
            })
          }
        })
        .catch((err) => {
          console.log(err)
          this.$notify({
            group: 'notifications',
            title: 'Sorry something went wrong. Please try again :(',
            text: '',
            type: 'error'
          })
        })
    },
    duplicate (card) {
      delete card._id
      this.$store.commit('duplicateCard', card)
      this.$router.push(`/admin/cards/new/${card.type}`)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cards-all-component .page-content {
  margin-top: 120px;
}

.cards-all-component .page-header {
  padding-bottom: 15px;
}

.cards-all-component .controls-wrapper ul {
  max-width: none;
  margin-bottom: 0 !important;
  margin-top: 20px;
  li {
    padding: 0;
  }
  a {
    color: #2c3e50;
    padding: 5px 30px;
    display: block;
    &:hover {
      text-decoration: none;
    }
  }
  .card-types-navigation.active {
    background-color: #f2494a;
    a {
      color: #fff;
    }
  }
}

.cards-all-component .btn-search {
    height: 45px;
    background: #f2494a;
    border-radius: 0;
    color: #fff;
    line-height: 32px;
}

.cards-all-list {
  padding: 5px 0 20px;
}

.cards-list-card {
  background-color: #fff;
  margin-bottom: 20px;
  border: 1px solid #eaeaea;

  .cards-list-card-img img {
    width: 100%;
    cursor: pointer;
  }

  .cards-list-card-content {
    padding: 15px;
    display: flex;
    justify-content: space-between;
  }
}


.cards-list-card-title{
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
