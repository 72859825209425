<template>
  <div>
    <div class="loading" v-if="loading">
      <div class="content-wrapper">
        <div class="progress-status">
          {{loadingText}}
        </div>
        <LoaderInline/>
      </div>
    </div>

    <div class="alert alert-info mt-3">
      <strong>Please Note:</strong> You can search videos by ID or keywords, previously uploaded and published on MediaStream platform.
    </div>

    <div class="row">
      <div class="col-9">
        <b-input-group size="sm">
          <b-form-input placeholder="Search videos" v-model="searchText" @input="searchByText"></b-form-input>
          <b-input-group-append is-text>
            <b-form-checkbox switch class="mr-n2 mb-n1 pr-2" v-model="searchById" @change="searchIntegrations(true)">
              Search by ID
            </b-form-checkbox>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="col-3">
        <div class="controls-wrapper">
          <ul>
            <li class="asset-types-navigation" v-for="orientation in orientations" :key="orientation.key"
                :class="{active: currentOrientation === orientation.key}"
                v-if="orientation.key === currentOrientation"
                @click="setOrientation(orientation.key)"
            >
              {{ orientation.label }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <hr class="divider"/>
    <div class="row">
      <div class="col-lg-8 col-6">
        <div class="list-items">
          <div v-if="items.length > 0">
            <transition-group tag="div" class="row list-items-wrapper" name="list-animation">
              <div class="col-md-3 item-col" v-for="item in items" v-bind:key="item.file_name"
                   @click="preview(item)"
              >
                <div class="list-item" v-bind:class="{'selected': currentItem && currentItem.file_name === item.file_name}">
                  <asset-video-item :item="item"></asset-video-item>
                </div>
              </div>
            </transition-group>
          </div>
          <div v-else class="pt-2 pr-2 pb-2 pl-2">
            <b-alert variant="info" show>No files found.</b-alert>
          </div>
          <b-button block @click="loadMore" v-if="loadMoreVisible">Load More</b-button>
        </div>
      </div>
      <div class="col-lg-4 col-6">
        <div class="item-preview">
          <template v-if="items.length > 0">
            <assets-item-preview v-if="currentItem" :item="currentItem" :show-download="false" :show-encode="false" :show-select="true" @selected="onVideoSelected"></assets-item-preview>
            <div class="pt-5" v-if="!currentItem">
              <LoaderInline/>
            </div>
          </template>
          <div v-else class="pt-2 pr-2 pb-2 pl-2">
            <b-alert variant="info" show>No item selected.</b-alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AssetVideoItem from './AssetsVideoItem'
import LoaderInline from '../../../share/LoaderInline'
import AssetsItemPreview from '../preview/AssetsItemPreview'

export default {
  name: 'AssetsVideos',
  components: {LoaderInline, AssetVideoItem, AssetsItemPreview},
  props: {
    orientation: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      currentOrientation: null,
      orientations: [
        {
          label: 'Portrait',
          key: 'portrait'
        },
        {
          label: 'Landscape',
          key: 'landscape'
        },
      ],
      items: [],
      currentItem: null,
      endpointUrl: `${process.env.VUE_APP_TRANSCODING_API}/videos`,
      loadingText: '',
      loading: false,
      pagination: {
        limit: 10,
        offset: 0
      },
      loadMoreVisible: false,
      videoFile: null,
      currentVideoFile: null,
      searchText: '',
      searchTimeOut: null,
      searchById: true
    }
  },
  mounted () {
    this.currentOrientation = this.orientation
  },
  methods: {
    searchByText() {
      clearTimeout(this.searchTimeOut)
      this.searchTimeOut = setTimeout(() => {
        this.searchIntegrations(true)
      }, 500)
    },
    searchIntegrations(override = false) {
      if(this.searchText.length < 3) {
        if(this.searchText === '') {
          this.items = []
          this.loadMoreVisible = false
          this.currentItem = null
        }

        return false
      }

      this.loading = true
      this.loadingText = 'Retrieving videos'

      const data = {
        params: {},
        orientation: this.currentOrientation
      }

      if(this.searchById) {
        data.params.id = this.searchText
      } else {
        data.params.query = this.searchText
      }

      this.$http.post(`/videos/integrations/mediastream/search`, data)
        .then((response) => {
          this.loading = false
          this.loadingText = ''

          if (response.success) {
            this.items = override ? response.data.items : [...this.items, ...response.data.items]
            this.loadMoreVisible = response.data.hasMore
            if(this.items.length > 0 && !this.currentItem) {
              this.preview(this.items[0])
            }
          } else {
            this.loadMoreVisible = false
            this.$notify({
              group: 'notifications',
              title: 'Error',
              text: response.message,
              type: 'error'
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          this.loadingText = ''
          this.items = []
        })
    },
    setOrientation (orientation) {
      this.currentOrientation = orientation
      this.pagination.offset = 0
      this.videos = []
      this.currentItem = null
    },
    loadMore () {
      this.pagination.offset += this.pagination.limit
      this.searchIntegrations(true)
    },
    reload() {
      this.pagination.offset = 0
      this.currentItem = null
      this.videos = []
      this.searchIntegrations(true)
    },
    preview (item) {
      this.currentItem = null
      EventHandler.emit('isLoading', true)
      setTimeout(() => {
        this.currentItem = item
        EventHandler.emit('isLoading', false)
      }, 500)
    },

    onVideoSelected () {
      this.$emit('videoSelected', this.currentItem)
    }
  }
}
</script>
<style scoped lang="scss">
.list-items {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 -15px -15px;
  padding: 0 15px;

  .list-items-wrapper {
    margin-left: -10px;

    .item-col{
      padding: 0;

      .list-item {
        box-sizing: border-box;
        margin: .5em .15em;
        width: 100%;
        max-width: 171px;
        height: 150px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.4);

        &:hover, &.selected {
          border-color: rgba(0, 0, 0, 0.6);
          color: rgba(0, 0, 0, 0.8);
        }

        &.selected {
          border-width: 2px;
        }
      }
    }
  }
}

.item-preview {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -15px 0 0;
  height: 400px;
}

hr {
  &.divider {
    margin: 1em -15px 0;
  }
}

.controls-wrapper {

  ul {
    margin-bottom: 0 !important;

    li {
      &.active {
        background-color: #f2494a;
        color: #fff;
      }
    }
  }
}

.selected {
  .assets-video-card {
    border-color: #f2494a;
  }
}

.confirm-filename {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.loading {
  position: absolute;
  z-index: 100;
  left: 0;
  top: -63px;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, .9);

  .content-wrapper {
    position: absolute;
    width: 300px;
    height: 150px;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -125px;

    .progress-status {
      text-align: center;
      margin-top: 20px;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }
}
</style>
