<template>
  <div class="episodes-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="toolbar">
          <ul class="menu left">
            <li class="item" v-bind:class="{ active: currentTab === 'episodes' }" @click="selectTab('episodes')">Episodes</li>
            <li class="item" v-bind:class="{ active: currentTab === 'drafts' }" @click="selectTab('drafts')">Drafts</li>
          </ul>

          <ul class="menu right">
            <li class="item" @click="reload" v-if="showReloadButton"><i class="fas fa-sync"></i> Refresh Episodes</li>
            <li class="item" @click="showEpisodeFormModal('create')"><i class="fas fa-plus"></i> Create Episode</li>
            <!--        <li class="item" v-if="currentTab === 'drafts'">Plan Inventory</li>-->
            <li class="item" @click="showAssetsModal"><i class="fas fa-folder"></i> Files Manager</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-6">
        <div class="search-wrapper">
          <b-input-group size="sm">
            <b-form-input placeholder="Search episodes" v-model="searchData.query" @input="search"></b-form-input>
            <b-input-group-append is-text>
              <b-form-checkbox switch class="mr-n2 mb-n1 pr-2" v-model="searchData.byId" @change="load">
                Search by ID
              </b-form-checkbox>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <div class="col-6 text-right" v-if="currentTab !== 'drafts'">
        <b-form-checkbox-group
          v-model="searchData.status"
          :options="statuses"
          value-field="code"
          text-field="label"
          disabled-field="notEnabled"
          switches
          @change="load"
        ></b-form-checkbox-group>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-12"><div class="loading-wrapper" v-if="loading">
        <LoaderInline/>
      </div>
        <ul class="list" v-if="groups.length > 0">
          <template v-for="(group, groupIndex) in groups">
            <li class="header">
              <div class="label">{{group.name}}</div>
            </li>
            <li v-for="(episode, index) in group.episodes" v-bind:key="episode._id">
              <div class="episode-img" v-if="episode.image">
                <img :src="utilities.getPodcastEpisodeArtworkURL(episode.image)" alt=""/>
              </div>
              <div class="episode-controls-wrapper">
                <div class="icon-wrapper icon-wrapper-play" v-if="episode.audio_url">
                  <i class="fas fa-pause" v-if="currentPlay[`${groupIndex}_${index}`] === true" @click="stopAudio()"></i>
                  <i class="fas fa-play" v-else @click="playAudio(groupIndex, index)"></i>
                </div>
                <div class="icon-wrapper icon-wrapper-video" v-if="episode.video_url" @click="openVideoPlayer(episode.video_url)">
                  <i class="fas fa-video"></i>
                </div>

                <div class="player">
                  <div class="episode-title">{{episode.title}}</div>
                  <div class="episode-date"><strong>Duration:</strong> {{secondsToMinutes(episode.duration)}} - <strong>Publish Date:</strong> {{formatDateWithHour(episode.published_at)}} <span v-if="episode.number">- <strong>Episode Number: </strong> {{episode.number}}</span></div>
                  <div class="episode-status">
                    <episode-status :status="episode.status" :status-message="episode.status === 'failed' ? episode.status_message : null"></episode-status>
                    <file-status :status="episode.file_status"></file-status>
                  </div>
                </div>

                <div class="wavesurfer" :id="`wavesurfer_${groupIndex}_${index}`" v-bind:class="{show: currentPlay[`${groupIndex}_${index}`] === true, 'has-video': episode.video_url}"></div>

                <div class="copy icon-wrapper" v-if="episode.status === 'published'" @click="copyEpisodeUrl(episode._id)">
                  <i class="fas fa-link"></i>
                </div>

                <div class="copy icon-wrapper" @click="showEpisodeFormModal('edit', episode)">
                  <i class="fas fa-edit"></i>
                </div>

                <div class="remove icon-wrapper" v-if="episode.status === 'draft'" @click="showDeleteConfirmModal(episode)">
                  <i class="fas fa-trash-alt"></i>
                </div>

                <div class="remove icon-wrapper" v-else @click="showDeleteConfirmModal(episode)">
                  <i class="fas fa-file-alt"></i>
                </div>
              </div>
            </li>
          </template>
          <li v-if="pagination.hasMore && !loading" class="load-more-wrapper">
            <b-button type="submit" variant="primary" @click="loadMore">Load More</b-button>
          </li>
        </ul>
        <template v-if="groups.length === 0 && !loading">
          <b-alert v-if="searchData.query || searchData.status.length" show class="text-center">Cannot find episodes matching with the search criteria.</b-alert>
          <b-alert v-else show class="text-center">There are no episodes added yet.</b-alert>
        </template>
      </div>
    </div>
    <b-modal id="modalCopyLink" ref="modalCopyLink" size="md" title="Link copied" :ok-only="true">
      <div class="row modal-wrapper">
        <div class="col-12">
          <p>The link has been copied to the clipboard. Also you can click on the following link:</p>
          <p><a :href="clipboardUrl" target="_blank">{{ clipboardUrl }}</a></p>
        </div>
      </div>
    </b-modal>

    <b-modal id="assetsModal" ref="assetsModal" size="xl" title="Podcast Files Manager" :hide-footer="true"  :no-close-on-backdrop="true" :no-close-on-esc="true">
      <div class="modal-wrapper">
        <assets-podcasts :podcast-id="podcast._id" :show-download="true" :show-select="false" :show-encode="false"></assets-podcasts>
      </div>
    </b-modal>

    <b-modal id="createEpisodeModal" ref="createEpisodeModal" size="xl" :title="currentEpisode && currentEpisode._id ? `Edit Episode: ${currentEpisode._id}` : 'New Episode'" :hide-footer="true" :no-close-on-backdrop="true" :no-close-on-esc="true">
      <div class="modal-wrapper">
        <episode-form :podcast="podcast" :data="currentEpisode" v-if="currentEpisode" @close="closeEpisodeFormModal" @updated="onUpdatedEpisodeForm($event)"></episode-form>
      </div>
    </b-modal>

    <b-modal id="confirmDeleteEpisode" ref="confirmDeleteEpisode" size="md" title="Confirmation"
             :hide-header-close="true"
             :no-close-on-backdrop="true"
             :no-close-on-esc="true"
             @ok="deleteEpisode"
    >
      <div class="row modal-wrapper">
        <div class="col-12">
          <div v-if="currentEpisode && currentEpisode.status === 'draft'">Confirm you are sure you want to delete this draft.</div>
          <div v-else>Confirm you are sure you want to mark this episode as a draft.</div>
        </div>
      </div>
    </b-modal>

    <b-modal id="videoPlayerModal"
             ref="videoPlayerModal"
             size="lg"
             title="Video Player"
             :no-close-on-backdrop="true"
             :hide-header-close="true"
             :ok-only="true"
             ok-title="Close"
             @ok="handleVideoPlayerOk">
      <div class="row modal-wrapper">
        <div class="col-12">
          <video-player class="video-player-box"
                        ref="videoPlayer"
                        :options="videoPlayerOptions"
                        :playsinline="true"
                        v-if="videoPlayerOptions.sources[0].src">
          </video-player>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import * as _ from 'lodash'
import moment from 'moment-timezone'
import WaveSurfer from 'wavesurfer.js'
import LoaderInline from '../../../share/LoaderInline.vue'
import PodcastsCommon from '../PodcastsCommon'
import EpisodesManager from '../episodes/episodes-manager.vue'
import AssetsPodcasts from '../../assets/postcasts/AssetsPodcasts.vue'
import EpisodeStatus from '../episode-status.vue'
import FileStatus from '../file-status.vue'
import EpisodeForm from './episode-form.vue'

export default {
  name: 'EpisodesManager',
  components: { EpisodeForm, FileStatus, EpisodeStatus, AssetsPodcasts, LoaderInline, EpisodesManager },
  props: {
    podcast: {
      type: Object,
      required: true
    },
    episodesCount: {
      type: Number,
      required: true
    },
    reloadEpisodes: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    reloadEpisodes: function(value) {
     if(value) {
        this.$nextTick(() => {
          this.showReloadButton = true
        })
     }
    }
  },
  data() {
    return {
      utilities: new PodcastsCommon({context: this.podcast}),
      episodes: [],
      groups: [],
      sortingStarted: {},
      pagination: {
        timestamp: null,
        limit: 100,
        hasMore: false
      },
      loading: false,
      checkTimeOut: null,
      currentPlay: {},
      wavesurfer: null,
      clipboardUrl: '',
      statuses: [
        { code: 'published', label: 'Published' },
        { code: 'scheduled', label: 'Scheduled' },
        { code: 'encoding', label: 'Encoding' },
        { code: 'failed', label: 'Failed' },
      ],
      searchData: {
        query: null,
        byId: false,
        status: [],
      },
      currentTab: 'episodes',
      currentEpisodeNumber: 0,
      videoPlayerOptions: {
        language: 'en',
        responsive: true,
        sources: [{
          type: 'application/x-mpegURL',
          src: null
        }],
        techOrder: ['html5'],
        sourceOrder: true,
        flash: {
          hls: { withCredentials: false },
          swf: '/static/media/video-js.swf'
        },
        html5: { hls: { withCredentials: false } },
        fluid: true
      },
      currentEpisode: null,
      showReloadButton: false
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    selectTab(tab) {
      this.currentTab = tab
      this.searchData.query = null
      this.searchData.byId = false

      this.load()
    },

    getEpisodes () {
      this.loading = true
      this.stopAudio()

      const params = {
        limit: this.pagination.limit,
        timestamp: this.pagination.timestamp
      }

      if(this.searchData.byId) {
        params.byId = this.searchData.byId
      }

      if(this.currentTab === 'drafts') {
        params.draft = true
      } else {
        params.status = this.searchData.status
      }

      if(this.searchData.query) {
        params.query = this.searchData.query
      }

      return this.$http.get(`/podcasts/${this.podcast._id}/episodes`, { params })
    },
    load() {
      this.pagination.timestamp = null
      this.getEpisodes().then((response) => {
        if(response.success) {
          this.processLoadedEpisodes(response.data, true)
        } else {
          this.$notify({
            group: 'notifications',
            title: 'Error',
            text: response.message,
            type: 'error'
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    search() {
      if(this.searchData.query && this.searchData.query.length < 3) {
        return
      }

      clearTimeout(this.checkTimeOut)

      this.checkTimeOut = setTimeout(() => {
        this.load()
      }, 500)
    },
    loadMore() {
      this.getEpisodes().then((response) => {
        if(response.success) {
          this.processLoadedEpisodes(response.data)
        } else {
          this.loading = false
        }
      }).finally(() => {
        this.loading = false
      })
    },
    reload() {
      this.load()
      this.showReloadButton = false
    },
    processLoadedEpisodes (episodesData, override = false) {
      this.pagination.timestamp = episodesData.timestamp
      this.pagination.hasMore = episodesData.hasMore

      if(override) {
        this.episodes = episodesData.episodes
      } else {
        this.episodes = [...this.episodes, ...episodesData.episodes]
      }

      this.groups = this.generateGroups(this.episodes)
    },

    generateGroups (episodes) {
      this.currentEpisodeNumber = this.episodesCount
      episodes = JSON.parse(JSON.stringify(episodes))
      let result = _.groupBy(episodes, (item) => {
        return moment(item.published_at).format('MMMM Do, YYYY')
      })

      result = Object.keys(result).map((item) => {
        return {
          name: item,
          episodes: result[item].map((episode) => {
            if(episode.status === 'published') {
              episode.number = this.currentEpisodeNumber--

              if(episode.file_status && (episode.file_status.audio && !episode.file_status.audio.code)) {
                episode.file_status.audio.code = 'success'
                episode.file_status.audio.label = 'The file is encoded'
              }
            }

            return episode
          })
        }
      })

      this.sortingStarted = {}

      return result
    },

    async playAudio (groupIndex, index) {
      const key = `${groupIndex}_${index}`

      if (Object.keys(this.currentPlay).length > 0) {
        this.stopAudio()
      }

      try {
        this.wavesurfer = WaveSurfer.create({
          container: `#wavesurfer_${key}`,
          height: '60',
          backend: 'MediaElement',
          waveColor: '#FD8283',
          progressColor: '#f2494a',
          responsive: true,
          barWidth: 2
        })

        this.wavesurfer.load(this.groups[groupIndex].episodes[index].audio_url)
        this.$set(this.currentPlay, key, true)
        await this.wavesurfer.play()
      } catch (error) {
        console.log(error)
        this.$notify({
          group: 'notifications',
          title: 'Error',
          text: 'Error playing the episode',
          type: 'error'
        })
        this.$set(this, 'currentPlay', {})
      }
    },

    stopAudio () {
      if(this.wavesurfer) {
        this.wavesurfer.pause()
        this.wavesurfer.empty()
        this.wavesurfer.destroy()
        this.wavesurfer = null
        this.$set(this, 'currentPlay', {})
      }
    },

    copyEpisodeUrl(episodeId) {
      this.clipboardUrl = `https://www.lamusica.com/podcasts/${this.podcast.meta.slug}/episodes/${episodeId}`
      const el = document.createElement('textarea')
      el.value = this.clipboardUrl
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.$refs.modalCopyLink.show()
    },

    showAssetsModal () {
      this.$refs.assetsModal.show()
    },

    openVideoPlayer (url) {
      this.$refs.videoPlayerModal.show()
      this.videoPlayerOptions.sources[0].src = url
    },

    handleVideoPlayerOk () {
      this.$refs.videoPlayerModal.hide()
      this.videoPlayerOptions.sources[0].src = null
    },

    showEpisodeFormModal(action, data = null) {
      this.currentEpisode = action === 'create' ? this.utilities.createNewEpisodeWithEmptySchema() : JSON.parse(JSON.stringify(data))
      if(!this.currentEpisode.files) {
        this.$set(this.currentEpisode, 'files', {
          audio: {
            url: null,
            is_new: false,
            options: {}
          },
          video: {
            url: null,
            is_new: false,
            options: {
              extract_audio: false
            }
          }
        })
      } else {
        if(!this.currentEpisode.files.audio ||  typeof this.currentEpisode.files.audio === 'string') {
          this.$set(this.currentEpisode.files, 'audio', {
            url: this.currentEpisode.files.audio || null,
            is_new: false,
            options: {}
          })
        }

        if(!this.currentEpisode.files.video || typeof this.currentEpisode.files.video === 'string') {
          this.$set(this.currentEpisode.files, 'video', {
            url: this.currentEpisode.files.video || null,
            is_new: false,
            options: {
              extract_audio: false
            }
          })
        }
      }

      if(Array.isArray(this.currentEpisode.meta.artist_guest)) {
        this.currentEpisode.meta.artist_guest = this.currentEpisode.meta.artist_guest.filter((item) => {
          return item.trim() !== ''
        }).join(',')
      }

      if(Array.isArray(this.currentEpisode.meta.tags)) {
        this.currentEpisode.meta.tags = this.currentEpisode.meta.tags.filter((item) => {
          return item.trim() !== ''
        }).join(',')
      }

      if(!this.currentEpisode.ads) {
        this.currentEpisode.ads = { audio: { prerolls: 0, postrolls: 0 } }
      }

      this.$refs.createEpisodeModal.show()
    },

    closeEpisodeFormModal() {
      this.currentEpisode = null
      this.$refs.createEpisodeModal.hide()
    },

    onUpdatedEpisodeForm(data) {
      this.closeEpisodeFormModal()
      this.load()
      this.$emit('updated')
    },

    showDeleteConfirmModal(episode) {
      this.currentEpisode = episode
      this.$refs.confirmDeleteEpisode.show()
    },

    deleteEpisode() {
      const callPromise = this.currentEpisode.status !== 'draft'
        ? this.$http.put(`/podcasts/${this.podcast._id}/episodes/${this.currentEpisode._id}/draft`)
        : this.$http.delete(`/podcasts/${this.podcast._id}/episodes/${this.currentEpisode._id}`)

        callPromise.then((response) => {
          if(response.success) {
            this.$notify({
              group: 'notifications',
              title: 'Success',
              text: response.message,
              type: 'success'
            })
            this.currentEpisode = null
            this.load()
            this.$emit('updated')
          } else {
            this.$notify({
              group: 'notifications',
              title: 'Error',
              text: response.message,
              type: 'error'
            })
          }
        })
        .catch((error) => {
        console.log(error)
        this.$notify({
          group: 'notifications',
          title: 'Something went wrong :(',
          text: '',
          type: 'error'
        })
      })
    }
  }
}
</script>
<style lang="scss">
.bv-no-focus-ring {
  .custom-control-inline {
    min-width: 12%;
    margin-right: 20px;
  }
}
</style>
<style scoped lang="scss">
.episodes-wrapper {
  .toolbar {
    position: relative;
    width: 100%;
    height: 30px;
    margin: 20px auto 10px;
    border-bottom: 1px solid rgba(0,0,0,0.1);

    .menu {
      line-height: 30px;
      //padding: 0 10px;

      &.left {
        float: left;

        .item {
          margin-right: 20px;

          &:hover {
            color: #21343a;
          }

          &.active {
            color: #21343a;
            border-bottom: 2px solid #f2494a;
            background-color: inherit;
          }
        }
      }

      &.right {
        float: right;
        margin-top: -5px;

        .item {
          border: 1px solid red;
          padding: 0 10px;
          margin-right: 10px;
          border-radius: 4px;
          background-color: #f2494a;
          color: #fff;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .item {
        vertical-align: top;
        display: inline-block;
        width: auto;
        height: 30px;
        color: #6a6e73;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }


hr {
  margin: 0.5rem 0;
}

.search-wrapper {
  input {
    font-size: 14px;
    height: 30px;
    border-radius: 0;
    border-color: #eaeaea;

    &:focus {
      box-shadow: none;
    }
  }
}

.controls-wrapper{
    ul{
      margin-bottom: 0 !important;
    }
  }

  .loading-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(247, 247, 247, 0.95);
    z-index: 1;

    .loader-inline {
      margin-top: calc(50vh - 230px);
    }
  }

  .list {
    height: calc(100vh - 540px);
    overflow-x: hidden;
    overflow-y: auto;

    li {
      display: flex;
      align-items: center;
      background-color: #fff;
      height: 80px;
      margin-bottom: 10px;
      border: 1px solid #eaeaea;

      &.sortable-ghost {
        opacity: 0.3;
      }

      &.header {
        height: 20px;
        border: none;
        background: none;

        div{
          display: inline-block;
          width: 50%;

          &.label {
            font-weight: 600;
          }

          &.actions {
            text-align: right;

            button {
              padding: 5px 10px 3px;
              margin: 9px 0 5px 0;
              text-align: center;
              background: #f2494a;
              border: 1px solid #f2494a;
              color: #fff;
              text-transform: uppercase;
              cursor: pointer;
              font-size: 12px;
              border-radius: 0;

              &:disabled {
                cursor: not-allowed;
              }
            }
          }
        }
      }

      .episode-order {
        min-width: 40px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        font-size: 12px;
        font-weight: 900;
        border-right: 1px solid #eaeaea;
      }

      .episode-img {
        padding-left: 5px;
        padding-right: 5px;
        border-right: 1px solid #eaeaea;
        height: 80px;
        line-height: 80px;

        img {
          width: 60px;
          cursor: move;
          border-radius: 4px;
        }
      }

      &.load-more-wrapper {
        height: auto;
        text-align: center;
        background: transparent;
        border: none;
        align-items: center;
        align-content: center;

        button {
          background-color: #f1494a;
          display: inline-block;
          padding: 5px 20px;
          color: #fff;
          cursor: pointer;
          border-radius: 0;
          border: none;
          width: 100%;
        }
      }
    }
  }

  .episode-controls-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;

    .wavesurfer{
      position: absolute;
      right: 130px;
      left: 50px;
      background: #fff;
      border: 1px solid #eaeaea;
      display: none;

      &.show{
        display: block;
      }

      &.has-video {
        left: 90px;
      }
    }

    .wavesurfer canvas{
      max-width: none;
    }

    .icon-wrapper {
      width: 40px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      border-left: 1px solid #eaeaea;
      flex-shrink: 0;

      &.icon-wrapper-video {
        border-left: none;
        border-right: 1px solid #eaeaea;
      }
    }

    .icon-wrapper-play {
      border-left: none;
      border-right: 1px solid #eaeaea;
    }

    .player {
      margin-right: 15px;
      flex: 1;
      position: relative;

      .episode-title {
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 500;
        padding-left: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 650px;
        white-space: nowrap;
      }

      .episode-status {
        padding-left: 10px;
        font-size: 12px;
      }

      .episode-number{
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        text-align: center;
        background: rgba(0, 0, 0, .2);
        border-radius: 5px;
        font-size: 11px;
        font-weight: 600;
        line-height: 20px;
      }

      .episode-date {
        padding-left: 10px;
        font-size: 12px;
        font-style: normal;
      }
    }
  }
}
</style>
