<template>
    <div class="artist-all-component">

      <div class="page-header">
        <div class="typeahead-wrapper">
          <div class="typeahead">
            <input id="input" class="form-control" type="text" placeholder="Explore Artists Collection..." v-model="query"  @keyup.13="search">
            <div class="btn btn-search" @click="search">
              <i class="fas fa-search"></i>
            </div>
          </div>
          <router-link class="add-btn" to="/admin/artists/new" tag="div">Add Artist</router-link>
        </div>
      </div>

      <div class="page-content">
        <div class="artist-all-list">
          <transition-group tag="div" class="row" name="list-animation">
            <div class="col-md-4" v-for="(artist, index) in artists" v-bind:key="artist._id">
              <div class="artist-card">
                <div class="artist-img" @click="selectArtist(artist)">
                    <img :src="artist.image" alt="" v-if="artist.image"/>
                    <img src="/static/img/LaMusica-Icon-artist-placeholder.png" alt="" v-if="!artist.image"/>
                </div>
                <div class="artist-controls">
                    <div class="artist-title">{{artist.name}}</div>

                    <div class="submenu-actions">
                      <div class="icon"  @click="confirm(artist, index)">
                        <i class="fas fas fa-times"></i>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </transition-group>
        </div>

        <b-pagination v-if="pages > 1 && displayPaging" align="right" size="md" :total-rows="limit * pages" v-model="current" :per-page="limit" @change="getResults"></b-pagination>
      </div>


      <b-modal id="confirmRemoveArtist" ref="confirmRemoveArtist" size="md" title="Confirm" @ok="handleRemove(toDelete)">
        <div class="row modal-wrapper">
          <div class="col-12">
            Are you sure want to remove <strong>{{toDelete.artist.name}}</strong> artist?
          </div>
        </div>
      </b-modal>
    </div>
</template>


<script>
import typeahead from '../../share/typeahead'

export default {
  name: 'MediaAll',
  components: {
    typeahead
  },
  data () {
    return {
      artists: [],
      limit: 60,
      pages: 1,
      current: 1,
      query: '',
      displayPaging: true,
      queryArtist: '',
      toDelete: {
        index: 0,
        artist: ''
      },
      label: null
    }
  },
  computed: {
    queryResults () {
      return this.artists.filter(artist => {
        return artist.name.toLowerCase().includes(this.queryArtist.toLowerCase())
      })
    }
  },
  mounted () {
    this.$http.get('/artists', {params: {limit: this.limit, page: this.pages, label: this.label}})
      .then((res) => {
        this.artists = res.data.artists
        this.pages = res.data.pages
        this.current = res.data.current
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    confirm (artist, index) {
      this.$refs.confirmRemoveArtist.show()
      this.toDelete = {
        index: index,
        artist: artist
      }
    },
    handleRemove (toDelete) {
      this.$http.delete('/artists', {params: {artistID: toDelete.artist._id}})
        .then((res) => {
          this.$notify({
            group: 'notifications',
            title: 'Artist successfully removed :(',
            text: '',
            type: 'success'
          })
          this.artists.splice(toDelete.index, 1)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    search () {
      if (this.query === '') {
        this.pages = 1
        this.$http.get('/artists', {params: {limit: this.limit, page: this.pages}})
          .then((res) => {
            this.displayPaging = true
            this.artists = res.data.artists
            this.pages = res.data.pages
            this.current = res.data.current
          })
          .catch((err) => {
            console.log(err)
          })
        return
      }
      this.$http.get(`/artists/search?q=${this.query}`)
        .then((res) => {
          this.artists = res.data
          this.displayPaging = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    selectArtist (artist) {
      this.searchList = []
      this.$router.push('artists/edit/' + artist._id)
    },
    getResults (page) {
      this.$http.get('/artists', {params: {limit: this.limit, page: page}})
        .then((res) => {
          this.artists = res.data.artists
          this.pages = res.data.pages
          this.current = res.data.current
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.artist-all-component .page-header .controls-wrapper ul{
  margin-bottom: 0 !important;
}

.artist-all-component .page-header .controls-wrapper ul li.active{
  background-color: #f2494a;
  color: #fff;
}

.artist-all-component .submenu-actions .icon {
  height: 60px;
  line-height: 60px;
  width: 60px;
  border-left: 1px solid #eaeaea;
  font-size: 14px;
  cursor: pointer;
}

.artist-all-list{
  padding: 5px 0;
  margin-bottom: 25px;
}

.artist-all-list .artist-card {
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 60px;
  margin-bottom: 10px;
  border: 1px solid #eaeaea;
  overflow: hidden;
}

.artist-all-list .artist-card .artist-img {
  margin-right: 10px;
}

.artist-all-list .artist-card img{
  width: 60px;
  cursor: pointer;
}

.artist-all-list .artist-card .artist-controls{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between
}

.artist-title {
  font-size: 12px;
  font-weight: bold;
}

.btn-search{
  height: 45px;
  background: #f2494a;
  border-radius: 0;
  color: #fff;
  line-height: 32px;
}

@media (max-width: 768px) {
  .add-btn {
    width: 100px;
  }
}


</style>
