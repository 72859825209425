<template>
  <div class="login-wrapper">
    <form class="login-form" @submit="submit" v-if="!showNotification">
      <label>Forgot Password?</label>
      <input type="email" placeholder="Email" v-model="email" required/>
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>

    <div class="register" v-if="!showNotification">
      <router-link to="register">Don't have an account? Sign Up</router-link>
    </div>

    <div class="notification" v-if="showNotification">
      <span>Check email to change your password</span>
      <div class="btn-wrapper">
        <button class="btn btn-primary" @click="redirectUSer">Got It</button>
      </div>
    </div>
  </div>
</template>


<script>
  export default {
    name: 'forgot',
    data () {
      return {
        email: '',
        showNotification: false
      }
    },
    methods: {
      submit (evt) {
        evt.preventDefault()
        EventHandler.emit('isLoading', true)
        this.$http.post('/auth/forgot', {email: this.email})
          .then((res) => {
            if (res.success) {
              // Notify user to check email to click the link to reset password
              this.showNotification = true
            } else {
              this.$notify({
                group: 'notifications',
                title: res.message,
                text: '',
                type: 'error'
              })
            }
            EventHandler.emit('isLoading', false)
          })
          .catch((err) => {
            console.log(err)
            this.$notify({
              group: 'notifications',
              title: 'There was a problem with your request. Please try again :(',
              text: '',
              type: 'error'
            })
            EventHandler.emit('isLoading', false)
          })
      },
      redirectUSer () {
        this.$router.push('/')
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .login-wrapper label{
    color: #2c3e50;
    text-transform: uppercase;
    font-weight: 900
  }

  .login-wrapper .login-form input{
    display: block;
    width: 100%;
    height: 45px;
    padding: 0 10px;
    margin-bottom: 15px;
    border: 1px solid #eaeaea;
  }

  .login-wrapper .login-form a{
    color: #fff;
    display: block;
    padding: 10px;
    text-align: center;
    margin-bottom: 15px;
    background-color: #f2494a;
  }

  .login-wrapper .login-form a:hover{
    background-color: #de4242;
    text-decoration: none;
  }

  .login-wrapper .register{
    text-align: center;
  }

  .login-wrapper .register a{
    color: #f2494a;
    font-size: 12px;
  }

  .login-wrapper .register.forgot a{
    font-size: 10px;
  }

  button{
    color: #fff;
    display: block;
    padding: 10px;
    text-align: center;
    margin-bottom: 15px;
    background-color: #f2494a;
    border: none;
    border-radius: 0;
    width: 100%;
  }

  button:hover,
  button:active,
  button:active:focus{
    background-color: #de4242 !important;
    border: none !important;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus{
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .notification {
    color: #de4242;
    font-size: 13px;
    text-transform: uppercase;
    padding: 15px;
    border: 1px solid #eaeaea;
    font-weight: bold;
  }

  .notification span {
    display: block;
    margin-bottom: 10px;
  }

  .notification .btn-wrapper button {
    margin-bottom: 0;
  }

</style>
