<template>
    <div class="artists-component">
      <router-view />
    </div>
</template>


<script>

export default {
  name: 'Artists',
  data () {
    return {
    }
  },

  mounted () {
  },

  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
