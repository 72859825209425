<template>
  <div class="register-wrapper">

    <b-form @submit="onSubmit" class="bootstrap-form">
      <label>Create new account</label>
      <b-form-input id="formEmail"
                    type="email"
                    v-model="form.email"
                    required
                    placeholder="Email">
      </b-form-input>

      <b-form-input id="formPassword"
                    type="password"
                    v-model="form.password"
                    required
                    placeholder="Password">
      </b-form-input>

      <b-form-input id="formFullname"
                    type="text"
                    v-model="form.fullname"
                    required
                    placeholder="Fullname">
      </b-form-input>
      <b-form-select id="formGender"
                     :options="gender"
                     required
                     v-model="form.gender">
      </b-form-select>

      <datepicker v-model="form.date"
                  :bootstrap-styling="true"
                  input-class="dateInput"
                  name="formDate"
                  :required="true"
                  placeholder="Bithdate">
      </datepicker>

      <b-form-input id="formZipCode"
                    type="text"
                    v-model="form.zipcode"
                    required
                    placeholder="Zip Code">
      </b-form-input>

      <b-button type="submit" variant="primary">Create Account</b-button>
    </b-form>

    <div class="login">
      <router-link to="/">Have an account? Sign In</router-link>
    </div>
  </div>
</template>


<script>
  import datepicker from 'vuejs-datepicker'

  export default {
    name: 'register',
    components: {
      datepicker
    },
    data () {
      return {
        form: {
          email: '',
          password: '',
          fullname: '',
          gender: null,
          date: null,
          zipcode: ''
        },
        gender: [
          { text: 'Select Gender', value: null },
          'Female', 'Male'
        ]
      }
    },
    methods: {
      onSubmit (evt) {
        evt.preventDefault()

        this.$http.post('/auth/register', {user: this.form})
          .then((res) => {
            console.log(res)
            if (res.success) {
              this.$notify({
                group: 'notifications',
                title: 'User Successfully Created :)',
                text: '',
                type: 'success'
              })
              this.$router.push('/')
            } else {
              this.$notify({
                group: 'notifications',
                title: res.message,
                text: '',
                type: 'error'
              })
            }
          })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .register-wrapper label{
    text-transform: uppercase;
    font-weight: 900
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus{
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .register-wrapper .login{
    text-align: center;
  }

  .register-wrapper .login a{
    color: #f2494a;
    font-size: 12px;
  }

</style>
